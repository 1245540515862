import * as types from "../types";

const initialState = {
  loading: false,
  postNtStatus:null,
  resNtverify: null,
  error: null,
};

export default function postNtTractorVerifyReducer(state = initialState, action) {
  switch (action.type) {
    case types.POST_NT_TRACTOR_ID_VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
        postNtStatus: action.payload.postNtStatus,
      };

    case types.POST_NT_TRACTOR_ID_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        resNtverify: action.payload.resNtverify,
      };

    case types.POST_NT_TRACTOR_ID_VERIFY_FAILURE:
      return {
        ...state,
        loading: false,
        postNtStatus:null,
        resNtverify: null,
        error: action.payload.error,
    };

    case types.POST_NT_TRACTOR_ID_VERIFY_RESET:
      return {
        ...state,
        loading: false,
        postNtStatus:null,
        resNtverify: null,
        error: null,
    };

    default:
      return state;
  }
}