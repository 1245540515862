import * as types from "../types";

export const additionalInfoQuestionRequest = ({reqAddInfoQues}) => ({
    type:types.FETCH_ADDITIONAL_INFO_QUESTION_REQUEST,
    payload:{
        reqAddInfoQues
    }
})

export const additionalInfoQuestionSuccess = ({resQuestion}) => ({
    type:types.FETCH_ADDITIONAL_INFO_QUESTION_SUCCESS,
    payload:{
        resQuestion
    }
})

export const additionalInfoQuestionFailure = ({error}) => ({
    type:types.FETCH_ADDITIONAL_INFO_QUESTION_FAILURE,
    payload:{
        error: error.message
    }
})
