import  { useState, useEffect, useRef } from "react";
import { base } from "../config/airtable.config";
import { useSelector } from "react-redux";

const useFetch = ({
  tableName,
  fields,
  filterByFormula = "",
  maxRecords = 1000,
  dependencyState = null,
}) => {
  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);
  const ref = useRef(false);
 // const { lang } = useContext(ProductContext);
  const languageObj = useSelector(state => state.languageReducer)
  const { languageCode } = languageObj;
  const lang = languageCode;

  useEffect(() => {
    setFetching(true);
    base(tableName)
      .select({
        maxRecords: 100,
        view: "Grid view",
        fields,
        filterByFormula,
        maxRecords,
      })
      .eachPage(
        function page(records, _fetchNextPage) {
          let recordsData = records.map((item) => {
            return { ...item.fields };
          });

          // console.log(recordsData);
          setData(recordsData);

          setError(false);
          setFetching(false);
        },
        function done(err) {
          setError(false);
          setData(null);
          setFetching(false);
        }
      );

  }, [dependencyState,lang]);
  return [data, fetching, error];
};

export default useFetch;