import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0, 0, 0, 0.5);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
`;

export const Head = styled.div`
  width: 100%;  
  height: 10%;
  padding: 10px 0 8px 0;
  .close-icon{
    color: #000;
    height:25px;
    width:25px;
    margin-left: 95%;
    cursor: pointer;
  }
  .close-icon:hover,
  .close-icon:focus{
    color:#000;
  }
`;

export const Main = styled.div`  
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

const fadeInDown = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    50% {
      opacity: 0.5;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
`;

const fadeOut = keyframes`
    0% {
      opacity: 0;
      transform: translateY(0);
    }
    50% {
      opacity: 0.5;
      transform: translateY(-20);
    }
    100% {
      opacity: 1;
      transform: translateY(-50px);
    }
`;

export const Modal = styled.div`
  width: 90%;
  max-width: 760px;
  border-radius: 5px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  animation: ${({ isOpen }) => (isOpen ? fadeInDown : fadeOut)} 0.4s forwards;

  @media (min-width: 760px){
    width: 40%;
  }

`;

export const Title = styled.p`
    font-size: 20px;
    color: #000;
`;

export const Image = styled.img`
    height: 80%;
    width: auto;
`;

export const CloseButton = styled.button``;