import * as types from "../types";

export const dynamicTableRequest = ({tableName,languageCode}) => ({
    type: types.FETCH_DYNAMICTABLE_REQUEST,
    payload : {
        tableName,
        languageCode
    }
})

export const dynamicTableSuccess = ({resDynamicData}) => ({
    type: types.FETCH_DYNAMICTABLE_SUCCESS,
    payload:{
        resDynamicData   
    }
})

export const dynamicTableFailure = ({error}) => ({
    type: types.FETCH_DYNAMICTABLE_FAILURE,
    payload: {
        error: error.message
    }
})