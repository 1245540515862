import React, { useEffect } from "react"; 
import Loader from "../../components/Loader/Loader";
import Section from "../../components/section";
import DynamicCarousel from "./dynamicCarousel";
import { useDispatch, useSelector } from "react-redux";
import { categoryRequest } from "../../store/actions/airtableCategoryAction";

export default function DynamicSections(){

    let table = "";
    const dispatch = useDispatch();
    const languageObj = useSelector(state => state.languageReducer);
    const { languageCode } = languageObj;
    const lang = languageCode.toUpperCase();
    const categoryObj = useSelector(state => state.airtableCategoryReducer);
    const { loading, resCategory } = categoryObj;
    const categoryFetchData =  resCategory;
    useEffect(() => {
        dispatch(categoryRequest());
    },[lang])    


    return (

        (loading ? 
            <Loader />
        :
        categoryFetchData?.map((item,index)=> {
            table = item.categoryID;
        return (
        (<Section id={item.categoryID} key={index}>
                <DynamicCarousel tableName={table} />
        </Section>)
        )}))
    )
}