import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/header";
import SectionHeader from "../../components/sectionheader";
import Gallery from "../../containers/gallery";
import PopupForm from "../../containers/modals/PopupForm";
import { ProductContext } from "../../utils/productContext";
import { useDispatch, useSelector } from "react-redux";
import { inventorySingleRequest } from "../../store/actions/inventorySingleAction";
import Loader from "../../components/Loader/Loader";
import { setLanguage } from "../../store/actions/languageAction";
import { usePost } from "../../utils/usePost";
import Swal from "sweetalert2";
import { useRef } from "react";
import {
  leadsSubmitRequest,
  leadsSubmitReset,
} from "../../store/actions/leadsSubmitAction";
import ProductDetailContainer from "../../components/productDetail";
import BuyCards from "../../components/buyCard";
import ShowMoreInfo from "../../containers/additionalinfo/showMoreInfo";
import { useTranslation } from "react-i18next";
import { convertToUppercase } from "../../utils/convertToLowerUppercase";
import { useChangLanguage } from "../../utils/useChangLanguage";

export default function CTInventoryDetails() {
  const { t: common } = useTranslation("common");
  const { t: successTrans } = useTranslation("success");

  const ref = useRef(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const queryState = location.state.product_data;
  const dispatch = useDispatch();
  const invSingleDataObj = useSelector((state) => state.inventorySingleReducer);
  const { loading, resSinleInv } = invSingleDataObj;
  const queryState = resSinleInv?.data;

  const languageObj = useSelector((state) => state.languageReducer);
  const leadsSubmitObj = useSelector((state) => state.leadsSubmitReducer);
  const { resLeads, error } = leadsSubmitObj;
  const { languageCode } = languageObj;

  const linkType = queryParams.get("linkType");
  const user_id = queryParams.get("id");
  const source = queryParams.get("source");
  const name = queryParams.get("name");
  const phone = queryParams.get("phone");
  const state = queryParams.get("state");
  const district = queryParams.get("district");
  const taluka = queryParams.get("taluka");
  const village = queryParams.get("village");
  const type = queryParams.get("type");
  const tractor_id = queryParams.get("tractor_id");
  const lang = convertToUppercase(queryParams.get("lang") || languageCode);
  const [handleChangeLanguage] = useChangLanguage();

  const navigate = useNavigate();

  const { show, setShow, setErrors } = useContext(ProductContext);

  const [postHandler, submitting, postError, success] = usePost();

  useEffect(() => {
    dispatch(inventorySingleRequest({ reqSinleInv: { tractor_id } }));
  }, [tractor_id]);

  useEffect(() => {
    if (lang && lang !== "") {
      handleChangeLanguage(lang);
      dispatch(setLanguage({ languageCode: lang }));
    }
  }, [lang]);

  useEffect(() => {
    if (resLeads) {
      Swal.fire({
        icon: "success",
        confirmButtonColor: "#652078",
        text: successTrans("success_thank_you_will_get_back_to_you"),
      }).then((res) => {
        if (res) {
          dispatch(leadsSubmitReset());
          if (user_id && user_id !== "") {
            navigate(
              `/ctInventory?linkType=${linkType}&id=${user_id}&lang=${lang}&source=${source}&name=${name}&phone=${phone}&state=${state}&district=${district}&taluka=${taluka}&village=${village}`
            );
          } else {
            navigate(
              `/ctInventory?linkType=${linkType}&lang=${lang}&source=${source}&name=${name}&phone=${phone}&state=${state}&district=${district}&taluka=${taluka}&village=${village}`
            );
          }
        }
      });
    }
  }, [resLeads]);

  const modalOpen = () => {
    if (linkType && linkType === "cointech") {
      let requestLeads = {
        user_id: queryState.user_id,
        l_source: "digital",
        phone: phone,
        name: name,
        sub_source: source,
        state: state,
        district: district,
        taluka: taluka && taluka !== "null" ? taluka : "",
        village: village && village !== "null" ? village : "",
        inventory_id: tractor_id,
      };
      dispatch(leadsSubmitRequest({ requestLeads }));

      postHandler({
        tableName: "Live Inventory Interested Details",
        fields: {
          Name: name,
          "Phone Number": phone,
          "Product id": tractor_id,
          source: source,
          state: state,
          district: district,
          taluka: taluka,
          village: village,
          Link_Type: linkType,
        },
      });
    } else {
      setShow((preShow) => !preShow);
      setErrors({});
    }
  };

  const backButton = () => {
    if (user_id && user_id !== "") {
      navigate(
        `/ctInventory?linkType=${linkType}&id=${user_id}&lang=${lang}&source=${source}&name=${name}&phone=${phone}&state=${state}&district=${district}&taluka=${taluka}&village=${village}`
      );
    } else {
      navigate(
        `/ctInventory?linkType=${linkType}&lang=${lang}&source=${source}&name=${name}&phone=${phone}&state=${state}&district=${district}&taluka=${taluka}&village=${village}`
      );
    }
  };

  return (
    <div>
      <Header>
        <Header.BackButtonContainer>
          <Header.BackIcon
            src="../assets/arrow_back.svg"
            alt=""
            onClick={backButton}
          />
          <Header.ButtonText>{common("back_to_home")}</Header.ButtonText>
        </Header.BackButtonContainer>
      </Header>
      {loading ? (
        <Loader />
      ) : queryState &&
        typeof queryState === "object" &&
        Object.keys(queryState).length > 0 ? (
        <>
          <ProductDetailContainer>
            {typeof queryState[`image_links`] === "object" &&
              Object.keys(queryState[`image_links`])?.length > 0 && (
                <Gallery
                  type={type}
                  tractor_id={tractor_id}
                  productFilterdata={queryState}
                  lang={lang}
                  district={district}
                />
              )}
            <SectionHeader>
              <SectionHeader.Title style={{ marginBottom: "5px" }}>
                {queryState[`brand`]} {queryState[`model`]}
                {queryState?.["rating"] > 0 && (
                  <BuyCards.Star
                    src={`../assets/staricon/${queryState?.["rating"]} star.svg`}
                    alt={`${queryState?.["rating"]} star`}
                  />
                )}
              </SectionHeader.Title>
            </SectionHeader>
            {queryState.user_location && (
              <ProductDetailContainer.LocationLabel>
                <ProductDetailContainer.LocationText>
                  <ProductDetailContainer.LocationIcon
                    src="../assets/location_icon.svg"
                    alt="location icon"
                  />{" "}
                  {queryState.user_location}
                  {queryState.state !== "" && `, ${queryState.state}`}
                </ProductDetailContainer.LocationText>
              </ProductDetailContainer.LocationLabel>
            )}
            <ProductDetailContainer.ProductContents>
              {/* <ProductDetailContainer.BrandText>Brand: {queryState[`brand`]}</ProductDetailContainer.BrandText> */}
              <ProductDetailContainer.PriceText>
                ₹ {queryState["max_price"]}
              </ProductDetailContainer.PriceText>
              <ProductDetailContainer.FeatureContainer>
                <ProductDetailContainer.FeatureDetails>
                  <ProductDetailContainer.FeatureIcon
                    src="../assets/certify/battery.svg"
                    alt=""
                  />
                  {queryState[`is_battery_branded`] && (
                    <ProductDetailContainer.FeatureText1>
                      {queryState[`is_battery_branded`] ? "Branded" : ""}
                    </ProductDetailContainer.FeatureText1>
                  )}
                  <ProductDetailContainer.FeatureText2>
                    {queryState[`battery`] ? "Available" : "Not Available"}
                  </ProductDetailContainer.FeatureText2>
                  <ProductDetailContainer.FeatureLabel>
                    Battery
                  </ProductDetailContainer.FeatureLabel>
                </ProductDetailContainer.FeatureDetails>
                <ProductDetailContainer.FeatureDetails>
                  <ProductDetailContainer.FeatureIcon
                    src="../assets/certify/buying_year.svg"
                    alt=""
                  />
                  <ProductDetailContainer.FeatureText2>
                    <b>{queryState[`buying_year`]}</b>
                  </ProductDetailContainer.FeatureText2>
                  <ProductDetailContainer.FeatureLabel>
                    Year
                  </ProductDetailContainer.FeatureLabel>
                </ProductDetailContainer.FeatureDetails>
                <ProductDetailContainer.FeatureDetails>
                  <ProductDetailContainer.FeatureIcon
                    src="../assets/certify/engine_hours.svg"
                    alt=""
                  />
                  <ProductDetailContainer.FeatureText2>
                    {queryState[`engine_hours`] === -1
                      ? "NA"
                      : `${queryState[`engine_hours`]} Hrs`}
                  </ProductDetailContainer.FeatureText2>
                  {queryState[`engine_condition`] && (
                    <ProductDetailContainer.FeatureLabel>
                      {queryState[`engine_condition`]}
                    </ProductDetailContainer.FeatureLabel>
                  )}
                </ProductDetailContainer.FeatureDetails>
                <ProductDetailContainer.FeatureDetails>
                  <ProductDetailContainer.FeatureIcon
                    src="../assets/certify/engine_power.svg"
                    alt=""
                  />
                  <ProductDetailContainer.FeatureText2>
                    {queryState[`engine_power`] === -1
                      ? "NA"
                      : queryState[`engine_power`]}
                  </ProductDetailContainer.FeatureText2>
                  <ProductDetailContainer.FeatureLabel>
                    Engine Power
                  </ProductDetailContainer.FeatureLabel>
                </ProductDetailContainer.FeatureDetails>
                <ProductDetailContainer.FeatureDetails>
                  <ProductDetailContainer.FeatureIcon
                    src="../assets/certify/tyre_condition.svg"
                    alt=""
                  />
                  <ProductDetailContainer.FeatureText2>
                    {queryState[`tyre_condition`] === "Original"
                      ? queryState[`is_tyre_brand_mrf`]
                        ? "MRF Original"
                        : "Original"
                      : queryState[`tyre_condition`]}
                  </ProductDetailContainer.FeatureText2>
                  <ProductDetailContainer.FeatureLabel>
                    {queryState[`tyre_condition`] === "Original"
                      ? queryState[`tyre_state`]
                      : "Tyre Condition"}
                  </ProductDetailContainer.FeatureLabel>
                </ProductDetailContainer.FeatureDetails>
                <ProductDetailContainer.FeatureDetails>
                  <ProductDetailContainer.FeatureIcon
                    src="../assets/certify/Finance.svg"
                    alt=""
                  />
                  <ProductDetailContainer.FeatureText2>
                    {queryState[`finance`] === "0%"
                      ? "NA"
                      : `Upto* ${queryState[`finance`]}`}
                  </ProductDetailContainer.FeatureText2>
                  <ProductDetailContainer.FeatureLabel>
                    Finance
                  </ProductDetailContainer.FeatureLabel>
                </ProductDetailContainer.FeatureDetails>
              </ProductDetailContainer.FeatureContainer>
            </ProductDetailContainer.ProductContents>
          </ProductDetailContainer>
          {queryState?.additional_data_available && (
            <ShowMoreInfo
              queryState={queryState?.additional_featureInfo}
              lang={lang}
            />
          )}

          <div style={{ width: "100%", height: "100px" }}></div>
          <ProductDetailContainer.ButtonContainer>
            <ProductDetailContainer.InterestedButton onClick={modalOpen}>
              {common("i_am_interested")}
            </ProductDetailContainer.InterestedButton>
          </ProductDetailContainer.ButtonContainer>
        </>
      ) : (
        <p>No Data Found</p>
      )}

      {show && <PopupForm tractor_id={tractor_id} />}
    </div>
  );
}
