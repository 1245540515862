import * as types from "../types";

export const inventorySingleRequest = ({reqSinleInv}) => ({
    type: types.FETCH_INVENTORY_SINGLE_DATA_REQUEST,
    payload: {
        reqSinleInv
    }
})

export const inventorySingleSuccess = ({resSinleInv}) => ({
    type: types.FETCH_INVENTORY_SINGLE_DATA_SUCCESS,
    payload: {
        resSinleInv
    }
})

export const inventorySingleFailure = ({error}) => ({
    type: types.FETCH_INVENTORY_SINGLE_DATA_FAILURE,
    payload: {
        error: error.message
    }
})