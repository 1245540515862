import React from "react";
import { Container, SearchBy, ListTitle, Cards, Card, CardDetails, Header,CardLabel, RTONumber, CardMain, CardTitle, ChassisNumber, PriceContainer, Price, PriceLabel, Bottom, Time, Button, Pagination, PageCountContainer, PageButton, PageCountLabel } from "./styles/expensecard";

export default function Expenses({children, ...props}){
    return <Container>{children}</Container>
}

Expenses.SearchBy = function ExpensesSearchBy({children, ...props}){
    return <SearchBy {...props}>{children}</SearchBy>
}

Expenses.ListTitle = function ExpensesListTitle({children,...props}){
    return <ListTitle {...props}>{children}</ListTitle>
}

Expenses.Cards = function ExpensesCards({children, ...props}){
    return <Cards {...props}>{children}</Cards>
}

Expenses.Card = function ExpensesCard({children, ...props}){
    return <Card {...props}>{children}</Card>
}

Expenses.CardDetails = function ExpensesCardDetails({children, ...props}){
    return <CardDetails {...props}>{children}</CardDetails>
}

Expenses.Header = function ExpensesHeader({children, ...props}){
    return <Header {...props}>{children}</Header>
}

Expenses.CardLabel = function ExpensesCardLabel({children, ...props}){
    return <CardLabel {...props}>{children}</CardLabel>
}

Expenses.RTONumber = function ExpensesRTONumber({children, ...props}){
    return <RTONumber {...props}>{children}</RTONumber>
}

Expenses.CardMain = function ExpensesCardMain({children, ...props}){
    return <CardMain {...props}>{children}</CardMain>
}

Expenses.CardTitle = function ExpensesCardTitle({children, ...props}){
    return <CardTitle {...props}>{children}</CardTitle>
}

Expenses.ChassisNumber = function ExpensesChassisNumber({children, ...props}){
    return <ChassisNumber {...props}>{children}</ChassisNumber>
}

Expenses.PriceContainer = function ExpensesPriceContainer({children, ...props}){
    return <PriceContainer {...props}>{children}</PriceContainer>
}

Expenses.PriceLabel = function ExpensesPriceLabel({children, ...props}){
    return <PriceLabel {...props}>{children}</PriceLabel>
}

Expenses.Price = function ExpensesPrice({children, ...props}){
    return <Price {...props}>{children}</Price>
}

Expenses.Bottom = function ExpensesBottom({children, ...props}){
    return <Bottom {...props}>{children}</Bottom>
}

Expenses.Time = function ExpensesTime({children, ...props}){
    return <Time {...props}>{children}</Time>
}

Expenses.Button = function ExpensesButton({children,  ...props}){
    return <Button {...props}>{children}</Button>
}

Expenses.Pagination = function ExpensesPagination({children,...props}){
    return <Pagination {...props}>{children}</Pagination>
}

Expenses.PageCountContainer = function ExpensesPageCountContainer({children,...props}){
    return <PageCountContainer {...props}>{children}</PageCountContainer>
}

Expenses.PageButton = function ExpensesPageButton({children,...props}){
    return <PageButton {...props}>{children}</PageButton>
}

Expenses.PageCountLabel = function ExpensesPageCountLabel({children,...props}){
    return <PageCountLabel {...props}>{children}</PageCountLabel>
}
