import * as types from "../types";

const initialState = {
  loading: false,
  requestPrice: null,
  resPrice: null,
  error: null,
};

export default function fetchPricingReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PRICING_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        requestPrice: action.payload.requestPrice,
      };

    case types.FETCH_PRICING_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        resPrice: action.payload.resPrice,
      };

    case types.FETCH_PRICING_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        requestPrice: null,
        resPrice: null,
        error: action.payload.error,
      };

    case types.FETCH_PRICING_DATA_RESET:
      return {
        ...state,
        loading: false,
        requestPrice: null,
        resPrice: null,
        error: null,
      };    

    default:
      return state;
  }
}