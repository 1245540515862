import React, { useContext, useEffect, useState } from "react";
import BuyCards from "../components/buyCard";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/header";
import Loader from "../components/Loader/Loader";
import InventoryFilter from "./filter/inventoryFIlter";
import { ProductContext } from "../utils/productContext";
import InventorySorting from "./filter/inventorySorting";
import { useDispatch, useSelector } from "react-redux";
import { inventoryAllRequest } from "../store/actions/inventoryAllAction";
import { setLanguage } from "../store/actions/languageAction";
import { useTranslation } from "react-i18next";
import { useChangLanguage } from "../utils/useChangLanguage";
import { convertToUppercase } from "../utils/convertToLowerUppercase";
import LoadMoreData from "./loadMore";
import ViewAllCard from "./tractorCardView/viewAllCard";

export default function ViewAllProduct() {
  const { t: common } = useTranslation("common");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let navigate = useNavigate();
  const [dataArray, setDataArray] = useState([]);
  const dispatch = useDispatch();
  const inventoryObj = useSelector((state) => state.inventoryAllReducer);
  const { loading, resInvAll } = inventoryObj;
  let queryState = resInvAll?.data;
   // console.log("queryState",[...queryState?.data])
  const {
    show,
    setShow,
    sortShow,
    setSortShow,
  } = useContext(ProductContext);

  const [handleChangeLanguage] = useChangLanguage();
  const languageObj = useSelector((state) => state.languageReducer);
  const { languageCode } = languageObj;
  const lang = convertToUppercase(queryParams.get("lang") || languageCode);
  const sortReducerObj = useSelector((state) => state.invSortReducer);
  let { sortObj, sorttedArray } = sortReducerObj;
  const filterReducerObj = useSelector((state) => state.invFilterReducer);
  let { filterObj, filteredArray } = filterReducerObj;

  useEffect(() => {
    // dispatch(inventoryRequest());
    dispatch(inventoryAllRequest());
  }, []);

  useEffect(() => {
    if (lang && lang !== "") {
      handleChangeLanguage(lang);
      dispatch(setLanguage({ languageCode: lang }));
    }
  }, [lang]);

  useEffect(() => {
    if (filteredArray.length > 0) {
        setDataArray(filteredArray);
    } else if (filteredArray.length === 0 && sorttedArray.length > 0) {
      setDataArray(sorttedArray);
    } else if (filteredArray.length > 0 && sorttedArray.length === 0) {
      setDataArray(filteredArray);
    } else { 
      setDataArray(queryState?.filter((item) => isStatus(item.status))?.sort((a, b) => b?.tractor_id - a?.tractor_id));
    }
  }, [filteredArray, sorttedArray, queryState]);

  const modalOpen = () => {
    setShow((preShow) => !preShow);
  };

  const sortModalOpen = () => {
    setSortShow((preShow) => !preShow);
  };

  const handleNav = (tractor_id, productjson, prodType) => {
    //navigate(`/productDetails?type=${prodType}&tractor_id=${tractor_id}`)
    navigate(
      `/productDetails?lang=${lang}&type=${prodType}&pagetype=viewall&tractor_id=${tractor_id}`,
      { state: { product_data: productjson, inv_data: queryState } }
    );
  };

  // console.log("productData",tableName,productData);
  const backButton = () => {
    navigate(`/`);
  };

  const isStatus = (status) => [1, 2, 3].includes(status);
  const filterMessage = (value) => {
    return value?.length > 0
      ? `After filter Showing ${value?.length} ${
          value?.length < 2 ? "tractor" : "tractors"
        }.`
      : `Showing all ${
          dataArray?.filter((item) => isStatus(item.status) && item)?.length
        } tractors.`;
  };

  const sortMessage = (value) => {
    return (
      sortObj.sort_by !== "" &&
      `Sort by ${sortObj?.sort_by?.split("_").join(" ")} ${
        filteredArray.length > 0
          ? filteredArray.length
          : value?.filter((item) => isStatus(item.status) && item)?.length
      } tractors.`
    );
  };

  const sortTractorIddesOrder = (value) => {
    return (filteredArray.length > 0 && sorttedArray.length === 0) ? value?.sort((a, b) => b?.tractor_id - a?.tractor_id) : value
  }

  return (
    <>
      <Header>
        <Header.BackButtonContainer>
          <Header.BackIcon
            src="../assets/arrow_back.svg"
            alt=""
            onClick={backButton}
          />
          <Header.ButtonText>{common("back_to_home")}</Header.ButtonText>
        </Header.BackButtonContainer>
      </Header>
      <BuyCards.FilterContainer>
        <BuyCards.FilterButton onClick={sortModalOpen}>
          <BuyCards.ButtonIcon
            src="./assets/sort_black_24dp.svg"
            alt="Sort icon"
          />
          {common("sort_by")}
        </BuyCards.FilterButton>
        <BuyCards.FilterButton style={{ color: "#F26F21" }} onClick={modalOpen}>
          <BuyCards.ButtonIcon
            src="./assets/filter_list_black.svg"
            alt="filter icon"
          />
          {common("filter")}
        </BuyCards.FilterButton>
      </BuyCards.FilterContainer>
      <BuyCards.FilterContent>
        <BuyCards.Message>{filterMessage(filteredArray)}</BuyCards.Message>
      </BuyCards.FilterContent>
      {sortObj.sort_by !== "" && sorttedArray.length > 0 && (
        <BuyCards.FilterContent>
          <BuyCards.Message>{sortMessage(sorttedArray)}</BuyCards.Message>
        </BuyCards.FilterContent>
      )}
      {loading && dataArray?.length === 0 ? (
        <Loader />
      ) : (
        <LoadMoreData component={<ViewAllCard />} dataArray={sortTractorIddesOrder(dataArray)}  incrementValue={20} isStatus={isStatus} handleNav={handleNav} />
      )}
      {show && (
        <InventoryFilter dataArray={queryState} />
      )}
      {sortShow && (
        <InventorySorting dataArray={dataArray} />
      )}
    </>
  );
}
