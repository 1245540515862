import * as types from "../types"

export const inventoryRequest = ({reqInv}) => ({
    type: types.FETCH_DEALER_INVENTORY_DATA_REQUEST,
    payload:{
        reqInv
    }
})

export const inventorySuccess = ({resInvData}) =>({
    type: types.FETCH_DEALER_INVENTORY_DATA_SUCCESS,
    payload:{
        resInvData
    }
})

export const expensePagePosition = ({currentPagePosition}) => ({
    type: types.EXPENSE_PAGE_POSITION,
    payload:{
        currentPagePosition
    }
});

export const inventoryFailure = ({error}) => ({
    type: types.FETCH_DEALER_INVENTORY_DATA_FAILURE,
    payload: {
        error: error.message
    }
})

export const inventoryReset = () => ({
    type: types.FETCH_DEALER_INVENTORY_DATA_RESET,
  });

