import * as types from "../types";

export const buySellFinancelistRequest = ({key}) => ({
    type: types.FETCH_BUYSELL_FINANCELIST_REQUEST,
    payload:{
        key
    }
});

export const buySellFinancelistSuccess = ({resFinList}) => ({
    type: types.FETCH_BUYSELL_FINANCELIST_SUCCESS,
    payload:{
        resFinList
    }
});

export const buySellFinancelistFailure = ({error}) => ({
    type: types.FETCH_BUYSELL_FINANCELIST_FAILURE,
    payload:{
        error: error.message
    }
})

export const buySellFinancelistReset = () => ({
    type: types.FETCH_BUYSELL_FINANCELIST_RESET
})
