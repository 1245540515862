import * as types from "../types";

const initialState = {
    loading: true,
    resUserLocation: null,
    error: null
}

export default function userLocationReducer(state = initialState, action){

    switch(action.type){
        case types.FETCH_USER_LOCATION_DATA_REQUEST:
            return{
                ...state,
                loading: true
            }
        
        case types.FETCH_USER_LOCATION_DATA_SUCCESS:
            return{
                ...state,
                loading: false,
                resUserLocation: action.payload.resUserLocation
            }

        case types.FETCH_USER_LOCATION_DATA_FAILURE:
            return{
                ...state,
                loading: false,
                resUserLocation: null,
                error: action.payload.error
            }

        default: 
        return state

    }
    
}