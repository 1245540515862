import * as types from "../types";

export const leadsSubmitRequest = ({requestLeads}) => ({
  type: types.LEADS_SUBMIT_REQUEST,
  payload:{requestLeads}
});

export const leadsSubmitSuccess = ({ resLeads }) => ({
  type: types.LEADS_SUBMIT_SUCCESS,
  payload: {
    resLeads,
  },
});

export const leadsSubmitFailure = ({ error }) => ({
  type: types.LEADS_SUBMIT_FAILURE,
  payload: {
    error: error.message,
  },
});

export const leadsSubmitReset = () => ({
  type: types.LEADS_SUBMIT_RESET,
});