import React from "react";
import BuyCards from "../../components/buyCard";
import urlContain from "../../utils/urlContain";
import { useTranslation } from "react-i18next";

export default function ViewAllCard({...props}) {
    const { t: common } = useTranslation("common");

  return (
    <BuyCards>
      {props?.dataFromParent?.initialPost?.slice(0, props?.dataFromParent?.visibleCards)?.map(
        (item, index) =>
        props?.dataFromParent?.isStatus(item.status) && (
            <BuyCards.Card
              key={index}
              onClick={() => props?.dataFromParent?.handleNav(item[`tractor_id`], item, "inventory")}
            >
              {typeof item[`image_links`] === "object" &&
              Object.keys(item[`image_links`])?.length > 0 ? (
                <BuyCards.Image
                  src={urlContain(item[`image_links`]?.[0]?.["image"])}
                  alt=""
                  loading="lazy"
                />
              ) : (
                <BuyCards.Image
                  src="../assets/no_image_available.png"
                  alt=""
                  loading="lazy"
                />
              )}
              <BuyCards.InfoContainer>
                <BuyCards.Title>
                  {item["brand"]}&nbsp;{item["model"]}
                </BuyCards.Title>
                {/* <BuyCards.InfoText>Brand: {item["brand"]}
            </BuyCards.InfoText> */}
                <BuyCards.Price>
                  <b>₹ {item["max_price"]}</b>
                  {item["rating"] > 0 && (
                    <BuyCards.Star
                      src={`../assets/staricon/${item["rating"]} star.svg`}
                      alt={`${item["rating"]} star`}
                    />
                  )}
                </BuyCards.Price>
                <BuyCards.SpecificationContainer>
                  <BuyCards.Specification>
                    <BuyCards.Icon
                      src="./assets/certify/engine_hours.svg"
                      alt=""
                    />
                    <BuyCards.SpecificationValue>
                      <BuyCards.SpecificationLabel>
                        Hrs
                      </BuyCards.SpecificationLabel>
                      {item[`engine_hours`] === -1
                        ? "NA"
                        : `${item[`engine_hours`]}`}
                    </BuyCards.SpecificationValue>
                  </BuyCards.Specification>
                  <BuyCards.Specification>
                    <BuyCards.Icon
                      src="./assets/certify/engine_power.svg"
                      alt=""
                    />
                    <BuyCards.SpecificationValue>
                      <BuyCards.SpecificationLabel>
                        Power
                      </BuyCards.SpecificationLabel>
                      {item[`engine_power`] === -1
                        ? "NA"
                        : `${item[`engine_power`]}`}
                    </BuyCards.SpecificationValue>
                  </BuyCards.Specification>
                  <BuyCards.Specification>
                    <BuyCards.Icon
                      src="./assets/certify/tyre_condition.svg"
                      alt=""
                    />
                    <BuyCards.SpecificationValue>
                      <BuyCards.SpecificationLabel>
                        Tyre
                      </BuyCards.SpecificationLabel>
                      {item[`tyre_condition`] === "Original"
                        ? "Original"
                        : item[`tyre_condition`]}
                    </BuyCards.SpecificationValue>
                  </BuyCards.Specification>
                </BuyCards.SpecificationContainer>
                <BuyCards.ButtonContainer>
                  <BuyCards.RTONumber>
                  {
                    item?.additional_featureInfo?.["Registration (RTO) No."] ||
                    item?.additional_featureInfo?.["Registration (RTO) No"]
                  }
                  </BuyCards.RTONumber>
                  <BuyCards.Button>
                    {common("see_details")}{" "}
                    <img
                      src="./assets/arrow/right_arrow_black.svg"
                      alt="right arrow"
                    />
                  </BuyCards.Button>
                </BuyCards.ButtonContainer>
              </BuyCards.InfoContainer>
            </BuyCards.Card>
          )
      )}
    </BuyCards>
  );
}
