import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BottomHighlightLine from "../../components/bottomhighlight";
import Loader from "../../components/Loader/Loader";
import Poster from "../../components/poster";
import SectionHeader from "../../components/sectionheader";
import VideoCard from "../../components/videocard";
import useGetVideoData from "../../utils/useGetVideoData";
import { useDispatch, useSelector } from "react-redux";
import { dynamicTableRequest } from "../../store/actions/airtableDynamicTableAction";
import { desktopView, mobileView } from "../viewType";

export default function DynamicCarousel({ tableName }) {
  const dispatch = useDispatch();
  const languageObj = useSelector((state) => state.languageReducer);
  const dynamicTableDataObj = useSelector(
    (state) => state.airtableDynamicTableReducer
  );
  const { resDynamicData } = dynamicTableDataObj;
  const { languageCode } = languageObj;
  const lang = languageCode.toUpperCase();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(dynamicTableRequest({ tableName, languageCode: lang }));
  }, [tableName]);

  const [videoData, loading] = useGetVideoData({
    urlData: resDynamicData,
    type: tableName,
  });

  const link = (e, tableName) => {
    navigate(`/video?type=${tableName}`, { state: videoData });
  };

  return dynamicTableDataObj.loading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader />
    </div>
  ) : (
    resDynamicData?.filter(
      (filterItem) =>
        (filterItem[`yt_links`] || filterItem[`banner(${lang})`]) && filterItem
    ).length > 0 && (
      <>
        <SectionHeader>
          <SectionHeader.Title>
            {resDynamicData[0]?.[`Name(${lang})`]}
          </SectionHeader.Title>
          <BottomHighlightLine></BottomHighlightLine>
        </SectionHeader>
        <div style={{ display: "flex", overflow: "scroll" }}>
          {videoData?.map(
            (proItem, index) =>
              proItem.thumbnail_url && (
                <VideoCard key={index}>
                  {/* {`./video?type=${tableName}`} */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={(e) => link(e, tableName)}
                  >
                    <VideoCard.PlayIcon src="./assets/videoplay.png" />
                    <VideoCard.ThumbNail src={proItem.thumbnail_url} />
                  </div>
                </VideoCard>
              )
          )}
          {
            <Poster.GridContainer>
              {resDynamicData?.map((item, index) => {
                if (
                  item.hasOwnProperty(`Desktop_Banner(${lang})`) &&
                  desktopView
                ) {
                  return (
                    <Poster.CardImage
                      key={index}
                      src={item?.[`Desktop_Banner(${lang})`][0].url}
                    />
                  );
                }
                if (item.hasOwnProperty(`banner(${lang})`) && mobileView) {
                  return (
                    <Poster.CardImage
                      key={index}
                      src={item?.[`banner(${lang})`][0].url}
                    />
                  );
                }
              })}
            </Poster.GridContainer>
          }
        </div>
      </>
    )
  );
}
