import React, { useEffect } from "react";
import HeaderContainer from "../containers/header";
import DynamicSections from "../containers/homeContainers/dynamicSections";
import OfferSection from "../containers/homeContainers/offerSection";
import PosterContainer from "../containers/homeContainers/poster";
import ProductCardSection from "../containers/homeContainers/productCardSection";


export default function Home() {
//  const [location,setLocation] = useState({});
//  const [address, setAddress] = useState('');

  // useEffect(() =>{
  //   const getUserLocation = () => {
  //       geolocation.getCurrentPosition((err, position) => {
  //           if (err) {
  //             console.log(err);
  //           } else {
  //             const { latitude, longitude } = position.coords;
  //             console.log("location",latitude, longitude);
  //             setLocation({ latitude, longitude })
  //           }
  //         });
  //   }
  //   getUserLocation()
  // },[])

  // useEffect(() => {
  //   // Set your Geocoding API key here if using a third-party service
  //   // Geocode.setApiKey('YOUR_API_KEY');
  //   const {latitude, longitude } = location;
  //   Geocode.fromLatLng(latitude, longitude)
  //     .then((response) => {
  //       console.log("response",response)
  //       const address = response.results[0].formatted_address;
  //       setAddress(address);
  //     })
  //     .catch((error) => {
  //       console.log('Error fetching location: ', error);
  //     });
  // }, [location]);

  //console.log("location",location,address);
    useEffect(() => {
      if(localStorage.getItem("ViewAllCard") && !isNaN(Number(localStorage.getItem("ViewAllCard")))){
        const timerId = setTimeout(function() {
          localStorage.removeItem("ViewAllCard");
        }, 3600000 * 6);

        return () => {
          clearTimeout(timerId);
        };

      }
    },[])

  return (
    <>
      <HeaderContainer />
      <PosterContainer />
      <OfferSection />
      <div style={{marginTop:'10px'}}>
      <ProductCardSection />
      </div>
      <DynamicSections />
    </>
  );
}