import React, { useEffect, useState } from "react";
import CollapseContainer from "../components/collapseContent";
import Loader from "../components/Loader/Loader";
import useFetch from "../utils/useFetch";
import { useFetchBE } from "../utils/useFetchBE";
import { useQuery } from "../utils/useQuery";
import AccordianMoreinformation from "./accordianMoreinformation";
import { API_BASE_URL, API_ROUTES } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../store/actions/languageAction";
import { useTranslation } from "react-i18next";
import { useChangLanguage } from "../utils/useChangLanguage";

export default function AdditionalInformation({ queryState }) {

  const { t:common } = useTranslation("common");
  const [showMore, setShowMore] = useState(false);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const languageObj = useSelector((state) => state.languageReducer);
  const { languageCode } = languageObj;
  const queryParams = useQuery();
  const product_id = queryParams.get("tractor_id");
  const brand = queryParams.get("brand");
  const model = queryParams.get("model");
  const lang = languageCode;

  const [ handleChangeLanguage ] = useChangLanguage();

  useEffect(() => {
      if(lang && lang !== "") {
        handleChangeLanguage(lang)
        dispatch(setLanguage({languageCode:lang}))
      }
  },[lang])


  const [dataBE, fetchdataBE, fetchingBE, statusBE] = useFetchBE({
    url: `${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_ADDITIONAL_DATA_ROUTE}${product_id}`,
  });

  const [categoryData, categoryFetching, categoryError] = useFetch({
    tableName: "Information_Category",
    fields: [`Category`, `Category(EN)`, `Category(${lang})`, `CategoryID`],
    filterByFormula: "",
  });

  useEffect(() => {
    setData(dataBE?.data?.additional_featureInfo);
  }, [dataBE, fetchdataBE]);

  let changeButton = "";
  let arrow = "";
  if (showMore) {
    changeButton = common("see_less_information");
    arrow = "arrow_up.svg";
  } else {
    changeButton = common("see_more_information");
    arrow = "arrow_down.svg";
  }

  return brand && model ? (
    fetchingBE ? (
      <Loader />
    ) : (
      data && (
        <CollapseContainer>
          <CollapseContainer.Content>
            <span className="more_info">More Information</span>
            {(brand || model) && (
              <p className="brand_model">
                {brand} {model}
              </p>
            )}
            {categoryData?.map((item, index) => (
              <AccordianMoreinformation
                data={data}
                androidApp={true}
                tableName={item[`Category(EN)`]}
                title={item[`Category(EN)`]}
                lang="EN"
                key={index}
              />
            ))}
          </CollapseContainer.Content>
        </CollapseContainer>
      )
    )
  ) : fetchingBE ? (
    <Loader />
  ) : (
    data && (
      <CollapseContainer>
        {showMore && (
          <CollapseContainer.Content>
            <span className="more_info">
              {common("more_information")}
            </span>
            {queryState && (
              <p className="brand_model">
                {queryState?.[`brand`]} {queryState?.[`model`]}
              </p>
            )}
            {categoryData?.map((item, index) => (
              <AccordianMoreinformation
                data={data}
                androidApp={false}
                tableName={item[`Category(EN)`]}
                title={item[`Category(${lang})`]}
                lang={lang}
                key={index}
              />
            ))}
          </CollapseContainer.Content>
        )}
        <CollapseContainer.TitleContainer
          onClick={() => setShowMore(!showMore)}
        >
          <CollapseContainer.Text>
            {changeButton} <img src={`./assets/arrow/${arrow}`} alt="" />
          </CollapseContainer.Text>
        </CollapseContainer.TitleContainer>
      </CollapseContainer>
    )
  );
}
