import React, { useState } from "react";
import FaqContainer from "../containers/faq/faqContainer";
import FaqModal from "../containers/modals/faqModal";
import Header from "../components/header";
import { AiOutlineGlobal } from "react-icons/ai";
import Form from "../components/form";
import { useDispatch, useSelector } from "react-redux";
import { setFaqLanguage } from "../store/actions/faqLanguageAction";

export default function FaqPage() {
	const [modalopen, setModalOpen] = useState(false);
	const [questionName, setQuestionName] = useState("");
	const [faqId, setFaqId] = useState("");
	const [showDropdown, setShowDropdown] = useState(false);
	const dispatch = useDispatch();
	const faqLangObj = useSelector(state => state.faqLanguageReducer);
	const { languageCode } = faqLangObj;

	const handleLanguageChange = (selectedValue) => {
		dispatch(setFaqLanguage({languageCode:selectedValue}));
	  setShowDropdown(false);
	  // Add logic here to handle language change, e.g., update translations, switch logos, etc.
	};
  
	const toggleDropdown = () => {
	  setShowDropdown(!showDropdown);
	};

	const langDrodown = [
		{ value: "EN", label: "English" },
		{ value: "HI", label: "Hindi" },
	];

	return (
		<>
			<Header>
				<Header.BackButtonContainer>
					&nbsp;&nbsp;&nbsp;&nbsp;FAQ
				</Header.BackButtonContainer>
				<div style={{ width: "80%" }}></div>
        {!showDropdown && <p
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "15px",
            padding: "5px",
            gap: "2px",
          }}
          onClick={toggleDropdown}
        >
			<AiOutlineGlobal style={{height:'25px', width:'25px'}} />{languageCode}
        </p>}

        {showDropdown && (
          <Form.Dropdown
            name="language"
            id="language"
            options={langDrodown}
            value={languageCode}
            onChange={(e) => handleLanguageChange(e.target.value)}
            style={{ width: "100px" }}
          ></Form.Dropdown>
        )}
			</Header>
			<FaqContainer
				setModalOpen={setModalOpen}
				setFaqId={setFaqId}
				setQuestionName={setQuestionName}
			/>
			{modalopen && (
				<FaqModal
					isOpen={modalopen}
					setModalOpen={setModalOpen}
					faqId={faqId}
					questionName={questionName}
				/>
			)}
		</>
	);
}
