import React, {  useEffect, useRef, useState } from "react";
import { ref, onValue } from "firebase/database";
import db from "../config/firebase.prod";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrandModelRequest } from "../store/actions/fetchBrandModelAction";

const ProductContext = React.createContext({});

function ProductContextProvider({ children }) {
  const [ytVideoData, setYtVideoData] = useState([]);
  const [onIndex, setOnIndex] = useState("");
  const [totalQuestions, setTotalQuestions] = useState({});
  const [tempJSON, setTempJSON] = useState({});
  const [show, setShow] = useState(false);
  const [sortShow, setSortShow] = useState(false);
  const [loadCheck, setLoadCheck] =  useState(true);
  const [errors, setErrors] = useState({});
  const [brandModel,setBrandModel] = useState({});
  const reference = useRef(false);
  const [afterFilter,setAfterFilter]=useState([]);
  const [afterSort,setAfterSort]=useState([]);
  const [filter,setFilter] = useState([])
  const [lang,setLang] = useState('MR');
  const [filterData,setFilterData] = useState({
    brand:'',
    model:'',
    price_range:'',
    state: 'all',
    location:'all',
    category:NaN   
  });
  const [invSortData,setInvSortData] = useState({
    sort_by:'',
  });
  const dispatch = useDispatch();
  const brandModelObj = useSelector(state => state.fetchBrandModelReducer);
  const { resBrandModel } = brandModelObj;


  useEffect(() => { 
    if(reference.current) return;
    dispatch(fetchBrandModelRequest());
    reference.current = true;
//     if(reference.current) return;
//           const brandVar = [];
//           const modelVar = [];
//   const query = ref(db, "tractor_v2");
//   //console.log("query",query)
//   onValue(query, (snapshot) => {
//     const data = snapshot.val();
//     if (snapshot.exists()) {
//       Object.values(data).map((project) => {
//        // console.log("project",project);
//        setBrandModel((brandModel) => [...brandModel, project]);
//         brandVar.push({
//           value: project.name,
//           label: project.name,
//         })
//       });
// //      brandVar.unshift({ value: "", label: "Select brand" });
// //      setBrand(brandVar);
//     }
//   })
//   reference.current = true;
  },[]);

  useEffect(() => {
    if(resBrandModel?.status){
      setBrandModel(resBrandModel?.data)
    }
  },[resBrandModel?.data])

//  console.log("brandModel",brandModel);

  return (
    <ProductContext.Provider
      value={{
        ytVideoData, 
        setYtVideoData,
        onIndex, 
        setOnIndex,
        tempJSON, 
        setTempJSON,
        totalQuestions, 
        setTotalQuestions,
        show, 
        setShow,
        sortShow, 
        setSortShow, 
        loadCheck, 
        setLoadCheck,
        errors, 
        setErrors,
        brandModel,
        setBrandModel,
        afterFilter,
        setAfterFilter,
        filterData,
        setFilterData,
        invSortData,
        setInvSortData,
        afterSort,
        setAfterSort,
        filter,
        setFilter,
        lang
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}

export { ProductContextProvider, ProductContext };