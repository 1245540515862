import moment from 'moment/moment';

export const dateFormat = (date) => {
  let dateObject = new Date(date);
  let humanDateFormat = dateObject.toString("en-US");
  var d = new Date(humanDateFormat),
  month = "" + (d.getMonth() + 1),
  day = "" + d.getDate(),
  year = "" + d.getFullYear(),
  hours = "" + d.getHours(),
  minutes = "" + d.getMinutes();
if (month.length < 2) month = "0" + month;
if (day.length < 2) day = "0" + day;
if (hours.length < 2) hours = "0" + hours;
if (minutes.length < 2) minutes = "0" + minutes;


return (
  [day, month, year].join("-") + " " + [hours, minutes].join(":")
);
};

export const dateTime_MWORD_AMPM = (date) => {
 
  const utcTime = date;
  const istTime = moment.utc(utcTime).local();

  const formattedIST = istTime.format('Do MMM YYYY | h:mm a');

return formattedIST;
};

export const dateTime_MWORD = (date) => {
 
  const utcTime = date;
  const istTime = moment.utc(utcTime).local();

  const formattedIST = istTime.format('Do MMM YYYY');

return formattedIST;
};

