import React, { useContext } from "react";
import ModalWrapper from "../../components/modal";
import SpecificationContainer from "../../components/specification";
import { ProductContext } from "../../utils/productContext";
import { BiRupee } from 'react-icons/bi';
import { dateFormat } from "../../utils/dateFormat";


export default function AlertMessage({value}) {

  const {
    setShow
  } = useContext(ProductContext);

  function closeModal() {
    setShow(false);
  }

  return (
    <>
      <ModalWrapper>
        <ModalWrapper.Modal>
        <div style={{backgroundColor:'white',padding:'10px',boxShadow:'0px 8px 12px #00000017',borderRadius:'5px'}}>
            <SpecificationContainer>
{  value[`name`] &&   <SpecificationContainer.TableConatiner>
                <SpecificationContainer.TableHeader>
                    <SpecificationContainer.TableHeaderText><b>Name</b></SpecificationContainer.TableHeaderText>
                </SpecificationContainer.TableHeader>
                <SpecificationContainer.TableValues>
                    <SpecificationContainer.TableValuesText>{value[`name`]}</SpecificationContainer.TableValuesText>
                </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>}
{  value[`phone`] &&  <SpecificationContainer.TableConatiner>
                <SpecificationContainer.TableHeader>
                    <SpecificationContainer.TableHeaderText><b>Phone Number</b></SpecificationContainer.TableHeaderText>
                </SpecificationContainer.TableHeader>
                <SpecificationContainer.TableValues  style={{display:'flex',justifyContent:'space-between'}}>
                    <SpecificationContainer.TableValuesText><a style={{textDecoration:'none',color:'#000'}} href={`tel:${value[`phone`]}`}>{value[`phone`]}</a></SpecificationContainer.TableValuesText>
                </SpecificationContainer.TableValues>                
        </SpecificationContainer.TableConatiner>}                
        {value[`location`] && <SpecificationContainer.TableConatiner>
        <SpecificationContainer.TableHeader>
            <SpecificationContainer.TableHeaderText><b>Location </b></SpecificationContainer.TableHeaderText>
        </SpecificationContainer.TableHeader>
        <SpecificationContainer.TableValues>
            <SpecificationContainer.TableValuesText>{value[`location`]}</SpecificationContainer.TableValuesText>
      </SpecificationContainer.TableValues>
  </SpecificationContainer.TableConatiner>}
{value[`brand`] && <SpecificationContainer.TableConatiner>
      <SpecificationContainer.TableHeader>
          <SpecificationContainer.TableHeaderText><b>Brand </b></SpecificationContainer.TableHeaderText>
      </SpecificationContainer.TableHeader>
      <SpecificationContainer.TableValues>
          <SpecificationContainer.TableValuesText>{value[`brand`]}</SpecificationContainer.TableValuesText>
      </SpecificationContainer.TableValues>
  </SpecificationContainer.TableConatiner>}
{value[`model`]&&  <SpecificationContainer.TableConatiner>
      <SpecificationContainer.TableHeader>
            <SpecificationContainer.TableHeaderText><b>Model </b></SpecificationContainer.TableHeaderText>
      </SpecificationContainer.TableHeader>
      <SpecificationContainer.TableValues>
          <SpecificationContainer.TableValuesText>{value[`model`]}</SpecificationContainer.TableValuesText>
      </SpecificationContainer.TableValues>
  </SpecificationContainer.TableConatiner>}
{ value[`budget`] && <SpecificationContainer.TableConatiner>
      <SpecificationContainer.TableHeader >
          <SpecificationContainer.TableHeaderText ><b style={{display:'flex', alignItems: 'center'}}>Budget <BiRupee style={{height:'18px',width:'18px'}}/></b></SpecificationContainer.TableHeaderText>
      </SpecificationContainer.TableHeader>
      <SpecificationContainer.TableValues>
          <SpecificationContainer.TableValuesText>{value[`budget`]}</SpecificationContainer.TableValuesText>
      </SpecificationContainer.TableValues>
  </SpecificationContainer.TableConatiner>}  
{value[`remarks`] &&  <SpecificationContainer.TableConatiner>
      <SpecificationContainer.TableHeader>
          <SpecificationContainer.TableHeaderText><b>Remarks </b></SpecificationContainer.TableHeaderText>
      </SpecificationContainer.TableHeader>
      <SpecificationContainer.TableValues>
          <SpecificationContainer.TableValuesText>{value[`remarks`]}</SpecificationContainer.TableValuesText>
      </SpecificationContainer.TableValues>
  </SpecificationContainer.TableConatiner>}
  <SpecificationContainer.TableConatiner>
                <SpecificationContainer.TableHeader>
                    <SpecificationContainer.TableHeaderText><b>Timestamp</b></SpecificationContainer.TableHeaderText>
                </SpecificationContainer.TableHeader>
                <SpecificationContainer.TableValues>
                    <SpecificationContainer.TableValuesText>{dateFormat(value[`updated_at`])}</SpecificationContainer.TableValuesText>
                </SpecificationContainer.TableValues>                
    </SpecificationContainer.TableConatiner>    
  <div style={{display:'flex',justifyContent:'center'}}>
  <button style={{padding:'10px 20px',marginTop:'10px',borderRadius:'5px',border:'none',backgroundColor:'#652078',color:'#FFFF'}} onClick={closeModal}>Close</button>
  </div>
  </SpecificationContainer>
       </div>;
        </ModalWrapper.Modal>
      </ModalWrapper>
    </>
  );
}