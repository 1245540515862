import * as types from "../../types";
import * as _airtableApi from "../../../api/airtable";
import { call, put, takeLatest } from "redux-saga/effects";
import { clearErrors, returnErrors } from "../../actions/errorAction";
import { airtableUserLocationFailure, airtableUserLocationSuccess } from "../../actions/airtableUserLocationAction";

function* asyncFetchUserLocation(action){
    const lang = action.payload.languageCode;
    const userLocationObj = {
        tableName: "Dealer Location",
        fields:[
            `Location(EN)`,
            `Location(${lang})`,
            `State(EN)`,
            `State(${lang})`
        ],
        filterByFormula: "",
    }
    
    try{
        yield put(clearErrors());
        let resATLocation = yield call(_airtableApi.fetchAirtableDataFn,userLocationObj)
        yield put(airtableUserLocationSuccess({resATLocation}));
    }catch(e){
        yield put(returnErrors({ msg: e.message, status: true }));
        yield put(airtableUserLocationFailure({error:e}))
    }

}

export default function* fetchAirtableUserLocation(){
    yield takeLatest(types.FETCH_AIRTABLE_USER_LOCATION_REQUEST,asyncFetchUserLocation)
}