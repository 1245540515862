import * as types from "../types";
import * as _api from "../../api";
import { clearErrors, returnErrors } from "../actions/errorAction";
import { call, put, takeLatest } from "redux-saga/effects";
import { buySellFinancelistFailure, buySellFinancelistSuccess } from "../actions/buySellFinanceList";

function* asyncFetchBuySellFinanceListSaga(action){

    try{
        yield put(clearErrors());
        let resFinList = yield call(_api.fetchBuySellFinanceList,{
            key: action.payload.key
        })
        yield put(buySellFinancelistSuccess({resFinList}));
    }catch(e){
        yield put(buySellFinancelistFailure({error:e}));
        yield put(returnErrors({msg: e.message, status: true}));
    }

}

export default function* fetchBuySellFinanceListSaga(){
    yield takeLatest(types.FETCH_BUYSELL_FINANCELIST_REQUEST,asyncFetchBuySellFinanceListSaga);
}