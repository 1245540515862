import React, { useContext, useState } from "react";
import VideoPlay from "../components/videoplay";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import { ProductContext } from "../utils/productContext";

export default function Video() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryState = location.state;
  const videoType = queryParams.get("type");
  const { ytVideoData, setOnIndex, onIndex } = useContext(ProductContext);

  const filterVideos = queryState.filter((video) => video.type === videoType);

  //console.log("ytVideoData",filterVideos)

  function handleScroll(e) {
    setOnIndex(e.target.id);
  }

  //console.log("filterVideos",filterVideos)
  return (
    <div>
      {filterVideos.map(
        (item, index) =>
          Object.keys(item).length > 0 && (
            <VideoPlay key={index} onScroll={handleScroll} id={index}>
              <div>
                {videoType !== "live" && (
                  <VideoPlay.DiscountTag discount={item.discount} />
                )}

                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                  }}
                >
                  <ReactPlayer
                    url={item.url}
                    loop
                    playsinline={true}
                    playing={index == onIndex ? true : false}
                    controls={false}
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </VideoPlay>
          )
      )}
    </div>
  );
}