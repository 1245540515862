import * as types from "../types";

const initialState = {
  loading: false,
  requestLeads:null,
  resLeads: null,
  error: null,
};

export default function leadsSubmitReducer(state = initialState, action) {
  switch (action.type) {
    case types.LEADS_SUBMIT_REQUEST:
      return {
        ...state,
        loading: true,
        requestLeads: action.payload.requestLeads,
      };

    case types.LEADS_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        resLeads: action.payload.resLeads,
      };

    case types.LEADS_SUBMIT_FAILURE:
      return {
        ...state,
        loading: false,
        requestLeads:null,
        resLeads: null,
        error: action.payload.error,
    };

    case types.LEADS_SUBMIT_RESET:
      return {
        ...state,
        loading: false,
        requestLeads:null,
        resLeads: null,
        error: null,
    };

    default:
      return state;
  }
}