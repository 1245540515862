import { takeLatest, call, put } from "redux-saga/effects";
import {
  leadsSubmitSuccess,
  leadsSubmitFailure,
} from "../actions/leadsSubmitAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncLeadsSubmitSaga(action) {
  try {
    yield put(clearErrors());
    let resLeads = yield call(_api.submitLeadsApi, {
      requestLeads: action.payload.requestLeads,
    });
    yield put(leadsSubmitSuccess({ resLeads }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(leadsSubmitFailure({ error: e }));
  }
}
export default function* leadsSubmitSaga() {
  yield takeLatest(types.LEADS_SUBMIT_REQUEST, asyncLeadsSubmitSaga);
}