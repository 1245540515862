import * as types from "../types";

export const userLocationRequest = () => ({
    type: types.FETCH_USER_LOCATION_DATA_REQUEST
})

export const userLocationSuccess = ({resUserLocation}) => ({
    type: types.FETCH_USER_LOCATION_DATA_SUCCESS,
    payload: {
        resUserLocation
    }
})

export const userLocationFailure = ({error}) => ({
    type: types.FETCH_USER_LOCATION_DATA_FAILURE,
    payload:{
        error: error.message
    }
})