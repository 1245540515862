import React, { useState } from "react";
import BuyCards from "../../components/buyCard";
import useFetch from "../../utils/useFetch";
import { useNavigate } from "react-router-dom";
import Section from "../../components/section";
import SectionHeader from "../../components/sectionheader";
import BottomHighlightLine from "../../components/bottomhighlight";
import Loader from "../../components/Loader/Loader";
import urlContain from "../../utils/urlContain";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { inventoryAllRequest } from "../../store/actions/inventoryAllAction";
import { useTranslation } from "react-i18next";

export default function ProductCardSection() {
  const { t: common } = useTranslation("common");
  const [visibleCards, setVisibleCards] = useState(4);
  const dispatch = useDispatch();
  const languageObj = useSelector((state) => state.languageReducer);
  //  const inventoryObj = useSelector(state => state.invReducer);
  // const { loading, resInvAll } = inventoryObj;
  const inventoryObj = useSelector((state) => state.inventoryAllReducer);
  const { loading, resInvAll } = inventoryObj;
  const { languageCode } = languageObj;
  //const langData = languageState;
  const lang = languageCode.toUpperCase();
  let initialPost = resInvAll?.data
    ?.filter(
      (item) =>
        (item?.status === 1 || item?.status === 2 || item?.status === 3) && item
    )
    ?.sort((a, b) => b?.tractor_id - a?.tractor_id);
  const [productData, productFetching, productError] = useFetch({
    tableName: "inventory",
    fields: [
      `Product_Id`,
      `Carousel_image`,
      `Product_Multiple_Image`,
      `offer_headline(${lang})`,
    ],
    filterByFormula: "",
  });
  useEffect(() => {
    // dispatch(inventoryRequest());
    dispatch(inventoryAllRequest());
  }, []);

  let navigate = useNavigate();
  const handleNav = (tractor_id, productjson, prodType) => {
    navigate(
      `/productDetails?lang=${lang}&type=${prodType}&tractor_id=${tractor_id}`,
      { state: { product_data: productjson } }
    );
  };

  const viewAllItems = () => {
    navigate(`/allproduct?lang=${lang}`);
  };

  const loadMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 4);
  };
  //const height = desktopView ? '350px' : mobileView ? '550px' : null;

  return loading && (resInvAll?.data || "").length === 0 ? (
    <Loader />
  ) : (
    initialPost?.length > 0 && (
      <Section>
        <SectionHeader>
          <SectionHeader.Title>
            {productData?.[0]?.[`offer_headline(${lang})`]}
            <SectionHeader.Button onClick={() => viewAllItems()}>
              {common("view_all")}
            </SectionHeader.Button>
          </SectionHeader.Title>
          <BottomHighlightLine></BottomHighlightLine>
        </SectionHeader>
        <BuyCards padding="0">
          {initialPost?.slice(0, visibleCards)?.map(
            (item, index) =>
              (item.status === 1 || item.status === 2 || item.status === 3) && (
                <BuyCards.Card
                  key={index}
                  onClick={() =>
                    handleNav(item[`tractor_id`], item, "inventory")
                  }
                >
                  {typeof item[`image_links`] === "object" &&
                  Object.keys(item[`image_links`])?.length > 0 ? (
                    <BuyCards.Image
                      src={urlContain(item[`image_links`][0]["image"])}
                      alt=""
                      loading="lazy"
                    />
                  ) : (
                    <BuyCards.Image
                      src="../assets/no_image_available.png"
                      alt=""
                      loading="lazy"
                    />
                  )}
                  <BuyCards.InfoContainer>
                    <BuyCards.Title>
                      {item["brand"]}&nbsp;{item["model"]}
                    </BuyCards.Title>
                    {/* <BuyCards.InfoText>Brand: {item["brand"]}
                    </BuyCards.InfoText> */}
                    <BuyCards.Price>
                      <b>₹ {item["max_price"]}</b>
                      {item["rating"] > 0 && (
                        <BuyCards.Star
                          src={`../assets/staricon/${item["rating"]} star.svg`}
                          alt={`${item["rating"]} star`}
                        />
                      )}
                    </BuyCards.Price>
                    <BuyCards.SpecificationContainer>
                      <BuyCards.Specification>
                        <BuyCards.Icon
                          src="./assets/certify/engine_hours.svg"
                          alt=""
                        />
                        <BuyCards.SpecificationValue>
                          <BuyCards.SpecificationLabel>
                            Hrs
                          </BuyCards.SpecificationLabel>
                          {item[`engine_hours`] === -1
                            ? "NA"
                            : `${item[`engine_hours`]}`}
                        </BuyCards.SpecificationValue>
                      </BuyCards.Specification>
                      <BuyCards.Specification>
                        <BuyCards.Icon
                          src="./assets/certify/engine_power.svg"
                          alt=""
                        />
                        <BuyCards.SpecificationValue>
                          <BuyCards.SpecificationLabel>
                            Power
                          </BuyCards.SpecificationLabel>
                          {item[`engine_power`] === -1
                            ? "NA"
                            : `${item[`engine_power`]}`}
                        </BuyCards.SpecificationValue>
                      </BuyCards.Specification>
                      <BuyCards.Specification>
                        <BuyCards.Icon
                          src="./assets/certify/tyre_condition.svg"
                          alt=""
                        />
                        <BuyCards.SpecificationValue>
                          <BuyCards.SpecificationLabel>
                            Tyre
                          </BuyCards.SpecificationLabel>
                          {item[`tyre_condition`] === "Original"
                            ? "Original"
                            : item[`tyre_condition`]}
                        </BuyCards.SpecificationValue>
                      </BuyCards.Specification>
                    </BuyCards.SpecificationContainer>
                    <BuyCards.ButtonContainer>
                      <BuyCards.RTONumber>
                        {
                          item?.additional_featureInfo?.[
                            "Registration (RTO) No."
                          ] || item?.additional_featureInfo?.[
                            "Registration (RTO) No"
                          ]
                        }
                      </BuyCards.RTONumber>
                      <BuyCards.Button>
                        {common("see_details")}
                        <img
                          src="./assets/arrow/right_arrow_black.svg"
                          alt="right arrow"
                        />
                      </BuyCards.Button>
                    </BuyCards.ButtonContainer>
                  </BuyCards.InfoContainer>
                </BuyCards.Card>
              )
          )}
        </BuyCards>
        {visibleCards < initialPost?.length &&
          (visibleCards < 12 ? (
            <BuyCards.LoadMore onClick={() => loadMore()}>
              {common("load_more")}
              <img src="../assets/arrow/arrow_down.svg" alt="" />
            </BuyCards.LoadMore>
          ) : (
            <BuyCards.LoadMore
              borderColor="#ccc"
              onClick={() => viewAllItems()}
            >
              {common("view_all")}
              <img
                src="../assets/arrow/arrow_down.svg"
                style={{ transform: "rotate(-90deg)" }}
                alt=""
              />
            </BuyCards.LoadMore>
          ))}
      </Section>
    )
  );
}
