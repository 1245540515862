import { useDispatch, useSelector } from "react-redux";
import Loader, { InfinitySpinLoader } from "../../components/Loader/Loader";
import SlideShow from "../../components/slideShow";
import { useQuery } from "../../utils/useQuery";
import { useEffect, useState } from "react";
import { fetchNtSingleInvRequest } from "../../store/actions/fetchNtSingleTractorAction";
import urlContain from "../../utils/urlContain";
import ProductDetailContainer from "../../components/productDetail";
import Swal from "sweetalert2";
import {
	submitNtTractorVerifyRequest,
	submitNtTractorVerifyReset,
} from "../../store/actions/postNtTractorVerifyAction";
import "animate.css/animate.min.css";
import { METABASE_BASE_URL, metabseEndpointRoutes } from "../../config";

const ImageWithLoading = ({ src, alt, loadingSpinner }) => {
	const [isLoading, setIsLoading] = useState(true);

	const handleImageLoad = () => {
		setIsLoading(false);
	};

	const handleImageError = () => {
		setIsLoading(false);
	  };

	return (
		<>
			{isLoading && loadingSpinner}
			<SlideShow.EachSlideEffect>
				<SlideShow.BackgroundImage
					src={src}
					alt={alt}
					loading="lazy"
					onLoad={handleImageLoad}
					onError={handleImageError}
					/>
			</SlideShow.EachSlideEffect>
		</>
	);
};

export default function TractorVerification() {
	const [disable, setDisable] = useState(false);
	const queryParams = useQuery();
	const tractorId = queryParams.get("tractor_id");
	const dispatch = useDispatch();
	const ntInvObj = useSelector((state) => state.fetchNtSingleTractorReducer);
	const { loading, resNtInv } = ntInvObj;
	const ntVerifyObj = useSelector((state) => state.postNtTractorVerifyReducer);
	const { loading: submitLoader, resNtverify, error } = ntVerifyObj;
	const images = resNtInv?.data?.image_links;

	useEffect(() => {
		dispatch(fetchNtSingleInvRequest({ reqTractorId: tractorId }));
	}, [tractorId]);

	useEffect(() => {
		if (resNtverify?.status) {
			Swal.fire({
				icon: "success",
				text: "The tractor verification is successfully confirmed.",
				confirmButtonColor: "#652078",
				showClass: {
					popup: "animate__animated animate__fadeInDown",
				},
				hideClass: {
					popup: "animate__animated animate__fadeOutUp",
				},
			}).then((res) => {
				if (res) {
					dispatch(submitNtTractorVerifyReset());
					setDisable(true);
				    window.location.href = `${METABASE_BASE_URL.BASE_URL}${metabseEndpointRoutes()?.NT_VERIFICATION_ROUTE}`;
				}
			});
		}
	}, [resNtverify]);

	const submitVerifyHandler = () => {
		if (tractorId) {
			Swal.fire({
				html:
					"Are you sure you want to proceed with tractor verification?<br/>" +
					"This process cannot be reversed.",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancel",
				confirmButtonColor: "#652078",
				confirmButtonText: "Yes",
				reverseButtons: true,
				showClass: {
					popup: "animate__animated animate__fadeInDown",
				},
				hideClass: {
					popup: "animate__animated animate__fadeOutUp",
				},
			}).then((result) => {
				if (result.dismiss === Swal.DismissReason.cancel) {
				} else if (result.isConfirmed) {
					const postNtStatus = {
						is_verified: true,
						inventory_id: tractorId,
					};
					dispatch(submitNtTractorVerifyRequest({ postNtStatus }));
				}
			});
		}
	};

	return loading ? (
		<Loader />
	) : (
		<>
			<div
				className={`animate__animated animate__fadeIn`}
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					margin: "20px 10px",
				}}
			>
				{resNtInv?.data &&
				typeof resNtInv?.data === "object" &&
				Object.keys(resNtInv?.data).length > 0 ? (
					<>
						<div>
							<p style={{ color: "#333", fontSize: "18px", fontWeight: "700" }}>
								{resNtInv.data.brand} {resNtInv.data.model}
							</p>
							<p style={{ color: "#666", fontSize: "14px", fontWeight: "300" }}>
								Brand: {resNtInv.data.brand}
							</p>
						</div>
						<div
							style={{
								boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
								padding: "10px",
							}}
						>
							{images && Object.keys(images).length > 0 ? (
								<SlideShow>
									<SlideShow.Slides>
										{Object.keys(images).map((key, index) => (
											<ImageWithLoading
												src={urlContain(images[key].image)}
												alt=""
												loadingSpinner={<InfinitySpinLoader />}
												key={index}
											/>
										))}
									</SlideShow.Slides>
								</SlideShow>
							) : (
								<p
									className={`animate__animated animate__fadeIn`}
									style={{ textAlign: "center" }}
								>
									No Images Available
									<span
										role="img"
										aria-label="Warning"
										className="animate__animated animate__rotateIn animate__infinite"
									>
										❗
									</span>
								</p>
							)}
						</div>
					</>
				) : (
					<p
						className={`animate__animated animate__fadeIn`}
						style={{ textAlign: "center" }}
					>
						No Data Available{" "}
						<span
							role="img"
							aria-label="Warning"
							className="animate__animated animate__rotateIn animate__infinite"
						>
							❗
						</span>
					</p>
				)}
			</div>
			<ProductDetailContainer.ButtonContainer>
				{submitLoader ? (
					<Loader />
				) : (
					resNtInv?.data &&
					typeof resNtInv?.data === "object" &&
					Object.keys(resNtInv?.data).length > 0 && (
						<ProductDetailContainer.InterestedButton
							disabled={resNtInv?.data?.is_verified || disable ? true : false}
							onClick={submitVerifyHandler}
						>
							{resNtInv?.data?.is_verified || disable ? (
								<>
									Verified
									<span
										role="img"
										aria-label="Checkmark"
										className="animate__animated animate__bounceIn animate__delay-0.5s"
										style={{
											fontWeight: "800",
											color: "green",
											fontSize: "22px",
										}}
									>
										✔
									</span>
								</>
							) : (
								"Verify"
							)}
						</ProductDetailContainer.InterestedButton>
					)
				)}
			</ProductDetailContainer.ButtonContainer>
		</>
	);
}
