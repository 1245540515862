import React from "react";
import { Container, Image, Title, Tile, Navigation } from "./styles/tile";

export default function TileWrapper({children,...props}){
    return <Container {...props}>{children}</Container>
}

TileWrapper.Tile = function TileWrapperTile({children, ...props}){
    return <Tile {...props}>{children}</Tile>
}

TileWrapper.Image = function TileWrapperIMage({...props}){
    return <Image {...props} />
}

TileWrapper.Title = function TileWrapperTitle({children,...props}){
    return <Title {...props}>{children}</Title>
}

TileWrapper.Navigation = function TileWrapperNavigate({children, ...props}){
    return <Navigation {...props}>{children}</Navigation>
}