import { omit } from "lodash";
import React, { useContext, useEffect, useState }  from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Form from "../../components/form";
import Header from "../../components/header";
import Loader from "../../components/Loader/Loader";
import { ProductContext } from "../../utils/productContext";
import { usePost } from "../../utils/usePost";
import { usePostBE } from "../../utils/usePostBE";
import { BiRupee } from 'react-icons/bi';
import "./multiselect.css";
import { API_BASE_URL, API_ROUTES } from "../../config";
import { useQuery } from "../../utils/useQuery";

export default function WalkingLeadForm(){
  const [projects, setProjects] = useState([]);
  const [brand,setBrand] = useState([]);
  const [model,setModel] = useState([{ value: "", label: "Select model" }]);
  const [field,setField] = useState({
        "user_name":'',
        "phone_number":'',
        "location":'',
        "brand":'',
        "model":'',
        "budget":'',
        "remarks":''
  });
  let navigate = useNavigate();    
  const queryParams = useQuery();
  const userid = queryParams.get('userid');

  const {
      errors,
      setErrors,
      brandModel
  } = useContext(ProductContext);
  let errorObj = errors;
  var errorReturn = "";

  const [postHandler, submitting, postError, success] = usePost();
  const [postData, postFn, submittingBE, postStatus] = usePostBE({url:`${API_BASE_URL.BASE_URL}${API_ROUTES.CAPTURE_LEAD_ROUTE}`,redirect:"walkinLead",user_id:userid});    

    useEffect(() => {
      if(postStatus){
        Swal.fire({
          icon: "success",
          text: `Submitted successfully`,
          confirmButtonColor: '#652078',
          }).then((res) => {
          if (res) {
           // handleRedirect();
           navigate(`/walkingLeadLanding?userid=${userid}`)
          }
          })        
      }
    },[postStatus])



        useEffect(() => { 
          let brands = [];
          if (typeof brandModel === "object") {
            Object?.keys(brandModel)?.map((brandName) => {
              brands.push({
                value: brandName,
                label: brandName,
              });
            });
          }
          brands.unshift({ value: "", label: "Select brand" });
          setBrand(brands);
       },[brandModel]);

       let brandDep = field["brand"];

        useEffect(() => { 
          let models = [];
          if (typeof brandModel === "object") {
            Object?.keys(brandModel)?.map((brandName) => {
              if (brandName === brandDep) {
                brandModel?.[brandName]?.models?.map((modelName) => {
                  models.push({
                    value: modelName,
                    label: modelName,
                  });
                });
              }
            });
          }
          models.unshift({ value: "", label: "Select model" });
          setModel(models);
         },[brandDep]);

      //  const [duplicateData,DuplicateDataFn,duplicateFetching] = useDuplicatePhone("https://used-tractor-backend.azurewebsites.net/leads/v1/fetch-lead-data")
      //  let phone = field["phone_number"];
      //   useEffect(() => {
      //    let postBody = {
      //       "campaign_name" : "walk-in leads",
      //       "phone" : phone
      //      }
      //      phone.length === 10 && DuplicateDataFn(postBody)
      //     if(phone.length < 10){
      //     //console.log("name",name)
      //       setErrors(omit(errors, "phone_number"));
      //      }else if(duplicateData?.data?.length > 0){
      //       setErrors(prev =>  ({...prev, phone_number: "This phone number already exist."}));
      //      }
      //   },[phone])

    useEffect(() => {
          setField({
            "user_name":'',
            "phone_number":'',
            "location":'',
            "brand":"",
            "model":'',
            "budget":'',
            "remarks":''
        })
    },[postData])


    const validate = (event, name, value, field) => {

      // else if( value !== "" && name==="location" && !((/^[a-zA-Z ]{2,30}$/).test(value)) && name !== "remarks"){
      //   errorObj =  {...errorObj, [name]: "Please enter a only alphabets"};
      // }

        if(value === "" && name==="user_name" && name !== "remarks"){
          errorObj =  {...errorObj, [name]: "Please enter a name"};
        }else if( value !== "" && name==="user_name" && !((/^[a-zA-Z ]{2,30}$/).test(value)) && name !== "remarks"){
          errorObj =  {...errorObj, [name]: "Please enter a valid name"};
        }else if(value === "" && name==="phone_number" && name !== "remarks"){
          errorObj =  {...errorObj, [name]: "Please enter a phone number"};
        }else if(value !== "" && name==="phone_number" && name !== "remarks"){
          if(isNaN(value) || !((/^[0-9]{10}/).test(value)) || value.length > 10 ){
            ///^[6-9][0-9]{10}/
            errorObj =  {...errorObj, [name]: "Please enter a valid phone number"};
          }else{
          let newObj = omit(errors, name);
          errorObj = newObj;
          }
        }else if(value === "" && name==="location"  && name !== "remarks"){
            errorObj = {...errorObj, [name]: "Please enter a location"};
        }else if(value === "" && name==="budget"  && name !== "remarks"){
            errorObj =  {...errorObj, [name]: "Please enter a budget"};
        }else if(value !== "" && name==="budget" && value > 1500000  && name !== "remarks"){
            errorObj =  {...errorObj, [name]: "Budget should not be greater than 1500000"};
        }else if(value !== "" && name==="budget" && !((/^[1-9]\d*(\.\d+)?$/).test(value)) && name !== "remarks"){
            errorObj =  {...errorObj, [name]: "Please fill valid value."};
        }else if(errors?.hasOwnProperty(name)){
          //console.log("name",name)
          let newObj = omit(errors, name);
          errorObj = newObj;
        }
        return errorObj;
    }

    const handleChange = (e) => {
        const {type,name,value} = e.target;
          errorReturn = validate(e, name, value,field);
        setErrors(errorReturn);
        setField((pre) => ({...pre,[name]:value}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    let fields = {
            tableName: "Walking Lead",
            fields: {
                "Name": field['user_name'],
                "Phone Number": field['phone_number'],
                "Location": field['location'],
                "Brand": field['brand'],
                "Model": field['model'],
                "Budget": field['budget'],
                "Remarks": field['remarks']
              },
        };

    let postBody = {
      "campaign_name" : "walkin",
      "name": field['user_name'],
      "phone": field['phone_number'],
      "location": field['location'],
      "brand": field['brand'],
      "model": field['model'],
      "budget": field['budget'],
      "remarks": field['remarks']
    }    

    var defaultFieldJsonKey = Object.keys(field); 
    for(var i = 0; i < defaultFieldJsonKey.length; i++){
      if(defaultFieldJsonKey){
          errorReturn = validate(e, defaultFieldJsonKey[i], field[defaultFieldJsonKey[i]],field);
      }
    }
      setErrors(errorReturn);
        if(!(Object.keys(errorReturn)?.length>0)){
          postHandler(fields);
          postFn(postBody);
        }else{
          Swal.fire({
            icon: "error",
            text: "Please fill all the mandatory fields."
          }).then((res) => {
            
          });
        }       
    }

    const backButton = () => {
      navigate(`/walkingLeadLanding?userid=${userid}`)
      }

    return (
        <>
        <Header>
          <Header.BackButtonContainer>
            <Header.BackIcon src="../assets/arrow_back.svg" alt="" onClick={backButton} />
            <Header.ButtonText>Walk-In Lead Information</Header.ButtonText>
          </Header.BackButtonContainer>
        </Header>
            <Form onSubmit={handleSubmit}>
                <Form.SectionContainer shadow={true}>
                    <Form.Section>
                    <Form.GroupFields>
                            <Form.Label htmlfor="user_name">
                            Name
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>
                            </Form.Label>
                            <Form.Input
                              name="user_name"
                              id="user_name"
                              type="text"
                              onChange={(e) => handleChange(e)}
                              value={field["user_name"]}
                            />
                            {(errors?.hasOwnProperty("user_name") && errors["user_name"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["user_name"]}</h5>}
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="phone_number">
                            Phone Number
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>
                            </Form.Label>
                            <Form.Input
                              name="phone_number"
                              id="phone_number"
                              type="number"
                              onChange={(e) => handleChange(e)}
                              value={field["phone_number"]}
                            />
                            {(errors?.hasOwnProperty("phone_number") && errors["phone_number"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["phone_number"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="location">
                            Location
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>
                            </Form.Label>
                            <Form.Input
                              name="location"
                              id="location"
                              type="text"
                              onChange={(e) => handleChange(e)}
                              value={field["location"]}
                            />
                            {(errors?.hasOwnProperty("location") && errors["location"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["location"]}</h5>}
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="brand">
                            Brand 
                            </Form.Label>
                            <Form.Dropdown
                              name="brand"
                              id="brand"
                              options={brand}
                              onChange={(e) => handleChange(e)}
                              value={field["brand"]}
                            />
                            {(errors?.hasOwnProperty("brand") && errors["brand"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["brand"]}</h5>}
                    </Form.GroupFields>                                        
                    <Form.GroupFields>
                            <Form.Label htmlfor="model">
                            Model
                            </Form.Label>
                            <Form.Dropdown
                              name="model"
                              id="model"
                              options={model}
                              onChange={(e) => handleChange(e)}
                              value={field["model"]}
                              disabled={(field["brand"] === "") ? true : false}
                            />
                            {(errors?.hasOwnProperty("model") && errors["model"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["model"]}</h5>}
                    </Form.GroupFields>
                      {/* <Form.GroupFields>
                            <Form.Label htmlfor="brand">
                            Brand
                            </Form.Label>
                            <Select
                              isMulti
                              name="brand"
                              id="brand"
                              options={brand}
                              className="fZMnD"
                              onChange={(e) => multiDrop(e, "brand")}
                              classNamePrefix="filter"
                              menuPlacement="auto"
                              components={{ IndicatorSeparator:() => null }}
                            />
                            {(errors?.hasOwnProperty("brand") && errors["brand"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["brand"]}</h5>}
                    </Form.GroupFields>
                          <Form.GroupFields>
                            <Form.Label htmlfor="model">
                            Model
                            </Form.Label>
                            <Select
                              //defaultValue={[colourOptions[2], colourOptions[3]]}
                              isMulti
                              name="model"
                              id="model"
                              options={model}
                              className="fZMnD"
                              onChange={(e) => multiDrop(e, "model")}
                              classNamePrefix="filter"
                              menuPlacement="auto"
                              components={{ IndicatorSeparator:() => null }}
                              isDisabled={(field["brand"] === "" || field["brand"].length === 0) ? true : false}
                            />
                            {(errors?.hasOwnProperty("model") && errors["model"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["model"]}</h5>}
                    </Form.GroupFields> */}
                    <Form.GroupFields>
                            <Form.Label htmlfor="budget" style={{display:'flex'}}>
                            Budget <BiRupee style={{height:'18px',width:'18px'}}/>
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>
                            </Form.Label>
                            <Form.Input
                              name="budget"
                              id="budget"
                              type="text"
                              onChange={(e) => handleChange(e)}
                              value={field["budget"]}
                            />
                            {(errors?.hasOwnProperty("budget") && errors["budget"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["budget"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                          <Form.Label  htmlfor="remarks">Remarks</Form.Label>
                          <Form.Textarea 
                            cols="30" 
                            rows="7"
                            name="remarks"
                            id="remarks"
                            onChange={(e) => handleChange(e)}
                            value={field["remarks"]}
                          />
                    </Form.GroupFields>
                    </Form.Section>
                <Form.ButtonContainer>
                    {submittingBE ? <Loader/> : <Form.Button>Submit</Form.Button>}
                </Form.ButtonContainer>
                </Form.SectionContainer>
            </Form>
        </>
    )
}