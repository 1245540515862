import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
    max-height: ${(props) =>  props?.height ? props?.height : "" };
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 12px 0 20px 0;
    overflow-y: scroll;

    @media (min-width: 760px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
        padding: ${prop => prop.padding ? prop.padding :  '0px 10px'};
        overflow-x: hidden;
    }

`;


export const Card = styled.div`
    position: relative;
    min-width: 340px;
    max-width: 340px;
    min-height: 130px;
    max-height: 140px; 
    margin: 5px; 
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: row;

    @media (max-width: 760px) {
        min-width: 100%;
        max-width: 100%;
    }


`;

export const Image = styled.img`
    width: 130px;
    height: 130px;
    object-fit: cover;
    margin-right: 10px;
`;

export const DiscountTag = styled.div`
    width: auto;
    height: 24px;
    padding: 3px 9px 5px 9px;
    background: #DB0000 0% 0% no-repeat padding-box;
    border-radius: 8px 0px;
    position: absolute;
`;

export const DiscountValue = styled.p`
    font-size: 12px;
    letter-spacing: 0px;
    color: #FFFFFF;
`;

export const InfoContainer = styled.div`
    width: calc(100% - 140px);
    bottom: 0;
    margin-bottom: 8px; 
    height: auto;
    display: flex;
    flex-direction: column;
`;

    // width: 100%;
    // bottom: 0;
    // margin-bottom: 8px; 
    // height: auto;
    // display: flex;
    // flex-direction: column;


export const Title = styled.p`
    font-size: 14px;
    font-weight: 700;
    margin: 12px 0px 2px 0px;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const InfoText = styled.p`
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:10px;
`;

export const Price = styled.span`
    font-size: 14px; 
    font-weight: 800;
    color: #20AF48;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 12px;
`;

export const Star = styled.img`
    height: 24.8px; 
    width: 25.87px;
`;

export const TitleTooltip = styled.div`
    visibility: hidden;
    background-color: #fff;
    -webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.3);
    opacity: 0;
    transition: opacity 0.5s ease;
`;

export const TitleContent = styled.p`
    white-space: normal; 
    width: 125px;
    height: auto;
    word-break: break-all; 
    position: relative;
    pointer-events:none;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &:after {
        content:"";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;
        z-index: 1;
        pointer-events:initial;
    }

    &:hover:after{
        cursor: pointer;
    }
    &:hover + ${TitleTooltip} {
        visibility: visible;
        transition: opacity 0.5s ease;
        opacity: 1;
    }
`;

export const SpecificationContainer = styled.div`
    width: calc(100% - 140px);
    display: flex;
    gap: 8px;
`;

export const Specification = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
`;

export const Icon = styled.img`
    height: 24px;
    width: 24px;

    @media (max-width: 320px) {
        height: 15px;
        width: 15px;
    }
`;

export const SpecificationLabel = styled.p`
    font-size: 9px;
`;

export const SpecificationValue = styled.span`
    width: 34px;
    font-size: 11px;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 320px) {
        width: 30px;
    }

    @media (min-width: 425px) {
        width: 40px;
    }

    @media (min-width: 768px) {
        width: 34px;
    }


`;

export const ButtonContainer = styled.div`
    width: calc(100% - 140px);
    position:absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
`;

export const Button = styled.button`
    padding: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    border-radius: 4px 0px;
    background: #F26F21;
    border:none;
    display: flex;
    align-items: center;
`;

export const RTONumber = styled.span`
    color: #666;
    font-size: 11px;
`;


export const FilterContainer = styled.div`  
    margin: 0px 0px 40px 0px;
`;

export const FilterButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    font-size: 20px;
    padding: 5px 10px;
    border: none;
    font-weight: 500;
    background-color: #FFFF;
`;

export const ButtonIcon = styled.img`
    height: 28px;
    width: 28px;
`;

export const FilterContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0px;
    font-weight: 500;
    font-size: small;
`;

export const Message = styled.p`
    font-style: italic;
    padding: 5px 20px;
    border: 2px solid #EAEAEA;
    border-radius: 15px;
`;

export const LoadMore = styled.button`
    width: 100%;
    padding: 10px 17px;
    color: #F26F21;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: ${props =>  props.borderColor ? `1px solid ${props.borderColor}` : 'none'};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
`;

