import * as types from "../types";

export const submitNtTractorVerifyRequest = ({ postNtStatus }) => ({
	type: types.POST_NT_TRACTOR_ID_VERIFY_REQUEST,
	payload: { postNtStatus },
});

export const submitNtTractorVerifySuccess = ({ resNtverify }) => ({
	type: types.POST_NT_TRACTOR_ID_VERIFY_SUCCESS,
	payload: {
		resNtverify,
	},
});

export const submitNtTractorVerifyFailure = ({ error }) => ({
	type: types.POST_NT_TRACTOR_ID_VERIFY_FAILURE,
	payload: {
		error: error.message,
	},
});

export const submitNtTractorVerifyReset = () => ({
	type: types.POST_NT_TRACTOR_ID_VERIFY_RESET,
});
