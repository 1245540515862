import * as types from "../types";

const initialState = {
    loading: false,
    resBrandModel: null,
    error: null
}

export default function fetchBrandModelReducer(state = initialState, action){

    switch(action.type){
        case types.FETCH_BRAND_MODEL_REQUEST:
            return {
                loading: true,
            }

        case types.FETCH_BRAND_MODEL_SUCCESS:
            return {
                loading: false,
                resBrandModel: action.payload.resBrandModel
            }

        case types.FETCH_BRAND_MODEL_FAILURE:
            return {
                loading: false,
                resFinList: null,
                error: action.payload.error
            }

        default :
            return state;
    }

}