export const API_ROUTES = {
	FETCH_DEALER_INVENTORY_ROUTE: "/inventory/web/v2/tractor/",
	INVENTORY_SINGLE_ROUTE: "/inventory/web/v2/tractor/limit-one/",
	ALL_DEALER_INVENTORY_ROUTE: "/inventory/web/v2/tractor/",
	FETCH_USER_LOCATION_ROUTE: "/user/web/user-location-details",
	FETCH_LEAD_ROUTE: "/leads/v1/fetch-lead-data",
	CAPTURE_LEAD_ROUTE: "/leads/v1/capture-lead",
	ADD_EDIT_ADDITIONAL_DATA_ROUTE: "/inventory/v1/additional-data/update",
	FETCH_ADDITIONAL_DATA_ROUTE: "/inventory/v1/additional-data/",
	FETCH_PRICING_MANUFACTURER: "/inventory/v1/pricing/manufacture-data",
	FETCH_PRICING_OTHER_DATA: "/inventory/v1/pricing/other-data",
	FETCH_PRICING_DATA: "/inventory/v1/pricing/price-details",
	ADD_UPDATE_EXPENSE_DATA: "/expense_tracking/v1/expense/update",
	FETCH_EXPENSE_DATA: "/expense_tracking/v1/expense/user-id/",
	CHECK_UNIQUE_CHASSISNUMBER: "/expense_tracking/v1/chassis-number/is_unique",
	FETCH_EXPENSE_ID_DATA: "/expense_tracking/v1/expense/expense-id/",
	FETCH_EXPENSE_SUMMARY: "/expense_tracking/v1/expense-summary",
	LEADS_CAPTURE: "/leads/v1/capture-lead-data",
	FETCH_BUYSELL_FINANCE_LIST_ROUTE: "/expense_tracking/v1/expense/config",
	FETCH_EXPENSE_CHASSIS_DATA_ROUTE:
		"/expense_tracking/v1/expense/chassis-number/",
	SEND_OTP_REQUEST: "/user/request-otp/",
	VERIFY_OTP_REQUEST: "/user/verify-otp/",
	FETCH_ADDITIONAL_INFO_QUESTION_JSON: "/media/feature_settings/?key=",
	FETCH_BRAND_MODEL: "/inventory/v1/brand_and_model",
	POST_NT_TRACTOR_ID_VERIFY_ROUTE: "/inventory/v2/nt/verify/",
	FETCH_SINGLE_NT_TRACTOR_ROUTE: "/inventory/web/v2/nt_tractor/limit-one/",
};

export const API_BASE_URL = {
	BASE_URL: process.env.REACT_APP_BASE_URL
		? process.env.REACT_APP_BASE_URL
		: "https://used-tractor-backend-staging.azurewebsites.net",
};

export const BLOB_BASE_URL = {
	BASE_URL: process.env.REACT_APP_AZURE_BLOB_BASE_URL
		? process.env.REACT_APP_AZURE_BLOB_BASE_URL
		: "https://castagingstorage.blob.core.windows.net/used-tractor-media-staging",
};

export const BLOB_SASTOKEN = {
	BASE_URL: process.env.REACT_APP_AZURE_SAS_TOKEN
		? process.env.REACT_APP_AZURE_SAS_TOKEN
		: "?sv=2021-06-08&ss=b&srt=sco&sp=rwlaciyx&se=2026-02-17T12:32:27Z&st=2023-02-17T04:32:27Z&spr=https&sig=6GEiTugwrJSoEXqNKJx2fdla07T%2FyFOLm9ku8fzc6fA%3D",
};

//metabase prod and staging links.

export const METABASE_BASE_URL = {
	BASE_URL: process.env.REACT_APP_METABASE_BASE_URL ?? "http://krishe-metabase.centralindia.azurecontainer.io:3000",
};

export const metabseEndpointRoutes = () => {
	let url = new URL(window.location.href);
	let baseUrl = url.origin;
	if (
		baseUrl === "https://production.d1kir5c0e4cr89.amplifyapp.com" ||
		baseUrl === "https://etractorworld.com"
	) {
		return {
      NT_VERIFICATION_ROUTE:"/public/question/52936d3e-0043-4352-995a-06ac15230d2b"
    };
	} else {
		return {
      NT_VERIFICATION_ROUTE:"/question/2345-ut-nt-tractors-list-staging"
    };
	}
};
