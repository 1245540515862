export function matchRange(value, ranges) {
    if(ranges.length > 0){
        for (let i = 0; i < ranges.length; i++) {
            if(!value || value === "" || value === "null" || value === "NA") {
              return "-";
            } else if ((ranges[i]?.value || '').toLowerCase()?.startsWith("less than ") && value < parseInt(ranges[i]?.value.substring(10))) {
              return ranges[i]?.value;
            } else if ((ranges[i]?.value || '').toLowerCase()?.startsWith("greater than ") && value > parseInt(ranges[i]?.value.substring(13))) {
              return ranges[i]?.value;
            } else {
              const rangeBounds = ranges[i]?.value?.split(" - ");
              const lowerBound = parseInt(rangeBounds[0]);
              const upperBound = parseInt(rangeBounds[1]);
              if (value >= lowerBound && value <= upperBound) {
                return ranges[i]?.value;
              }
            }
          }
    }
    return "-";
  }