import React, { useContext, useEffect, useRef, useState } from "react";
import FilterContainer from "../../components/filter";
import ModalWrapper from "../../components/modal";
import Form from "../../components/form";
import { ProductContext } from "../../utils/productContext";
import { useDispatch, useSelector } from "react-redux";
import { userLocationRequest } from "../../store/actions/userLocationAction";
import { airtableUserLocationRequest } from "../../store/actions/airtableUserLocationAction";
import Loader from "../../components/Loader/Loader";
import { useLocation } from "react-router-dom";
import { useInventoryFilter } from "../../utils/useInventoryFilter";
import { checkValuesExist } from "../../utils/checkValuesExist";
import { removeDuplicateArrayOfObj } from "../../utils/removeDuplicateArrayOfObj";
import { useTranslation } from "react-i18next";
import { convertToUppercase } from "../../utils/convertToLowerUppercase";
import { setInvFilterValuesAction } from "../../store/actions/invFilterAction";

export default function InventoryFilter({ dataArray }) {
	//hooks
	const { t: common } = useTranslation("common");
	const { t: field } = useTranslation("field");
	const queryLocation = useLocation();
	const queryParams = new URLSearchParams(queryLocation.search);
	const [brand, setBrand] = useState([]);
	const [model, setModel] = useState([{ value: "", label: "Select model" }]);
	const [state, setState] = useState([{ value: "all", label: "All" }]);
	const [location, setLocation] = useState([
		{ value: "all", label: "All", state: "all" },
	]);
	const [reset, closeModal, apply] = useInventoryFilter();
	const dispatch = useDispatch();

	let locationOg = useRef([{ value: "all", label: "All", state: "all" }]);

	const { brandModel, show } = useContext(ProductContext);

	const languageObj = useSelector((state) => state.languageReducer);
	const { languageCode } = languageObj;
	const lang = convertToUppercase(queryParams.get("lang") || languageCode);
	const userlocationObj = useSelector((state) => state.userLocationReducer);
	const { loading, resUserLocation } = userlocationObj;
	const airtableUserLocationObj = useSelector(
		(state) => state.airtableUserLocationReducer
	);
	const { resATLocation } = airtableUserLocationObj;
	const filterReducerObj = useSelector((state) => state.invFilterReducer);
	let { filterObj } = filterReducerObj;

	useEffect(() => {
		dispatch(userLocationRequest());
		dispatch(airtableUserLocationRequest({ languageCode: lang }));
	}, []);
	//console.log("resATLocation",resATLocation)

	useEffect(() => {
		if (resUserLocation && resUserLocation) {
			let locationVal = location;
			let langLocationVal = [];
			let stateVar = [];
			let shouldBreak = false;
			let stateLoopbreak = false;
			resUserLocation?.data &&
				typeof resUserLocation?.data === "object" &&
				Object.keys(resUserLocation?.data).map((item, index) => {
					item &&
						item !== "null" &&
						(locationVal = [
							...locationVal,
							{
								value: item,
								label: item,
								state: resUserLocation?.data[item]?.["state"],
							},
						]);
				});
			resATLocation &&
				locationVal?.forEach((item) => {
					shouldBreak = false;
					stateLoopbreak = false;
					for (let filterLoc of resATLocation) {
						const itemValueLowerCase = (item.value || "").toLowerCase();
						const filterLocEnLowerCase = (
							filterLoc[`Location(EN)`] || ""
						).toLowerCase();
						if (itemValueLowerCase === filterLocEnLowerCase) {
							langLocationVal.push({
								value: item.value,
								label: filterLoc[`Location(${lang})`],
								state: item?.state,
							});
							shouldBreak = true;
						} else if (
							!checkValuesExist(item.value, resATLocation, "Location(EN)") &&
							filterLocEnLowerCase !== "all"
						) {
							langLocationVal.push({
								value: item.value,
								label: item.value,
								state: item?.state,
							});
							shouldBreak = true;
						}

						if (shouldBreak) {
							break;
						}
					}

					for (let filterLoc of resATLocation) {
						const itemStateLowerCase = (item.state || "").toLowerCase();
						const filterStateEnLowerCase = (
							filterLoc[`State(EN)`] || ""
						).toLowerCase();
						//console.log("resATLocation",itemStateLowerCase, filterStateEnLowerCase)
						if (
							item?.state &&
							filterLoc[`State(EN)`] &&
							itemStateLowerCase === filterStateEnLowerCase
						) {
							//console.log("if",filterLoc?.[`State(${lang})`], item?.state,{value: item?.state, label: filterLoc[`State(${lang})`] });
							stateVar.push({
								value: item?.state,
								label: filterLoc[`State(${lang})`],
							});
							stateLoopbreak = true;
						} else if (
							item?.state &&
							filterLoc[`State(EN)`] &&
							filterStateEnLowerCase !== "all" &&
							!checkValuesExist(item.state, resATLocation, "State(EN)")
						) {
							//console.log("else if",filterLoc?.[`State(EN)`], item?.state,{value: item?.state, label: item.state });
							stateVar.push({ value: item?.state, label: item.state });
							stateLoopbreak = true;
						}

						if (stateLoopbreak) {
							break;
						}
					}
				});
			// console.log("stateVar",stateVar)

			langLocationVal = removeDuplicateArrayOfObj({
				arraywithDuplicateObj: langLocationVal,
			});
			stateVar = removeDuplicateArrayOfObj({ arraywithDuplicateObj: stateVar });

			//  console.log("stateVar",stateVar)
			locationOg.current = langLocationVal;
			//setLocation(langLocationVal);
			setState(stateVar);
		}
	}, [resUserLocation, resATLocation]);

	useEffect(() => {
		let filteredLocation = locationOg.current?.filter((location) => {
			if (filterObj["state"] === "all") {
				return location.value === "all";
			} else if (filterObj["state"]) {
				return (
					location.value === "all" || location.state === filterObj["state"]
				);
			}
		});
		if (filterObj["state"] === "all") {
			filterReducerObj.filterObj = {
				...filterReducerObj.filterObj,
				location: "all",
			};
			dispatch(
				setInvFilterValuesAction({ filterObj: filterReducerObj.filterObj })
			);
		}

		setLocation(filteredLocation);
	}, [filterObj["state"]]);

	useEffect(() => {
		let brands = [];
		if (typeof brandModel === "object") {
			Object?.keys(brandModel)?.map((brandName) => {
				brands.push({
					value: brandName,
					label: brandName,
				});
			});
		}
		brands.unshift({ value: "", label: "Select brand" });
		setBrand(brands);
	}, [brandModel]);

	let brandVar = filterObj["brand"];
	useEffect(() => {
		let models = [];
		if (typeof brandModel === "object") {
			Object?.keys(brandModel)?.map((brandName) => {
				if (brandName === brandVar) {
					brandModel?.[brandName]?.models?.map((modelName) => {
						models.push({
							value: modelName,
							label: modelName,
						});
					});
				}
			});
		}
		models.unshift({ value: "", label: "Select model" });
		setModel(models);
	}, [brandVar]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		//console.log("handlestart",name, value,filterObj)
		if (
			name === "brand" &&
			filterObj["brand"] !== "" &&
			filterObj["brand"] !== value
		) {
			filterObj = { ...filterReducerObj.filterObj, model: "" };
		}
		filterObj = {
			...filterObj,
			[name]: value,
		};
		//console.log("handle",filterObj)
		dispatch(setInvFilterValuesAction({ filterObj }));
	};

	const iconvalue = (e, fieldName, answer) => {
		filterObj = { ...filterObj, [fieldName]: answer };
		// console.log("icon",filterObj)

		dispatch(setInvFilterValuesAction({ filterObj }));
	};

	return (
		<ModalWrapper>
			<FilterContainer isShown={show}>
				<FilterContainer.Header>
					<FilterContainer.HeaderText>
						{common("filter")}
					</FilterContainer.HeaderText>
					<div>
						<FilterContainer.HeaderText
							style={{ display: "flex", alignItems: "center" }}
							onClick={closeModal}
						>
							{common("close")}{" "}
							<img src="./assets/close_black.svg" alt="back" />
						</FilterContainer.HeaderText>
					</div>
				</FilterContainer.Header>
				<FilterContainer.Main>
					<FilterContainer.MainContent>
						<FilterContainer.MainLabel>
							{field("brand")}
						</FilterContainer.MainLabel>
						<Form.GroupFields>
							<Form.Dropdown
								name="brand"
								id="brand"
								options={brand}
								onChange={(e) => handleChange(e)}
								value={filterObj["brand"]}
							></Form.Dropdown>
						</Form.GroupFields>
					</FilterContainer.MainContent>
					<FilterContainer.MainContent>
						<FilterContainer.MainLabel>
							{field("model")}
						</FilterContainer.MainLabel>
						<Form.GroupFields>
							<Form.Dropdown
								name="model"
								id="model"
								options={model}
								onChange={(e) => handleChange(e)}
								value={filterObj["model"]}
							></Form.Dropdown>
						</Form.GroupFields>
					</FilterContainer.MainContent>
					{/* <FilterContainer.MainContent>
                    <FilterContainer.MainLabel>Brand</FilterContainer.MainLabel>
                    <Form.GroupFields>
                        <Select
                              isMulti
                              name="brand"
                              id="brand"
                              options={brand}
                              className="fZMnD"
                              onChange={(e) => multiDrop(e, "brand")}
                              classNamePrefix="filter"
                              menuPlacement="auto"
                              components={{ IndicatorSeparator:() => null }}
                              value={multiValue["brand"]}
                        />
                    </Form.GroupFields>
                </FilterContainer.MainContent>
                <FilterContainer.MainContent>
                    <FilterContainer.MainLabel>Model</FilterContainer.MainLabel>
                    <Form.GroupFields>
                        <Select
                              isMulti
                              name="model"
                              id="model"
                              options={model}
                              className="fZMnD"
                              onChange={(e) => multiDrop(e, "model")}
                              classNamePrefix="filter"
                              menuPlacement="auto"
                              components={{ IndicatorSeparator:() => null }}
                              value={multiValue["model"]}
                        />
                    </Form.GroupFields>
                </FilterContainer.MainContent> */}
					<FilterContainer.MainContent>
						<FilterContainer.MainLabel>
							{field("category")}
						</FilterContainer.MainLabel>
						<Form.GroupFields>
							<div style={{ display: "flex", gap: "5px" }}>
								{filterObj["category"] === 3 ? (
									<FilterContainer.Icon
										style={{ border: "1px solid orange" }}
										onClick={(e) => iconvalue(e, "category", 3)}
									>
										<img
											src="./assets/certify/certify.svg"
											alt="certify Icon"
										/>
										Certified
									</FilterContainer.Icon>
								) : (
									<FilterContainer.Icon
										onClick={(e) => iconvalue(e, "category", 3)}
									>
										<img
											src="./assets/certify/certify.svg"
											alt="certify Icon"
										/>
										Certified
									</FilterContainer.Icon>
								)}
								{filterObj["category"] === 1 ? (
									<FilterContainer.Icon
										style={{ border: "1px solid orange" }}
										onClick={(e) => iconvalue(e, "category", 1)}
									>
										<img
											src="./assets/certify/noncertify.svg"
											alt="non-certify Icon"
										/>
										Non Certified
									</FilterContainer.Icon>
								) : (
									<FilterContainer.Icon
										onClick={(e) => iconvalue(e, "category", 1)}
									>
										<img
											src="./assets/certify/noncertify.svg"
											alt="non-certify Icon"
										/>
										Non Certified
									</FilterContainer.Icon>
								)}
							</div>
						</Form.GroupFields>
					</FilterContainer.MainContent>
					<FilterContainer.MainContent>
						<FilterContainer.MainLabel>
							{field("price_range")}
						</FilterContainer.MainLabel>
						<Form.GroupFields>
							<div style={{ display: "flex", gap: "5px" }}>
								{filterObj["price_range"] === "50k-2L" ? (
									<FilterContainer.Icon
										style={{ border: "1px solid orange" }}
										onClick={(e) => iconvalue(e, "price_range", "50k-2L")}
									>
										50K-2L
									</FilterContainer.Icon>
								) : (
									<FilterContainer.Icon
										onClick={(e) => iconvalue(e, "price_range", "50k-2L")}
									>
										50K-2L
									</FilterContainer.Icon>
								)}
								{filterObj["price_range"] === "3L-5L" ? (
									<FilterContainer.Icon
										style={{ border: "1px solid orange" }}
										onClick={(e) => iconvalue(e, "price_range", "3L-5L")}
									>
										3L-5L
									</FilterContainer.Icon>
								) : (
									<FilterContainer.Icon
										onClick={(e) => iconvalue(e, "price_range", "3L-5L")}
									>
										3L-5L
									</FilterContainer.Icon>
								)}
								{filterObj["price_range"] === "5L+" ? (
									<FilterContainer.Icon
										style={{ border: "1px solid orange" }}
										onClick={(e) => iconvalue(e, "price_range", "5L+")}
									>
										5L+
									</FilterContainer.Icon>
								) : (
									<FilterContainer.Icon
										onClick={(e) => iconvalue(e, "price_range", "5L+")}
									>
										5L+
									</FilterContainer.Icon>
								)}
							</div>
						</Form.GroupFields>
					</FilterContainer.MainContent>
					<FilterContainer.MainContent>
						<FilterContainer.MainLabel>
							{field("state")}
						</FilterContainer.MainLabel>
						<Form.GroupFields>
							<Form.Dropdown
								name="state"
								id="state"
								options={state}
								onChange={(e) => handleChange(e)}
								value={filterObj["state"]}
							></Form.Dropdown>
						</Form.GroupFields>
					</FilterContainer.MainContent>
					<FilterContainer.MainContent>
						<FilterContainer.MainLabel>
							{field("location")}
						</FilterContainer.MainLabel>
						{loading && !resUserLocation ? (
							<Loader />
						) : (
							<Form.GroupFields>
								<Form.Dropdown
									name="location"
									id="location"
									options={location}
									onChange={(e) => handleChange(e)}
									value={filterObj["location"]}
								></Form.Dropdown>
							</Form.GroupFields>
						)}
					</FilterContainer.MainContent>
				</FilterContainer.Main>
				<FilterContainer.ButtonContent>
					<FilterContainer.Button
						style={{ background: "#FFFF", color: "#000" }}
						onClick={reset}
					>
						{common("reset")} {common("filter").toUpperCase()}
					</FilterContainer.Button>
					<FilterContainer.Button onClick={() => apply(dataArray)}>
						{common("apply")}
					</FilterContainer.Button>
				</FilterContainer.ButtonContent>
			</FilterContainer>
		</ModalWrapper>
	);
}
