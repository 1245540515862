import * as types from "../types";

const initialStates = {
    loading: false,
    resInvAll: null,
    error: null
}

export default function inventoryAllReducer(state = initialStates, action){
    switch(action.type){
        case types.FETCH_ALL_INVENTORY_DATA_REQUEST:
            return{
                ...state,
                loading: true
            }

        case types.FETCH_ALL_INVENTORY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                resInvAll: action.payload.resInvAll
            }

        case types.FETCH_ALL_INVENTORY_DATA_FAILURE:
            return{
                ...state,
                loading: false,
                resInvAll: null,
                error: action.payload.error
            }
        
        case types.FETCH_ALL_INVENTORY_DATA_RESET:
            return{
                ...state,
                loading: false,
                resInvAll: null,
                error: null
            }        

        default :
            return state;
    }

}