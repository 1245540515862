import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { ProductContext } from "./productContext";

const useGetVideoData = ({ urlData, type }) => {
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(true);
  //console.log("urlData11",urlData);
  useEffect(() => {
    setLoading(true);
    fetchUrl();
  }, [urlData]);
  const { setYtVideoData, ytVideoData } = useContext(ProductContext);
  
  //  console.log("urlDataurlFieldName",urlData,urlFieldName);

  const fetchUrl = async () => {
    const urlPromises = [];
    if (urlData) {
      for (const url of urlData) {
        if (url.hasOwnProperty("yt_links") && url["yt_links"]) {
          const promise = axios.get(
            `https://www.youtube.com/oembed?url=${url["yt_links"]}`
          );
          urlPromises.push(promise);
        }
      }
      if (urlPromises.length > 0) {
        const resolvedStories = await Promise.all(urlPromises);
        const data = resolvedStories.map((response, index) => ({
          ...response.data,
          url: urlData[index]["yt_links"],
          type: type,
        }));
        //    console.log("data",data,type);
        setVideoData(data);
        if (ytVideoData) {
          let ytdata = ytVideoData;
          data.map((pre) => {
            if (pre.hasOwnProperty("type") && pre.type === type) {
              ytdata = [...ytdata, pre];
            } else {
              ytdata = [...ytdata, { ...pre, [type]: type }];
            }
          });
          // console.log("ytdata",ytdata)
          setYtVideoData(ytdata);
        }
        setLoading(false);
      }
    }
  };
  return [videoData, loading];
};

export default useGetVideoData;
