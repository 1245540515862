import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { utFaqBase } from "../config/airtable.config";

const useFaqFetch = ({
  tableName,
  fields,
  filterByFormula = "",
  maxRecords = 1000,
  dependencyState = null,
}) => {
  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);
  const ref = useRef(false);

  const faqLangObj = useSelector(state => state.faqLanguageReducer)
  const { languageCode } = faqLangObj;

  useEffect(() => {
    setFetching(true);
    utFaqBase(tableName)
      .select({
        maxRecords: 100,
        view: "Grid view",
        fields,
        filterByFormula,
      })
      .eachPage(
        function page(records, _fetchNextPage) {
          let recordsData = records.map((item) => {
            return { ...item.fields };
          });

          setData(recordsData);

          setError(false);
          setFetching(false);
        },
        function done(err) {
          setError(false);
          setData(null);
          setFetching(false);
        }
      );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencyState,languageCode]);
  return [data, fetching, error];
};

export default useFaqFetch;