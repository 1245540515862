import React from "react";
import {
  Container,
  DiscountTag,
  VideoElement,
  VideoDetails,
  RequestButton,
} from "./styles/videoplay";
import { GrLinkPrevious } from "react-icons/gr";
import { Link } from "react-router-dom";

export default function VideoPlay({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

VideoPlay.DiscountTag = function VideoPlayDiscountTag({
  children,
  ...props
}) {
  return (
    <DiscountTag {...props}>
      <Link to={"/"}>
        <GrLinkPrevious style={{ width: "20px" }} />
      </Link>
      {children}
    </DiscountTag>
  );
};

VideoPlay.VideoElement = function VideoPlayVideoElement({ children, src }) {
  return <VideoElement src={src}>{children}</VideoElement>;
};

VideoPlay.VideoDetails = function VideoPlayVideoDetails({
  children,
  ...props
}) {
  return (
    <VideoDetails>
      {children}
    </VideoDetails>
  );
};

VideoPlay.RequestButton = function VideoPlayRequestButton({
  children,
  ...props
}) {
  return <RequestButton {...props}>{children}</RequestButton>;
};