import React, { useState, useEffect } from 'react';
import Loader from "../../components/Loader/Loader";
import Poster from "../../components/poster";
import Form from "../../components/form";
import { useCampaignPost } from '../../utils/useCampaingPost';
import Swal from 'sweetalert2';
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import {leadsSubmitRequest} from "../../store/actions/leadsSubmitAction";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { convertToUppercase } from '../../utils/convertToLowerUppercase';

ReactGA.initialize("G-QTYHP65SBV");

const Campaign = ({...props}) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const { t: landingPageLang, i18n } = useTranslation('landingPage');

  const lang = sessionStorage.getItem("lang");
  const source = sessionStorage.getItem("source");
  const campaign = sessionStorage.getItem("campaign");
  const location = sessionStorage.getItem("location");
  const state = sessionStorage.getItem("state");

  useEffect(()=>{
    i18n.changeLanguage(convertToUppercase(lang));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

      let url = new URL(window.location.href);
      let baseUrl = url.origin;
      let sourceUrl;

      if(baseUrl === "https://production.d1kir5c0e4cr89.amplifyapp.com"){
        sourceUrl = 'Production';
      }else{
        sourceUrl = 'staging';
      }

      const [postHandler, postSubmitting, postError, postSuccess] = useCampaignPost({
        tableName: `Submission`,
        fields: {
          Name: name,
          Phone:phone,
          TimeStamp: new Date().toLocaleString(),
          Source: source,
          Language: lang,
          url: sourceUrl,
          Campaign: campaign,
          Location: location,
          State: state,
       },
      });

    const eventTrack = (category, action, label) => {
      ReactGA.event({
        category: category,
        action: action,
        label: label,
     })
    }

    useEffect(() => {
      if(postSuccess){
        eventTrack("Success", "generate_lead", "Success Message");
        Swal.fire({
          icon: "success",
          text: landingPageLang('success_message'),
          confirmButtonColor: '#652078',
          }).then((res) => {
          if (res) {
            setName("");
            setPhone("");
          }
          })        
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[postSuccess])

    const handleSubmit=() => {
      if(name === ""){
          Swal.fire({
          icon: "info",
          text: landingPageLang('field_error'),
          confirmButtonColor: '#652078',
          })
      }else if(!/^[A-Za-z\s]*$/.test(name)){
        Swal.fire({
          icon: "info",
          text: landingPageLang('name_error'),
          confirmButtonColor: '#652078',
          })
      }
      else if(phone === ""){
          Swal.fire({
          icon: "info",
          text: landingPageLang('field_error'),
          confirmButtonColor: '#652078',
          })
      }else if(phone && phone.length !== 10){
          Swal.fire({
          icon: "info",
          text: landingPageLang('number_error'),
          confirmButtonColor: '#652078',
          })
      }else{
      postHandler();

      let requestLeads={ 
          "l_source": "digital",
          "sub_source":source,
          "phone": phone,
          "name": name,
          "language": lang,
          "l_campaign": campaign,
          "location": location,
          "state": state,
      };
      props.submitLeads({requestLeads})

      }
    }

    const bannerImage=()=>{
      if(lang === "hi"){
        return "../assets/campaign-banner-hi.jpg"
      }else{
        return "../assets/campaign-banner.jpg"
      }
    }

  return (
    <>
       {lang !== "hi" && ( 
       <div style={{display: "flex",alignItems:"center",height:"100px",background:"#fff",padding:"10px"}}>
          <img src="../assets/tractor_world.svg" alt="" height="90px"/>
        </div>)}
        <Poster>
          <Poster.Image src={bannerImage()} />
        </Poster>
        <div style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", margin:"10px"}}>
        <Form style={{marginTop:"0px",marginBottom:"0px"}}>
            <Form.GroupFields>
            <div style={{display: "flex", justifyContent: 'center',alignItems:"center",marginBottom:'20px'}}>          
              <h3 style={{textAlign:"center",color:"#652078",fontSize:"26px"}}>{landingPageLang('form_header')}</h3>
            </div>
              <Form.InputWrapper style={{height:"40px",borderRadius:"17px 15px 15px 17px"}}>
                <img src="../assets/person.png" alt="" width="40px" height="40px" />
                <Form.Input
                  type="text"
                  name="name"
                  value={name}
                  placeholder={landingPageLang('name')}
                  onChange={(e) => setName(e.target.value)}
                  required
                  style={{border:'none',borderRadius:"15px"}}
                />
              </Form.InputWrapper>
              <Form.InputWrapper style={{height:"40px",borderRadius:"17px 15px 15px 17px"}}>
              <img src="../assets/call.png" alt="" width="40px" height="40px"/>
              <Form.Input
                type="text"
                name="phone"
                value={phone}
                placeholder={landingPageLang('mobile_number')}
                onChange={(e) => setPhone(e.target.value)}
                required
                style={{border:'none',borderRadius:"15px"}}
              />
              </Form.InputWrapper>
            </Form.GroupFields>
          </Form>
          <div style={{padding:"16px 16px",display:"flex",alignItems:"center",justifyContent:"center"}}>
           {postSubmitting ? 
           <Loader/>:(<button 
              style=
                  {{background: '#652078',
                    borderRadius: '4px', opacity: '1', 
                    width:'35%',height: '40px', 
                    border: 'none', color:'#FFFF', fontSize:'18px',
                    textTransform:"uppercase"}}
                    disabled={postSuccess}
                    onClick={handleSubmit}>
                    {landingPageLang('submit')}
              </button>)}
            </div>
          </div>
          <div style={{display: "flex", justifyContent: 'center',alignItems:"center",marginBottom:'20px'}}>          
            <h3 style={{textAlign:"center",color:"#652078",marginTop:"10px"}}>{landingPageLang('header')}<br></br>{landingPageLang('header_last')}</h3>
          </div> 
          <Poster>
            <Poster.Image src="../assets/tractors-group.jpg" />
          </Poster>
          <div style={{display: "flex", justifyContent: 'center',alignItems:"center",marginBottom:'20px'}}>          
            <h3 style={{textAlign:"center",color:"#652078",marginTop:"10px",fontSize:"26px"}}>{landingPageLang('testimonials_header')}</h3>
          </div>
          <div style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",margin:"25px",height:"375px",display:"flex",flexDirection:"column"}}>
            <ReactPlayer
              url={landingPageLang('testimonial_video_first')}
              playing={false}
              playsinline={true}
              controls
              width="100%"
              height="300px"
            />
            <div style={{display:"flex",flexDirection:"column",padding:"20px"}}>
              <span>{landingPageLang('name')}: <b>{landingPageLang('testimonial_first_name')}</b></span>
              <span>{landingPageLang('village')}: <b>{landingPageLang('testimonial_first_place')}</b></span>
            </div>
          </div>
          <div style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",margin:"25px",height:"375px"}}>
            <ReactPlayer
              url={landingPageLang('testimonial_video_second')}
              playing={false}
              playsinline={true}
              controls
              width="100%"
              height="300px"
            />
            <div style={{display:"flex",flexDirection:"column",padding:"20px"}}>
              <span>{landingPageLang('name')}: <b>{landingPageLang('testimonial_second_name')}</b></span>
              <span>{landingPageLang('village')}: <b>{landingPageLang('testimonial_second_place')}</b></span>
            </div>
          </div>
          <div style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",margin:"25px",height:"375px",marginBottom:"40px"}}>
            <ReactPlayer
              url={landingPageLang('testimonial_video_third')}
              playing={false}
              playsinline={true}
              controls
              width="100%"
              height="300px"
            />
            <div style={{display:"flex",flexDirection:"column",padding:"20px"}}>
              <span>{landingPageLang('name')}: <b>{landingPageLang('testimonial_third_name')}</b></span>
              <span>{landingPageLang('village')}: <b>{landingPageLang('testimonial_third_place')}</b></span>
            </div>
          </div>
          <div style={{display: "flex", justifyContent: 'center',alignItems:"center",height:"50px",background:"#652078"}}>          
            <h3 style={{textAlign:"center",color:"#fff",fontSize:"14px"}}>Powered By: <span style={{color:"rgb(253, 170, 119)"}}>Tractor World</span></h3>
          </div>
      </>
  )
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  submitLeads:({requestLeads})=>{
    dispatch(leadsSubmitRequest({requestLeads}))
  },
});
 

export default connect(mapStateToProps,mapDispatchToProps)(Campaign);