import * as types from "../types";

const initialState = {
    loading: false,
    tableName: null,
    resDynamicData: null,
    error: null
} 

export default function airtableDynamicTableReducer(state = initialState, action){

    switch(action.type){
        case types.FETCH_DYNAMICTABLE_REQUEST:
            return {
                ...state,
                loading: true,
                tableName: action.payload.tableName
            }

        case types.FETCH_DYNAMICTABLE_SUCCESS: 
            return {
                ...state,
                loading: false,
                resDynamicData: action.payload.resDynamicData
            }

        case types.FETCH_DYNAMICTABLE_FAILURE: 
            return {
                ...state,
                loading: false,
                resDynamicData: null,
                error: action.payload.error
        }    


        default : 
            return state
    }

}