import React from "react";
import FilterContainer from "../../components/filter";
import ModalWrapper from "../../components/modal";
import Form from "../../components/form";
import { useDispatch, useSelector } from "react-redux";
import { expensePagePosition } from "../../store/actions/dealerInventoryAction";
import { ExpenseFinalOption, filterExpenseReset, filterExpenseSetData, filterExpenseShow, filterExpenseTypeData } from "../../store/actions/expenseFilterAction";
import Swal from "sweetalert2";
import { useQuery } from "../../utils/useQuery";


export default function ExpenseFilter(){
    const dispatch = useDispatch();
    const dealerInvObj = useSelector(state => state.dealerInventoryReducer);
    const filterExpenseObj = useSelector(state => state.expenseFilterReducer);
    const { showFilter, filterOptions } = filterExpenseObj;
    const { resInvData } = dealerInvObj;
    const queryParams = useQuery();
    const userid = queryParams.get("userid");


    function closeModal() {
       dispatch(filterExpenseShow({userid:userid,showFilter:false}));  
    }

    const reset = () => { 
        dispatch(filterExpenseReset());
        dispatch(expensePagePosition({currentPagePosition:1}))
    }

    const apply = () => {

      if(filterOptions.status !== ""){
        dispatch(ExpenseFinalOption({finalOption:filterOptions.status}))
      let filteredData =  resInvData?.data?.filter((item) => {
            if(filterOptions.status === "inward" && item.status === 0){
                return item;
            }else if(filterOptions.status === "live" && (item.status === 1 || item.status === 2 || item.status === 3)){
                return item;
            }else if(filterOptions.status === "sold" && item.status === 4){
                return item;
            }else if(filterOptions.status === ""){
                return item;
            }
        })

            dispatch(filterExpenseSetData({filteredData}))
            dispatch(expensePagePosition({currentPagePosition:1}));
            dispatch(filterExpenseShow({userid:userid,showFilter:false}));
            if(filteredData.length === 0){
                Swal.fire({
                    icon: "error",
                    text: "No Result Found. Please try with different combination.",
                    confirmButtonText: "Ok"
                  }).then((res) => {
                    
                  });
            }
        
    }
    }

    const iconvalue = (value) => {
        dispatch(filterExpenseTypeData({filterOptions:value}))
    } 

    return (
        <ModalWrapper>
        <FilterContainer isShown={showFilter}>
            <FilterContainer.Header>
                <FilterContainer.HeaderText>Filter By</FilterContainer.HeaderText>
                <div>
                    <FilterContainer.HeaderText style={{display:'flex',alignItems:'center'}} onClick={closeModal}>Close <img src="./assets/close_black.svg" alt="back" /></FilterContainer.HeaderText>
                </div>
            </FilterContainer.Header>
             <FilterContainer.Main>
                <FilterContainer.MainContent>
                    <FilterContainer.MainLabel>Status</FilterContainer.MainLabel>
                    <Form.GroupFields>
                    <div style={{display:'flex',gap:'5px'}}>
                    {filterOptions.status === "inward" ? <FilterContainer.Icon style={{border:'1px solid orange'}} onClick={() => iconvalue("inward")}>
                        Inward
                    </FilterContainer.Icon> : <FilterContainer.Icon onClick={() => iconvalue("inward")}>
                        Inward
                    </FilterContainer.Icon>}
                    {filterOptions.status === "live" ? <FilterContainer.Icon style={{border:'1px solid orange'}} onClick={() => iconvalue("live")}>
                        Live
                    </FilterContainer.Icon> : <FilterContainer.Icon onClick={() => iconvalue("live")}>
                        Live
                    </FilterContainer.Icon>}
                    {filterOptions.status === "sold" ? <FilterContainer.Icon style={{border:'1px solid orange'}} onClick={() => iconvalue("sold")}>
                        Sold
                    </FilterContainer.Icon> : <FilterContainer.Icon onClick={() => iconvalue("sold")}>
                        Sold
                    </FilterContainer.Icon>}
                    </div>
                    </Form.GroupFields>
                </FilterContainer.MainContent>               
            </FilterContainer.Main>
            <FilterContainer.ButtonContent>
            <FilterContainer.Button style={{background:'#FFFF',color:'#000'}} onClick={reset}>RESET FILTER</FilterContainer.Button>
                <FilterContainer.Button onClick={apply}>APPLY</FilterContainer.Button>
            </FilterContainer.ButtonContent>
        </FilterContainer>
        </ModalWrapper>
    )
}