import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
//url,redirect
export const usePostBE = ({...props}) => {
    const [postData, setPostData] = useState("");
    const [submittingBE,setSubmittingBE] = useState(false);
    const [postStatus, setPostStatus] = useState(false);
    const isFirstRender = useRef(true);
    const ref =  useRef(false);
    let navigate = useNavigate();    

    const handleRedirect = () => {
      window.location.href =
        window.location.href + "&submit.jotform.com/submit";
    };


    const postFn = (postval) => {
      setSubmittingBE(true);
        let header = props?.user_id ? { 'Content-Type': 'application/json','User-Id': props?.user_id } : { 'Content-Type': 'application/json' }

        const requestOptions = {
            method: 'POST',
            headers: header,
            body: JSON.stringify(postval)
          };
          let url = props?.url;
          let redirect = props?.redirect;
         // console.log("request",requestOptions);      
          fetch(url, requestOptions)
          .then(resp => resp.json())
          .then(data => {
            setPostData(data)
            setPostStatus(data.status)
            setSubmittingBE(false);    
           // console.log("setStatus22",data.status)
            if(submittingBE === false){
                if(data.status){
                  if(redirect !== "walkinLeadView" && redirect !== "walkinLead" && redirect !== "Expense" && redirect !== "ExpenseEdit"){
                    Swal.fire({
                      icon: "success",
                      confirmButtonColor: '#652078',
                      text: `Successfully Submitted`
                    }).then((res) => {
                      if (res) {
                        handleRedirect();
                      }
                    })}
                  }else if(!data.status){
                    Swal.fire({
                        icon: "error",
                        text: data.message
                      }).then((res) => {
                        if (res) {
                            if(!redirect || redirect!=="" ){ }else{ navigate(`/`)};
                        }
                  });
                }
            }
          });
      } 
    
    
      useEffect(() => {
        if(ref.current) return;
        if(isFirstRender.current){
            isFirstRender.current = false;
        }else{
          postFn();
        }
        ref.current = true;
      },[])
    


    return [postData,postFn,submittingBE,postStatus]
}