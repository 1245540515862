import React from "react";
import SpecificationContainer from "../../../components/specification";
import { dateTime_MWORD_AMPM } from "../../../utils/dateFormat";
import { BiRupee } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../utils/useQuery";

export default function ExpenseOperationView({ value }) {
  const queryParams = useQuery();
  const userid = queryParams.get("userid");
  const brand = queryParams.get("brand");
  const model = queryParams.get("model");
  const year = queryParams.get("year");
  const rto = queryParams.get("rto");


  const allData = value;
  let dateTime = value?.["updated_at"];
  value = value?.["form_data"];
  const headerFontSize = "12px";
  const valueFontSize = "12px";
  const bgColor = "#F1E5F1";

  let navigate = useNavigate();
  const editTo = () => {
    navigate(`/edit-expense-tracker?userid=${userid}&brand=${brand}&model=${model}&year=${year}&rto=${rto}`, {
      state: { expense_id: allData.id, formType: allData.form_type },
    });
  };

  return (
    <SpecificationContainer style={{ marginTop: "24px" }}>
      <SpecificationContainer.TableConatiner>
        <SpecificationContainer.TableHeader
          bgColor={bgColor}
        >
          <SpecificationContainer.TableHeaderText>
            <b>Refurbishment Details</b>
            <span className="edit_text" onClick={() => editTo()}>
              <img src="../assets/expense/edit.svg" alt="edit" />
              Edit
            </span>
          </SpecificationContainer.TableHeaderText>
        </SpecificationContainer.TableHeader>
      </SpecificationContainer.TableConatiner>
      {value?.["cost_of_refurbishment_cost"] !== 0 &&
        value?.["cost_of_refurbishment_cost"] && (
          <SpecificationContainer.TableConatiner>
            <SpecificationContainer.TableHeader fontSize={headerFontSize}>
              <SpecificationContainer.TableHeaderText>
                <span>Cost of refurbishment</span>
              </SpecificationContainer.TableHeaderText>
            </SpecificationContainer.TableHeader>
            <SpecificationContainer.TableValues fontSize={valueFontSize}>
              <SpecificationContainer.TableValuesText>
              <BiRupee />{value?.["cost_of_refurbishment_cost"]}
              </SpecificationContainer.TableValuesText>
            </SpecificationContainer.TableValues>
          </SpecificationContainer.TableConatiner>
        )}
      {value?.["provision_image"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Provision image
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.["provision_image"] && (
                <a href={value?.["provision_image"]}>
                  <img
                    src={value?.["provision_image"]}
                    height="50"
                    width="50"
                    alt=""
                  />
                  <p>click here to donwload</p>
                </a>
              )}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.["documentation_cost"] !== 0 && value?.["documentation_cost"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              <span>Documentation cost</span>
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
            <BiRupee />{value?.["documentation_cost"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.["certification_cost"] !== 0 && value?.["certification_cost"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              <span>Certification cost</span>
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
            <BiRupee />{value?.["certification_cost"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.["others"] !== 0 && value?.["others"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              <span>Others (Commission fees, etc)</span>
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
            <BiRupee />{value?.["others"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.["insurance"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Insurance
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.["insurance"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.["remarks"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Remarks
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.["remarks"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      <SpecificationContainer.TableConatiner>
        <SpecificationContainer.TableHeader fontSize={headerFontSize}>
          <SpecificationContainer.TableHeaderText>
            Timestamp
          </SpecificationContainer.TableHeaderText>
        </SpecificationContainer.TableHeader>
        <SpecificationContainer.TableValues fontSize={valueFontSize}>
          <SpecificationContainer.TableValuesText>
            {dateTime_MWORD_AMPM(dateTime)}
          </SpecificationContainer.TableValuesText>
        </SpecificationContainer.TableValues>
      </SpecificationContainer.TableConatiner>
    </SpecificationContainer>
  );
}
