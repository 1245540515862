import React from "react";
import { Container, HeaderConatiner, Title, ContentContainer, Content, IconContainer } from "./styles/accordian"

export default function Accordian({children,...props}){
    return <Container {...props}>{children}</Container>
}

Accordian.HeaderConatiner = function AccordianHeaderConatiner({children,...props}){
    return <HeaderConatiner {...props}>{children}</HeaderConatiner>
}

Accordian.Title = function AccordianTitle({children,...props}){
    return <Title {...props}>{children}</Title>
}

Accordian.IconContainer = function AccordianIconContainer({children,...props}){
    return <IconContainer {...props}>{children}</IconContainer>
}

Accordian.ContentContainer = function AccordianContentContainer({children,...props}){
    return <ContentContainer {...props}>{children}</ContentContainer>
}

Accordian.Content = function AccordianContent({children,...props}){
    return <Content {...props}>{children}</Content>
}