import * as types from "../types";

const initialState = {
    loading: false,
    reqTractorId: null,
    resNtInv: null,
    error: null
}

export default function fetchNtSingleTractorReducer(state = initialState, action){
    switch(action.type){
        case types.FETCH_SINGLE_NT_TRACTOR_REQUEST:
            return {
                ...state,
                loading: true,
                reqTractorId: action.payload.reqTractorId
            }
        
        case types.FETCH_SINGLE_NT_TRACTOR_SUCCESS:
            return {
                ...state,
                loading: false,
                resNtInv: action.payload.resNtInv
            }

        case types.FETCH_SINGLE_NT_TRACTOR_FAILURE:
            return {
                ...state,
                loading: false,
                reqTractorId: null,
                resNtInv: null,
                error: action.payload.error,
            }

        default :
        return state
    }
}