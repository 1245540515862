import { omit } from "lodash";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import Form from "../../components/form";
import Loader from "../../components/Loader/Loader";
import ModalWrapper from "../../components/modal";
import { ProductContext } from "../../utils/productContext";
import { usePost } from "../../utils/usePost";
import { useDispatch, useSelector } from "react-redux";
import { leadsSubmitRequest, leadsSubmitReset } from "../../store/actions/leadsSubmitAction";
import { useTranslation } from "react-i18next";


export default function PopupForm({queryState,user_id,tractor_id}) {

  const { t:common } = useTranslation("common");
  const { t:fieldTrans } = useTranslation("field");
  const { t:successTrans } = useTranslation("success");
  const { t:errorTrans } = useTranslation("error");

  const [field,setField] = useState({
    "name":'',
    "phone":''
  });
  const dispatch = useDispatch();
  const leadsSubmitObj = useSelector(state => state.leadsSubmitReducer);
  const { loading, resLeads } = leadsSubmitObj;


  const {
    errors,
    setErrors,
    setShow,
  } = useContext(ProductContext);

  const [postHandler, submitting, postError, success] = usePost();

  useEffect(() => {
    if(resLeads){ 
    Swal.fire({
      icon: "success",
      confirmButtonColor: '#652078',
      text: successTrans("success_thank_you_will_get_back_to_you")
    }).then((res) => {
      if (res) {
        dispatch(leadsSubmitReset())
        setShow(false);
      }
    })}
  },[resLeads])
  


  let errorObj = errors;
  var errorReturn = "";

  const validate = (event, name, value, field) => {
    if(value === "" && name==="name"){
      errorObj =  {...errorObj, [name]: errorTrans("error_enter_name")};
    }else if( value !== "" && name==="name" && !((/^[a-zA-Z ]{2,30}$/).test(value))){
      errorObj =  {...errorObj, [name]: errorTrans("error_enter_valid_name")};
    }else if(value === "" && name==="phone"){
      errorObj =  {...errorObj, [name]: errorTrans("error_enter_phone_number")};
    }else if(value !== "" && name==="phone"){
      if(isNaN(value) || value.length > 10 || !((/^[0-9]{10}/).test(value))){
        ///^[6-9][0-9]{10}/
        errorObj =  {...errorObj, [name]: errorTrans("error_enter_valid_phone_number")};
      }else{
      let newObj = omit(errors, name);
      errorObj = newObj;
      }
    }else if(errors?.hasOwnProperty(name)){
      //console.log("name",name)
      let newObj = omit(errors, name);
      errorObj = newObj;
    }
    return errorObj;
  }

  const handleChange = (e,field) => {
    const {type,name,value} = e.target;
      errorReturn = validate(e, name, value,field);
    setErrors(errorReturn);
    setField((pre) => ({...pre,[name]:value}));
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    var defaultFieldJsonKey = Object.keys(field); 
    for(var i = 0; i < defaultFieldJsonKey.length; i++){
      if(defaultFieldJsonKey){
          errorReturn = validate(e, defaultFieldJsonKey[i], field[defaultFieldJsonKey[i]],field);
      }
    }
      setErrors(errorReturn);
        if(!(Object.keys(errorReturn)?.length>0)){
          let requestLeads={ 
            "user_id": user_id,
            "l_source": "digital",
            "phone": field["phone"],
            "name": field["name"],
            "inventory_id": tractor_id
        };
        dispatch(leadsSubmitRequest({requestLeads}));
          postHandler({
            tableName: "Live Inventory Interested Details",
            fields: {
              "Name": field["name"],
              "Phone Number": field["phone"],
              "Product id":tractor_id
            },
          }); 

        }else{
          Swal.fire({
            icon: "error",
            text: errorTrans("error_fill_all_mandatory_fields"),
            confirmButtonColor:'#652078'
          }).then((res) => {
            
          });
        }       
  }


  function closeModal() {
    setShow(false);    
    setErrors({});
    setField({
      "name":'',
      "phone":''
    });
  }

  return (
    <>
      <ModalWrapper>
        <ModalWrapper.Modal>
          <Form onClick={(e) => e.stopPropagation()}>
            <Form.GroupFields>
            <div style={{display: "flex", justifyContent: 'space-between',marginBottom:'20px'}}>          
            <h3>{common("your_details")}</h3>
            <span style={{display: 'flex',alignItems:'center'}}>{common("close")}<img src="../assets/close.svg" alt="" onClick={closeModal} /></span>
            </div>
            <Form.GroupFields>
              <Form.InputWrapper>
                <img src="../assets/person.png" alt="" style={{padding:'8px 0px 8px 8px', height:'38px',width:'38px'}} />
                <Form.Input
                  type="text"
                  name="name"
                  value={field["name"]}
                  placeholder={fieldTrans("name")}
                  onChange={(e) => handleChange(e,"name")}
                  required
                  style={{border:'none'}}
                />
              </Form.InputWrapper>
              {(errors?.hasOwnProperty("name") && errors["name"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["name"]}</h5>}
              </Form.GroupFields>
              <Form.GroupFields>
              <Form.InputWrapper>
              <img src="../assets/call.png" alt="" style={{padding:'8px 0px 8px 8px', height:'38px',width:'38px'}} />
              <Form.Input
                type="text"
                name="phone"
                value={field["phone"]}
                placeholder={fieldTrans("phone")}
                onChange={(e) => handleChange(e,"phone")}
                required
                style={{border:'none'}}
              />
              </Form.InputWrapper>
              {(errors?.hasOwnProperty("phone") && errors["phone"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["phone"]}</h5>}             
              </Form.GroupFields> 
              <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              {loading ? <Loader /> :  <Form.Button onClick={(e) => handleSubmit(e)}>{common("submit")}</Form.Button>}
              </div>
            </Form.GroupFields>
          </Form>
        </ModalWrapper.Modal>
      </ModalWrapper>
    </>
  );
}