import React from "react";
import { Container, Logo, BackButtonContainer, BackIcon, ButtonText  } from "./styles/header";

export default function Header({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

Header.Logo = function HeaderLogo({ ...props }) {
  return <Logo {...props} />;
};

Header.BackButtonContainer = function HeaderBackButtonContainer({children, ...props }) {
  return <BackButtonContainer {...props} >{children}</BackButtonContainer>;
};

Header.BackIcon = function HeaderBackIcon({...props }) {
  return <BackIcon {...props} />;
};

Header.ButtonText = function HeaderButtonText({children,...props}) {
  return <ButtonText {...props} >{children}</ButtonText>;
};