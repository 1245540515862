import React, { useContext }  from "react";
import { ProductContext } from "../../../utils/productContext";
import Header from "../../../components/header";
import Form from "../../../components/form";



export default function ExpenseOperation({handleChange,operation,imageList,chassisDisable}){
    const {
        errors
      } = useContext(ProductContext);

    const yes_no_drodpwn = [
        {value:"",label:"Select..."},
        {value:"yes",label:"Yes"},
        {value:"no",label:"No"}
    ] 
    const styles={width:"100%",maxWidth: "100%"};
     

    return (
        <>
        <Header>
        <div style={{display:'flex',alignItems:'center',gap:'5px',color:'#FFFF',marginLeft:'8px'}}>
            <p>Refurbishment</p>
            </div>
        </Header>
            <>
                <Form.GroupFields>
                    <Form.Label htmlfor="cost_of_refurbishment_cost">
                    Cost of refurbishment
                    </Form.Label>
                    <Form.Input
                        name="cost_of_refurbishment_cost"
                        id="cost_of_refurbishment_cost"
                        type="number"
                        onChange={(e) => handleChange(e)}
                        value={operation["cost_of_refurbishment_cost"]}
                        style={styles}
                    />
                            {(errors?.hasOwnProperty("cost_of_refurbishment_cost") && errors["cost_of_refurbishment_cost"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["cost_of_refurbishment_cost"]}</h5>}                            
                </Form.GroupFields>
                <Form.GroupFields>
                    <Form.Label htmlfor="provision_image">
                    Need provision to upload/capture image
                    </Form.Label>
                    <Form.Input
                        name="provision_image"
                        id="provision_image"
                        type="file"
                        onChange={(e) => handleChange(e)}
                        style={styles}
                    />
                    {(imageList && typeof(imageList) !== "object" && imageList!=="") && <a style={{wordBreak:'break-word'}} href={imageList}>{imageList}</a>}
                    {(errors?.hasOwnProperty("provision_image") && errors["provision_image"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["provision_image"]}</h5>}                            
                </Form.GroupFields>
                <Form.GroupFields>
                    <Form.Label htmlfor="documentation_cost">
                    Documentation cost
                    </Form.Label>
                    <Form.Input
                        name="documentation_cost"
                        id="documentation_cost"
                        type="number"
                        onChange={(e) => handleChange(e)}
                        value={operation["documentation_cost"]}
                        style={styles}
                    />
                            {(errors?.hasOwnProperty("documentation_cost") && errors["documentation_cost"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["documentation_cost"]}</h5>}                            
                </Form.GroupFields>
                <Form.GroupFields>
                    <Form.Label htmlfor="certification_cost">
                    Certification cost
                    </Form.Label>
                    <Form.Input
                        name="certification_cost"
                        id="certification_cost"
                        type="number"
                        onChange={(e) => handleChange(e)}
                        value={operation["certification_cost"]}
                        style={styles}
                    />
                            {(errors?.hasOwnProperty("certification_cost") && errors["certification_cost"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["certification_cost"]}</h5>}                            
                </Form.GroupFields>
                <Form.GroupFields>
                    <Form.Label htmlfor="others_cost">
                    Others (Commission fees, etc)
                    </Form.Label>
                    <Form.Input
                        name="others_cost"
                        id="others_cost"
                        type="number"
                        onChange={(e) => handleChange(e)}
                        value={operation["others_cost"]}
                        style={styles}
                    />
                            {(errors?.hasOwnProperty("others_cost") && errors["others_cost"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["others_cost"]}</h5>}                            
                </Form.GroupFields>
                <Form.GroupFields>
                        <Form.Label htmlfor="insurance">
                        Insurance
                        </Form.Label>
                        <Form.Dropdown
                            name="insurance"
                            id="insurance"
                            options={yes_no_drodpwn}
                            onChange={(e) => handleChange(e)}
                            value={operation["insurance"]}
                            style={styles}
                        />
                            {(errors?.hasOwnProperty("insurance") && errors["insurance"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["insurance"]}</h5>}                            
                </Form.GroupFields>
                <Form.GroupFields>
                            <Form.Label htmlfor="chassis_number">
                            Chassis Number
                            </Form.Label>
                            <Form.Input
                              name="chassis_number"
                              id="chassis_number"
                              type="text"
                              onChange={(e) => handleChange(e)}
                              disabled
                              value={operation["chassis_number"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("chassis_number") && errors["chassis_number"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["chassis_number"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                          <Form.Label htmlfor="remarks">Remarks</Form.Label>
                          <Form.Textarea 
                            cols="30" 
                            rows="7"
                            name="remarks"
                            id="remarks"
                            onChange={(e) => handleChange(e)}
                            value={operation["remarks"]}
                            style={styles}
                          />
                    </Form.GroupFields>
            </>
        </>
    )
}