import * as types from "../types";

const initialState = {
  loading: false,
  requestPricingOtherDetail: null,
  pricingOtherDetail: null,
  error: null,
};

export default function fetchPricingOtherDetailReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PRICING_OTHER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        requestPricingOtherDetail: action.payload.requestPricingOtherDetail,
      };

    case types.FETCH_PRICING_OTHER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        pricingOtherDetail: action.payload.pricingOtherDetail,
      };

    case types.FETCH_PRICING_OTHER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        requestPricingOtherDetail: null,
        pricingOtherDetail: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}