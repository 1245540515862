import styled from "styled-components"

export const Container = styled.div`
    background-color: #fff;
    width: 100%;
    max-width: 760px;
    height: auto;
    border: 3px solid orange;
    padding: 5px;
`; 

export const HeaderBody = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Logo = styled.img`
    height: 70px;
    width: 70px;
`;

export const Text = styled.p`
    font-size: 11px;
`;

export const Title = styled.p`
    color: #722875;
    font-size: 18px;
    font-weight: 600;
    word-break: break-word;
    word-wrap: break-word;
    
    span {
      color: orange;
    }

`;

export const Main = styled.div`
    border-radius: 25px;
    background-color: #f2f2f2;
    display: flex;
    gap: 2px;
    padding: 5px;
`;

export const MainLeftContent = styled.div`
    width: 70%;
    padding: 5px;
    margin-right: 10px;
`;

export const MainTitle = styled.p`
    color: #722875;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
`;

export const CertifyCheckPoints = styled.div`
    widht: 70%;
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
`;

export const CertifyContent = styled.div`
    width: ${({dynamicWidth}) => dynamicWidth ? dynamicWidth : '30%' };
    height: auto; 
    border: 1px solid black;
    padding: 5px 2px;
    font-weight: 500;
    font-size: 12px;
`;

export const CertifyLogo = styled.img`
    width: 19px;    
    height: 19px;
    margin-bottom: 5px;
`;

export const CertifyText = styled.p`
    font-size: 12px;
    word-break: break-word;
    word-wrap: break-word;
`;

export const Signature = styled.img`
    width: 90px;
    height: 50px; 
`;
 
export const InspectorLabel = styled.p`
    width: 60%;
    word-break: break-word;
    word-wrap: break-word;
    font-size: 13px;
    font-weight: 500; 
`;

export const MainRightContent = styled.div`
    width: 30%;
    padding: 2px;
`;

export const RatingContent = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const RatingCountDiv = styled.div`
    position: absolute;
    left: -12px;
    top: -2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid #FFFF;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFF;
`;

export const RatingCount = styled.p`
    font-size: 12px;
    font-weight: 500;
`;

export const StarContainer = styled.div`
    position: relative;
    width: 95%;
    height: 21px;    
    display: flex;
    align-items: center;  
    margin-top: 3px;
    padding-left: 14px;
    background-color: #722875;
    border-radius: 7px;
`;

export const ProductDetails = styled.div`
    border-left: 1px solid grey;
    margin-top: 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding-left: 3px;
`;

export const DetailLabel = styled.span`
    font-size: 7px;
    font-weight: 500;
`;

export const DetailValue = styled.span`
    font-size: 7px;
    font-weight: 500;
    word-break: break-word;
    word-wrap: break-word;
`;
