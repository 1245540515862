import React from "react";
import { Container, HeaderBody, LogoContainer, Logo, Text, Title,Main, MainTitle, CertifyContent, CertifyLogo, CertifyText, MainLeftContent, Signature, InspectorLabel, CertifyCheckPoints, MainRightContent, RatingContent, RatingCount, RatingCountDiv, StarContainer, ProductDetails, DetailLabel, DetailValue } from "./styles/certificate";

export default function Certifcate({children,...props}){
    return <Container {...props}>{children}</Container>
}

Certifcate.HeaderBody = function CertifcateHeaderBody({children,...props}){
    return <HeaderBody {...props}>{children}</HeaderBody>
}

Certifcate.LogoContainer = function CertifcateLogoContainer({children,...props}){
    return <LogoContainer {...props}>{children}</LogoContainer>
}

Certifcate.Logo = function CertifcateLogo({...props}){
    return <Logo {...props} />
}

Certifcate.Title = function CertifcateTitle({children,...props}){
    return <Title {...props}>{children}</Title>
}

Certifcate.Text = function CertifcateText({children,...props}){
    return <Text {...props}>{children}</Text>
}

Certifcate.Main = function CertifcateMain({children, ...props}){
    return <Main {...props}>{children}</Main>
}

Certifcate.MainTitle = function CertifcateMainTitle({children,...props}){
    return <MainTitle {...props}>{children}</MainTitle>
}

Certifcate.MainLeftContent = function CertificateMainLeftContent({children,...props}){
    return <MainLeftContent {...props}>{children}</MainLeftContent>
}

Certifcate.CertifyCheckPoints = function CertificateCertifyCheckPoints({children,...props}){
    return <CertifyCheckPoints {...props}>{children}</CertifyCheckPoints>
}

Certifcate.CertifyContent = function CertificateCertifyContent({children,...props}){
    return <CertifyContent {...props}>{children}</CertifyContent>
}

Certifcate.CertifyLogo = function CertificateCertifyLogo({...props}){
    return <CertifyLogo {...props} />
}

Certifcate.CertifyText = function CertificateCertifyText({children,...props}){
    return <CertifyText {...props}>{children}</CertifyText>
}

Certifcate.Signature = function CertificateSignature({...props}){
    return <Signature {...props} />
}

Certifcate.InspectorLabel = function CertificateInspectorLabel({children,...props}){
    return <InspectorLabel {...props}>{children}</InspectorLabel>
}

Certifcate.MainRightContent = function CertifcateMainRightContent({children,...props}){
    return <MainRightContent {...props}>{children}</MainRightContent>
}

Certifcate.RatingContent = function CertifcateRatingContent({children,...props}){
    return <RatingContent {...props}>{children}</RatingContent>
}

Certifcate.RatingCountDiv = function CertifcateRatingCountDiv({children,...props}){
    return <RatingCountDiv {...props}>{children}</RatingCountDiv>
}

Certifcate.RatingCount = function CertifcateRatingCount({children,...props}){
    return <RatingCount {...props}>{children}</RatingCount>
}

Certifcate.StarContainer = function CertifcateStarContainer({children,...props}){
    return <StarContainer {...props}>{children}</StarContainer>
}

Certifcate.ProductDetails = function CertifcateProductDetails({children,...props}){
    return <ProductDetails {...props}>{children}</ProductDetails>
}

Certifcate.DetailLabel = function CertifcateDetailLabel({children,...props}){
    return <DetailLabel {...props}>{children}</DetailLabel>
}

Certifcate.DetailValue = function CertifcateDetailValue({children,...props}){
    return <DetailValue {...props}>{children}</DetailValue>
}

