import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 17px;
    background-color: #FFFFFF;
`;


export const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    min-height: 56px;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #E7E7E7;
    border-bottom: 2px solid #E7E7E7;
    margin-top: 10px;

`;

export const Text = styled.p`
    color: #F26F21;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
`;

export const Content = styled.div`

    .more_info, .brand_model {
        color: #333333;
        font-weight: 400;
        font-size: 17px;
    }

    .more_info{
        font-weight: 800;
    }

    .brand_model{
        margin-top: 5px;
        font-weight: 600;
    }


`;