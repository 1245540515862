import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

export const Tile = styled.div`
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
    background: #f4edf5 0% 0% no-repeat padding-box; 
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0px 8px 12px #00000017;
    padding: 16px 0 0 0;
`;

export const Image = styled.img`

`;

export const Title = styled.p`
    color: #333333;
    font-size: 14px;
    letter-spacing: 0px;
    padding: 20px 0;
    font-weight: 550;
`;

export const Navigation = styled(Link)`
    text-decoration: none;
`;