import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchManufacturerStateFailure,
  fetchManufacturerStateSuccess,
} from "../actions/manufacturerStateAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchManufacturerStateSaga(action) {
  try {
    yield put(clearErrors());
    let manufacturerState = yield call(_api.fetchManufacturerState);
    yield put(fetchManufacturerStateSuccess({ manufacturerState }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchManufacturerStateFailure({ error: e }));
  }
}
export default function* fetchManufacturerStateSaga() {
  yield takeLatest(types.FETCH_MANUFACTURER_AND_STATE_REQUEST, asyncFetchManufacturerStateSaga);
}