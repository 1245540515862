import { base } from "../config/airtable.config";

export const fetchAirtableDataFn = async ({
    tableName,
    fields,
    filterByFormula = "",
    maxRecords = 1000,
    dependencyState = null,
  }) => {
  try {
    let bufferData = [];
   await base(tableName)
    .select({
      maxRecords: 100,
      view: "Grid view",
      fields,
      filterByFormula,
    })
    // .eachPage(
    //   function page(records, _fetchNextPage) {
    //     records.map((item) => {
    //       //console.log("item",item)
    //       bufferData.push(item.fields);
    //       return { ...item.fields };
    //     });
    //   }
    // )    
    .eachPage((records, fetchNextPage) => {
      records.forEach((record) => {
       bufferData.push(record.fields);
      });
      fetchNextPage();
     });

    return bufferData;
  } catch (error) {
    throw error;
  }
};    

