import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 5px;
`;

export const Title = styled.h2`
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  gap: 17px;
`;

export const Button = styled.button`
    padding:10px 20px;
    background-color: #f75e80;
    font-weight: 500;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
`;

