import * as types from "../types";
import * as _api from "../../api";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import { clearErrors, returnErrors } from "../actions/errorAction";
import { inventorySingleFailure, inventorySingleSuccess } from "../actions/inventorySingleAction";

function* asyncFetchInventorySingleSaga(action){
    try{
        yield put(clearErrors());
        let resSinleInv = yield call(_api.fetchInventorySingle,{
            reqSinleInv:action.payload.reqSinleInv
        })
        yield put(inventorySingleSuccess({resSinleInv}));
    }catch(e){
        yield put(inventorySingleFailure({error:e}))
        yield put(returnErrors({msg: e.message, status: true}))
    }
}

export default function* fetchInventorySingleSaga(){
    yield takeLatest(types.FETCH_INVENTORY_SINGLE_DATA_REQUEST,asyncFetchInventorySingleSaga)
}