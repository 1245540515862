import * as types from "../types";

const initialState = {
    filterObj: {
        brand: '',
        model: '',
        price_range: '',
        state: 'all',
        location: 'all',
        category: NaN,
      },
    filteredArray: []
}

export default function invFilterReducer( state = initialState, action ){

    switch(action.type){

        case types.SET_INV_FILTER_DROPDOWN:
            return {
                ...state,
                filterObj: action.payload.filterObj
            }

        case types.SET_INV_FILTERED_DATA:
            return {
                ...state,
                filteredArray: action.payload.filteredArray
            }

        case types.SET_INV_FILTER_RESET:
            return {
                filterObj: {
                    brand: '',
                    model: '',
                    price_range: '',
                    state: 'all',
                    location: 'all',
                    category: NaN,
                  },
                filteredArray: []
            }

        default:
            return state;

    }

}