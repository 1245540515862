import React, { useContext } from "react";
import FilterContainer from "../../components/filter";
import ModalWrapper from "../../components/modal";
import Form from "../../components/form";
import { ProductContext } from "../../utils/productContext";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { convertToUppercase } from "../../utils/convertToLowerUppercase";
import { useInventorySort } from "../../utils/useInventorySort";
import { setInvAfterSortAction, setInvSortValuesAction } from "../../store/actions/invSortAction";

export default function InventorySorting({dataArray}) {
  const { t: common } = useTranslation("common");
  const { t: field } = useTranslation("field");
  const dispatch = useDispatch();
  const [ applySorting, reset ] = useInventorySort();

  const {
    setSortShow,
    sortShow,
  } = useContext(ProductContext);

  const sortReducerObj = useSelector((state) => state.invSortReducer);
  let { sortObj, sorttedArray } = sortReducerObj;
  //console.log("sortObj",sortObj)

  function closeModal() {
    setSortShow(false);
  }


  const handleChange = (e) => {
    const { value } = e.target;
    sorttedArray = applySorting(dataArray,value);
    sortObj = { ...sortObj, sort_by: value };
    dispatch(setInvSortValuesAction({ sortObj }));
    dispatch(setInvAfterSortAction({sorttedArray}))
    setSortShow(false);
  };

  return (
    <ModalWrapper>
      <FilterContainer isShown={sortShow}>
        <FilterContainer.Header>
          <FilterContainer.HeaderText>
            {common("sort_by")}
          </FilterContainer.HeaderText>
          <div>
            <FilterContainer.HeaderText
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => closeModal()}
            >
              {common("close")}{" "}
              <img src="./assets/close_black.svg" alt="back" />
            </FilterContainer.HeaderText>
          </div>
        </FilterContainer.Header>
        <FilterContainer.Main onChange={(e) => handleChange(e)}>
          <FilterContainer.MainContent>
            <Form.GroupFields>
              <input
                type="radio"
                name="sort"
                id="rating_Low_to_high"
                value="rating_Low_to_high"
                defaultChecked={sortObj.sort_by === "rating_Low_to_high"}
              />
              &nbsp; {field("rating")} - (
              {common("low_to_high")})
            </Form.GroupFields>
          </FilterContainer.MainContent>
          <FilterContainer.MainContent>
            <Form.GroupFields>
              <input
                type="radio"
                name="sort"
                id="rating_high_to_Low"
                value="rating_high_to_Low"
                defaultChecked={sortObj.sort_by === "rating_high_to_Low"}
              />
              &nbsp; {field("rating")} - (
              {common("high_to_low")})
            </Form.GroupFields>
          </FilterContainer.MainContent>
          <FilterContainer.MainContent>
            <Form.GroupFields>
              <input
                type="radio"
                name="sort"
                id="price_Low_to_high"
                value="price_Low_to_high"
                defaultChecked={sortObj.sort_by === "price_Low_to_high"}
              />
              &nbsp; {field("price_range")} - (
              {common("low_to_high")})
            </Form.GroupFields>
          </FilterContainer.MainContent>
          <FilterContainer.MainContent>
            <Form.GroupFields>
              <input
                type="radio"
                name="sort"
                id="price_high_to_Low"
                value="price_high_to_Low"
                defaultChecked={sortObj.sort_by === "price_high_to_Low"}
              />
              &nbsp; {field("price_range")} - (
              {common("high_to_low")})
            </Form.GroupFields>
          </FilterContainer.MainContent>
          <FilterContainer.MainContent>
            <Form.GroupFields>
              <input
                type="radio"
                name="sort"
                id="hours_driven_Low_to_high"
                value="hours_driven_Low_to_high"
                defaultChecked={
                  sortObj.sort_by === "hours_driven_Low_to_high"
                }
              />
              &nbsp; {field("hours_driven")} - (
              {common("low_to_high")})
            </Form.GroupFields>
          </FilterContainer.MainContent>
          <FilterContainer.MainContent>
            <Form.GroupFields>
              <input
                type="radio"
                name="sort"
                id="hours_driven_high_to_Low"
                value="hours_driven_high_to_Low"
                defaultChecked={
                  sortObj.sort_by === "hours_driven_high_to_Low"
                }
              />
              &nbsp; {field("hours_driven")} - (
              {common("high_to_low")})
            </Form.GroupFields>
          </FilterContainer.MainContent>
        </FilterContainer.Main>
        <FilterContainer.ButtonContent>
          <FilterContainer.Button
            style={{ background: "#FFFF", color: "#000" }}
            disabled={sorttedArray.length === 0 ? true:false}
            onClick={() => reset()}
          >
            {convertToUppercase(common("clear_sort"))}
          </FilterContainer.Button>
        </FilterContainer.ButtonContent>
      </FilterContainer>
    </ModalWrapper>
  );
}
