import * as types from "../types";

const initialState = {
    loading: false,
    reqLocation: null,
    resATLocation: null,
    error: null
}

export default function airtableUserLocationReducer(state = initialState, action){

    switch(action.type){
        case types.FETCH_AIRTABLE_USER_LOCATION_REQUEST:
            return{
                ...state,
                loading: true,
                reqLocation: action.payload.reqLocation
            }

        case types.FETCH_AIRTABLE_USER_LOCATION_SUCCESS:
            return{
                ...state,
                loading: false,
                resATLocation: action.payload.resATLocation
            }

        case types.FETCH_AIRTABLE_USER_LOCATION_FAILURE:
            return{
                ...state,
                loading: false,
                reqLocation: null,
                resATLocation: null,
                error: action.payload.error
            }

        case types.FETCH_AIRTABLE_USER_LOCATION_RESET:
            return{
                loading: false,
                reqLocation: null,
                resATLocation: null,
                error: null
            }


        default :
            return state;
    }

}