import * as types from "../types";
import * as _api from "../../api";
import { call, put, takeLatest } from "redux-saga/effects";
import { clearErrors, returnErrors } from "../actions/errorAction";
import { userLocationFailure, userLocationSuccess } from "../actions/userLocationAction";

function* asyncFetchUserLocation(){

    try{
        yield put(clearErrors());
        let resUserLocation = yield call(_api.fetchUserLocation);
        yield put(userLocationSuccess({resUserLocation}));
    }catch(e){
        yield put(returnErrors({msg:e.message,status: true}));
        yield put(userLocationFailure({error:e}));
    }
    
}

export default function* fetchUserLocation(){
    yield takeLatest(types.FETCH_USER_LOCATION_DATA_REQUEST,asyncFetchUserLocation);
}