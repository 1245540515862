import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
`;

export const HeaderConatiner = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items:center;
    justify-content: space-between;
    font-weight: 600;
    margin: 20px 0 5px 0;
    background: #F1F1F1 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
`;

export const Title = styled.p`
    text-align: left;
    padding: 10px;
    font-size: 17px; 

`;

export const IconContainer = styled.p`
    height: 50px;
    width: 20%;
    background: #F1F1F1 0% 0% no-repeat padding-box;
    border-radius: 0px 4px 4px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFF;

    .arrow{
        height: 40px;
        width: 35%;
        color: #F37021;
    }
`;

export const ContentContainer = styled.div``;

export const Content = styled.p``;