import { useTranslation } from "react-i18next";

export const useChangLanguage = () => {
    const { i18n } = useTranslation();
    const handleChangeLanguage = (newLanguage) => {
        i18n.changeLanguage(newLanguage); // Change the language with i18n
    }

    return [ handleChangeLanguage ]; 

}