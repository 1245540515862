import React from "react";
import BuyCards from "../../components/buyCard";
import useFetch from "../../utils/useFetch";
import { useNavigate } from "react-router-dom";
import Section from "../../components/section";
import SectionHeader from "../../components/sectionheader";
import BottomHighlightLine from "../../components/bottomhighlight";
import Loader from "../../components/Loader/Loader";
import urlContain from "../../utils/urlContain";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  inventoryAllRequest,
  inventoryAllReset,
} from "../../store/actions/inventoryAllAction";
import { useQuery } from "../../utils/useQuery";
import { setLanguage } from "../../store/actions/languageAction";
import {
  inventoryRequest,
  inventoryReset,
} from "../../store/actions/dealerInventoryAction";
import { useTranslation } from "react-i18next";
import { convertToUppercase } from "../../utils/convertToLowerUppercase";
import { useChangLanguage } from "../../utils/useChangLanguage";
import Header from "../../components/header";

export default function CTInventoryList() {
  const { t: common } = useTranslation("common");
  const dispatch = useDispatch();
  const languageObj = useSelector((state) => state.languageReducer);
  //  const inventoryObj = useSelector(state => state.invReducer);
  // const { loading, resInvAll } = inventoryObj;
  const inventoryObj = useSelector((state) => state.inventoryAllReducer);
  const dealerInventoryObj = useSelector(
    (state) => state.dealerInventoryReducer
  );
  const { resInvData } = dealerInventoryObj;
  const { loading, resInvAll } = inventoryObj;

  const { languageCode } = languageObj;

  const queryParams = useQuery();
  const user_id = queryParams.get("id");
  const linkType = queryParams.get("linkType");
  const source = queryParams.get("source");
  const name = queryParams.get("name");
  const phone = queryParams.get("phone");
  const state = queryParams.get("state");
  const district = queryParams.get("district");
  const taluka = queryParams.get("taluka");
  const village = queryParams.get("village");
  const lang = convertToUppercase(queryParams.get("lang") || languageCode);

  const [handleChangeLanguage] = useChangLanguage();
  const [productData, productFetching, productError] = useFetch({
    tableName: "inventory",
    fields: [
      `Product_Id`,
      `Carousel_image`,
      `Product_Multiple_Image`,
      `offer_headline(${lang})`,
    ],
    filterByFormula: "",
  });

  useEffect(() => {
    dispatch(inventoryReset());
    dispatch(inventoryAllReset());
  }, []);

  useEffect(() => {
    // dispatch(inventoryRequest());
    user_id && user_id !== ""
      ? dispatch(inventoryRequest({ reqInv: { user_id } }))
      : dispatch(inventoryAllRequest());
  }, [user_id]);

  useEffect(() => {
    if (lang && lang !== "") {
      handleChangeLanguage(lang);
      dispatch(setLanguage({ languageCode: lang }));
    }
  }, [lang]);

  let navigate = useNavigate();
  const handleNav = (tractor_id, productjson, prodType) => {
    if (user_id && user_id !== "") {
      navigate(
        `/ctInventorydetails?type=${prodType}&tractor_id=${tractor_id}&id=${user_id}&lang=${lang}&linkType=${linkType}&source=${source}&name=${name}&phone=${phone}&state=${state}&district=${district}&taluka=${taluka}&village=${village}`,
        { state: productjson }
      );
    } else {
      navigate(
        `/ctInventorydetails?type=${prodType}&tractor_id=${tractor_id}&lang=${lang}&linkType=${linkType}&source=${source}&name=${name}&phone=${phone}&state=${state}&district=${district}&taluka=${taluka}&village=${village}`,
        { state: productjson }
      );
    }
  };

  // const viewAllItems = (resInvAll) => {
  //     navigate(`/allproduct?lang=${lang}`,{state:{inv_data:resInvAll}})
  // }

  return (
    <>
      {" "}
      <Header />
      {(loading || dealerInventoryObj.loading) ? (
        <Loader />
      ) : (
        (resInvData?.data || resInvAll?.data)?.length > 0 && (
          <Section>
            <SectionHeader>
              <SectionHeader.Title>
                {productData?.[0]?.[`offer_headline(${lang})`]}
              </SectionHeader.Title>
              <BottomHighlightLine></BottomHighlightLine>
            </SectionHeader>
            <BuyCards>
              {/* {productData?.map((proItem,index) => 
            ((proItem[`Carousel_image`]) && <BuyCards.Card key={index} onClick={() => handleNav(proItem[`Product_Id`],"inventory")}> 
                    <img src={proItem[`Carousel_image`]?.[0].url} alt="" style={{height: '270px', maxHeight: "300px", borderRadius: '8px'}} />
                    <BuyCards.InfoContainer>
                    {proItem[`buttonText`] && <BuyCards.Button onClick={() => handleNav(proItem[`Product_Id`],"inventory")}>{proItem[`buttonText`]}</BuyCards.Button>}
                    </BuyCards.InfoContainer> 
                </BuyCards.Card> ) 
                    
            )} */}
              {(resInvData?.data || resInvAll?.data)
                ?.sort((a, b) => b.tractor_id - a.tractor_id)
                ?.map(
                  (item, index) =>
                    (item.status === 1 ||
                      item.status === 2 ||
                      item.status === 3) && (
                      <BuyCards.Card
                        key={index}
                        onClick={() =>
                          handleNav(item[`tractor_id`], item, "inventory")
                        }
                      >
                        {typeof item?.[`image_links`] === "object" &&
                        Object.keys(item?.[`image_links`])?.length > 0 ? (
                          <BuyCards.Image
                            src={urlContain(
                              item?.[`image_links`]?.[0]?.["image"]
                            )}
                            alt=""
                          />
                        ) : (
                          <BuyCards.Image src="" alt="" />
                        )}
                        <BuyCards.InfoContainer>
                          <BuyCards.Title>
                            {item["brand"]}&nbsp;{item["model"]}
                          </BuyCards.Title>
                          {/* <BuyCards.InfoText>Brand: {item["brand"]}
                    </BuyCards.InfoText> */}
                          <BuyCards.Price>
                            <b>₹ {item["max_price"]}</b>
                            {item["rating"] > 0 && (
                              <BuyCards.Star
                                src={`../assets/staricon/${item["rating"]} star.svg`}
                                alt={`${item["rating"]} star`}
                              />
                            )}
                          </BuyCards.Price>
                          <BuyCards.SpecificationContainer>
                            <BuyCards.Specification>
                              <BuyCards.Icon
                                src="./assets/certify/engine_hours.svg"
                                alt=""
                              />
                              <BuyCards.SpecificationValue>
                                <BuyCards.SpecificationLabel>
                                  Hrs
                                </BuyCards.SpecificationLabel>
                                {item[`engine_hours`] === -1
                                  ? "NA"
                                  : `${item[`engine_hours`]}`}
                              </BuyCards.SpecificationValue>
                            </BuyCards.Specification>
                            <BuyCards.Specification>
                              <BuyCards.Icon
                                src="./assets/certify/engine_power.svg"
                                alt=""
                              />
                              <BuyCards.SpecificationValue>
                                <BuyCards.SpecificationLabel>
                                  Power
                                </BuyCards.SpecificationLabel>
                                {item[`engine_power`] === -1
                                  ? "NA"
                                  : `${item[`engine_power`]}`}
                              </BuyCards.SpecificationValue>
                            </BuyCards.Specification>
                            <BuyCards.Specification>
                              <BuyCards.Icon
                                src="./assets/certify/tyre_condition.svg"
                                alt=""
                              />
                              <BuyCards.SpecificationValue>
                                <BuyCards.SpecificationLabel>
                                  Tyre
                                </BuyCards.SpecificationLabel>
                                {item[`tyre_condition`] === "Original"
                                  ? "Original"
                                  : item[`tyre_condition`]}
                              </BuyCards.SpecificationValue>
                            </BuyCards.Specification>
                          </BuyCards.SpecificationContainer>
                          <BuyCards.ButtonContainer>
                            <BuyCards.RTONumber>
                            {
                                item?.additional_featureInfo?.["Registration (RTO) No."] ||
                                item?.additional_featureInfo?.["Registration (RTO) No"]
                            }
                            </BuyCards.RTONumber>
                            <BuyCards.Button>
                              {common("see_details")}{" "}
                              <img
                                src="./assets/arrow/right_arrow_black.svg"
                                alt="right arrow"
                              />
                            </BuyCards.Button>
                          </BuyCards.ButtonContainer>
                        </BuyCards.InfoContainer>
                      </BuyCards.Card>
                    )
                )}
            </BuyCards>
          </Section>
        )
      )}
    </>
  );
}
