import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 300px;

  @media (min-width: 425px) {
    height: 350px;
  }
`;

export const Slides = styled.div`
  .slide {
    max-height: 250px;

    @media (min-width: 425px) {
      max-height: 300px;
    }
  }
`;

export const EachSlideEffect = styled.div`
  width: 100%;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: contain;

  @media (min-width: 425px) {
    height: 300px;
  }
`;
