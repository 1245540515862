import React from 'react'

const CountDown = ({seconds}) => {
   const [sec,setSec]=React.useState(seconds);
   const timer=React.useRef();

   React.useEffect(()=>{
    timer.current=setInterval(()=>{
        setSec(prev => prev-1);
    },1000);
    return () => clearInterval(timer.current);
   },[])

  return (
    <div style={{textAlign:"right",color:"#652078"}}>OTP पुन्हा पाठवा : 00:{sec}</div>
  )
}

export default CountDown