import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
`;

export const SearchBy = styled.div``;

export const ListTitle = styled.span`
    color: #000;
    font-size: 16px;
    font-weight: 400;
`;

export const Cards = styled.div`
    width: 100%;
    height: auto;
    max-height: ${(props) =>  props?.height ? props?.height : "" };
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 12px 0 20px 0;

    @media (min-width: 720px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

`;

export const Card = styled.div`
    position: relative;
    width: 100%;
    min-height: 146px;
    max-height: 160px; 
    margin: 5px; 
    padding: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: row;


    @media (min-width: 720px) {
        width: 360px;
    }

`;

export const CardDetails = styled.div`
    width: 100%;
    height: auto;
`;

export const Header = styled.div`
    width: 100%;
    margin-bottom: 12px;
`;

export const CardLabel = styled.label`
    display: inline-flex;
    padding: 2px 4px;
    border-radius: 2px;
    background: #652278;
    font-size: 12px;
    font-weight: 700;
    color: #FFF;
`;

export const RTONumber = styled.span`
    float: right;
    font-size: 12px;
    font-weight: 400;
`;

export const CardMain = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const CardTitle = styled.span`
    width: 320px;
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    color: #333;
    text-overflow: ellipsis;
`;

export const ChassisNumber = styled.span`
    width: 320px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
`;

export const PriceContainer = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 13px;
`;

export const Price = styled.span`
    color: #2F742C;
    font-size: 14px;
    font-weight: 700;
`;

export const PriceLabel = styled.span`
    color: #333;
    font-size: 12px;
    font-weight: 400;
    margin-right: 5px;
`;

export const Bottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
`;

export const Time = styled.span`
    width: 250px;
    overflow: hidden;
    color: #999;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
`;
 
export const Button = styled.button`
    position: absolute;
    display: inline-flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    right: 0;
    bottom: 0;
    gap: 4px;
    border-radius: 4px 0px;
    background: #F37021;
    color: #FFF;
    border: none;
`;

export const Pagination = styled.div`
    width: 100%;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px; 
    display: flex;
    justify-content: center;
`;


export const PageCountContainer = styled.div`
    border-radius: 5px;
    display: flex;
    align-items:center;
`;

export const PageButton = styled.button`
    background: #fff; 
    border: none;
    height: 30px;
    width: 30px;
    margin: 5px;
`;

export const PageCountLabel = styled.p``;
