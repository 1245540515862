import * as types from "../types";

export const setInvSortValuesAction = ({sortObj}) => ({
    type: types.SET_INV_SORT_DROPDOWN,
    payload:{
        sortObj
    }
})

export const setInvAfterSortAction = ({sorttedArray}) => ({
    type: types.SET_INV_SORTTED_DATA,
    payload:{
        sorttedArray
    }
})

export const setInvSortReset = () => ({
    type: types.SET_INV_SORT_RESET,
})
