import React, { useContext }  from "react";
import { ProductContext } from "../../../utils/productContext";
import Header from "../../../components/header";
import Form from "../../../components/form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { buySellFinancelistRequest } from "../../../store/actions/buySellFinanceList";
import { useState } from "react";



export default function ExpenseSourcing({handleChange,sourcing,chassisDisable}){

    const [nbfcDropdown, setNbfcDropdown] = useState([]);
    const dispatch =  useDispatch();
    const buyFinanaceObj = useSelector(state => state.buySellFinanceListReducer);
    const { loading, resFinList } = buyFinanaceObj;

    useEffect(() => {
      dispatch(buySellFinancelistRequest({key:"buying_category_financers"}))
    },[])

    useEffect(()=>{
      if(resFinList){
        let nbfcDropVar = [{value:"",label:"Select..."}];
        resFinList?.data?.buying_category_financers?.map((item,index)=>{
          if(item){
            nbfcDropVar = [...nbfcDropVar, {value:item,label:item}]
          }
        })
        setNbfcDropdown(nbfcDropVar)
      }
    },[resFinList])

    const {
        errors
      } = useContext(ProductContext);

    const sourceDropdown = [
        {value:"",label:"Select source"},
        {value:"NBFC – Direct",label:"NBFC – Direct"},
        {value:"NBFC – Auction",label:"NBFC – Auction"},
        {value:"Dealer – Mahindra & Swaraj",label:"Dealer – Mahindra & Swaraj"},
        {value:"Dealer – Other",label:"Dealer – Other"},
        {value:"Broker",label:"Broker"},
        {value:"Farmer",label:"Farmer"}
    ]
    
  //   const nbfcDropdown = [
  //     {value:"",label:"Select..."},
  //     {value:"M&MFSL",label:"M&MFSL"},
  //     {value:"L&T",label:"L&T"},
  //     {value:"Kotak",label:"Kotak"},
  //     {value:"Chola",label:"Chola"},
  //     {value:"TVS",label:"TVS"},
  //     {value:"SK Finance",label:"SK Finance"},
  //     {value:"IndusInd",label:"IndusInd"},
  //     {value:"Shriram",label:"Shriram"},
  //     {value:"Axis",label:"Axis"},
  //     {value:"Bank of Baroda",label:"Bank of Baroda"},
  //     {value:"AU Bank",label:"AU Bank"},
  //     {value:"SBI",label:"SBI"},
  //     {value:"HDFC",label:"HDFC"},
  //     {value:"Other",label:"Other"}
  // ]    


    const yes_no_drodpwn = [
        {value:"",label:"Select..."},
        {value:"Yes",label:"Yes"},
        {value:"No",label:"No"}
    ]  
    
    const styles={width:"100%",maxWidth: "100%"};

    return (
        <>
        <Header>
            <div style={{display:'flex',alignItems:'center',gap:'5px',color:'#FFFF',marginLeft:'8px'}}>
            <p>Buying</p>
            </div>
        </Header>

        <>
                    <Form.GroupFields>
                            <Form.Label htmlfor="sourcing_date">
                            Buying Date
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>                            
                            </Form.Label>
                            <Form.Input
                              name="sourcing_date"
                              id="sourcing_date"
                              type="date"
                              onChange={(e) => handleChange(e)}
                              value={sourcing["sourcing_date"]} 
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("sourcing_date") && errors["sourcing_date"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["sourcing_date"]}</h5>}
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="purchase_cost">
                            Purchase Cost
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>
                            </Form.Label>
                            <Form.Input
                              name="purchase_cost"
                              id="purchase_cost"
                              type="number"
                              onChange={(e) => handleChange(e)}
                              value={sourcing["purchase_cost"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("purchase_cost") && errors["purchase_cost"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["purchase_cost"]}</h5>}
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="transportation_charges_cost">
                            Transportation Charges
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>                            
                            </Form.Label>
                            <Form.Input
                              name="transportation_charges_cost"
                              id="transportation_charges_cost"
                              type="number"
                              onChange={(e) => handleChange(e)}
                              value={sourcing["transportation_charges_cost"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("transportation_charges_cost") && errors["transportation_charges_cost"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["transportation_charges_cost"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="parking_charge_cost">
                            Parking + Other Yard charges
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>                            
                            </Form.Label>
                            <Form.Input
                              name="parking_charge_cost"
                              id="parking_charge_cost"
                              type="text"
                              onChange={(e) => handleChange(e)}
                              value={sourcing["parking_charge_cost"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("parking_charge_cost") && errors["parking_charge_cost"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["parking_charge_cost"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="Source">
                            Source 
                            </Form.Label>
                            <Form.Dropdown
                                name="Source"
                                id="Source"
                                options={sourceDropdown}
                                onChange={(e) => handleChange(e)}
                                value={sourcing["Source"]}
                                style={styles}
                            />
                            {(errors?.hasOwnProperty("Source") && errors["Source"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["Source"]}</h5>}                            
                    </Form.GroupFields>
                    {(sourcing["Source"] === "NBFC – Direct" || sourcing["Source"] === "NBFC – Auction") && <Form.GroupFields>
                            <Form.Label htmlfor="nbfc">
                            Select NBFC
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>
                            </Form.Label>
                            <Form.Dropdown
                                name="nbfc"
                                id="nbfc"
                                options={nbfcDropdown}
                                onChange={(e) => handleChange(e)}
                                value={sourcing["nbfc"]}
                                style={styles}
                            />
                            {(errors?.hasOwnProperty("nbfc") && errors["nbfc"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["nbfc"]}</h5>}                            
                    </Form.GroupFields>}
                    {(sourcing["nbfc"] || '').toLowerCase() === "other" && <Form.GroupFields>
                            <Form.Label htmlfor="specify_other">
                            Specify Other
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>
                            </Form.Label>
                            <Form.Input
                              name="specify_other"
                              id="specify_other"
                              type="text"
                              onChange={(e) => handleChange(e)}
                              value={sourcing["specify_other"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("specify_other") && errors["specify_other"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["specify_other"]}</h5>}                            
                    </Form.GroupFields>}
                   {(sourcing["Source"] !== "NBFC – Direct" && sourcing["Source"] !== "NBFC – Auction" && sourcing["Source"] !== "Dealer – Mahindra & Swaraj") && <Form.GroupFields>
                            <Form.Label htmlfor="name_source">
                            Name of Source
                            {(sourcing["Source"] === "Dealer – Other" || sourcing["Source"] === "Broker" || sourcing["Source"] === "Farmer") && 
                            <span
                              style={{ color: "red", marginLeft: "2px" }}
                            >
                              *
                            </span>                            
                            }
                            </Form.Label>
                            <Form.Input
                              name="name_source"
                              id="name_source"
                              type="text"
                              onChange={(e) => handleChange(e)}
                              value={sourcing["name_source"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("name_source") && errors["name_source"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["name_source"]}</h5>}                            
                    </Form.GroupFields>}
                    <Form.GroupFields>
                            <Form.Label htmlfor="contact_source">
                            Contact of Source
                            </Form.Label>
                            <Form.Input
                              name="contact_source"
                              id="contact_source"
                              type="number"
                              onChange={(e) => handleChange(e)}
                              value={sourcing["contact_source"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("contact_source") && errors["contact_source"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["contact_source"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="sourcing_enabled_M_and_M">
                            Was Buying enabled by M&M?
                            </Form.Label>
                            <Form.Dropdown
                                name="sourcing_enabled_M_and_M"
                                id="sourcing_enabled_M_and_M"
                                options={yes_no_drodpwn}
                                onChange={(e) => handleChange(e)}
                                value={sourcing["sourcing_enabled_M_and_M"]}
                                style={styles}
                            />
                            {(errors?.hasOwnProperty("sourcing_enabled_M_and_M") && errors["sourcing_enabled_M_and_M"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["sourcing_enabled_M_and_M"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="chassis_number">
                            Chassis Number
                            </Form.Label>
                            <Form.Input
                              name="chassis_number"
                              id="chassis_number"
                              type="text"
                              onChange={(e) => handleChange(e)}
                              disabled
                              value={sourcing["chassis_number"]}
                              style={styles}
                            />
                            {(errors?.hasOwnProperty("chassis_number") && errors["chassis_number"]) && <h5 style={{color: 'red',marginTop:'2px'}}>{errors["chassis_number"]}</h5>}                            
                    </Form.GroupFields>
                    <Form.GroupFields>
                          <Form.Label htmlfor="remarks">Remarks</Form.Label>
                          <Form.Textarea 
                            cols="30" 
                            rows="7"
                            name="remarks"
                            id="remarks"
                            onChange={(e) => handleChange(e)}
                            value={sourcing["remarks"]}
                            style={styles}
                          />
                    </Form.GroupFields>
        </>
        </>
    )
}