import React from "react";
import SpecificationContainer from "../../../components/specification";
import { dateTime_MWORD_AMPM } from "../../../utils/dateFormat";
import { BiRupee } from "react-icons/bi";
import dateFormatDDMMYYYY from "../../../utils/dateFormatDDMMYYYY";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../utils/useQuery";

export default function ExpenseSourcingView({ value }) {
  const queryParams = useQuery();
  const userid = queryParams.get("userid");
  const brand = queryParams.get("brand");
  const model = queryParams.get("model");
  const year = queryParams.get("year");
  const rto = queryParams.get("rto");

  const allData = value;
  let dateTime = value?.["updated_at"];
  value = value?.["form_data"];
  const headerFontSize = "12px";
  const valueFontSize = "12px";
  const bgColor = "#F1E5F1";

  let navigate = useNavigate();
  const editTo = () => {
     navigate(`/edit-expense-tracker?userid=${userid}&brand=${brand}&model=${model}&year=${year}&rto=${rto}`, {
       state: { expense_id: allData.id, formType: allData.form_type },
     });
  };

  return (
    <SpecificationContainer style={{ marginTop: "24px" }}>
      <SpecificationContainer.TableConatiner>
        <SpecificationContainer.TableHeader
          bgColor={bgColor}
        >
          <SpecificationContainer.TableHeaderText>
            <b>Buying Details</b>
            <span className="edit_text" onClick={() => editTo()}>
              <img src="../assets/expense/edit.svg" alt="edit" />
              Edit
            </span>
          </SpecificationContainer.TableHeaderText>
        </SpecificationContainer.TableHeader>
      </SpecificationContainer.TableConatiner>
      {value?.["sourcing_date"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Buying Date
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {dateFormatDDMMYYYY(value?.["sourcing_date"])}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.["purchase_cost"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              <span>Purchase Cost</span>
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
            <BiRupee />{value?.["purchase_cost"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.[`name_source`] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Name of Source
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.[`name_source`]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.[`transportation_charges_cost`] !== 0 &&
        value?.[`transportation_charges_cost`] && (
          <SpecificationContainer.TableConatiner>
            <SpecificationContainer.TableHeader fontSize={headerFontSize}>
              <SpecificationContainer.TableHeaderText>
                <span>Transportation Charges</span>
              </SpecificationContainer.TableHeaderText>
            </SpecificationContainer.TableHeader>
            <SpecificationContainer.TableValues fontSize={valueFontSize}>
              <SpecificationContainer.TableValuesText>
              <BiRupee />{value?.[`transportation_charges_cost`]}
              </SpecificationContainer.TableValuesText>
            </SpecificationContainer.TableValues>
          </SpecificationContainer.TableConatiner>
        )}
      {value?.[`parking_charge_cost`] !== 0 &&
        value?.[`parking_charge_cost`] && (
          <SpecificationContainer.TableConatiner>
            <SpecificationContainer.TableHeader fontSize={headerFontSize}>
              <SpecificationContainer.TableHeaderText>
                <span>Parking + Other Yard charges</span>
              </SpecificationContainer.TableHeaderText>
            </SpecificationContainer.TableHeader>
            <SpecificationContainer.TableValues fontSize={valueFontSize}>
              <SpecificationContainer.TableValuesText>
              <BiRupee />{value?.[`parking_charge_cost`]}
              </SpecificationContainer.TableValuesText>
            </SpecificationContainer.TableValues>
          </SpecificationContainer.TableConatiner>
        )}
      {value?.[`Source`] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Source
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.[`Source`]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.[`nbfc`] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              NBFC
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.[`nbfc`]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.[`specify_other`] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Other
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.[`specify_other`]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.[`contact_source`] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Contact of Source
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.[`contact_source`]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.[`sourcing_enabled_M_and_M`] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Was Sourcing enabled by M&M?
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.[`sourcing_enabled_M_and_M`]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.[`remarks`] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Remarks
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.[`remarks`]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      <SpecificationContainer.TableConatiner>
        <SpecificationContainer.TableHeader fontSize={headerFontSize}>
          <SpecificationContainer.TableHeaderText>
            Timestamp
          </SpecificationContainer.TableHeaderText>
        </SpecificationContainer.TableHeader>
        <SpecificationContainer.TableValues fontSize={valueFontSize}>
          <SpecificationContainer.TableValuesText>
            {dateTime_MWORD_AMPM(dateTime)}
          </SpecificationContainer.TableValuesText>
        </SpecificationContainer.TableValues>
      </SpecificationContainer.TableConatiner>
    </SpecificationContainer>
  );
}
