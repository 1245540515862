import React from "react"; 
import Main from "../../../components/main";
import useFetch from "../../../utils/useFetch";
import { useQuery } from "../../../utils/useQuery";
import FetaureChecks from "./fetaureChecks";

export default function FeatureTable(){

    const queryParams = useQuery();
    const lang = queryParams.get("lang").toUpperCase();
    const [categoryData, categoryFetching, categoryError] = useFetch({
        tableName: "Information_Category",
        fields: [
          `Category`,
          `Category(EN)`,
          `CategoryID`,
          `Category(${lang})`
        ],
        filterByFormula: "",
    });

    return (
        <Main>
        {categoryData && <FetaureChecks categoryData={categoryData} lang={lang} />}
        </Main>
    )
}