import styled from "styled-components";

export const Container = styled.div`
    padding: 17px;
`;
export const LocationLabel = styled.div`
    margin: 12px 0px 18px 0px;
`;

export const LocationIcon = styled.img``;

export const LocationText = styled.span`
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px; 
    color: #333333;
`;

export const ProductContents = styled.div``;

export const BrandText = styled.p`
    fontSize: 22px;
    margin-top: 5px; marginBottom: 10px;
    color: #404040;
    font-size: 16px;
`;

export const PriceText = styled.span`
    font-size: 20px;
    margin-top: 10px; 
    font-weight: 700;
`;

export const FeatureContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px; 
    fontSize: 14px;
    margin-top: 10px;
`;

export const FeatureDetails = styled.div`
    height: 110px;
    background-color: #FCF8F8;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const FeatureIcon = styled.img``;

export const FeatureLabel = styled.span``;

export const FeatureText1 = styled.span``;

export const FeatureText2 = styled.span`
    font-weight: 700;
`;

export const ButtonContainer = styled.div`
    bottom: 0;
    width: 100%;
    padding: 17px; 
    position: fixed;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px -2px 6px #00000029;

    @media (min-width: 768px) {
        width: 760px;
    }   

`;

export const InterestedButton = styled.button`
    background: ${({disabled}) => disabled ? '#b0b0b0' : 'transparent linear-gradient(98deg, #FDAA77 0%, #F67626 100%) 0% 0% no-repeat padding-box'};
    cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
    border-radius: 4px;
    width: 100%;
    opacity: 1;
    height: 48px;
    border: none;
    color: #FFFF;
    font-size: 16px;
`;