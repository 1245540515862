// ERROR TYPES
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//FETCH MANUFACTURER AND STATE
export const FETCH_MANUFACTURER_AND_STATE_REQUEST = "FETCH_TRACTOR_REQUEST";
export const FETCH_MANUFACTURER_AND_STATE_SUCCESS = "FETCH_MANUFACTURER_AND_STATE_SUCCESS";
export const FETCH_MANUFACTURER_AND_STATE_FAILURE = "FETCH_MANUFACTURER_AND_STATE_FAILURE";

//FETCH PRICING OTHER DETAILS
export const FETCH_PRICING_OTHER_DETAILS_REQUEST = "FETCH_PRICING_OTHER_DETAILS_REQUEST";
export const FETCH_PRICING_OTHER_DETAILS_SUCCESS = "FETCH_PRICING_OTHER_DETAILS_SUCCESS";
export const FETCH_PRICING_OTHER_DETAILS_FAILURE = "FETCH_PRICING_OTHER_DETAILS_FAILURE";

//FETCH PRICING DATA
export const FETCH_PRICING_DATA_REQUEST = "FETCH_PRICING_DATA_REQUEST";
export const FETCH_PRICING_DATA_SUCCESS = "FETCH_PRICING_DATA_SUCCESS";
export const FETCH_PRICING_DATA_FAILURE = "FETCH_PRICING_DATA_FAILURE";
export const FETCH_PRICING_DATA_RESET = "FETCH_PRICING_DATA_RESET";

//FETCH_INVENTORY_SINGLE_DATA
export const FETCH_INVENTORY_SINGLE_DATA_REQUEST = "FETCH_INVENTORY_SINGLE_DATA_REQUEST";
export const FETCH_INVENTORY_SINGLE_DATA_SUCCESS = "FETCH_INVENTORY_SINGLE_DATA_SUCCESS";
export const FETCH_INVENTORY_SINGLE_DATA_FAILURE = "FETCH_INVENTORY_SINGLE_DATA_FAILURE";

//CAMPAIGN LEADS
export const LEADS_SUBMIT_REQUEST = "LEADS_SUBMIT_REQUEST";
export const LEADS_SUBMIT_SUCCESS = "LEADS_SUBMIT_SUCCESS";
export const LEADS_SUBMIT_FAILURE = "LEADS_SUBMIT_FAILURE";
export const LEADS_SUBMIT_RESET = "LEADS_SUBMIT_RESET";

//LANGUAGE FETCH
export const SHOW_LANGUAGE_DROPDOWN = "SHOW_LANGUAGE_DROPDOWN";
export const SET_LANGUAGE = "SET_LANGUAGE";
// export const FETCH_LANGUAGE_REQUEST = "FETCH_LANGUAGE_REQUEST";
// export const FETCH_LANGUAGE_SUCCESS = "FETCH_LANGUAGE_SUCCESS";

//FAQ LANGUAGE SET
export const SET_FAQ_LANGUAGE = "SET_FAQ_LANGUAGE";

//FETCH CATEGORY
export const FETCH_CATEGORY_REQUEST = "FETCH_CATEGORY_REQUEST";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";

//FETCH_DYNAMICTABLE
export const FETCH_DYNAMICTABLE_REQUEST = "FETCH_DYNAMICTABLE_REQUEST";
export const FETCH_DYNAMICTABLE_SUCCESS = "FETCH_DYNAMICTABLE_SUCCESS";
export const FETCH_DYNAMICTABLE_FAILURE = "FETCH_DYNAMICTABLE_FAILURE";

//FETCH INVENTORY
export const FETCH_DEALER_INVENTORY_DATA_REQUEST = "FETCH_DEALER_INVENTORY_DATA_REQUEST";
export const FETCH_DEALER_INVENTORY_DATA_SUCCESS = "FETCH_DEALER_INVENTORY_DATA_SUCCESS";
export const FETCH_DEALER_INVENTORY_DATA_FAILURE = "FETCH_DEALER_INVENTORY_DATA_FAILURE";
export const FETCH_DEALER_INVENTORY_DATA_RESET = "FETCH_DEALER_INVENTORY_DATA_RESET";



//FETCH_ALL_INVENTORY_DATA_REQUEST
export const FETCH_ALL_INVENTORY_DATA_REQUEST = "FETCH_ALL_INVENTORY_DATA_REQUEST";
export const FETCH_ALL_INVENTORY_DATA_SUCCESS = "FETCH_ALL_INVENTORY_DATA_SUCCESS";
export const FETCH_ALL_INVENTORY_DATA_FAILURE = "FETCH_ALL_INVENTORY_DATA_FAILURE";
export const FETCH_ALL_INVENTORY_DATA_RESET = "FETCH_ALL_INVENTORY_DATA_RESET";

//FETCH_ALL_INVENTORY_DATA_REQUEST
export const FETCH_USER_LOCATION_DATA_REQUEST = "FETCH_USER_LOCATION_DATA_REQUEST";
export const FETCH_USER_LOCATION_DATA_SUCCESS = "FETCH_USER_LOCATION_DATA_SUCCESS";
export const FETCH_USER_LOCATION_DATA_FAILURE = "FETCH_USER_LOCATION_DATA_FAILURE";

//FETCH_AIRTABLE_USER_LOCATION
export const FETCH_AIRTABLE_USER_LOCATION_REQUEST = "FETCH_AIRTABLE_USER_LOCATION_REQUEST";
export const FETCH_AIRTABLE_USER_LOCATION_SUCCESS = "FETCH_AIRTABLE_USER_LOCATION_SUCCESS";
export const FETCH_AIRTABLE_USER_LOCATION_FAILURE = "FETCH_AIRTABLE_USER_LOCATION_FAILURE";
export const FETCH_AIRTABLE_USER_LOCATION_RESET = "FETCH_AIRTABLE_USER_LOCATION_RESET";

//FETCH_BUYSELL_FINANCELIST
export const FETCH_BUYSELL_FINANCELIST_REQUEST = "FETCH_BUYSELL_FINANCELIST_REQUEST";
export const FETCH_BUYSELL_FINANCELIST_SUCCESS = "FETCH_BUYSELL_FINANCELIST_SUCCESS";
export const FETCH_BUYSELL_FINANCELIST_FAILURE = "FETCH_BUYSELL_FINANCELIST_FAILURE";
export const FETCH_BUYSELL_FINANCELIST_RESET = "FETCH_BUYSELL_FINANCELIST_RESET";

//FETCH_EXPENSE_CHASSIS_DATA
export const FETCH_EXPENSE_CHASSIS_DATA_REQUEST = "FETCH_EXPENSE_CHASSIS_DATA_REQUEST";
export const FETCH_EXPENSE_CHASSIS_DATA_SUCCESS = "FETCH_EXPENSE_CHASSIS_DATA_SUCCESS";
export const FETCH_EXPENSE_CHASSIS_DATA_FAILURE = "FETCH_EXPENSE_CHASSIS_DATA_FAILURE";
export const FETCH_EXPENSE_CHASSIS_DATA_RESET = "FETCH_EXPENSE_CHASSIS_DATA_RESET";
export const EXPENSE_PAGE_POSITION = "EXPENSE_PAGE_POSITION";

//EXPENSE_FILTER_DATA
export const FILTER_EXPENSE_SHOW = "FILTER_EXPENSE_SHOW";
export const FILTER_EXPENSE_TYPE_DATA = "FILTER_EXPENSE_TYPE_DATA";
export const FILTER_EXPENSE_SET_DATA = "FILTER_EXPENSE_SET_DATA";
export const FILTER_EXPENSE_TYPE_RESET = "FILTER_EXPENSE_TYPE_RESET";
export const FILTER_EXPENSE_FINAL_OPTION = "FILTER_EXPENSE_FINAL_OPTION";

//SEND OTP
export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";
export const SEND_OTP_RESET = "SEND_OTP_RESET";

//VERIFY OTP
export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";
export const VERIFY_OTP_RESET = "VERIFY_OTP_RESET";

//CAMPAIGN INVENTORY
export const CAMPAIGN_INVENTORY_REQUEST = "CAMPAIGN_INVENTORY_REQUEST";
export const CAMPAIGN_INVENTORY_SUCCESS = "CAMPAIGN_INVENTORY_SUCCESS";
export const CAMPAIGN_INVENTORY_FAILURE = "CAMPAIGN_INVENTORY_FAILURE";
export const CAMPAIGN_INVENTORY_RESET = " CAMPAIGN_INVENTORY_RESET";
export const CAMPAIGN_PAGE_POSITION = "CAMPAIGN_PAGE_POSITION";

//FETCH ADDITIONAL INFO QUESTION
export const FETCH_ADDITIONAL_INFO_QUESTION_REQUEST = "FETCH_ADDITIONAL_INFO_QUESTION_REQUEST";
export const FETCH_ADDITIONAL_INFO_QUESTION_SUCCESS = "FETCH_ADDITIONAL_INFO_QUESTION_SUCCESS";
export const FETCH_ADDITIONAL_INFO_QUESTION_FAILURE = "FETCH_ADDITIONAL_INFO_QUESTION_FAILURE";

//APPLY FILTER
export const SET_INV_FILTER_DROPDOWN = "SET_INV_FILTER_DROPDOWN";
export const SET_INV_FILTERED_DATA = "SET_INV_FILTERED_DATA";
export const SET_INV_FILTER_RESET = "SET_INV_FILTER_RESET";

//APPLY SORT
export const SET_INV_SORT_DROPDOWN = "SET_INV_SORT_DROPDOWN";
export const SET_INV_SORTTED_DATA = "SET_INV_SORTTED_DATA";
export const SET_INV_SORT_RESET = "SET_INV_SORT_RESET";

//FETCH BRAND MODEL
export const FETCH_BRAND_MODEL_REQUEST = "FETCH_BRAND_MODEL_REQUEST";
export const FETCH_BRAND_MODEL_SUCCESS = "FETCH_BRAND_MODEL_SUCCESS";
export const FETCH_BRAND_MODEL_FAILURE = "FETCH_BRAND_MODEL_FAILURE";

//FETCH BRAND MODEL
export const POST_NT_TRACTOR_ID_VERIFY_REQUEST = "POST_NT_TRACTOR_ID_VERIFY_REQUEST";
export const POST_NT_TRACTOR_ID_VERIFY_SUCCESS = "POST_NT_TRACTOR_ID_VERIFY_SUCCESS";
export const POST_NT_TRACTOR_ID_VERIFY_FAILURE = "POST_NT_TRACTOR_ID_VERIFY_FAILURE";
export const POST_NT_TRACTOR_ID_VERIFY_RESET = "POST_NT_TRACTOR_ID_VERIFY_RESET";

//FETCH BRAND MODEL
export const FETCH_SINGLE_NT_TRACTOR_REQUEST = "FETCH_SINGLE_NT_TRACTOR_REQUEST";
export const FETCH_SINGLE_NT_TRACTOR_SUCCESS = "FETCH_SINGLE_NT_TRACTOR_SUCCESS";
export const FETCH_SINGLE_NT_TRACTOR_FAILURE = "FETCH_SINGLE_NT_TRACTOR_FAILURE";