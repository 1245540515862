import * as types from "../types";

export const languageDropdownShow = ({showLangDrodpdown}) => ({
    type: types.SHOW_LANGUAGE_DROPDOWN,
    payload:{
        showLangDrodpdown
    }
})

export const setLanguage = ({languageCode}) => ({
    type: types.SET_LANGUAGE,
    payload:{
        languageCode
    }
})

// export const languageRequest = () => ({
//    type: types.FETCH_LANGUAGE_REQUEST
// });

// export const languageSuccess = ({languageState}) => ({
//     type: types.FETCH_LANGUAGE_SUCCESS,
//     payload:{
//         languageState
//     }
// });