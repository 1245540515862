import * as types from "../types";
import * as _api from "../../api";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { clearErrors, returnErrors } from "../actions/errorAction";
import { campaignInventoryFailure, campaignInventorySuccess } from "../actions/campaignInventoryAction";

function* asyncfetchCampaignInvtorySaga(action){
    try{
        yield put(clearErrors());
        let resCampaignInvData = yield all(
        new Array(action.payload.reqCampaignInv.idLength)
        .fill(0)
        .map((_, index) => {
          return call(_api.fetchCampaignInventory, {
            reqCampaignInv: {
              user_id: action.payload.reqCampaignInv.user_id[index],
            },
          });
        })
    );
        yield put(campaignInventorySuccess({resCampaignInvData}));
    }catch(e){
        yield put(campaignInventoryFailure({error:e}));
        yield put(returnErrors({msg: e.message, status: true}));
    }   
}

export default function* campaignInventorySaga(){
    yield takeLatest(types.CAMPAIGN_INVENTORY_REQUEST, asyncfetchCampaignInvtorySaga)
}