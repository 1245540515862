import * as types from "../types"

export const expenseChassisRequest = ({reqChassis}) => ({
    type: types.FETCH_EXPENSE_CHASSIS_DATA_REQUEST,
    payload:{
        reqChassis
    }
})

export const expenseChassisSuccess = ({resChassisData}) =>({
    type: types.FETCH_EXPENSE_CHASSIS_DATA_SUCCESS,
    payload:{
        resChassisData
    }
})

export const expenseChassisFailure = ({error}) => ({
    type: types.FETCH_EXPENSE_CHASSIS_DATA_FAILURE,
    payload: {
        error: error.message
    }
})
