import React,{useEffect} from 'react';
import { useNavigate } from "react-router-dom";

const AuthPageTest = () => {
    let navigate = useNavigate();
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
     });
    const lang = params.lang;
    const source = params.source;
    const campaign = params.campaign;
    const location = params.location;
    const state = params.state;

    useEffect(()=>{
        if (lang !== null) {
            sessionStorage.setItem("lang", lang);
        }
    
        if (source !== null) {
            sessionStorage.setItem("source", source);
        }
    
        if (campaign !== null) {
            sessionStorage.setItem("campaign", campaign);
        }
    
        if (location !== null) {
            sessionStorage.setItem("location", location);
        }
        if (state !== null) {
            sessionStorage.setItem("state", state);
        }
        navigate(`/lpsv2`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  return (
    <div>AuthPage</div>
  )
}

export default AuthPageTest