import React from "react";
import {
  Container,
  LocationLabel,
  LocationIcon,
  LocationText,
  BrandText,
  PriceText,
  FeatureContainer,
  FeatureDetails,
  FeatureIcon,
  FeatureLabel,
  FeatureText1,
  FeatureText2,
  ProductContents,
  InterestedButton,
  ButtonContainer
} from "./styles/productDetail";

export default function ProductDetailContainer({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

ProductDetailContainer.LocationLabel =
  function ProductDetailContainerLocationLabel({ children, ...props }) {
    return <LocationLabel {...props}>{children}</LocationLabel>;
  };

ProductDetailContainer.LocationIcon =
  function ProductDetailContainerLocationIcon({ ...props }) {
    return <LocationIcon {...props} />;
  };

ProductDetailContainer.LocationText =
  function ProductDetailContainerLocationText({ children, ...props }) {
    return <LocationText {...props}>{children}</LocationText>;
  };

ProductDetailContainer.ProductContents =
  function ProductDetailContainerProductContents({ children, ...props }) {
    return <ProductContents {...props}>{children}</ProductContents>;
  };

ProductDetailContainer.BrandText = function ProductDetailContainerBrandText({
  children,
  ...props
}) {
  return <BrandText {...props}>{children}</BrandText>;
};

ProductDetailContainer.PriceText = function ProductDetailContainerPriceText({
  children,
  ...props
}) {
  return <PriceText {...props}>{children}</PriceText>;
};

ProductDetailContainer.FeatureContainer =
  function ProductDetailContainerFeatureContainer({ children, ...props }) {
    return <FeatureContainer {...props}>{children}</FeatureContainer>;
  };

ProductDetailContainer.FeatureDetails =
  function ProductDetailContainerFeatureDetails({ children, ...props }) {
    return <FeatureDetails {...props}>{children}</FeatureDetails>;
  };

ProductDetailContainer.FeatureIcon =
  function ProductDetailContainerFeatureIcon({ ...props }) {
    return <FeatureIcon {...props} />;
  };

ProductDetailContainer.FeatureLabel =
  function ProductDetailContainerFeatureLabel({ children, ...props }) {
    return <FeatureLabel {...props}>{children}</FeatureLabel>;
  };

ProductDetailContainer.FeatureText1 =
  function ProductDetailContainerFeatureText1({ children, ...props }) {
    return <FeatureText1 {...props}>{children}</FeatureText1>;
  };

ProductDetailContainer.FeatureText2 =
  function ProductDetailContainerFeatureText1({ children, ...props }) {
    return <FeatureText2 {...props}>{children}</FeatureText2>;
  };

ProductDetailContainer.ButtonContainer =
  function ProductDetailContainerButtonContainer({ children, ...props }) {
    return <ButtonContainer {...props}>{children}</ButtonContainer>;
  }; 

ProductDetailContainer.InterestedButton =
  function ProductDetailContainerInterestedButton({ children, ...props }) {
    return <InterestedButton {...props}>{children}</InterestedButton>;
  };