import React from "react";
import Header from "../components/header";
import FeatureTable from "../containers/features/add/featureTable";

export default function FeatureCheckList(){
    return (
        <>
        <Header style={{padding:'5px',fontWeight:'700',fontSize:'17px'}}>
            More Details
        </Header>
        <FeatureTable />
        </>
    )
}