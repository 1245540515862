import React, { Fragment } from "react";
import TractorVerification from "../../containers/metabase/tractorVerification";
import Header from "../../components/header";
import { METABASE_BASE_URL, metabseEndpointRoutes } from "../../config";

export default function TractorVerificationPage() {

	const backButton = () => {
		window.location.href = `${METABASE_BASE_URL.BASE_URL}${metabseEndpointRoutes()?.NT_VERIFICATION_ROUTE}`;
	}

	return (
		<Fragment>
			<Header>
				<Header.BackButtonContainer>
					<Header.BackIcon
						src="../assets/arrow_back.svg"
						alt=""
						onClick={backButton}
					/>
					<Header.ButtonText>Verification</Header.ButtonText>
				</Header.BackButtonContainer>
			</Header>
			<TractorVerification />
		</Fragment>
	);
}
