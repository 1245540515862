import React from 'react'
import Loader from '../../components/Loader/Loader';
import Section from '../../components/section';
import { useFetchBE } from '../../utils/useFetchBE';
import { API_BASE_URL, API_ROUTES } from '../../config';
import { useQuery } from '../../utils/useQuery';
import SpecificationContainer from '../../components/specification';
import { priceFormatter } from '../../utils/priceFormatter';

export default function ExpenseSummary(){
 
    const queryParams = useQuery();
    const userid = queryParams.get("userid");
    const [data, fetchdata, fetching, statusBE] = useFetchBE({url:`${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_EXPENSE_SUMMARY}/${userid}`,redirect:"ExpenseSummary"});

    const centeralign = {display:'flex', alignItems:'center', justifyContent:'center',width:'100%',minHeight:'45px'}

    return (
        <> 
        {fetching ? <Loader/> : 
        <>
        {(data?.status && typeof(data?.data) === "object") &&
        <>
        <SpecificationContainer.TableHeader bgColor="#F1E5F1">
                    <SpecificationContainer.TableHeaderText>Expense summary</SpecificationContainer.TableHeaderText>
        </SpecificationContainer.TableHeader>
        <div style={{boxShadow:'0px 8px 12px #00000017',display:'flex',flexDirection:'column',width:'100%' }}>
                <SpecificationContainer.TableValues justifyContent="center" padding="11px">
                    <SpecificationContainer.TableValuesText flexDirection="column" fontWeight="700">{data?.data?.["total_tractors_sold"]}<SpecificationContainer.PriceLabel color="#666666" fontWeight="400">Total tractors sold</SpecificationContainer.PriceLabel></SpecificationContainer.TableValuesText>
                </SpecificationContainer.TableValues>
                <div style={{display:'grid',gridTemplateColumns:'1fr 1fr',alignItems:'center',justifyContent:'center',width:'100%'}}>    
                <SpecificationContainer.TableValues  justifyContent="center" padding="11px">
                    <SpecificationContainer.TableValuesText flexDirection="column" fontWeight="700">₹ {priceFormatter(data?.data?.["avg_procurement_price"])}<SpecificationContainer.PriceLabel color="#666666" fontWeight="400">Average Procurement price</SpecificationContainer.PriceLabel></SpecificationContainer.TableValuesText>
                </SpecificationContainer.TableValues>
                <SpecificationContainer.TableValues  justifyContent="center" padding="11px">
                    <SpecificationContainer.TableValuesText flexDirection="column" fontWeight="700">₹ {priceFormatter(data?.data?.["avg_selling_price"])}<SpecificationContainer.PriceLabel color="#666666" fontWeight="400">Average Selling Price</SpecificationContainer.PriceLabel></SpecificationContainer.TableValuesText>
                </SpecificationContainer.TableValues>
                </div>
        </div>
        <br />
        <br />
        </>
        

        
 }

    </>
}
    </>
    )

}