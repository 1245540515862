import React, { useEffect } from "react";
import BuyCards from "../components/buyCard";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function LoadMoreData({...props}){
    const { t: common } = useTranslation("common");
    const [visibleCards, setVisibleCards] = useState(12);

    let initialPost = props?.dataArray;
    let localStorageGetCurrentCardsize = localStorage.getItem(props?.component?.type?.name)
    useEffect(() => {
      if(localStorageGetCurrentCardsize && !isNaN(localStorageGetCurrentCardsize)){
        setVisibleCards(Number(localStorageGetCurrentCardsize));
      }
    },[localStorageGetCurrentCardsize])

    const loadMore = () => {
        setVisibleCards((prevVisibleCards) => prevVisibleCards + props.incrementValue);
        localStorage.setItem(props?.component?.type?.name,visibleCards + props.incrementValue)
    };

    return (
        <>
        {React.cloneElement(props.component, { dataFromParent: {...props,initialPost,visibleCards} })}
        {visibleCards < initialPost?.length &&
            <BuyCards.LoadMore onClick={() => loadMore()}>
              {common("load_more")}
              <img src="../assets/arrow/arrow_down.svg" alt="" />
            </BuyCards.LoadMore>
          }
        </>
    )

}

