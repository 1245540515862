import * as types from "../types";
import * as _api from "../../api";
import { clearErrors, returnErrors } from "../actions/errorAction";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchBrandModelFailure, fetchBrandModelSuccess } from "../actions/fetchBrandModelAction";

function* asyncFetchBrandModelSaga(action){

    try{
        yield put(clearErrors());
        let resBrandModel = yield call(_api.fetchBrandModelApi)
        yield put(fetchBrandModelSuccess({resBrandModel}));
    }catch(e){
        yield put(fetchBrandModelFailure({error:e}));
        yield put(returnErrors({msg: e.message, status: true}));
    }

}

export default function* fetchBrandModelSaga(){
    yield takeLatest(types.FETCH_BRAND_MODEL_REQUEST,asyncFetchBrandModelSaga);
}