import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const useDuplicateChassisNumber = ({...props}) => {

    const [duplicateData, setDuplicateData] = useState("");
    const [duplicateFetching,setDuplicateFetching] = useState(false);
    const isFirstRender = useRef(true);
    const ref =  useRef(false);
    let navigate = useNavigate();    

    const DuplicateDataFn = (postval) => {
        setDuplicateFetching(true);  
       const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json'},
         body: JSON.stringify(postval)
       };
       let url = props?.url;

          fetch(url,requestOptions)
          .then(resp => resp.json())
          .then(datass => {
            setDuplicateData(datass)
            setDuplicateFetching(false);
            //console.log("setStatus22",data.status)
            // if(duplicateFetching === false){
            //     if(!datass.status){
            //         Swal.fire({
            //             icon: "error",
            //             text: "Something went wrong..."
            //           }).then((res) => {
            //             if (res) {
            //               navigate(`/`);
            //             }
            //           });
            //     }
            // }
          });
      } 
    
      useEffect(() => {
        if(isFirstRender.current){
            isFirstRender.current = false;
        }else{
          DuplicateDataFn();
        }
      },[])
    


    return [duplicateData,DuplicateDataFn,duplicateFetching]
}