import * as types from "../types";
import * as _api from "../../api";
import { clearErrors, returnErrors } from "../actions/errorAction";
import { call, put, takeLatest } from "redux-saga/effects";
import { additionalInfoQuestionFailure, additionalInfoQuestionSuccess } from "../actions/additionalInfoQuestionAction";

function* asynFetchAdditonalInfoQuestionSaga(action){

    try{
        yield put(clearErrors());
        let resQuestion = yield call(_api.fetchAdditionalInfoFeature,{
            reqAddInfoQues: action.payload.reqAddInfoQues
        })
        yield put(additionalInfoQuestionSuccess({resQuestion}));
    }catch(e){
        yield put(additionalInfoQuestionFailure({error:e}));
        yield put(returnErrors({msg:e.message, status: true}));
    }
}

export default function* fetchAdditonalInfoQuestionSaga(){
   yield takeLatest(types.FETCH_ADDITIONAL_INFO_QUESTION_REQUEST,asynFetchAdditonalInfoQuestionSaga);
}


