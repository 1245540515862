import React from "react";
import { Container, Header, HeaderText, Icon, Image, Main, MainContent, MainLabel, MainText, ButtonContent, Button } from "./styles/filter";

export default function FilterContainer({children,...props}){
    return <Container {...props}>{children}</Container>
}

FilterContainer.Header = function FilterContainerHeader({children,...props}){
    return <Header {...props}>{children}</Header>
}

FilterContainer.HeaderText = function FilterContainerHeaderText({children,...props}){
    return <HeaderText {...props}>{children}</HeaderText>
}

FilterContainer.ButtonContent = function FilterContainerButtonContent({children,...props}){
    return <ButtonContent {...props}>{children}</ButtonContent>
}

FilterContainer.Icon = function FilterContainerIcon({children,...props}){
    return <Icon {...props}>{children}</Icon>
}

FilterContainer.Image = function FilterContainerImage({children,...props}){
    return <Image {...props}>{children}</Image>
}

FilterContainer.Main = function FilterContainerMain({children,...props}){
    return <Main {...props}>{children}</Main>
}

FilterContainer.MainContent = function FilterContainerMainContent({children,...props}){
    return <MainContent {...props}>{children}</MainContent>
}

FilterContainer.MainLabel = function FilterContainerMainLabel({children,...props}){
    return <MainLabel {...props}>{children}</MainLabel>
}

FilterContainer.MainText = function FilterContainerMainText({children,...props}){
    return <MainText {...props}>{children}</MainText>
}

FilterContainer.Button = function FilterContainerButton({children,...props}){
    return <Button {...props}>{children}</Button>
}



