import React from "react";
import { Container, Text,Content, TitleContainer } from "./styles/collapseContent";

export default function CollapseContainer({children,...props}){
    return <Container {...props}>{children}</Container>
}

CollapseContainer.TitleContainer = function CollapseTitleContainer({children,...props}){
    return <TitleContainer {...props}>{children}</TitleContainer>
}


CollapseContainer.Text = function CollapseContainerText({children,...props}){
    return <Text {...props}>{children}</Text>
}

CollapseContainer.Content = function CollapseContainerContent({children,...props}){
    return <Content {...props}>{children}</Content>
}