import styled from "styled-components";

export const Container = styled.div`
  width: 100%;        
  min-height: 48px; 
  background: #652078 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #0000000D;
  color: #FFFF;
  font-weight: 700;
  padding: 4px;
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const BackIcon = styled.img``;

export const ButtonText = styled.p`
  font-weight: bold;
`;