import React from "react";
import { Container, GroupFields, Input, RangeInput, Label, Button, Dropdown, InputWrapper,Textarea, Section, SectionTitle, FieldBox, ButtonContainer, SectionContainer, Image, FileInputConatiner, IconButton, PricingDropdown, PricingInput } from "./styles/form";

export default function Form({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

Form.SectionContainer = function FormSectionContainer({children,...props}){
    return <SectionContainer {...props}>{children}</SectionContainer>
}

Form.Section = function FormSection({children, ...props}){
    return <Section {...props}>{children}</Section>;
}

Form.InputWrapper = function FormInputWrapper({ children, ...props }) {
  return <InputWrapper {...props}>{children}</InputWrapper>;
};


Form.SectionTitle = function FormSectionTitle({children,...props}){
    return <SectionTitle {...props}>{children}</SectionTitle>
}

Form.FieldBox = function FormFieldBox({children,...props}){
    return <FieldBox {...props}>{children}</FieldBox>
}

Form.GroupFields = function FormGroupFields({ children, ...props }) {
  return <GroupFields {...props}>{children}</GroupFields>;
};

Form.Input = function FormInput({ ...props }) {
  // console.log(props);
  return <Input {...props} />;
};

Form.PricingInput = function FormInput({ ...props }) {
  // console.log(props);
  return <PricingInput {...props} />;
};

Form.RangeInput = function FormRangeInput({children,...props}){
  return <RangeInput {...props} />
} 

Form.Label = function FormLabel({ children, ...props }) {
  return <Label {...props}>{children}</Label>;
};

Form.Dropdown = function FormDropdown({ options, ...props }){
  // console.log(props)
    return <Dropdown {...props} >
             {options.map((item,index)=> <option key={index} value={item.value}>{item.label}</option>)}
        </Dropdown>
}

Form.PricingDropdown = function FormPricingDropdown({ options, ...props }){
  // console.log(props)
    return <PricingDropdown {...props} >
             {options.map((item,index)=> <option key={index} value={item.value}>{item.label}</option>)}
        </PricingDropdown>
}

Form.FileInputConatiner = function FormFileInputConatiner({children,...props}){
    return <FileInputConatiner {...props}>{children}</FileInputConatiner>
}


Form.Textarea = function FormTextarea({...props}){
    return <Textarea {...props} /> 
}

Form.ButtonContainer = function FormButtonContainer({children, ...props}){
    return <ButtonContainer {...props}>{children}</ButtonContainer>
}

Form.Button = function FormButton({...props }) {
  return <Button {...props}></Button>;
};

Form.Image = function FormImage({...props }) {
    return <Image {...props} />
};

Form.IconButton = function FormIconButton({children,...props}){
  return <IconButton {...props}>{children}</IconButton>
}