import * as types from "../../types";
import * as _airtableApi from "../../../api/airtable";
import { clearErrors, returnErrors } from "../../actions/errorAction";
import { call, put, takeLatest } from "redux-saga/effects";
import { categorySuccess } from "../../actions/airtableCategoryAction";

function * asyncFetchCategorySaga(){
  const categoryObj = {
        tableName: "Category",
        fields: [
            `Name`,
            `categoryID`
        ],
  filterByFormula: "",
  }
    
    try{
        yield put(clearErrors())
        let resCategory = yield call(_airtableApi.fetchAirtableDataFn,categoryObj)
        yield put(categorySuccess({resCategory}));
    }catch(e){
        yield put(returnErrors({msg: e.message,status: true}))
    }
}

export default function* fetchCategorySaga(){
    yield takeLatest(types.FETCH_CATEGORY_REQUEST,asyncFetchCategorySaga);
}