import styled from "styled-components";

export const Container = styled.div`
    min-height: auto;
    max-height: 500px;
    overflow:scroll;
`;

export const TableConatiner = styled.div`
    width: 100%;
    height: auto;
    display: flex;
`;

export const VarientConatiner = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    gap: 8px;
    overflow-x: scroll;
    margin-bottom: 10px;
`;

export const Varient = styled.p`
    width: 40%;
    padding: 4px 8px;
    height: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #C3C3C3;
    border-radius: 4px;
    display: flex;
    &:hover{
    background: #FBC4A2 0% 0% no-repeat padding-box;
    border: 2px solid #F37021;
}
`;

export const TableHeader = styled.div`
    width: 100%;
    min-width: 150px;
    height: auto;
    display: flex;
    align-items: center;
    border: 1px solid #EAEAEA;
    background-color: ${(props) => props.bgColor ? props.bgColor : '#F3F3F3'};
    padding: 11px;
    font-size: ${props => props.fontSize ? props.fontSize : '14px'};

    .edit_text{
        display: flex;
        color: #F37021;
        gap: 6px;
        cursor: pointer;
    }

`;

export const TableHeaderText = styled.div`
    width: 100%;
    height: auto;
    display: flex; 
    justify-content: space-between;
    align-items: center;
   
    .rupee_icon {
        width: 15%;
        height: 18px;
    }
`;

export const TableValues = styled.div`
    width: 100%;
    min-width: 150px;
    height: auto;
    display: flex;
    align-items: center;
    ${(props) => props.justifyContent ? `justify-content: ${props.justifyContent};`:''}
    border: 1px solid #EAEAEA;
    padding: ${(props) => props.padding ? props.padding : '11px 11px 11px 28px'};
    word-break: break-word;
    font-weight: ${(props) => props.fontWeight ? props.fontWeight : '550'};
    font-size: ${props => props.fontSize ? props.fontSize : '14px'};
`;


export const TableValuesText = styled.div`
    display: flex; 
    flex-direction: ${ (props) => props.flexDirection ? props.flexDirection : '' };
    gap: 5px;
    font-weight: ${(props) => props.fontWeight ? props.fontWeight : '550'};
    align-items: center;
    height: auto;
`;

export const PriceLabel = styled.span`
    font-size: 11px;
    font-weight: ${(props) => props.fontWeight ? props.fontWeight : '550'};
    color: ${(props) => props.color ? props.color: '#000000'}
`;