import * as types from "../types";

const initialState = {
    loading: false,
    reqAddInfoQues: null,
    resQuestion: null,
    error: null
}

export default function additionalInfoQuestionReducer(state = initialState, action){
    
    switch(action.type){

        case types.FETCH_ADDITIONAL_INFO_QUESTION_REQUEST:
            return{
                loading: true,
                reqAddInfoQues: action.payload.reqAddInfoQues
            }
        
        case types.FETCH_ADDITIONAL_INFO_QUESTION_SUCCESS:
            return{
                loading: false,
                resQuestion: action.payload.resQuestion
            }
        
        case types.FETCH_ADDITIONAL_INFO_QUESTION_FAILURE:
            return{
                loading: false,
                reqAddInfoQues: null,
                resQuestion: null,
                error: action.payload.error
            }

        default :
        return state

    }

}