import React from "react";
import Certifcate from "../../components/certificate";
import { CertifyContent } from "../../components/certificate/styles/certificate";
import { MdStar } from "react-icons/md";
import { useQuery } from "../../utils/useQuery";

export default function QualityCheckCertificate(){

    const ownerArray = ["1 st","2 nd","3 rd","4 th"]
    const queryParams =useQuery();
    const rating = queryParams.get("rating");
    const product_name = queryParams.get("product_name");
    const model_no = queryParams.get("model_no");
    const year = queryParams.get("year");
    const location = queryParams.get("location");
    const engine_power = queryParams.get("engine_power");
    const engine_hours = queryParams.get("engine_hours");
    const tyre_condition = queryParams.get("tyre_condition");
    const battery = queryParams.get("battery");
    const owner = queryParams.get("owner");
    const noc_avail = queryParams.get("noc_avail");
    const insurance_avail = queryParams.get("insurance_avail");

    const dynamicWidthCertifyContentBox = () => {
        if((!noc_avail || noc_avail === "null" || noc_avail?.toLowerCase() === "false") || (!insurance_avail || insurance_avail === "null" || insurance_avail?.toLowerCase() === "false")){
            return "40%";
        }
        return '30%';
    } 

    return (
        <Certifcate>
            <Certifcate.HeaderBody>
                <Certifcate.LogoContainer>
                    <Certifcate.Logo src="./assets/certificate/tractorworld.svg" alt="" />
                    {/* <Certifcate.Text>Stock ID: 123456789</Certifcate.Text> */}
                </Certifcate.LogoContainer>
                <Certifcate.Title>Tractor Quality <span>Certificate</span></Certifcate.Title>
            </Certifcate.HeaderBody>
             <Certifcate.Main>
                <Certifcate.MainLeftContent>
                    <Certifcate.MainTitle>{product_name} {model_no}</Certifcate.MainTitle>
                    <Certifcate.CertifyCheckPoints>
                        <CertifyContent dynamicWidth={dynamicWidthCertifyContentBox}>
                            <Certifcate.CertifyLogo src="./assets/certificate/checked.png" alt="" />
                            <Certifcate.CertifyText>40+ points inspected</Certifcate.CertifyText>
                        </CertifyContent>
                        {(noc_avail && noc_avail !== "null" && noc_avail?.toLowerCase() === "true") && <CertifyContent dynamicWidth={dynamicWidthCertifyContentBox}>
                            <Certifcate.CertifyLogo src="./assets/certificate/checked.png" alt="" />
                            <Certifcate.CertifyText>NOC cleared</Certifcate.CertifyText>
                        </CertifyContent>}
                        {(insurance_avail && insurance_avail !== "null" && insurance_avail?.toLowerCase() === "true") && <CertifyContent dynamicWidth={dynamicWidthCertifyContentBox}>
                            <Certifcate.CertifyLogo src="./assets/certificate/checked.png" alt="" />
                            <Certifcate.CertifyText>Insurance available</Certifcate.CertifyText>
                        </CertifyContent>}
                    </Certifcate.CertifyCheckPoints>
                    <Certifcate.Signature src="./assets/certificate/signature.png" alt="" />
                    <Certifcate.InspectorLabel>Inspector name</Certifcate.InspectorLabel>
                </Certifcate.MainLeftContent>
                 <Certifcate.MainRightContent>
                    <Certifcate.RatingContent>
                        <Certifcate.StarContainer>
                        <Certifcate.RatingCountDiv>
                            <Certifcate.RatingCount>{rating}</Certifcate.RatingCount>
                        </Certifcate.RatingCountDiv>
                            {Array.apply(null, { length: 5 }).map((e, i) => {
                                if((i+1) <= rating){
                                return <MdStar style={{color:'#FFBD00',width:'13px',height:'13px'}} key={i} />
                                }else{
                                return <MdStar style={{color:'#FFFF',width:'13px',height:'13px'}} key={i} />
                                }
                                })
                    }</Certifcate.StarContainer>
                    </Certifcate.RatingContent>
                    <Certifcate.ProductDetails>
                        <Certifcate.DetailLabel>Year: <Certifcate.DetailValue>{year}</Certifcate.DetailValue></Certifcate.DetailLabel>
                        <Certifcate.DetailLabel>Location: <Certifcate.DetailValue>{location}</Certifcate.DetailValue></Certifcate.DetailLabel>
                        <Certifcate.DetailLabel>Engine power: <Certifcate.DetailValue>{engine_power}</Certifcate.DetailValue></Certifcate.DetailLabel>
                        <Certifcate.DetailLabel>Engine hours: <Certifcate.DetailValue>{engine_hours}</Certifcate.DetailValue></Certifcate.DetailLabel>
                        <Certifcate.DetailLabel>Tyre condition: <Certifcate.DetailValue>{tyre_condition}</Certifcate.DetailValue></Certifcate.DetailLabel>
                        <Certifcate.DetailLabel>Battery: <Certifcate.DetailValue>{(battery && battery !== "null" && battery?.toLowerCase() === "true") ? "Available":"Not Available"}</Certifcate.DetailValue></Certifcate.DetailLabel>
                        <Certifcate.DetailLabel>Owner: <Certifcate.DetailValue>{ownerArray[owner]}</Certifcate.DetailValue></Certifcate.DetailLabel>
                    </Certifcate.ProductDetails>
                </Certifcate.MainRightContent> 
            </Certifcate.Main> 
        </Certifcate>
    )
}