import React, { useContext, useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import QuestionSection from "../../../components/questionSection";
import { ProductContext } from "../../../utils/productContext";
import useFetch from "../../../utils/useFetch";
import Form from "../../../components/form";

export default function EditFeatureCheckQuestions({ tableName, status, answeredData,product_id, userid, lang }) {
  const [data, setData] = useState();
  const [textData,setTextData]=useState({})
  const [listData, listFetching, listError] = useFetch({
    tableName: tableName,
    fields: [`feature_name(EN)`, `feature_name(${lang})`, `answer_type`, `answers(EN)`,`answers(${lang})`],
    filterByFormula: "",
  });

  const {
    tempJSON,
    setTempJSON,
    loadCheck, 
    setLoadCheck
  } = useContext(ProductContext);

  useEffect(() => {
    let responseData = "";

    if(answeredData?.data){
      responseData = answeredData.data?.additional_featureInfo;
    } 
   // console.log("responseData",responseData)   
    let textValue = textData;
    let preAnswer = tempJSON;
    responseData && Object?.keys(responseData)?.map((item,index) => {
       preAnswer = {...preAnswer, [item]:responseData[item]}
       listData?.map((itemText,index) => {
        if(itemText?.hasOwnProperty("answer_type") && itemText?.["feature_name(EN)"] === item && itemText?.["answer_type"] === "text") {
          textValue = {...textValue, [itemText["feature_name(EN)"]]: responseData[item]}
          setTextData(textValue)
         }
       })
       //console.log("preAnswer111",preAnswer)
       setLoadCheck(false);
       return preAnswer;
    })  
    if(loadCheck) {setTempJSON(preAnswer)}
  },[answeredData,listData])


  const handleChange = (e, response, defaultResponse,color, categoryName, index) => {
    if(color === "radio" && !status){
      setTempJSON((prev) => ({
        ...prev,
        [listData[index][`feature_name(EN)`]]: response,
      }));
    }else if(color === "text" && !status){
        setTextData((prevData) => ({...prevData,[listData[index][`feature_name(EN)`]]:e.target.value}))
        setTempJSON((prev) => ({
          ...prev,
          [listData[index][`feature_name(EN)`]]: e.target.value,
        }));
    }else if(color !== "text" && color !== "radio" && !status){
      setTempJSON((prev) => ({
        ...prev,
        [listData[index][`feature_name(EN)`]]: response?.[`answers(EN)`]?.split('/')?.[`${defaultResponse === "Yes" ? 1 : 0}`],
      }));
    }
  };

  return (
    <div>
      {(listFetching) ? (
        <Loader />
      ) : (
        listData?.map((item, index) => {
           return (
          <QuestionSection key={index}>
            <h4 style={{marginBottom:'5px'}}>
              {index + 1}.&nbsp;{item[`feature_name(${lang})`]}
             </h4>
           { ((tempJSON[item[`feature_name(EN)`]] !== "" || tempJSON[item[`feature_name(EN)`]] === "")  && item.answer_type === "text") ?
                <Form.Input type="text" name={`${tableName} ${index}`} id={`${tableName} ${index}`} onChange={(e) => handleChange(e, item, 'No',"text", tableName, index)} value={textData[item[`feature_name(EN)`]]}  />
            : ((tempJSON[item[`feature_name(EN)`]] === "" || tempJSON[item[`feature_name(EN)`]] !== "")  && item.answer_type === "dropdown") ?
            <div>
          {item?.[`answers(EN)`]?.split("/")?.map((itemList, itemIndex) => {
            return(<div key={itemIndex} style={{display:'flex',alignItems:'center'}}>
              <input type="radio" name={`question_${index}`} id={`q${index}answer_${itemIndex}`} value={`${itemList}`} onChange={(e) => handleChange(e, itemList, 'No',"radio", tableName, index)}  defaultChecked={tempJSON[item[`feature_name(EN)`]] === itemList}  />&nbsp;
              <span>{item?.[`answers(${lang})`]?.split("/")[itemIndex]}</span>
            </div>)
          })}

            </div>
            :  <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 80px 0 80px",
              gap: '20px'
            }}
          >
            {((item?.[`answers(EN)`] && tempJSON[item[`feature_name(EN)`]] === item?.[`answers(EN)`]?.split('/')?.[0])) ? (
              <Form.IconButton onClick={(e) => handleChange(e, item, 'No',"red", tableName, index)} color="red">{item?.[`answers(${lang})`]?.split('/')?.[0]}</Form.IconButton>
            ) : (
                <Form.IconButton onClick={(e) => handleChange(e, item,'No', "red", tableName, index)}>{item?.[`answers(${lang})`]?.split('/')?.[0]}</Form.IconButton>
            )}
            {((item?.[`answers(EN)`] && tempJSON[item[`feature_name(EN)`]] === item?.[`answers(EN)`]?.split('/')?.[1])) ? (
              <Form.IconButton onClick={(e) => handleChange(e, item,'Yes', "green", tableName, index)}  color="green" >{item?.[`answers(${lang})`]?.split('/')?.[1]}</Form.IconButton>
            ) : (
                <Form.IconButton onClick={(e) => handleChange(e, item, 'Yes',"green", tableName, index)}>{item?.[`answers(${lang})`]?.split('/')?.[1]}</Form.IconButton>
            )}
          </div>
            }
            <div style={{ marginBottom: "10px" }}></div>
          </QuestionSection>)
        })
      )}
    </div>
  );
}