import React, { useContext } from "react";
import { ProductContext } from "./productContext";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useInventorySort } from "./useInventorySort";
import {
  setInvFilterReset,
  setInvFilteredArrayAction,
} from "../store/actions/invFilterAction";
import { convertToLowercase } from "./convertToLowerUppercase";
import { useEffect } from "react";
import { setInvAfterSortAction } from "../store/actions/invSortAction";

export const useInventoryFilter = () => {
  const { t: common } = useTranslation("common");
  const { t: errorTrans } = useTranslation("error");
  const dispatch = useDispatch();
  //const languageObj = useSelector(state => state.languageReducer);
  //const langData = languageObj.languageState
  const filterReducerObj = useSelector((state) => state.invFilterReducer);
  const { filterObj, filteredArray } = filterReducerObj;
  const sortReducerObj = useSelector((state) => state.invSortReducer);
  const { sortObj } = sortReducerObj;
  const inventoryObj = useSelector((state) => state.inventoryAllReducer);
  const { resInvAll } = inventoryObj;
  const [applySorting] = useInventorySort();

  const { setShow } = useContext(ProductContext);

  const reset = () => {
    dispatch(setInvFilterReset());
  };

  useEffect(() => {
    // if sorting has apllied after reset filter => applying sorting to og length.
    if (
      filteredArray.length === 0 &&
      sortObj.sort_by &&
      sortObj.sort_by !== ""
    ) {
      let sorttedArrayUE = applySorting(resInvAll?.data, sortObj.sort_by);
      dispatch(setInvAfterSortAction({ sorttedArray: sorttedArrayUE }));
    }
  }, [filteredArray.length]);

  function closeModal() {
    setShow(false);
  }

  const isStatusValid = (status) => [1, 2, 3].includes(status);

  const isMatchingBrand = (itemBrand, filterBrand) =>
    convertToLowercase(itemBrand) === convertToLowercase(filterBrand);

  const isMatchingModel = (itemModel, filterModel) =>
    convertToLowercase(itemModel) === convertToLowercase(filterModel);

  const isMatchingLocation = (itemLocation, filterLocation) =>
    convertToLowercase(filterLocation) === "all" ||
    convertToLowercase(filterLocation) === convertToLowercase(itemLocation);

  const isMatchingState = (itemState, filterState) =>
    convertToLowercase(filterState) === "all" ||
    convertToLowercase(filterState) === convertToLowercase(itemState);

  const isMatchingPriceRange = (itemMaxPrice, filterPriceRange) => {
    const priceRanges = {
      "50k-2L": { min: 50000, max: 200000 },
      "3L-5L": { min: 300000, max: 500000 },
      "5L+": { min: 500000 },
    };
    const range = priceRanges[filterPriceRange];
    return (
      range &&
      ((range.max && itemMaxPrice >= range.min && itemMaxPrice <= range.max) ||
        (!range.max && itemMaxPrice >= range.min))
    );
  };

  const hasValidCategory = (status, filterCategory) =>
    status === filterCategory;

  const apply = (allData) => {
    //console.log("allData",allData,filterObj)
    if (
      filterObj.brand !== "" ||
      filterObj.model !== "" ||
      filterObj.price_range !== "" ||
      filterObj.category ||
      filterObj.location !== "all" ||
      filterObj.state !== "all" ||
      (filteredArray.length > 0 && filterObj.location === "all") ||
      (filteredArray.length > 0 && filterObj.state === "all")
    ) {
      let filtered = allData?.filter((item, index) => {
        const { status, brand, model, max_price, state, user_location } = item;
        const {
          brand: filterBrand,
          model: filterModel,
          category: filterCategory,
          price_range: filterPriceRange,
          location: filterLocation,
          state: filterState,
        } = filterObj;

        if (!isStatusValid(status)) {
          return false;
        }

        const brandMatches = isMatchingBrand(brand, filterBrand);
        const modelMatches = isMatchingModel(model, filterModel);
        const locationMatches = isMatchingLocation(
          user_location,
          filterLocation
        );
        const stateMatches = isMatchingState(state, filterState);
        const priceRangeMatches = isMatchingPriceRange(
          max_price,
          filterPriceRange
        );
        const validCategory = hasValidCategory(status, filterCategory);
        // console.log("isMatchingLocation",brand,model,brandMatches, modelMatches,filterObj,locationMatches,stateMatches,priceRangeMatches,modelMatches,validCategory,isNaN(filterCategory),(validCategory || isNaN(filterCategory)))

        if (
          (brandMatches || filterBrand === "") &&
          (modelMatches || filterModel === "") &&
          (locationMatches || filterLocation === "all") &&
          (stateMatches || filterState === "all") &&
          (priceRangeMatches || filterPriceRange === "") &&
          (validCategory || !filterCategory || isNaN(filterCategory))
        ) {
          return item;
        }
      });
      // console.log("filtered1",filtered,filterObj);
      if (sortObj?.sort_by && sortObj?.sort_by !== "") {
        filtered = applySorting(filtered, sortObj.sort_by);
      } else {
        filtered = filtered?.sort((a, b) => b?.tractor_id - a?.tractor_id);
      }
      //console.log("filtered2",filtered);
      if (filtered.length === 0) {
        setShow(true);
        Swal.fire({
          icon: "error",
          text: errorTrans("error_no_result_try_different_combination"),
          confirmButtonText: common("ok"),
        }).then((res) => {});
      } else {
        setShow(false);
        dispatch(setInvFilteredArrayAction({ filteredArray: filtered }));
      }
    }
  };

  return [reset, closeModal, apply];
};
