import * as types from "../../types";
import * as _airtableApi from "../../../api/airtable";
import { call, put, takeLatest } from "redux-saga/effects";
import { dynamicTableFailure, dynamicTableSuccess } from "../../actions/airtableDynamicTableAction";
import { returnErrors } from "../../actions/errorAction";

function* asyncFetchDynamicTableSaga(action){
    const lang = action.payload.languageCode
    const dynamicTableObj = {
        tableName: action.payload.tableName,
        fields:[
            `Name(${lang})`,
            `banner(${lang})`,
            `Desktop_Banner(${lang})`,
            `yt_links`
        ],
        filterByFormula: "",
    }


    try{
        let resDynamicData = yield call(_airtableApi.fetchAirtableDataFn,dynamicTableObj)

        yield put(dynamicTableSuccess({resDynamicData}))
    }catch(e){
        yield put(dynamicTableFailure({error:e}))
        yield put(returnErrors({msg:e.message, status: true}))
    }
}

export default function* fetchDynamicTableSaga(){
    yield takeLatest(types.FETCH_DYNAMICTABLE_REQUEST,asyncFetchDynamicTableSaga)
}