import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import FeatureCheckQuestions from './featureCheckQuestions';
import { FaCheck } from 'react-icons/fa';
import { useQuery } from '../../../utils/useQuery';
import { usePost } from '../../../utils/usePost';
import { ProductContext } from '../../../utils/productContext';
import { usePostBE } from '../../../utils/usePostBE';
import { API_BASE_URL, API_ROUTES } from '../../../config';

const MainContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: relative;
  :before {
    content: '';
    position: absolute;
    background: #f3e7f3;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: #4a154b;
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`

const StepStyle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid ${({ step }) =>
      step === 'completed' ? '#4A154B' : '#F3E7F3'};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StepCount = styled.span`
  font-size: 19px;
  color: #f3e7f3;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const StepsLabelContainer = styled.div`
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StepLabel = styled.span`
  font-size: 19px;
  color: #4a154b;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  bottom:0;
  position:fixed;
  width: 96%;
  max-width: 747px;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 5px; 
  background-color: #FFFF;
  border: 2px solid #EAEAEA;
`

const ButtonStyle = styled.button`
  width: 33%;
  border-radius: 4px;
  border: 0;
  background: #4a154b;
  color: #ffffff;
  cursor: pointer;
  padding: 20px;
  :active {
  transform: scale(0.98);
  }
  :disabled {
  background: #f3e7f3;
  color: #000000;
  cursor: not-allowed;
  }
`

const CheckMark = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: #4a154b;
  display: flex;
  justify-Content: center;
  align-items: center;
`

const ProgressSteps = ({categoryData, lang}) => {
    const [activeStep, setActiveStep] = useState(1);
    const [step,setStep] = useState([]);
    const [status,setStatus] = useState(false);

    const queryParams = useQuery();
    const product_id = queryParams.get("product_id");
    const userid = queryParams.get("userid");
    const product_Name = queryParams.get("product_name");
    const { tempJSON, setTempJSON } = useContext(ProductContext);
    let fields = {
      tableName: "submission",
      fields: {
        User_ID: "",
        Response: ""
      },
    };

    const [postHandler, submitting, postError, success] = usePost();

    const [postData, postFn, submittingBE, postStatus] = usePostBE({url:`${API_BASE_URL.BASE_URL}${API_ROUTES.ADD_EDIT_ADDITIONAL_DATA_ROUTE}`,redirect:"noredirect",user_id:userid});

    useEffect(()=>{
        let steps = step;
        for(var i = 0; i<categoryData.length; i++){
            steps = [...steps,{step:i+1,label:categoryData[i].Category}]
        }
        setStep(steps);
    },[]);

    // const nextStep = () => {
    //   (activeStep !== totalSteps && setActiveStep(activeStep + 1));
    //   (activeStep === totalSteps && Swal.fire({
    //     icon: "success",
    //     text: `Submitted successfully`,
    //     confirmButtonColor: '#652078',
    //     }).then((res) => {
    //     if (res) {
    //     setStatus(true);
    //     let tempJSONVar = tempJSON;
    //       //tempJSONVar = {...tempJSONVar, remarks: remarks, image: imageName}
    //     fields = {
    //       tableName: "submission",
    //       fields: {
    //         User_ID: userid,
    //         Response: JSON.stringify(tempJSONVar),
    //         Product_Id:product_id,
    //         Product_Name:product_Name,
    //       },
    //     };
    //     setTempJSON(tempJSONVar);
    //     postHandler(fields);
    //     }
    //     }))
    //   }

      const nextStep = () => {
        (activeStep !== totalSteps && setActiveStep(activeStep + 1));
        if (activeStep === totalSteps) {
          setStatus(true); 
          let tempJSONVar = tempJSON;
         // tempJSONVar = {...tempJSONVar, remarks: remarks, image: imageName}
          tempJSONVar = {...tempJSONVar}
          fields = {
            tableName: "submission",
            fields: {
              User_ID: userid,
              Response: JSON.stringify(tempJSONVar),
              Product_Id:product_id,
              Product_Name:product_Name,
            },
          };
          let postBody = {
           "inventory_id": product_id,
           "additional_featureInfo" : tempJSONVar
          }
          setTempJSON(tempJSONVar);
          postFn(postBody);
          postHandler(fields);         
         }
      }
 
  
     const prevStep = () => {
       setActiveStep(activeStep - 1)
       setStatus(false);
     }

  
    const totalSteps = step.length
  
    const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`
  
    return (
      <MainContainer>
        <StepContainer width={width}>
          {step.map(({ step, label }) => (
            (label !== "" && <StepWrapper key={step}>
              <StepStyle step={activeStep >= step ? 'completed' : 'incomplete'}>
                {(activeStep > step || status) ? (
                  <CheckMark>{<FaCheck />}</CheckMark>
                ) : (
                  <StepCount>{step}</StepCount>
                )}
              </StepStyle>
              {/* <StepsLabelContainer>
                <StepLabel key={step}>{label}</StepLabel>
              </StepsLabelContainer> */}
            </StepWrapper>)
          ))}
        </StepContainer>
        {step.map(({step, label},index) => (
            
            activeStep === step &&
            <div style={{marginTop:'10px'}} key={step}> 
            <h3 style={{background:'#652078 0% 0% no-repeat padding-box',boxShadow:'0px 2px 2px #0000000D',color:'#FFFF',padding:'10px'}}>{categoryData?.[index]?.[`Category(${lang})`]}</h3>
            <div>
            <FeatureCheckQuestions tableName={label}  status={status} lang={lang} key={step} />
            </div>
            </div>
        ))}
        <div style={{height:'50px'}}></div>
         <ButtonsContainer>
         {activeStep !== 1 ? <ButtonStyle onClick={prevStep} disabled={status ? true :false}>
            Previous
          </ButtonStyle> : <div></div>}
          <ButtonStyle onClick={nextStep}  disabled={status ? true :false}>
            {activeStep === totalSteps ? "Submit" : "Next"}
          </ButtonStyle>
        </ButtonsContainer>
      </MainContainer>
    )
  }
  
  export default ProgressSteps