import * as types from "../types";

export const fetchPricingOtherDetailRequest = ({requestPricingOtherDetail}) => ({
  type: types.FETCH_PRICING_OTHER_DETAILS_REQUEST,
  payload:{requestPricingOtherDetail}
});

export const fetchPricingOtherDetailSuccess = ({ pricingOtherDetail }) => ({
  type: types.FETCH_PRICING_OTHER_DETAILS_SUCCESS,
  payload: {
    pricingOtherDetail,
  },
});

export const fetchPricingOtherDetailFailure = ({ error }) => ({
  type: types.FETCH_PRICING_OTHER_DETAILS_FAILURE,
  payload: {
    error: error.message,
  },
});