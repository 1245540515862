import React, { useEffect } from 'react';
import Poster from "../../components/poster";
import { connect } from "react-redux";
import {leadsSubmitRequest} from "../../store/actions/leadsSubmitAction";
import ReactPlayer from "react-player";
import CampaignInvList from "./campaignInvList";
import { sendOtpRequest, sendOtpReset } from "../../store/actions/sendOtpAction";
import { verifyOtpRequest, verifyOtpReset } from "../../store/actions/verifyOtpAction";
import { leadsSubmitReset } from "../../store/actions/leadsSubmitAction";
import CampaignForm from "./campaignForm";
import { useTranslation } from "react-i18next";
import { convertToUppercase } from '../../utils/convertToLowerUppercase';

const CampaignTest = ({...props}) => {

  const lang = sessionStorage.getItem("lang");
  const { t: landingPageLang, i18n } = useTranslation('landingPage');

  useEffect(()=>{
      props.submitOtpReset();
      props.submitVerifyOtpReset();
      props.submitLeadsReset();
      i18n.changeLanguage(convertToUppercase(lang));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const bannerImage=()=>{
      if(lang === "hi"){
        return "../assets/campaign-banner-hi.jpg"
      }else if(lang === "kn"){
        return "../assets/campaign-banner-kn.png"
      }else{
        return "../assets/campaign-banner.jpg"
      }
    }

  return (
    <>
        <div style={{display: "flex",alignItems:"center",height:"100px",background:"#fff",padding:"10px",gap:"10px"}}>
          <img src="../assets/tractor_world.svg" alt="" height="90px"/>
          <div>
            <p style={{background:"#652078",color:"#fff",padding:"5px",fontWeight:"600",borderRadius:"4px"}}>मल्टी ब्रँड सेकंड हॅन्ड ट्रॅक्टर स्टोर </p>
            <p style={{color:"#652078",padding:"5px"}}>1800-210-1444</p>
          </div>
        </div>
        <Poster>
          <Poster.Image src={bannerImage()} />
        </Poster>
        <>
        <div style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", margin:"10px"}}>
          <div style={{display: "flex", justifyContent: 'center',alignItems:"center",marginBottom:'20px'}}>          
              <h3 style={{textAlign:"center",color:"#652078",fontSize:"22px"}}>{landingPageLang('form_header')}</h3>
            </div>
          <CampaignForm/>
          </div>
        <div style={{marginTop:'10px'}}>
          <CampaignInvList />
        </div>
          <div style={{display: "flex", justifyContent: 'center',alignItems:"center",marginBottom:'20px'}}>          
            <h3 style={{textAlign:"center",color:"#652078",marginTop:"10px"}}>{landingPageLang('header')}<br></br>{landingPageLang('header_last')}</h3>
          </div> 
          <Poster>
            <Poster.Image src="../assets/tractors-group.jpg" />
          </Poster>
          <div style={{display: "flex", justifyContent: 'center',alignItems:"center",marginBottom:'20px'}}>          
            <h3 style={{textAlign:"center",color:"#652078",marginTop:"10px",fontSize:"26px"}}>{landingPageLang('testimonials_header')}</h3>
          </div>
          <div style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",margin:"25px",height:"375px",display:"flex",flexDirection:"column"}}>
            <ReactPlayer
              url={landingPageLang('testimonial_video_first')}
              playing={false}
              playsinline={true}
              controls
              width="100%"
              height="300px"
            />
            <div style={{display:"flex",flexDirection:"column",padding:"20px"}}>
              <span>{landingPageLang('name')}: <b>{landingPageLang('testimonial_first_name')}</b></span>
              <span>{landingPageLang('village')}: <b>{landingPageLang('testimonial_first_place')}</b></span>
            </div>
          </div>
          <div style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",margin:"25px",height:"375px"}}>
            <ReactPlayer
              url={landingPageLang('testimonial_video_second')}
              playing={false}
              playsinline={true}
              controls
              width="100%"
              height="300px"
            />
            <div style={{display:"flex",flexDirection:"column",padding:"20px"}}>
              <span>{landingPageLang('name')}: <b>{landingPageLang('testimonial_second_name')}</b></span>
              <span>{landingPageLang('village')}: <b>{landingPageLang('testimonial_second_place')}</b></span>
            </div>
          </div>
          <div style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",margin:"25px",height:"375px",marginBottom:"40px"}}>
            <ReactPlayer
              url={landingPageLang('testimonial_video_third')}
              playing={false}
              playsinline={true}
              controls
              width="100%"
              height="300px"
            />
            <div style={{display:"flex",flexDirection:"column",padding:"20px"}}>
              <span>{landingPageLang('name')}: <b>{landingPageLang('testimonial_third_name')}</b></span>
              <span>{landingPageLang('village')}: <b>{landingPageLang('testimonial_third_place')}</b></span>
            </div>
          </div>
          <div style={{display: "flex", justifyContent: 'center',alignItems:"center",height:"50px",background:"#652078"}}>          
            <h3 style={{textAlign:"center",color:"#fff",fontSize:"14px"}}>Powered By: <span style={{color:"rgb(253, 170, 119)"}}>Tractor World</span></h3>
          </div>
      </>
    </>
  )
}

const mapStateToProps = (state) => ({
  verifyOtpValue: state.verifyOtpReducer.resVerifyOtp,
});

const mapDispatchToProps = (dispatch) => ({
  submitLeads:({requestLeads})=>{
    dispatch(leadsSubmitRequest({requestLeads}))
  },
  submitOtpRequest:({requestOtp})=>{
    dispatch(sendOtpRequest({requestOtp}))
  },
  submitVerifyOtpRequest:({verifyOtp})=>{
    dispatch(verifyOtpRequest({verifyOtp}))
  },
  submitOtpReset:()=>{
    dispatch(sendOtpReset())
  },
  submitVerifyOtpReset:()=>{
    dispatch(verifyOtpReset())
  },submitLeadsReset:()=>{
    dispatch(leadsSubmitReset())
  }
});
 

export default connect(mapStateToProps,mapDispatchToProps)(CampaignTest);