import * as types from "../types";

const initialState = {
    loading: false,
    reqInv: null,
    currentPagePosition: 1,
    resInvData: null,
    error: null
}

export default function dealerInventoryReducer(state = initialState, action){
    switch(action.type){
        case types.FETCH_DEALER_INVENTORY_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                reqInv: action.payload.reqInv
            }

        case types.FETCH_DEALER_INVENTORY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                resInvData: action.payload.resInvData
        }

        case types.EXPENSE_PAGE_POSITION:
            return {
                ...state,
                currentPagePosition: action.payload.currentPagePosition
        }
        
        case types.FETCH_DEALER_INVENTORY_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                reqInv: null,
                resInvData: null,
                currentPagePosition: 1,
                error: action.payload.error
        }

        case types.FETCH_DEALER_INVENTORY_DATA_RESET:
            return {
                ...state,
                loading: false,
                reqInv: null,
                resInvData: null,
                currentPagePosition: 1,
                error: null
        }
         
        default: 
            return state
    }

}