import Form from "../components/form";
import Header from "../components/header";
import { AiOutlineGlobal } from 'react-icons/ai';
import { useDispatch, useSelector } from "react-redux";
import { languageDropdownShow, setLanguage } from "../store/actions/languageAction";
import { convertToLowercase, convertToUppercase } from "../utils/convertToLowerUppercase";
import { useChangLanguage } from "../utils/useChangLanguage";
import { useTransition } from "react";


export default function HeaderContainer() {
  const dispatch = useDispatch();
  const languageObj = useSelector(state => state.languageReducer)
  const { languageCode } = languageObj;
  const langDrodown = [
    {value:'EN',label:'English'},
    {value:'MR',label:'Marathi'}
  ]

  const [ handleChangeLanguage ] = useChangLanguage();

  const languageChange = (e) => {
    const { name, type, value } = e.target;
      handleChangeLanguage(value);
      dispatch(setLanguage({languageCode:value}))
      dispatch(languageDropdownShow({showLangDrodpdown:false}));
  }

  //console.log("languageObj",languageObj);

  // const language = () => {
  //   setLangShow(true);
  // }  

  // const [headerdata, headerFetching, headerError] = useFetch({
  //   tableName: "tractor",
  //   fields: ["Company_Logo"],
  //   maxRecords: 1,
  //   filterByFormula: "",
  // });
//console.log("languageObj",languageObj)
//console.log("currentLanguageCode",currentLanguageCode,languageObj)

  return (
    <Header>
    <div style={{width:'80%'}}></div>  
    {!languageObj.showLangDrodpdown && <p style={{display:'flex',alignItems:'center',fontSize:'15px',padding:'5px',gap:'2px'}} onClick={() => dispatch(languageDropdownShow({showLangDrodpdown:true}))}><AiOutlineGlobal style={{height:'25px', width:'25px'}} />Language</p>}
    {languageObj.showLangDrodpdown && <Form.Dropdown
    name="language"
    id="language"
    options={langDrodown}
    onChange={(e)=>languageChange(e)}
    style={{width:'100px'}}
    value={convertToUppercase(languageCode)}
    >
    </Form.Dropdown>}
   </Header>

  );
}