import * as types from "../types";

export const setInvFilterValuesAction = ({filterObj}) => ({
    type: types.SET_INV_FILTER_DROPDOWN,
    payload:{
        filterObj
    }
})

export const setInvFilteredArrayAction = ({filteredArray}) => ({
    type: types.SET_INV_FILTERED_DATA,
    payload:{
        filteredArray
    }
})

export const setInvFilterReset = () => ({
    type: types.SET_INV_FILTER_RESET,
})
