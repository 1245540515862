import * as types from "../types";
import * as _api from "../../api";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import { clearErrors, returnErrors } from "../actions/errorAction";
import { fetchNtSingleInvFailure, fetchNtSingleInvSuccess } from "../actions/fetchNtSingleTractorAction";

function* asyncFetchNtSingleTractorSaga(action){
    try{
        yield put(clearErrors());
        let resNtInv = yield call(_api.fetchNTSingleTractorApi,{
            reqTractorId:action.payload.reqTractorId
        })
        yield put(fetchNtSingleInvSuccess({resNtInv}));
    }catch(e){
        yield put(fetchNtSingleInvFailure({error:e}))
        yield put(returnErrors({msg: e.message, status: true}))
    }
}

export default function* fetchNtSingleTractorSaga(){
    yield takeLatest(types.FETCH_SINGLE_NT_TRACTOR_REQUEST,asyncFetchNtSingleTractorSaga)
}