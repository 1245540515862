import React, { useState, useEffect } from 'react';
import Loader from "../../components/Loader/Loader";
import Form from "../../components/form";
import Swal from 'sweetalert2';
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import {leadsSubmitRequest, leadsSubmitReset } from "../../store/actions/leadsSubmitAction";
import { sendOtpRequest, sendOtpReset } from "../../store/actions/sendOtpAction";
import { verifyOtpRequest, verifyOtpReset } from "../../store/actions/verifyOtpAction";
import { useLocation } from "react-router-dom";
import CountDown from './countdown';
import { useTranslation } from "react-i18next";
import { convertToUppercase } from '../../utils/convertToLowerUppercase';

ReactGA.initialize("GTM-PWFNXTM7");

const CampaignForm = ({...props}) => {
  const loc = useLocation();
  const queryParams = new URLSearchParams(loc.search);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");

  const [disableOtp,setDisableOtp]=useState(false);
  const [submitDisable,setSubmitDisable]=useState(true);

  const lang = sessionStorage.getItem("lang");
  const source = sessionStorage.getItem("source");
  const location = sessionStorage.getItem("location");
  const tractor_id = queryParams.get("tractor_id");
  const campaign = sessionStorage.getItem("campaign");
  const state = sessionStorage.getItem("state");

  const { t: landingPageLang, i18n } = useTranslation('landingPage');

  useEffect(()=>{
    i18n.changeLanguage(convertToUppercase(lang));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
    
    useEffect(()=>{
      props.submitOtpReset();
      props.submitVerifyOtpReset();
      props.submitLeadsReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const eventTrack = (category, action, label) => {
      ReactGA.event({
        category: category,
        action: action,
        label: label,
     })
    }

    useEffect(() => {
      if(props.resLeadsData?.status){
        eventTrack("Success", "generate_lead", "Success Message");
        Swal.fire({
          icon: "success",
          text: landingPageLang('success_message'),
          confirmButtonColor: '#652078',
          }).then((res) => {
          if (res) {
            setName("");
            setPhone("");
            setOtp("");
          }
          })        
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.resLeadsData]);

    useEffect(()=>{
      if(otp?.length === 4){
        setSubmitDisable(false);
      }else{
        setSubmitDisable(true);
      }
    },[otp])

    useEffect(()=>{
      if(props.verifyOtpValue?.status){
        handleSubmit();
      }else if(props.verifyOtpValue){
        if(!props.verifyOtpValue.status){
          Swal.fire({
          icon: "info",
          text: landingPageLang("otp_error"),
          confirmButtonColor: '#652078',
          })
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.verifyOtpValue]);

     const handleOtpRequest=()=>{
      if(phone && phone.length === 10){
        let requestOtp={
          "phone":phone,
          "is_login_request": false
        };
        props.submitOtpRequest({requestOtp});
        setDisableOtp(true);
        setTimeout(() => {
          setDisableOtp(false);
        }, 30000);
      }else{
        Swal.fire({
          icon: "info",
          text: landingPageLang("number_error"),
          confirmButtonColor: '#652078',
          })
      }
    }

    const handleVerifyOtp=()=>{
      if(name === ""){
          Swal.fire({
          icon: "info",
          text: landingPageLang('field_error'),
          confirmButtonColor: '#652078',
          })
      }else if(!/^[A-Za-z\s]*$/.test(name)){
        Swal.fire({
          icon: "info",
          text: landingPageLang('name_error'),
          confirmButtonColor: '#652078',
          })
      }
      else if(phone === ""){
          Swal.fire({
          icon: "info",
          text: landingPageLang('field_error'),
          confirmButtonColor: '#652078',
          })
      }else if(phone && phone.length !== 10){
          Swal.fire({
          icon: "info",
          text: landingPageLang('number_error'),
          confirmButtonColor: '#652078',
          })
        }else if(otp === ""){
          Swal.fire({
          icon: "info",
          text: landingPageLang('field_error'),
          confirmButtonColor: '#652078',
          })
      }else{
        let verifyOtp={
            "phone": phone,
            "otp": otp
        };
        props.submitVerifyOtpRequest({verifyOtp});
      }
    }

    const handleSubmit=() => {
      if(name === ""){
          Swal.fire({
          icon: "info",
          text: landingPageLang('field_error'),
          confirmButtonColor: '#652078',
          })
      }else if(!/^[A-Za-z\s]*$/.test(name)){
        Swal.fire({
          icon: "info",
          text: landingPageLang('name_error'),
          confirmButtonColor: '#652078',
          })
      }
      else if(phone === ""){
          Swal.fire({
          icon: "info",
          text: landingPageLang('field_error'),
          confirmButtonColor: '#652078',
          })
      }else if(phone && phone.length !== 10){
          Swal.fire({
          icon: "info",
          text: landingPageLang('number_error'),
          confirmButtonColor: '#652078',
          })
      }else{
      let requestLeads;
      if(tractor_id !== null){
        requestLeads={ 
          "l_source": "digital",
          "sub_source":source,
          "phone": phone,
          "name": name,
          "language": lang,
          "l_campaign": campaign,
          "location": location,
          "inventory_id":tractor_id,
          "store_page_version": 2,
          "campaign_version":"landing_v2",
          "state":state
        };
      }else{
        requestLeads={ 
          "l_source": "digital",
          "sub_source":source,
          "phone": phone,
          "name": name,
          "language": lang,
          "l_campaign": campaign,
          "location": location,
          "store_page_version": 2,
          "campaign_version":"landing_v2",
          "state":state
        };
      }
      props.submitLeads({requestLeads});
      }
    }

  return (
    <>
        <Form style={{marginTop:"0px",marginBottom:"0px"}}>
            <Form.GroupFields>
              <Form.InputWrapper style={{height:"40px",borderRadius:"20px 15px 15px 20px"}}>
                <img src="../assets/person.png" alt="" width="40px" height="40px" />
                <Form.Input
                  type="text"
                  name="name"
                  value={name}
                  placeholder={landingPageLang('name')}
                  onChange={(e) => setName(e.target.value)}
                  required
                  style={{border:'none',borderRadius:"15px"}}
                />
              </Form.InputWrapper>
              <Form.InputWrapper style={{height:"40px",borderRadius:"20px 15px 15px 20px"}}>
              <img src="../assets/call.png" alt="" width="40px" height="40px"/>
              <Form.Input
                type="text"
                name="phone"
                value={phone}
                placeholder={landingPageLang('mobile_number')}
                onChange={(e) => setPhone(e.target.value)}
                required
                style={{border:'none',borderRadius:"15px"}}
              />
              </Form.InputWrapper>
              {!props.resLeadsData?.status && 
              <div style={{padding:"16px 16px",display:"flex",flexDirection:"column"}}>
              {disableOtp && <CountDown seconds={30}/>}
              <button 
                style=
                  {{background: '#652078',
                    borderRadius: '4px', opacity: `${disableOtp ? "0.5":"1"}`, 
                    width:'100%',height: '40px', 
                    border: 'none', color:'#FFFF', fontSize:'18px',
                    textTransform:"uppercase",
                    display:"flex",alignItems:"center",justifyContent:"center"
                  }}
                    disabled={disableOtp}
                    onClick={handleOtpRequest}>
                    {landingPageLang("get_otp")}
                </button>
              </div>}
              <Form.InputWrapper style={{height:"40px",borderRadius:"20px 15px 15px 20px"}}>
              <img src="../assets/otp.png" alt="" width="40px" height="40px"/>
              <Form.Input
                type="text"
                name="otp"
                value={otp}
                placeholder={landingPageLang('otp')}
                onChange={(e) => setOtp(e.target.value)}
                required
                style={{border:'none',borderRadius:"15px"}}
              />
              </Form.InputWrapper>
            </Form.GroupFields>
          </Form>
          {props.reqOtp?.status && (
            <div style={{padding:"16px 16px",display:"flex",alignItems:"center",justifyContent:"center"}}>
           {props.resLeadsLoading ? 
           <Loader/>:(<button 
              style=
                  {{background: '#652078',
                    borderRadius: '4px', opacity: `${submitDisable ? "0.5":"1"}`, 
                    width:'90%',height: '40px', 
                    border: 'none', color:'#FFFF', fontSize:'18px',
                    textTransform:"uppercase"}}
                    disabled={props.resLeadsData?.status || submitDisable}
                    onClick={handleVerifyOtp}>
                    {props.resLeadsData?.status ? landingPageLang('buttonMessage'):landingPageLang('submit')}
              </button>)}
            </div>
          )}
    </>
  )
}

const mapStateToProps = (state) => ({
  verifyOtpValue: state.verifyOtpReducer.resVerifyOtp,
  resLeadsLoading: state.leadsSubmitReducer.loading,
  resLeadsData: state.leadsSubmitReducer.resLeads,
  reqOtp: state.sentOtpReducer.resOtp,
});

const mapDispatchToProps = (dispatch) => ({
  submitLeads:({requestLeads})=>{
    dispatch(leadsSubmitRequest({requestLeads}))
  },
  submitOtpRequest:({requestOtp})=>{
    dispatch(sendOtpRequest({requestOtp}))
  },
  submitVerifyOtpRequest:({verifyOtp})=>{
    dispatch(verifyOtpRequest({verifyOtp}))
  },
  submitOtpReset:()=>{
    dispatch(sendOtpReset())
  },
  submitVerifyOtpReset:()=>{
    dispatch(verifyOtpReset())
  },
  submitLeadsReset:()=>{
    dispatch(leadsSubmitReset())
  }
});
 

export default connect(mapStateToProps,mapDispatchToProps)(CampaignForm);