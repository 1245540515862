import * as types from "../types";

const initialState = {
    loading: false,
    resCategory: null,
    error: null
}

export default function airtableCategoryReducer(state = initialState, action){

    switch(action.type){
        case types.FETCH_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        
        case types.FETCH_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                resCategory: action.payload.resCategory
            }
        
        default:
        return state
    }
}