import * as types from "../types";

const initialState = {
    languageCode: "EN",
}

export default function faqLanguageReducer(state = initialState, action){
    switch(action.type){ 
        case types.SET_FAQ_LANGUAGE:
            return {
                ...state,
                languageCode: action.payload.languageCode
            }
            
            default :
            return state;
        }
        
    } 