import React, { useContext, useEffect, useState } from 'react'
import Header from '../../components/header'
import Loader from '../../components/Loader/Loader';
import Section from '../../components/section';
import SpecificationContainer from '../../components/specification';
import { FcInfo } from "react-icons/fc";
import { useNavigate } from 'react-router-dom';
import { usePostBE } from '../../utils/usePostBE';
import AlertMessage from '../modals/alertMessage';
import { ProductContext } from '../../utils/productContext';
import { slice } from 'lodash';
import Form from '../../components/form';
import { API_BASE_URL, API_ROUTES } from '../../config';
import { useQuery } from '../../utils/useQuery';
import { dateFormat } from '../../utils/dateFormat';


export default function WalkingLeadView(){
    const [value,setValue] = useState('');
    const [isCompleted, setIsCompleted] = useState(false)
    const [index, setIndex] = useState(20);
    let navigate = useNavigate(); 
    const queryParams = useQuery();
    const userid = queryParams.get('userid');

    const [postData, postFn, submittingBE, postStatus] = usePostBE({url:`${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_LEAD_ROUTE}`,redirect:"walkinLeadView",user_id:userid});    
    
    const initialPosts = (postData?.status) ? slice(postData?.data?.sort((a, b) => (Number(new Date(b.updated_at)) - Number(new Date((a.updated_at))))), 0, index)  : [];

    const {
        show,setShow
    } = useContext(ProductContext);

    useEffect(() => {
            const fetchBody = {
                "lead_source" : "walkin"
            }
            postFn(fetchBody);    
    },[postStatus])

      const toast = (e, item) => {  
        setValue(item);
        setShow(true);
       };
      
    const loadMore = () => {
        setIndex(index + 10)
        if (index >= postData?.data?.length) {
          setIsCompleted(true)
        } else {
          setIsCompleted(false)
        }
      }

      const backButton = () => {
        navigate(`/walkingLeadLanding?userid=${userid}`)
      }
  
    return (
        <>
        <Header>
        <Header.BackButtonContainer>
            <Header.BackIcon src="../assets/arrow_back.svg" alt="" onClick={backButton} />
            <Header.ButtonText>Walk-In Lead Information</Header.ButtonText>
          </Header.BackButtonContainer>
        </Header>        
        <Section>
        {/* <div style={{marginBottom:'10px'}}>
                <Form.Label>Filter: </Form.Label>
                <Form.Dropdown style={{width:'30%'}} options={[{value:'',label:'Select filter'},{value:'date',label:'Date'},{value:'date_range',label:'Date Range'}]}>
                </Form.Dropdown>
        </div>     */}
          <div style={{textAlign:'center',marginBottom:'8px'}}>
            <i style={{padding:'5px 20px',border:'2px solid #EAEAEA',borderRadius:'15px'}}>Showing last 20 entries...</i>
        </div>

        {submittingBE ? <Loader/> :   initialPosts?.map((item,index) =>
        <>
        <div key={index} style={{boxShadow:'0px 8px 12px #00000017'}}>
        <SpecificationContainer.TableConatiner>
                <SpecificationContainer.TableHeader>
                    <SpecificationContainer.TableHeaderText><b>Name</b></SpecificationContainer.TableHeaderText>
                </SpecificationContainer.TableHeader>
                <SpecificationContainer.TableValues>
                    <SpecificationContainer.TableValuesText>{item[`name`]}</SpecificationContainer.TableValuesText>
                </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
        <SpecificationContainer.TableConatiner>
                <SpecificationContainer.TableHeader>
                    <SpecificationContainer.TableHeaderText><b>Phone Number</b></SpecificationContainer.TableHeaderText>
                </SpecificationContainer.TableHeader>
                <SpecificationContainer.TableValues style={{display:'flex',justifyContent:'space-between'}} >
                    <SpecificationContainer.TableValuesText><a style={{textDecoration:'none',color:'#000'}} href={`tel:${item[`phone`]}`}>{item[`phone`]}</a></SpecificationContainer.TableValuesText>
                    <FcInfo
                        style={{width: "20px", height: "20px",bottom:'0' }}
                        onClick={(e) => toast(e, item)}
                    />
                </SpecificationContainer.TableValues>                
        </SpecificationContainer.TableConatiner>
        </div>
        <div style={{float:'right',color:'#666666',margin:'2px 0 8px 0',fontSize:'12.5px'}}>
                <div>
                    <SpecificationContainer.TableValuesText>{dateFormat(item[`updated_at`])}</SpecificationContainer.TableValuesText>
                </div>                
        </div>
        <br/>
        </>
        
)}


    </Section>
    {postData?.status ? <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginBottom:'20px'}}>
        {(!isCompleted && postData) && (
          <Form.Button onClick={loadMore} type="button" style={{backgroundColor:'#652078',color:'#FFFF'}}
          >
            Load More
          </Form.Button>
        )}
    </div> : ''}
    {show && <AlertMessage value={value} />}
    </>
    )

}