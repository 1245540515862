import React from "react";
import Header from "../../components/header";
import { useQuery } from "../../utils/useQuery";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { expenseChassisRequest } from "../../store/actions/chassisExpenseDataAction";
import Section from "../../components/section";
import SpecificationContainer from "../../components/specification";
import ExpenseLiquidationView from "./ViewSection/expenseLiquidationView";
import Loader from "../../components/Loader/Loader";
import ExpenseSourcingView from "./ViewSection/expenseSourcingView";
import ExpenseOperationView from "./ViewSection/expenseOperationView";

export default function ExpenseTrackerView() {
  const queryParams = useQuery();
  const userid = queryParams.get("userid");
  const brand = queryParams.get("brand");
  const model = queryParams.get("model");
  const year = queryParams.get("year");
  const rto = queryParams.get("rto");


  const { chassisNumber } = useParams();
  const dispatch = useDispatch();
  const { loading, resChassisData } = useSelector(
    (state) => state.chassisExpenseDataReducer
  );

  let navigate = useNavigate();
  const backButton = () => {
    navigate(`/expenseHome?userid=${userid}`);
  };
  let expenseData = resChassisData?.data;

  useEffect(() => {
    dispatch(expenseChassisRequest({ reqChassis: chassisNumber }));
  }, [chassisNumber]);

  const headerFontSize = "12px";
  const valueFontSize = "12px";

  return (
    <>
      <Header>
        <Header.BackButtonContainer>
          <Header.BackIcon
            src="../assets/arrow_back.svg"
            alt=""
            onClick={backButton}
          />
          <Header.ButtonText>Expense Information</Header.ButtonText>
        </Header.BackButtonContainer>
      </Header>

      <Section>
        <SpecificationContainer>
          <SpecificationContainer.TableHeader bgColor="#F1E5F1">
            <SpecificationContainer.TableHeaderText>
              <b>Tractor Details</b>
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          {chassisNumber && (
            <SpecificationContainer.TableConatiner>
              <SpecificationContainer.TableHeader fontSize={headerFontSize}>
                <SpecificationContainer.TableHeaderText>
                  Chassis number
                </SpecificationContainer.TableHeaderText>
              </SpecificationContainer.TableHeader>
              <SpecificationContainer.TableValues fontSize={valueFontSize}>
                <SpecificationContainer.TableValuesText>
                  {chassisNumber}
                </SpecificationContainer.TableValuesText>
              </SpecificationContainer.TableValues>
            </SpecificationContainer.TableConatiner>
          )}
          {brand && (
            <SpecificationContainer.TableConatiner>
              <SpecificationContainer.TableHeader fontSize={headerFontSize}>
                <SpecificationContainer.TableHeaderText>
                  Brand
                </SpecificationContainer.TableHeaderText>
              </SpecificationContainer.TableHeader>
              <SpecificationContainer.TableValues fontSize={valueFontSize}>
                <SpecificationContainer.TableValuesText>
                  {brand}
                </SpecificationContainer.TableValuesText>
              </SpecificationContainer.TableValues>
            </SpecificationContainer.TableConatiner>
          )}
          {model && (
            <SpecificationContainer.TableConatiner>
              <SpecificationContainer.TableHeader fontSize={headerFontSize}>
                <SpecificationContainer.TableHeaderText>
                  Model
                </SpecificationContainer.TableHeaderText>
              </SpecificationContainer.TableHeader>
              <SpecificationContainer.TableValues fontSize={valueFontSize}>
                <SpecificationContainer.TableValuesText>
                  {model}
                </SpecificationContainer.TableValuesText>
              </SpecificationContainer.TableValues>
            </SpecificationContainer.TableConatiner>
          )}
          {year && (
            <SpecificationContainer.TableConatiner>
              <SpecificationContainer.TableHeader fontSize={headerFontSize}>
                <SpecificationContainer.TableHeaderText>
                  Year
                </SpecificationContainer.TableHeaderText>
              </SpecificationContainer.TableHeader>
              <SpecificationContainer.TableValues fontSize={valueFontSize}>
                <SpecificationContainer.TableValuesText>
                  {year}
                </SpecificationContainer.TableValuesText>
              </SpecificationContainer.TableValues>
            </SpecificationContainer.TableConatiner>
          )}
          {(rto && rto !== "undefined") && (
            <SpecificationContainer.TableConatiner>
              <SpecificationContainer.TableHeader fontSize={headerFontSize}>
                <SpecificationContainer.TableHeaderText>
                  RTO Number
                </SpecificationContainer.TableHeaderText>
              </SpecificationContainer.TableHeader>
              <SpecificationContainer.TableValues fontSize={valueFontSize}>
                <SpecificationContainer.TableValuesText>
                  {rto}
                </SpecificationContainer.TableValuesText>
              </SpecificationContainer.TableValues>
            </SpecificationContainer.TableConatiner>
          )}



        </SpecificationContainer>

        {loading ? (
          <Loader />
        ) : (
          <>
            {(expenseData?.sourcing && typeof(expenseData?.sourcing) === "object" &&
              Object?.keys(expenseData?.sourcing)?.length > 0) && (
                <ExpenseSourcingView value={expenseData?.sourcing} />
              )}
            {(expenseData?.operation && typeof(expenseData?.operation) === "object" &&
              Object?.keys(expenseData?.operation)?.length > 0) && (
                <ExpenseOperationView value={expenseData?.operation} />
              )}
            {(expenseData?.liquidation && typeof(expenseData?.liquidation) === "object" &&
              Object?.keys(expenseData?.liquidation)?.length > 0) && (
                <ExpenseLiquidationView value={expenseData?.liquidation} />
              )}
          </>
        )}
      </Section>
    </>
  );
}
