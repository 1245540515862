import React from "react"; 
import Main from "../../../components/main";
import useFetch from "../../../utils/useFetch";
import { useQuery } from "../../../utils/useQuery";
import FetaureChecks from "./editFetaureChecks";

export default function EditFeatureTable(){

    const queryParams = useQuery();
    const lang = queryParams.get("lang").toUpperCase();

    const [categoryData, categoryFetching, categoryError] = useFetch({
        tableName: "Information_Category",
        fields: [
            `Category`,
            `Category(EN)`,
            `CategoryID`,
            `Category(${lang})`
        ],
        filterByFormula: "",
    });

    return (
        <Main>
        {categoryData && <FetaureChecks categoryData={categoryData} lang={lang} />}
        </Main>
    )
}