import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const useFetchBE = ({...props}) => {

    const [data, setData] = useState("");
    const [fetching,setFetching] = useState(false);
    const [statusBE,setStatusBE] = useState(false);
    let navigate = useNavigate();     

    const fetchdata = () => {
      setFetching(true);
      const header = props?.user_id ? { 'Content-Type': 'application/json', 'user-id': props?.user_id }:{ 'Content-Type': 'application/json' }
      const requestOptions = {
        method: 'GET',
        headers: header
      };
        let url = props?.url;
        let redirect = props?.redirect;
        
          fetch(url,requestOptions)
          .then(resp => resp.json())
          .then(data => {
            setFetching(false)
            setData(data)
            setStatusBE(data.status)
            //console.log("setStatus22",data.status)
            if(fetching === false){
                if(!data.status){
                    Swal.fire({
                        icon: "error",
                        text: "Something went wrong..."
                      }).then((res) => {
                        if (res) {
                          if(!redirect || redirect!=="" ){ }else{ navigate(`/`)};
                        }
                      });
                }
            }
          });
      } 
    
      useEffect(() => {
         fetchdata();
      },[])

    return [data,fetchdata,fetching,statusBE]
}