import React, { useEffect, useState } from 'react';
import Main from '../../components/main';
import Header from '../../components/header';
import { useQuery } from '../../utils/useQuery';
import ExpenseSummary from './expenseSummary';
import { useDispatch, useSelector } from 'react-redux';
import { expensePagePosition, inventoryRequest } from '../../store/actions/dealerInventoryAction';
import { useNavigate } from 'react-router-dom';
import ExpenseInvList from './expenseInvList';
import Swal from 'sweetalert2';
import ExpenseFilter from '../filter/expenseFilter';
import { filterExpenseReset, filterExpenseSetData, filterExpenseShow } from '../../store/actions/expenseFilterAction';


export default function ExpenseHome() {

  const queryParams = useQuery();
  const userid = queryParams.get("userid");
  const dispatch = useDispatch();
  const dealerInvObj = useSelector(state => state.dealerInventoryReducer)
  const { reqInv, resInvData, currentPagePosition } = dealerInvObj;
  const filterExpenseObj = useSelector(state => state.expenseFilterReducer);
  const { showFilter, filteredData, filterOptions } = filterExpenseObj;

  const applyFilterUpdateData = () => {
    if(filterOptions.status !== ""){
    let filteredData =  resInvData?.data?.filter((item) => {
          if(filterOptions.status === "inward" && item.status === 0){
              return item;
          }else if(filterOptions.status === "live" && (item.status === 1 || item.status === 2 || item.status === 3)){
              return item;
          }else if(filterOptions.status === "sold" && item.status === 4){
              return item;
          }else if(filterOptions.status === ""){
              return item;
          }
      })

          dispatch(filterExpenseSetData({filteredData}))      
  }
  }

  React.useEffect(() => {
    if(userid && userid !== "") {
       dispatch(expensePagePosition({currentPagePosition:(currentPagePosition && userid === reqInv?.user_id && currentPagePosition > 1) ? currentPagePosition : 1}))
       dispatch(inventoryRequest({reqInv:{user_id:userid}}))
       userid !== filterExpenseObj.userid &&  dispatch(filterExpenseReset())
      }
  },[userid])

  useEffect(() => {
    applyFilterUpdateData();
  },[resInvData?.data])

  let navigate = useNavigate();
  const viewExpense = (args) => {
    if((args?.expense_data?.sourcing || args?.expense_data?.liquidation || args?.expense_data?.operation)){
      navigate(`/expense-info/${args.chassis_number}?userid=${userid}&model=${args.model}&brand=${args.brand}&year=${args.year}&rto=${args?.additional_featureInfo?.["Registration (RTO) No."]}`)
    }
  }

  const addExpense = (e,args) => {
    e.stopPropagation();
    if((args?.chassis_number && !args?.chassis_number !== "")){   
    navigate(`/add-expense-tracker/${args.chassis_number}?userid=${userid}`);
    }else{
      Swal.fire({
        icon: "error",
        text: `Chassis number is required`,
        confirmButtonColor: '#652078',
        }).then((res) => {
        if (res) {
        }
        })
    }
  }

  const modalOpen = () => {
    dispatch(filterExpenseShow({userid:userid,showFilter:!showFilter}))
  }

  return (

    <>
        <Header>
            &nbsp;&nbsp;<p>Expense Tracker</p>
        </Header> 
        <br />   
      <Main style={{padding: '0 12px'}}>
      <ExpenseSummary />
        <ExpenseInvList  data={(filteredData && filteredData.length > 0 ) ? filteredData : resInvData?.data} viewExpense={viewExpense} addExpense={addExpense} modalOpen={modalOpen} />
        </Main>
        {showFilter && <ExpenseFilter />}
    </>

  );
}