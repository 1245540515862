import React from "react";
import { Container, ImageDisplayContainer, ImageDisplay, DiscountTag, DiscountValue, ImageDisplayArrow, SelectImagesContainer, SelectImageContainer, SelectImage } from "./styles/carousel";

export default function Carousel({children,...props}){
    return <Container {...props}>{children}</Container>
}

Carousel.ImageDisplayContainer = function CarouselImageDisplayContainer({children,...props}){
    return <ImageDisplayContainer {...props}>{children}</ImageDisplayContainer>
}

Carousel.ImageDisplay = function CarouselImageDisplay({...props}){
    return <ImageDisplay {...props} />
}

Carousel.DiscountTag = function BuyCardsDiscountTag({children,...props}){
    return <DiscountTag {...props}>{children}</DiscountTag>
}

Carousel.DiscountValue = function BuyCardsDiscountValue({children,...props}){
    return <DiscountValue {...props}>{children}</DiscountValue>
}

Carousel.ImageDisplay = function CarouselImageDisplay({...props}){
    return <ImageDisplay {...props} />
}

Carousel.ImageDisplayArrow = function CarouselImageDisplayArrow({...props}){
    return <ImageDisplayArrow {...props} />
}

Carousel.SelectImagesContainer = function CarouselSelectImagesContainer({children,...props}){
    return <SelectImagesContainer {...props} >{children}</SelectImagesContainer>
}

Carousel.SelectImageContainer = function CarouselSelectImageContainer({children,...props}){
    return <SelectImageContainer {...props} >{children}</SelectImageContainer>
}

Carousel.SelectImage = function CarouselSelectImage({children,...props}){
    
    return <SelectImage {...props} />
}

