import React, {useEffect,useState} from "react";
import BuyCards from "../../components/buyCard";
import {  useNavigate } from "react-router-dom";
import Section from "../../components/section";
import SectionHeader from "../../components/sectionheader";
import BottomHighlightLine from "../../components/bottomhighlight";
import Loader from "../../components/Loader/Loader";
import urlContain from "../../utils/urlContain";
import { useDispatch, useSelector } from "react-redux";
import { campaignInventoryRequest, campaignPagePosition } from "../../store/actions/campaignInventoryAction";
import Expenses from "../../components/expensecard";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import {leadsSubmitReset } from "../../store/actions/leadsSubmitAction";
import { sendOtpReset } from "../../store/actions/sendOtpAction";
import { verifyOtpReset } from "../../store/actions/verifyOtpAction";
import { useTranslation } from "react-i18next";
import { convertToUppercase } from '../../utils/convertToLowerUppercase';

export default function CampaignInvList(){
    
    const dispatch = useDispatch();
    const campaignInventoryObj = useSelector(state => state.campaignInventoryReducer);
    const { currentPagePosition,resCampaignInvData } = campaignInventoryObj;
    const [data,setData]=useState([]);

    const lang = sessionStorage.getItem("lang");
    const { t: landingPageLang, i18n } = useTranslation('landingPage');

    useEffect(()=>{
       dispatch(campaignPagePosition({currentPagePosition:(currentPagePosition > 1) ? currentPagePosition : 1}))
        dispatch(campaignInventoryRequest({reqCampaignInv:{
          user_id:["10","6"],
          idLength:2,
        }}));
        i18n.changeLanguage(convertToUppercase(lang));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
      if(resCampaignInvData?.length > 0){
        setData([...resCampaignInvData[0].data,...resCampaignInvData[1].data]);
      }
    },[resCampaignInvData]);

    const itemsPerPage = 5;
    const finalLength= data?.filter((item) => (item?.status === 1 || item?.status === 2 || item?.status === 3) && item).length;
    const totalPages = Math.ceil(finalLength / itemsPerPage);
    const currentItems = data?.filter((item) => (item?.status === 1 || item?.status === 2 || item?.status === 3) && item).sort((a, b) => (b.tractor_id - a.tractor_id))?.slice(
        (currentPagePosition - 1) * itemsPerPage,
        currentPagePosition * itemsPerPage
    );

    const handlePageChange = (newPage) => {
    if(data?.length > itemsPerPage){
      dispatch(campaignPagePosition({currentPagePosition:newPage}))
    }
  };

    let navigate = useNavigate();

    const handleNav = (tractor_id, productjson,prodType) => {
        dispatch(leadsSubmitReset());
        dispatch(sendOtpReset());
        dispatch(verifyOtpReset());
        navigate(`/lpsv2product?type=${prodType}&tractor_id=${tractor_id}`,{state:productjson})
    }

    return (

        ((campaignInventoryObj.loading) ? 
            <Loader />:
        ((data)?.length > 0 &&
        <Section>
        <SectionHeader>
        <SectionHeader.Title>
          {landingPageLang('available_tractors')}
        </SectionHeader.Title>
        <BottomHighlightLine></BottomHighlightLine>
        </SectionHeader>
        <BuyCards>
        <Expenses.Pagination>
        <Expenses.PageCountContainer>
        <Expenses.PageCountLabel>&nbsp;<b>Total Pages: {totalPages}</b></Expenses.PageCountLabel>
        <Expenses.PageButton
          disabled={currentPagePosition === 1}
          onClick={() => handlePageChange(currentPagePosition - 1)}
        >
          <BiChevronLeft style={{height:'30px',width:'30px'}} />
        </Expenses.PageButton>
        <b>{currentPagePosition}</b>
        <Expenses.PageButton
          disabled={currentPagePosition === totalPages}
          onClick={() => handlePageChange(currentPagePosition + 1)}
          style={{backgroundColor:'white', border:'none', height:'30px',width:'30px', marginLeft:'5px'}}
        >
          <BiChevronRight style={{height:'30px',width:'30px'}} />
        </Expenses.PageButton>
        </Expenses.PageCountContainer>
        </Expenses.Pagination>
        
        {currentItems?.map((item,index) => 
           <BuyCards.Card key={index} onClick={() => handleNav(item[`tractor_id`],item,"inventory")}>
                    {(typeof(item[`image_links`]) === "object" && Object.keys(item[`image_links`])?.length > 0) ? <BuyCards.Image src={urlContain(item[`image_links`][0]["image"])} alt="" /> : <BuyCards.Image src="" alt="" />}
                    <BuyCards.InfoContainer>
                    <BuyCards.Title>{item["brand"]}&nbsp;{item["model"]}</BuyCards.Title>
                    <BuyCards.Price>
                        <b>₹ {item["max_price"]}</b>
                        {item["rating"] > 0 && 
                            <BuyCards.Star src={`../assets/staricon/${item["rating"]} star.svg`} alt={`${item["rating"]} star`} />
                        }
                    </BuyCards.Price>
                    <BuyCards.SpecificationContainer>
                        <BuyCards.Specification>
                            <BuyCards.Icon src="./assets/certify/engine_hours.svg" alt="" />
                            <BuyCards.SpecificationValue><BuyCards.SpecificationLabel>Hrs</BuyCards.SpecificationLabel>{item[`engine_hours`] === -1 ? "NA" : `${item[`engine_hours`]}`}</BuyCards.SpecificationValue>
                        </BuyCards.Specification>
                        <BuyCards.Specification>
                            <BuyCards.Icon src="./assets/certify/engine_power.svg" alt="" />
                            <BuyCards.SpecificationValue><BuyCards.SpecificationLabel>Power</BuyCards.SpecificationLabel>{item[`engine_power`] === -1 ? "NA" : `${item[`engine_power`]}`}</BuyCards.SpecificationValue>
                        </BuyCards.Specification>
                        <BuyCards.Specification>
                            <BuyCards.Icon src="./assets/certify/tyre_condition.svg" alt="" />
                            <BuyCards.SpecificationValue><BuyCards.SpecificationLabel>Tyre</BuyCards.SpecificationLabel>{item[`tyre_condition`] === "Original" ? "Original" : item[`tyre_condition`] }</BuyCards.SpecificationValue>
                        </BuyCards.Specification>
                    </BuyCards.SpecificationContainer>
                    <BuyCards.ButtonContainer>
                    <BuyCards.RTONumber>{item?.additional_featureInfo?.["Registration (RTO) No."]}</BuyCards.RTONumber>
                    <BuyCards.Button>{landingPageLang("more_info")} <img src="./assets/arrow/right_arrow_black.svg" alt="right arrow" /></BuyCards.Button>
                    </BuyCards.ButtonContainer>
                    </BuyCards.InfoContainer>
            </BuyCards.Card>
            )}
        </BuyCards>
        </Section>
        )

    ))
}