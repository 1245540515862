import * as types from "../types";

const initialState = {
    sortObj: {
        sort_by:'',
    },
    sorttedArray: []
}

export default function invSortReducer( state = initialState, action ){

    switch(action.type){

        case types.SET_INV_SORT_DROPDOWN:
            return {
                ...state,
                sortObj: action.payload.sortObj
            }

        case types.SET_INV_SORTTED_DATA:
            return {
                ...state,
                sorttedArray: action.payload.sorttedArray
            }

        case types.SET_INV_SORT_RESET:
            return {
                sortObj: {
                    sort_by:'',
                },
                sorttedArray: []
            }

        default:
            return state;

    }

}