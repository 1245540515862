import * as types from "../types";

const initialState = {
  loading: false,
  verifyOtp:null,
  resVerifyOtp: null,
  error: null,
};

export default function verifyOtpReducer(state = initialState, action) {
  switch (action.type) {
    case types.VERIFY_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        verifyOtp: action.payload.verifyOtp,
      };

    case types.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        resVerifyOtp: action.payload.resVerifyOtp,
      };

    case types.VERIFY_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        verifyOtp:null,
        resVerifyOtp: null,
        error: action.payload.error,
    };

    case types.VERIFY_OTP_RESET:
      return {
        ...state,
        loading: false,
        verifyOtp:null,
        resVerifyOtp: null,
        error: null,
    };

    default:
      return state;
  }
}