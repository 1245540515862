import * as types from "../types";

export const sendOtpRequest = ({requestOtp}) => ({
  type: types.SEND_OTP_REQUEST,
  payload:{requestOtp}
});

export const sendOtpSuccess = ({ resOtp }) => ({
  type: types.SEND_OTP_SUCCESS,
  payload: {
    resOtp,
  },
});

export const sendOtpFailure = ({ error }) => ({
  type: types.SEND_OTP_FAILURE,
  payload: {
    error: error.message,
  },
});

export const sendOtpReset = () => ({
  type: types.SEND_OTP_RESET,
});