import React, { useEffect, useState } from "react";
import Accordian from "../components/accordian";
import SpecificationContainer from "../components/specification";
import useFetch from "../utils/useFetch";
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import Loader from "../components/Loader/Loader";


export default function AccordianMoreinformation({data, androidApp,tableName,title,lang}){
    const [show, setshow] = useState({});

    const [listData, listFetching, listError] = useFetch({
        tableName: tableName,
        fields: [`feature_name(EN)`,`feature_name(${lang})`, `answer_type`, `answers(${lang})`],
        filterByFormula: "",
      });

    useEffect(()=>{
        setshow((pre) => ({...pre,[tableName]:true}))
    },[tableName])  
    
    const showMoreDetail = (accordianName) => {
        setshow((prev) => ({...prev,[accordianName]:!show[accordianName]}));
    }  

    return (
       (listData?.filter((filterItem) => (data?.hasOwnProperty([filterItem[`feature_name(EN)`]]) && data?.[filterItem[`feature_name(EN)`]] !== "") && filterItem).length > 0 && <Accordian>
            <Accordian.HeaderConatiner onClick={() => showMoreDetail(tableName)}>
                <div style={{display:'flex',alignItems:'center'}}>
                <Accordian.Title>{title}</Accordian.Title>
                </div>
                <Accordian.IconContainer>{show[tableName] ? <IoIosArrowUp className="arrow" />:  <IoIosArrowDown className="arrow" />}</Accordian.IconContainer>
            </Accordian.HeaderConatiner>
            {listFetching ? <Loader /> :
            show[tableName] && listData?.map((item,index) => 
            ((data?.hasOwnProperty([item[`feature_name(EN)`]]) && data?.[item[`feature_name(EN)`]] !== "") && <SpecificationContainer.TableConatiner key={index}>
                <SpecificationContainer.TableHeader>
                    <SpecificationContainer.TableHeaderText>{item[`feature_name(${lang})`]}</SpecificationContainer.TableHeaderText>
                </SpecificationContainer.TableHeader>
                <SpecificationContainer.TableValues>
                   {item[`feature_name(EN)`] === "Registration (RTO) No." ? <SpecificationContainer.TableValuesText>{(item[`feature_name(EN)`] === "Registration (RTO) No." && androidApp) ? data[item[`feature_name(EN)`]] : `${data[item[`feature_name(EN)`]].substring(0, 4)}*********`}</SpecificationContainer.TableValuesText>:<SpecificationContainer.TableValuesText>{data[item[`feature_name(EN)`]]}</SpecificationContainer.TableValuesText>}
                </SpecificationContainer.TableValues>
            </SpecificationContainer.TableConatiner>)
            )}
        </Accordian>)
    )
}