import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    max-width: 760px;
    height: auto;
    background-color: #FFF;
    padding: 20px 10px;
    border-radius: 16px 16px 0px 0px;
    position: fixed;
    bottom: 0;
    animation: ${props =>  props.isShown ? 'slideDown' : 'slideUp'} 0.5s ease-in-out;
    display: ${props =>  props.isShown ? 'block' : 'none'};

    @keyframes slideDown {
        from {
          transform: translateY(100%);
        }
        to {
          transform: translateY(0);
        }
    }
      
    @keyframes slideUp {
        from {
          transform: translateY(0);
        }
        to {
          transform: translateY(-100%);
        }
    }

`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    font-weight: 700;
    font-size: 20px;    
    justify-content: space-between;
`;

export const HeaderText = styled.p``;

export const ButtonContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
`;

export const Main = styled.div`
    widht: 100%;
    height: auto;
    padding: 10px;
    border-radius: 8px; 
`;

export const MainContent = styled.div`
    width: 100%;
    height:auto;
`;

export const MainLabel = styled.span`
`;

export const Image = styled.img``;

export const MainText = styled.p``;

export const Icon = styled.div`
    display: flex;
    border: 1px solid #E7E7E7;
    align-items: center;
    gap: 2px;
    padding: 10px;
    width: auto;
    font-size: 15px;
    height: 40px;
    cursor: pointer;
`;

export const Button = styled.button`
    width: 156px;
    height: 47px;
    border: none;
    background: linear-gradient(180deg, #FF8F4D 0%, #F26F21 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: #FFFF;
`;