
import React from "react";
import Home from "./pages/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductDetails from "./pages/productDetails";
import AboutUs from "./containers/aboutUs";
import FeatureCheckList from "./pages/featureCheckList";
import Video from "./pages/Video";
import ViewAllProduct from "./containers/viewAllProduct";
import EditFeature from "./pages/editFeature";
import AdditionalInformation from "./containers/additionalInformation";
import WalkingLeadForm from "./containers/lead/walkingLeadForm";
import WalkingLeadView from "./containers/lead/walkingLeadView";
import WalkingLeadLanding from "./containers/lead/walkingLeadLanding";
import ExpenseHome from "./containers/expenseTracker_v2/expenseHome";
import PricingTool from "./containers/pricingTool/PricingTool";
import Campaign from "./containers/campaign/Campaign";
import Certificate from "./containers/certificate/certificate";
import ExpenseTrackerAddForm from "./containers/expenseTracker_v2/expenseTrackerAddForm";
import ExpenseTrackerEditForm from "./containers/expenseTracker_v2/ExpenseTrackerEditForm";
import AuthPage from "./containers/authpage";
import CTInventoryList from "./containers/inventoryCoinTechLinks/ctInventoryList";
import CTInventoryDetails from "./pages/coinTech/ctInventoryDetails";
import ExpenseTrackerView from "./containers/expenseTracker_v2/expenseTrackerView";
import AuthPageTest from "./containers/authpage/authpageTest";
import CampaignTest from "./containers/campaignTest";
import CampaignProductDetail from "./containers/campaignTest/campaignProductDetail";
import AuthPageFirst from "./containers/authpage/authpageFirst";
import FaqPage from "./pages/faqPage";
import TractorVerificationPage from "./pages/metabase/tractorVerificationPage";
import NTFaqPage from "./pages/ntFaqPage";


function App() {

  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home />}  />
      <Route path="/productDetails" element={<ProductDetails />}  />
      <Route path="/allproduct" element={<ViewAllProduct />}  />
      <Route path="/video" element={<Video />}  />
      <Route path="/aboutus/:dealer_location" element={<AboutUs />}  />
      <Route path="/featureCheckList" element={<FeatureCheckList />}  />
      <Route path="/ctInventory" element={<CTInventoryList />}  />
      <Route path="/ctInventorydetails" element={<CTInventoryDetails />}  />      
      <Route path="/editFeature" element={<EditFeature />}  />
      <Route path="/additionalInfo" element={<AdditionalInformation />}  />
      <Route path="/walkinLead" element={<WalkingLeadForm />}  />
      <Route path="/walkinLeadView" element={<WalkingLeadView />}  />
      <Route path="/walkingLeadLanding" element={<WalkingLeadLanding />}  />
      <Route path="/expenseHome" element={<ExpenseHome />}  />
      <Route path="/add-expense-tracker/:chassisNumber" element={<ExpenseTrackerAddForm />} />
      <Route path="/edit-expense-tracker" element={<ExpenseTrackerEditForm />} />
      <Route path="/expense-info/:chassisNumber" element={<ExpenseTrackerView />} />
      {/* <Route path="/filter" element={<InventoryFilter />} /> */}
      <Route path="/pricing" element={<PricingTool />} />
      <Route path="/campaign" element={<AuthPage />} />
      <Route path="/campaigns" element={<Campaign />} />
      <Route path="/lp" element={<AuthPageFirst />} />
      <Route path="/lps" element={<Campaign />} />
      <Route path="/lpv2" element={<AuthPageTest />} />
      <Route path="/lpsv2" element={<CampaignTest />} />
      <Route path="/lpsv2product" element={<CampaignProductDetail />} />
      <Route path="/qualityCertificate" element={<Certificate />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/nt-faq" element={<NTFaqPage />} />
      <Route path="/verify-nt" element={<TractorVerificationPage />} />
      </Routes>
    </Router>  
  );
}

export default App;