import * as types from "../types";

const initialState = {
    loading: false,
    showLangDrodpdown:false,
    languageCode: "MR",
}
// languageState: null,
//error: null,

export default function languageReducer(state = initialState, action){
    switch(action.type){
        case types.SHOW_LANGUAGE_DROPDOWN:
            return {
                ...state,
                showLangDrodpdown: action.payload.showLangDrodpdown
            }
        
        case types.SET_LANGUAGE:
            return {
                ...state,
                languageCode: action.payload.languageCode
            }
        
            
            default :
            return state;
        }
        
    } 
    
    // case types.FETCH_LANGUAGE_REQUEST:
    //     return {
    //         ...state,
    //         loading: true
    //     }
    
    // case types.FETCH_LANGUAGE_SUCCESS:
    //     return {
    //         ...state,
    //         loading: false,
    //         languageState: action.payload.languageState?.filter((item) => (item["language"] === state.languageCode) && item)
    //     }