import React from "react";
import Header from "../components/header";
import EditFeatureTable from "../containers/features/edit/editFeatureTable";

export default function EditFeature(){
    return (
        <>
        <Header style={{padding:'5px',fontWeight:'700',fontSize:'17px'}}>
           Edit More Details
        </Header>
        <EditFeatureTable />
        </>
    )
}