import * as types from "../types";

export const fetchPricingRequest = ({requestPrice}) => ({
  type: types.FETCH_PRICING_DATA_REQUEST,
  payload:{requestPrice}
});

export const fetchPricingSuccess = ({ resPrice }) => ({
  type: types.FETCH_PRICING_DATA_SUCCESS,
  payload: {
    resPrice,
  },
});

export const fetchPricingFailure = ({ error }) => ({
  type: types.FETCH_PRICING_DATA_FAILURE,
  payload: {
    error: error.message,
  },
});

export const fetchPricingReset = () => ({
  type: types.FETCH_PRICING_DATA_RESET
});