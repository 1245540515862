import * as types from "../types";

const initialState = {
  loading: false,
  requestOtp:null,
  resOtp: null,
  error: null,
};

export default function sentOtpReducer(state = initialState, action) {
  switch (action.type) {
    case types.SEND_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        requestOtp: action.payload.requestOtp,
      };

    case types.SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        resOtp: action.payload.resOtp,
      };

    case types.SEND_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        requestOtp:null,
        resOtp: null,
        error: action.payload.error,
    };

    case types.SEND_OTP_RESET:
      return {
        ...state,
        loading: false,
        requestOtp:null,
        resOtp: null,
        error: null,
    };

    default:
      return state;
  }
}