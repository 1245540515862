import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Carousel from "../components/carousel";
import urlContain from "../utils/urlContain";


export default function Gallery({type,productId, productFilterdata,lang,district}){

    const [images,setImages] = useState([]); 
    const otherImages = images;
    const totalImages = otherImages.length;
    const [selectedImage, setSelectedImage] = useState(0);
//    const validVideoTypes = ['video/mp4','video/webm','video/ogg','video/avi','video/mpg','video/mpeg','video/rm','video/ram','video/mov','video/quicktime','video/wmv','video/swf','video/flv'];
    const validVideoTypes = ['mp4','webm','ogg','avi','mpg','mpeg','rm','ram','mov','quicktime','wmv','swf','flv'];

    useEffect(() => {
      let imagesArr = [];
      (typeof(productFilterdata[`image_links`]) === "object" && Object.keys(productFilterdata[`image_links`])?.length > 0) && Object.keys(productFilterdata[`image_links`])?.map((key) => {
        imagesArr = [...imagesArr,productFilterdata[`image_links`][key]["image"]]
      })
      productFilterdata[`video_file_exists`] && imagesArr.unshift(productFilterdata[`video_url`])
      setImages(imagesArr);

    },[productFilterdata])

    function getFileExtension(filename){
      // get file extension
      const extension = filename.split('.').pop();
      return extension;
    }

    const imageClickHandler = (index) => {
      setSelectedImage(index);
    };
    const arrowClickHandler = (direction) => {
      if (direction === "left") {
        setSelectedImage((selectedImage + totalImages - 1) % totalImages);
      } else {
        setSelectedImage((selectedImage + 1) % totalImages);
      }
    };
    return (
        <Carousel>
            <Carousel.ImageDisplayContainer>
                {otherImages?.filter((item,index) => ((otherImages && item === otherImages[selectedImage] && validVideoTypes.includes(getFileExtension(item))) && item)).length > 0 ?
                <ReactPlayer
                                url={otherImages.length > 0 ? urlContain(otherImages[selectedImage]) : ""}
                                playing={false}
                                playsinline={true}
                                controls
                                width="100%"
                                height="100%"
                />
                :
                <Carousel.ImageDisplay src={otherImages.length > 0 ? urlContain(otherImages[selectedImage]) : ""} />
                }

                <Carousel.ImageDisplayArrow
                direction="left"
                onClick={() => arrowClickHandler("left")}
                src="../assets/carousel-arrow.svg"
                />
                <Carousel.ImageDisplayArrow
                direction="right"
                onClick={() => arrowClickHandler("right")}
                src="../assets/carousel-arrow.svg"
                />
            </Carousel.ImageDisplayContainer>
            <Carousel.SelectImagesContainer>
            {otherImages?.map((item, index) => {
            return (
              (otherImages?.filter((imageItem,index) => ((item && imageItem === item && validVideoTypes.includes(getFileExtension(item))) && imageItem)).length > 0 ?
                <Carousel.SelectImageContainer
                open={selectedImage === index}
                onClick={() => imageClickHandler(index)}
                selected={item.id}
                key={index}
                color={(selectedImage === index) ? "#F37021" : "#00000029"}
                >
                <div style={{position:'relative',display:'flex',alignItems:'center',justifyContent:'center'}}>
                <ReactPlayer
                                url={(otherImages.length > 0 && item) ? urlContain(item) : ''}
                                playing={false}
                                playsinline={true}
                                controls={false}
                                width="74px"
                                height="74px"
                                style={{position:'relative'}}
                />
                <img src="./assets/videoplay.png" alt="" style={{position:'absolute'}} />
                </div>
                </Carousel.SelectImageContainer>
                :
                <Carousel.SelectImageContainer
                open={selectedImage === index}
                onClick={() => imageClickHandler(index)}
                selected={item.id}
                key={index}
                color={(selectedImage === index) ? "#F37021" : "#00000029"}
                >
                <Carousel.SelectImage src={(otherImages.length > 0 && item) ? urlContain(item) : ''}  />  
                              
                </Carousel.SelectImageContainer>
            )
            );
            })}
            </Carousel.SelectImagesContainer>
        </Carousel>
    )

}