import styled, { keyframes } from "styled-components";
export const Container = styled.form`
  margin: 40px auto;
  width: 50%;
  margin-top: 40px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  width: 100%;
  height: auto;
  padding: 16px 25px;
  border-radius: 4px;
  opacity: 1;
  font-size: 14px;
`;

export const FieldBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  justify-content: space-between;
  height: auto;
`;

export const GroupFields = styled.div`
  color: black;
  color: #000;
  display: block;
  width: 100%;
  margin: 10px auto;
`;

export const Input = styled.input`
  opacity: 1;
  width: 100%;
  max-width: 100%;
  min-height: 38px;
  text-indent: 15px;
  transition: all 100ms ease 0s;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0px !important;
`;

export const PricingInput = styled.input`
  opacity: 1;
  width: 85%;
  max-width: 90%;
  min-height: 38px;
  text-indent: 15px;
  transition: all 100ms ease 0s;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0px !important;
  border: none;
`;

export const RangeInput = styled.input`
  text-indent: 15px;
  width: 135px;
  min-height: 38px;
  transition: all 100ms ease 0s;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0px !important;
  opacity: 1;
`;

export const Label = styled.label`
  margin: 0 0.5em 0.5em 0;
  color: Black;
  display: block;
`;

export const Dropdown = styled.select`
  width: 100%;
  max-width: 100%;
  min-height: 38px;
  text-indent: 15px;
  transition: all 100ms ease 0s;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0px !important;
`;

export const PricingDropdown = styled.select`
  width: 100%;
  max-width: 100%;
  min-height: 38px;
  transition: all 100ms ease 0s;
  background-color: #fff;
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0px !important;
  border:none;
`;

export const Textarea = styled.textarea`
  height: auto;
  padding: 15px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  background: #652078 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 10px 20px;
  color: #FFFF;
  margin-right: 5px;
  border: none;
  font-weight: 600;
  &:hover, &:focus {
    background-color: rgba(101, 32, 120, 0.8);
  }

`;

export const SectionContainer = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-bottom: 40px;
  max-width: 100%;
  overflow: auto;
  padding: 10px;
  box-shadow: ${prop => prop?.shadow ? '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)' : '0px 8px 12px #00000017'} ;
`;

export const FileInputConatiner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Section = styled.div`
  width: 100%;
  height: auto;
  padding: 40px 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const SectionTitle = styled.p`
  color: #333333;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Image = styled.img`
  height: 50px;
  width: 50px;
`;


export const IconButton = styled.button`
  border: 2px solid ${prop => prop.color ? prop.color : 'black'};
  padding: 5px 20px; 
  min-width: 100px;
  min-height: 35px;
  display: flex;
  color: ${prop => prop.color ? 'white' : 'black'};
  background: ${prop => prop.color ? prop.color : 'none'};
  border-radius: 5px;
  align-items: center;
  justify-content:center;
  box-shadow: ${prop => prop.color ? '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)' : ''};
}
`;

export const InputWrapper = styled.div`
  width:100%;
  height: auto;
  border-radius: 4px;
  border: 1px solid #999999;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;