import * as types from "../types";

const initialState = {
    loading: false,
    reqChassis: null,
    resChassisData: null,
    error: null
}

export default function chassisExpenseDataReducer(state = initialState, action){

    switch(action.type){
        case types.FETCH_EXPENSE_CHASSIS_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                reqChassis: action.payload.reqChassis
            }

        case types.FETCH_EXPENSE_CHASSIS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                resChassisData: action.payload.resChassisData
        }
        
        case types.FETCH_EXPENSE_CHASSIS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                reqChassis: null,
                resChassisData: null,
                error: action.payload.error
        }

        case types.FETCH_EXPENSE_CHASSIS_DATA_RESET:
            return {
                ...state,
                loading: false,
                reqChassis: null,
                resChassisData: null,
                error: null
        }        
         
        default: 
            return state
    }

}