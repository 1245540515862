import React from "react";
import styled from "styled-components";
import Section from "../../components/section";
import useFaqFetch from "../../utils/useFaqFetch";
import Loader from "../../components/Loader/Loader";
import { FcInfo } from "react-icons/fc";

const FaqItemWrapper = styled.div`
	cursor: pointer;
	padding: 15px;
	margin: 10px 0;
	border: 1px solid #ddd;
	border-radius: 8px;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	background: linear-gradient(
		to right,
		rgba(241, 241, 241, 0.7),
		rgba(241, 241, 241, 0.7)
	);

	&:hover {
		transform: scale(1.02);
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	}
`;

const FaqQuestionText = styled.p`
	margin: 0;
	font-size: 16px;
	font-weight: 600;
	color: #333;
`;

const MessageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000000;
	font-size: 20px;
	font-weight: bold;
`;

const Icon = styled(FcInfo)`
	margin-right: 5px;
	height: 24px;
	width: 24px;
`;

export default function NTFaqContainer({
	setModalOpen,
	setFaqId,
	setQuestionName,
}) {
	const [faqQuestionLists, faqQuestionListFetching, faqQuestionListError] =
		useFaqFetch({
			tableName: "Question_List",
			fields: [`Name`, `q_id`, `visibility`],
			filterByFormula: "",
		});

	const questionDetailsHandler = (quesArgs) => {
		if (quesArgs?.q_id && quesArgs?.q_id !== "") {
			setModalOpen(true);
			setQuestionName(quesArgs?.["Name"]);
			setFaqId(quesArgs?.q_id);
		}
	};
	const anyVisible = faqQuestionLists?.some(
		(faqquestionList) =>
			(faqquestionList?.visibility || "").toLowerCase() === "yes"
	);

	return (
		<Section>
			{faqQuestionListFetching ? (
				<Loader />
			) : anyVisible ? (
				faqQuestionLists?.map(
					(faqquestionList, index) =>
						faqquestionList?.["Name"] &&
						(faqquestionList?.["visibility"] || "").toLowerCase() === "yes" && (
							<FaqItemWrapper
								key={`faqquestionList_${index}`}
								onClick={() => questionDetailsHandler(faqquestionList)}
							>
								<FaqQuestionText>{faqquestionList?.["Name"]}</FaqQuestionText>
							</FaqItemWrapper>
						)
				)
			) : (
				<MessageContainer>
					<Icon />
					No Data Available
				</MessageContainer>
			)}
		</Section>
	);
}
