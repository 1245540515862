import * as types from "../types";

export const fetchNtSingleInvRequest = ({reqTractorId}) => ({
    type: types.FETCH_SINGLE_NT_TRACTOR_REQUEST,
    payload: {
        reqTractorId
    }
})

export const fetchNtSingleInvSuccess = ({resNtInv}) => ({
    type: types.FETCH_SINGLE_NT_TRACTOR_SUCCESS,
    payload: {
        resNtInv
    }
})

export const fetchNtSingleInvFailure = ({error}) => ({
    type: types.FETCH_SINGLE_NT_TRACTOR_FAILURE,
    payload: {
        error: error.message
    }
})