import * as types from "../types";

export const filterExpenseShow = ({userid,showFilter}) => ({
    type:types.FILTER_EXPENSE_SHOW,
    payload:{
        userid,
        showFilter
    }
})

export const filterExpenseTypeData = ({filterOptions}) => ({
    type: types.FILTER_EXPENSE_TYPE_DATA,
    payload:{
        filterOptions
    }
})

export const ExpenseFinalOption = ({finalOption}) => ({
    type: types.FILTER_EXPENSE_FINAL_OPTION,
    payload:{
        finalOption
    }
})

export const filterExpenseSetData = ({filteredData}) => ({
    type: types.FILTER_EXPENSE_SET_DATA,
    payload:{
        filteredData
    }
})

export const filterExpenseReset = () => ({
    type: types.FILTER_EXPENSE_TYPE_RESET
})