import { BlobServiceClient} from '@azure/storage-blob';
import { BLOB_BASE_URL, BLOB_SASTOKEN } from '../config';

// const containerName = `tutorial-container`;
// const sasToken = process.env.REACT_APP_STORAGESASTOKEN;
// const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME; 

//const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN;
//const sasToken = "sv=2021-06-08&ss=b&srt=sco&sp=rwlaciyx&se=2026-02-17T12:32:27Z&st=2023-02-17T04:32:27Z&spr=https&sig=6GEiTugwrJSoEXqNKJx2fdla07T%2FyFOLm9ku8fzc6fA%3D";
const sasToken = BLOB_SASTOKEN.BASE_URL;

//const storageAccountName =  `${process.env.REACT_APP_AZURE_BLOB_BASE_URL}/assets/campaigns`; 
//const storageAccountName =  "https://castagingstorage.blob.core.windows.net/used-tractor-media-staging";
const storageAccountName =  BLOB_BASE_URL.BASE_URL;



export const isStorageConfigured = () => {
  return (!storageAccountName || !sasToken) ? false : true;
}

const getBlobsInContainer = async (containerClient,fileName,containerName) => {
 // const returnedBlobUrls = [];
  //console.log("containerClient",containerClient)
  // get list of blobs in container
  // eslint-disable-next-line
  // console.log(containerClient);

  //  for await (const blob of containerClient.listBlobsFlat()) {
  // //   // if image is public, just construct URL
  //    console.log('blob namer', blob, blob.name)
  //    returnedBlobUrls.push(
  //      `${storageAccountName}${containerName}/${blob.name}`
  //    );
  //  }
  
  //return returnedBlobUrls;
  return `${storageAccountName}/${containerName}/${fileName}`;
}

const createBlobInContainer = async (containerClient, file) => {  
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);
  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { 'x-ms-blob-type' : 'BlockBlob',"Access-Control-Allow-Origin":"*"} };
  // upload file
  await blobClient.uploadData(file, options);
}

const uploadFileToBlob = async (file,containerName) => {
  if (!file) return [];
  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `${storageAccountName}?${sasToken}`
  );
  // get Container - full public read access
  const containerClient = blobService.getContainerClient(containerName);

  // upload file
  await createBlobInContainer(containerClient, file);
  // get list of blobs in container
  return getBlobsInContainer(containerClient,file.name,containerName);
};

export default uploadFileToBlob;