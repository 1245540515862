import React from "react"
import { Container, TableConatiner, TableHeader, TableHeaderText, TableValues, TableValuesText, VarientConatiner, Varient, PriceLabel } from "./styles/specification"

export default function SpecificationContainer({children,...props}){
    return <Container {...props}>{children}</Container>
}

SpecificationContainer.VarientConatiner = function SpecificationContainerVarientConatiner({children,...props}){
    return <VarientConatiner {...props}>{children}</VarientConatiner>
}

SpecificationContainer.Varient = function SpecificationContainerVarient({children,...props}){
    return <Varient {...props}>{children}</Varient>
}

SpecificationContainer.TableConatiner = function SpecificationContainerTableConatiner({children,...props}){
    return <TableConatiner {...props}>{children}</TableConatiner>
}

SpecificationContainer.TableHeader = function SpecificationContainerTableHeader({children,...props}){
    return <TableHeader {...props}>{children}</TableHeader>
}

SpecificationContainer.TableHeaderText = function SpecificationContainerTableHeaderText({children,...props}){
    return <TableHeaderText {...props}>{children}</TableHeaderText>
}

SpecificationContainer.TableValues = function SpecificationContainerTableValues({children,...props}){
    return <TableValues {...props}>{children}</TableValues>
}

SpecificationContainer.TableValuesText = function SpecificationContainerTableValuesText({children,...props}){
    return <TableValuesText {...props}>{children}</TableValuesText>
}

SpecificationContainer.PriceLabel = function SpecificationContainerPriceLabel({children,...props}){
    return <PriceLabel {...props}>{children}</PriceLabel>
}

