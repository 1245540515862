import * as types from "../types";

export const inventoryAllRequest = () => ({
    type: types.FETCH_ALL_INVENTORY_DATA_REQUEST
});

export const inventoryAllSuccess = ({resInvAll}) => ({
    type: types.FETCH_ALL_INVENTORY_DATA_SUCCESS,
    payload:{
        resInvAll
    }
});

export const inventoryAllFailure = ({error}) => ({
    type: types.FETCH_ALL_INVENTORY_DATA_FAILURE,
    payload:{
        error: error.message
    }
});

export const inventoryAllReset = () => ({
    type: types.FETCH_ALL_INVENTORY_DATA_RESET,
});