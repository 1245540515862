import * as types from "../types";
import * as _api from "../../api";
import { call, put, takeLatest } from "redux-saga/effects";
import { clearErrors, returnErrors } from "../actions/errorAction";
import { expenseChassisFailure, expenseChassisSuccess } from "../actions/chassisExpenseDataAction";

function* asyncfetchExepnseChassisSaga(action){
    try{
        yield put(clearErrors());
        let resChassisData = yield call(_api.fetchExepnseChassisData,{
            reqChassis: action.payload.reqChassis
        })
        yield put(expenseChassisSuccess({resChassisData}));
    }catch(e){
        yield put(expenseChassisFailure({error:e}));
        yield put(returnErrors({msg: e.message, status: true}));
    }   
}

export default function* fetchExpenseChassisDataSaga(){
    yield takeLatest(types.FETCH_EXPENSE_CHASSIS_DATA_REQUEST, asyncfetchExepnseChassisSaga)
}