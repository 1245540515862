import React, { createRef } from "react";
import { exportComponentAsPNG } from "react-component-export-image";
import './Certificate.css';
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import QualityCheckCertificate from "./qualityCheckCertificate";

const Certificate=({modalOpen,setModalOpen,item,certificateData}) => {
  const certificateWrapper = createRef();
 
    return (
        <PureModal
        width="760px"
        height="80vh"
        playing={true}
        controls={true}
        volume={1}
        pip={true}
        isOpen={true}
      >
    <div className="app">
        <div id="downloadWrapper" ref={certificateWrapper}>
          <div id="certificateWrapper">
            <QualityCheckCertificate />
          </div>
        </div>
        <button
            onClick={(e) => {
              e.preventDefault();
              exportComponentAsPNG(certificateWrapper, {
                fileName:"Krishe-Certificate",
                html2CanvasOptions: { backgroundColor: null }
              });
            }}
          >
            DOWNLOAD
          </button>
      </div>
      </PureModal>
    );
}

export default Certificate;
