import * as types from "../types";

export const verifyOtpRequest = ({verifyOtp}) => ({
  type: types.VERIFY_OTP_REQUEST,
  payload:{verifyOtp}
});

export const verifyOtpSuccess = ({ resVerifyOtp }) => ({
  type: types.VERIFY_OTP_SUCCESS,
  payload: {
    resVerifyOtp,
  },
});

export const verifyOtpFailure = ({ error }) => ({
  type: types.VERIFY_OTP_FAILURE,
  payload: {
    error: error.message,
  },
});

export const verifyOtpReset = () => ({
  type: types.VERIFY_OTP_RESET,
});