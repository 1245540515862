import React from 'react';
import "./mui.css"; 
import Main from '../../components/main';
import TileWrapper from '../../components/tile';
import Header from '../../components/header';
import { useQuery } from '../../utils/useQuery';

export default function WalkingLeadLanding() {
  const queryParams = useQuery();
  const userid = queryParams.get('userid');

  return (

    <>
        <Header>
            &nbsp;&nbsp;<p>Walk-In Lead</p>
        </Header> 
        <br />   
      <Main>
        <TileWrapper>
          <TileWrapper.Navigation to={`/walkinLead?userid=${userid}`}>
            <TileWrapper.Tile>
              <TileWrapper.Image
                src="../assets/add_item.svg"
                alt=""
              />
              <TileWrapper.Title>Add Walk-In Lead</TileWrapper.Title>
            </TileWrapper.Tile>
          </TileWrapper.Navigation>
          <TileWrapper.Navigation to={`/walkinLeadView?userid=${userid}`}>
            <TileWrapper.Tile>
              <TileWrapper.Image src="../assets/view_leads.svg" alt="" />
              <TileWrapper.Title>View Walk-In Lead</TileWrapper.Title>
            </TileWrapper.Tile>
          </TileWrapper.Navigation>
        </TileWrapper>
        </Main>
    </>

  );
}