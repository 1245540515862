import React, { useContext, useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import Swal from "sweetalert2";
import QuestionSection from "../../../components/questionSection";
import { ProductContext } from "../../../utils/productContext";
import useFetch from "../../../utils/useFetch";
import Form from "../../../components/form";

export default function FeatureCheckQuestions({ tableName, status, lang }) {
  const [data, setData] = useState();
  const [listData, listFetching, listError] = useFetch({
    tableName: tableName,
    fields: [`feature_name(EN)`, `feature_name(${lang})`, `answer_type`, `answers(EN)`,`answers(${lang})`],
    filterByFormula: "",
  });

  const {
    tempJSON,
    setTempJSON,
    setTotalQuestions
  } = useContext(ProductContext);

  useEffect(() => {
    let totalNo = listData ? listData.length : "";
    setTotalQuestions((prev) => ({ ...prev, [tableName]: totalNo }));
  }, [tableName, listData]);

  const handleChange = (e, response, defaultResponse,color, categoryName, index) => {
    if(color === "radio" && !status){
      setTempJSON((prev) => ({
        ...prev,
        [listData[index][`feature_name(EN)`]]: response,
      }));
    }else if(color === "text" && !status){
      setTempJSON((prev) => ({
        ...prev,
        [listData[index][`feature_name(EN)`]]: e.target.value,
    }));
    }else if(!status){
      setTempJSON((prev) => ({
        ...prev,
        [listData[index][`feature_name(EN)`]]: response?.[`answers(EN)`]?.split('/')?.[`${defaultResponse === "Yes" ? 1 : 0}`],
      }));

    }

   };

  const toast = (e, message) => {
    Swal.fire({
      html: `<div style={{text-align:"center"}}>${message}</div>`,
      customClass: {
        container: "position-absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      confirmButtonColor: "#652078",
      toast: true,
      confirmButtonText: "Close",
      position: "bottom-center",
    });
  };

  return (
    <div>
      {listFetching ? (
        <Loader />
      ) : (
        listData?.map((item, index) => {
           return (
          <QuestionSection key={index}>
            <h4 style={{marginBottom:'5px'}}>
              {index + 1}.&nbsp;{item[`feature_name(${lang})`]}
             </h4>
           { (item.answer_type === "text") ?
                <Form.Input type="text" name={`${tableName} ${index}`} onChange={(e) => handleChange(e, item, 'No',"text", tableName, index)} id={`${tableName} ${index}`} />
            : (item.answer_type === "dropdown") ?
            <div>
            {/* <Form.Dropdown
                name={`${tableName} ${index}`}
                id={`${tableName} ${index}`}
                options = {item?.answers?.split("/")?.map((item,index) => (index === 0 ? ({value:'',label:'Select Value'},{value:item,label:item}) : {value:item,label:item}))}
            ></Form.Dropdown> */}
          {item?.[`answers(EN)`]?.split("/")?.map((itemAns, itemIndex) => (
            <div key={itemIndex} style={{display:'flex',alignItems:'center'}}>
              <input type="radio" name={`question_${index}`} id={`q${index}answer_${itemIndex}`} value={`${itemAns}`} onChange={(e) => handleChange(e, itemAns, 'No',"radio", tableName, index)} />&nbsp;
              <span>{item?.[`answers(${lang})`]?.split("/")[itemIndex]}</span>
            </div>
          ))}

            </div>
            : <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 80px 0 80px",
              gap: '20px'
            }}
          >
            {((item?.[`answers(EN)`] && tempJSON[item[`feature_name(EN)`]] === item?.[`answers(EN)`]?.split('/')?.[0])) ? (
              <Form.IconButton onClick={(e) => handleChange(e, item, 'No',"red", tableName, index)} color="red">{item?.[`answers(${lang})`]?.split('/')?.[0]}</Form.IconButton>
            ) : (
                <Form.IconButton onClick={(e) => handleChange(e, item,'No', "red", tableName, index)}>{item?.[`answers(${lang})`]?.split('/')?.[0]}</Form.IconButton>
            )}
            {((item?.[`answers(EN)`] && tempJSON[item[`feature_name(EN)`]] === item?.[`answers(EN)`]?.split('/')?.[1])) ? (
              <Form.IconButton onClick={(e) => handleChange(e, item,'Yes', "green", tableName, index)}  color="green" >{item?.[`answers(${lang})`]?.split('/')?.[1]}</Form.IconButton>
            ) : (
                <Form.IconButton onClick={(e) => handleChange(e, item, 'Yes',"green", tableName, index)}>{item?.[`answers(${lang})`]?.split('/')?.[1]}</Form.IconButton>
            )}
          </div>
            }
            <div style={{ marginBottom: "10px" }}></div>
          </QuestionSection>)
        })
      )}
    </div>
  );
}