import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
    padding: 0 8px;
`;

export const TitleWrapper = styled.div`
width: 100%;
font-size: 32px;
display: flex;
justify-content: center; 
margin-top: 60px;
gap: 10px;
`;

export const Title = styled.p`
    font-size: 23px;
    font-weight: 550;
`;

export const Image = styled.img`
    width: 30px;
    height: 30px;
`;