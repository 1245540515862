import * as types from "../types";

const initialState = {
    loading: false,
    reqSinleInv: null,
    resSinleInv: null,
    error: null
}

export default function inventorySingleReducer(state = initialState, action){
    switch(action.type){
        case types.FETCH_INVENTORY_SINGLE_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                reqSinleInv: action.payload.reqSinleInv
            }
        
        case types.FETCH_INVENTORY_SINGLE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                resSinleInv: action.payload.resSinleInv
            }

        case types.FETCH_INVENTORY_SINGLE_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                reqSinleInv: null,
                resSinleInv: null,
                error: action.payload.error
            }

        default :
        return state
    }
}