import { all } from "redux-saga/effects";
import fetchManufacturerStateSaga from "./manufacturerStateSaga";
import fetchPricingOtherDetailSaga from "./pricingOtherDetailSaga";
import fetchPricingSaga from "./pricingDataSaga";
import leadsSubmitSaga from "./leadsSubmitSaga";
import fetchInventorySingleSaga from "./inventorySingleSaga";
import fetchCategorySaga from "./airtable/categorySaga";
import fetchDynamicTableSaga from "./airtable/dynamicTableSaga";
import fetchInventoryAllSaga from "./inventoryAllSaga";
import fetchUserLocation from "./userLocationSaga";
import fetchDealerInventorySaga from "./dealerInventorySaga";
import fetchAirtableUserLocation from "./airtable/userLocationSaga";
import fetchBuySellFinanceListSaga from "./buySellFinanceListSaga";
import fetchExpenseChassisDataSaga from "./chassisExpenseSaga";
import sendOtpSaga from "./sendOtpSaga";
import verifyOtpSaga from "./verifyOtpSaga";
import campaignInventorySaga from "./campaignInventorySaga";
import fetchAdditonalInfoQuestionSaga from "./fetchAdditionalInfoQuestionSaga";
import fetchBrandModelSaga from "./fetchBrandModelSaga";
import fetchNtSingleTractorSaga from "./fetchNtSingleTractorSaga";
import postNtTractorVerifySaga from "./postNtTractorVerifySaga";

export default function* rootSaga() {
  yield all([
    fetchManufacturerStateSaga(),
    fetchPricingOtherDetailSaga(),
    fetchPricingSaga(),
    leadsSubmitSaga(),
    fetchInventorySingleSaga(),
    //languageLabelData(),
    fetchDealerInventorySaga(),
    fetchCategorySaga(),
    fetchDynamicTableSaga(),
    fetchInventoryAllSaga(),
    fetchUserLocation(),
    fetchAirtableUserLocation(),
    fetchBuySellFinanceListSaga(),
    fetchExpenseChassisDataSaga(),
    sendOtpSaga(),
    verifyOtpSaga(),
    campaignInventorySaga(),
    fetchAdditonalInfoQuestionSaga(),
    fetchBrandModelSaga(),
    fetchNtSingleTractorSaga(),
    postNtTractorVerifySaga(),
  ]);
}