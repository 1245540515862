import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import fetchManufacturerStateReducer from "./manufacturerStateReducer";
import fetchPricingOtherDetailReducer from "./pricingOtherDetailReducer";
import fetchPricingReducer from "./pricingDataReducer";
import leadsSubmitReducer from "./leadsSubmitReducer";
import inventorySingleReducer from "./inventorySingleReducer";
import languageReducer from "./languageReducer";
import dealerInventoryReducer from "./dealerInventoryReducer";
import inventoryAllReducer from "./inventoryAllReducer";
import userLocationReducer from "./userLocationReducer";
import airtableCategoryReducer from "./airtableCategoryReducer";
import airtableDynamicTableReducer from "./airtableDynamicTableReducer";
import airtableUserLocationReducer from "./airtableUserLocationReducer";
import buySellFinanceListReducer from "./buySellFinanceListReducer";
import chassisExpenseDataReducer from "./chassisExpenseDataReducer";
import expenseFilterReducer from "./expenseFilterReducer";
import sentOtpReducer from "./sendOtpReducer";
import verifyOtpReducer from "./verifyOtpReducer";
import campaignInventoryReducer from "./campaignInventoryReducer";
import additionalInfoQuestionReducer from "./additionalInfoQuestionReducer";
import invFilterReducer from "./invFilterReducer";
import invSortReducer from "./invSortReducer";
import fetchBrandModelReducer from "./fetchBrandModelReducer";
import fetchNtSingleTractorReducer from "./fetchNtSingleTractorReducer";
import postNtTractorVerifyReducer from "./postNtTractorVerifyReducer";
import faqLanguageReducer from "./faqLanguageReducer";


import { persistReducer } from "redux-persist";
import  storage  from "redux-persist/lib/storage";


const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    languageReducer,
    userLocationReducer
  ]
}

const rootReducer = combineReducers({
  errorReducer,
  fetchManufacturerStateReducer,
  fetchPricingOtherDetailReducer,
  fetchPricingReducer,
  leadsSubmitReducer,
  inventorySingleReducer,
  languageReducer,
  dealerInventoryReducer,
  inventoryAllReducer,
  userLocationReducer,
  airtableUserLocationReducer,
  airtableCategoryReducer,
  airtableDynamicTableReducer,
  buySellFinanceListReducer,
  chassisExpenseDataReducer,
  expenseFilterReducer,
  sentOtpReducer,
  verifyOtpReducer,
  campaignInventoryReducer,
  additionalInfoQuestionReducer,
  invFilterReducer,
  invSortReducer,
  fetchBrandModelReducer,
  fetchNtSingleTractorReducer,
  postNtTractorVerifyReducer,
  faqLanguageReducer
});

export default  persistReducer(persistConfig, rootReducer);