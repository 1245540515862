import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { additionalInfoQuestionRequest } from "../../store/actions/additionalInfoQuestionAction";
import CollapseContainer from "../../components/collapseContent";
import Loader from "../../components/Loader/Loader";
import SpecificationContainer from "../../components/specification";
import { useTranslation } from "react-i18next";

export default function ShowMoreInfo({ queryState }) {
  const { t:common } = useTranslation("common");
  const [showMore, setShowMore] = useState(false);
  const dispatch = useDispatch();
  const addInfoQuestionObj = useSelector(
    (state) => state.additionalInfoQuestionReducer
  );
  const { loading, resQuestion } = addInfoQuestionObj;
  const fixQuestionObj = {
    "Registration (RTO) No": true,"Registration (RTO) No.": true, "Old RTO Number": true
  }

  useEffect(() => {
    dispatch(
      additionalInfoQuestionRequest({
        reqAddInfoQues: "inventory_additional_info_questions",
      })
    );
  }, []);

  let changeButton = "";
  let arrow = "";
  if (showMore) {
    changeButton = common("see_less_information");
    arrow = "arrow_up.svg";
  } else {
    changeButton = common("see_more_information");
    arrow = "arrow_down.svg";
  }

  return loading &&
    typeof resQuestion?.data === "object" &&
    Object.keys(resQuestion?.data).length === 0 ? (
    <Loader />
  ) : (
    <CollapseContainer>
      {showMore && (
        <CollapseContainer.Content>
          <span className="more_info">
            {common("more_information")}
          </span>
          {queryState && (
            <p className="brand_model">
              {queryState?.[`brand`]} {queryState?.[`model`]}
            </p>
          )}
          {/* {(typeof(resQuestion?.data?.value?.sections) === "object") && Object.keys(resQuestion?.data?.value?.sections)?.map((item,index)=> 
                <SectionExpandInfo questionsJson={resQuestion?.data?.value?.sections} data={queryState} sectionKey={item} key={index} />
                )} */}
          {Object.keys(queryState)?.map(
            (question, index) =>
              queryState[question] &&
              queryState[question] !== "" && (
                <SpecificationContainer.TableConatiner key={index}>
                  <SpecificationContainer.TableHeader>
                    <SpecificationContainer.TableHeaderText>
                      {question}
                    </SpecificationContainer.TableHeaderText>
                  </SpecificationContainer.TableHeader>
                  <SpecificationContainer.TableValues>
                    {fixQuestionObj[question] ? (
                      <SpecificationContainer.TableValuesText>
                        {`${(queryState[question] || "").substring(
                          0,
                          4
                        )}*********`}
                      </SpecificationContainer.TableValuesText>
                    ) : (
                      <SpecificationContainer.TableValuesText>
                        {queryState[question]}
                      </SpecificationContainer.TableValuesText>
                    )}
                  </SpecificationContainer.TableValues>
                </SpecificationContainer.TableConatiner>
              )
          )}
        </CollapseContainer.Content>
      )}
      <CollapseContainer.TitleContainer onClick={() => setShowMore(!showMore)}>
        <CollapseContainer.Text>
          {changeButton} <img src={`./assets/arrow/${arrow}`} alt="" />
        </CollapseContainer.Text>
      </CollapseContainer.TitleContainer>
    </CollapseContainer>
  );
}
