import React, { useState } from "react";
import Expenses from "../../components/expensecard";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { expensePagePosition } from "../../store/actions/dealerInventoryAction";
import { dateTime_MWORD, dateTime_MWORD_AMPM } from "../../utils/dateFormat";
import BuyCards from "../../components/buyCard";

const ExpenseInvList = ({ data, viewExpense, addExpense, modalOpen }) => {

  const dealerInvObj = useSelector(state => state.dealerInventoryReducer)
  const { loading, currentPagePosition } = dealerInvObj;
  const filterExpenseObj = useSelector(state => state.expenseFilterReducer);
  const { filteredData,finalOption } = filterExpenseObj;

  //const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const cardStatus = ["Inward","Live","Live","Live","Sold"]
  const cardStatusColor = ["#652278","#64B161","#64B161","#64B161","#DC3F43"]
  const dispatch = useDispatch();

  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const currentItems = data?.sort((a, b) => (b.tractor_id - a.tractor_id))?.slice(
    (currentPagePosition - 1) * itemsPerPage,
    currentPagePosition * itemsPerPage
  );
  const handlePageChange = (newPage) => {
    //setCurrentPage(newPage);
    if(data.length > itemsPerPage){
      dispatch(expensePagePosition({currentPagePosition:newPage}))
    }
  };

  return (
    <Expenses>
    <Expenses.ListTitle style={{display:'flex', alignItems: 'center',justifyContent:'space-between'}}>All Tractors
    <BuyCards.FilterButton style={{color: '#F26F21', fontSize:'16px'}} onClick={() => modalOpen()}><BuyCards.ButtonIcon src="./assets/filter_list_black.svg" alt="filter icon" style={{height:'24px'}} />Filter</BuyCards.FilterButton>
    </Expenses.ListTitle>
    {<BuyCards.FilterContent>
            <BuyCards.Message>{filteredData?.length > 0 ? `After filter showing ${filteredData?.length} ${finalOption ? finalOption : ''} ${filteredData?.length < 2 ? 'tractor' : 'tractors'}.` : `Showing all ${data?.length} ${data?.length < 2 ? 'tractor' : 'tractors'}.`}</BuyCards.Message>
        </BuyCards.FilterContent>}
        <Expenses.Pagination>
        <Expenses.PageCountContainer>
        <Expenses.PageCountLabel>&nbsp;<b>Total Pages: {totalPages}</b></Expenses.PageCountLabel>
        <Expenses.PageButton
          disabled={currentPagePosition === 1}
          onClick={() => handlePageChange(currentPagePosition - 1)}
        >
          <BiChevronLeft style={{height:'30px',width:'30px'}} />
        </Expenses.PageButton>
        <b>{currentPagePosition}</b>
        <Expenses.PageButton
          disabled={currentPagePosition === totalPages}
          onClick={() => handlePageChange(currentPagePosition + 1)}
          style={{backgroundColor:'white', border:'none', height:'30px',width:'30px', marginLeft:'5px'}}
        >
          <BiChevronRight style={{height:'30px',width:'30px'}} />
        </Expenses.PageButton>
        </Expenses.PageCountContainer>
        </Expenses.Pagination>
    {loading ? <Loader /> :  <Expenses.Cards>
        {currentItems?.map((item,index) => 
          <Expenses.Card onClick={() => viewExpense(item)} key={index}>
            <Expenses.CardDetails>
              <Expenses.Header>
                <Expenses.CardLabel style={{backgroundColor:cardStatusColor[item?.["status"]]}}>{cardStatus[item?.["status"]]}</Expenses.CardLabel><span style={{fontSize:'11px',color:"#666",marginLeft:'8px'}}> {dateTime_MWORD(item?.["created_at"])}</span>
                <Expenses.RTONumber>{item?.additional_featureInfo?.["Registration (RTO) No"]}</Expenses.RTONumber>
              </Expenses.Header>
              <Expenses.CardMain>
              <Expenses.CardTitle>{item[`buying_year`]} {item[`brand`]} {item[`model`]}</Expenses.CardTitle>
              <Expenses.ChassisNumber>Chassis No. {item?.chassis_number}</Expenses.ChassisNumber>
              <Expenses.PriceContainer>
                <Expenses.PriceLabel>Price:</Expenses.PriceLabel>
                <Expenses.Price>₹ {item?.max_price}</Expenses.Price>
              </Expenses.PriceContainer>
              </Expenses.CardMain>
              <Expenses.Bottom>
              <Expenses.Time>{item?.expense_data?.expense_last_updated_at && `Last Updated: ${dateTime_MWORD_AMPM(item?.expense_data?.expense_last_updated_at)}`}</Expenses.Time>
                {((!item?.expense_data?.sourcing || !item?.expense_data?.liquidation || !item?.expense_data?.operation)) && <Expenses.Button onClick={(e) => addExpense(e,item)}>
                  <img src="../assets/expense/add.svg" alt="Add icon" />
                  EXPENSE
                </Expenses.Button>}
              </Expenses.Bottom>
            </Expenses.CardDetails>
          </Expenses.Card>)}
        </Expenses.Cards>}
    </Expenses>
  );
};

export default ExpenseInvList;
