import * as types from "../types";
import * as _api from "../../api";
import { call, put, takeLatest } from "redux-saga/effects";
import { inventoryAllFailure, inventoryAllSuccess } from "../actions/inventoryAllAction";
import { clearErrors, returnErrors } from "../actions/errorAction";

function* asyncFetchInventoryAllSaga(){

    try{
        yield put(clearErrors());
        let resInvAll = yield call(_api.fetchAllDealerInventory);
        yield put(inventoryAllSuccess({resInvAll}));
    }catch(e){
        yield put(inventoryAllFailure({error:e}));
        yield put(returnErrors({msg: e.message, status: true}));
    }

}

export default function* fetchInventoryAllSaga(){
    yield takeLatest(types.FETCH_ALL_INVENTORY_DATA_REQUEST,asyncFetchInventoryAllSaga)
}