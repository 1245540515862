import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/header";
import SectionHeader from "../../components/sectionheader";
import AdditionalInformation from "../additionalInformation";
import Gallery from "../gallery";
import { useDispatch, useSelector } from "react-redux";
import { inventorySingleRequest } from "../../store/actions/inventorySingleAction";
import Loader from "../../components/Loader/Loader";
import ProductDetailContainer from "../../components/productDetail";
import BuyCards from "../../components/buyCard";
import CampaignForm from "./campaignForm";
import {leadsSubmitReset } from "../../store/actions/leadsSubmitAction";
import { sendOtpReset } from "../../store/actions/sendOtpAction";
import { verifyOtpReset } from "../../store/actions/verifyOtpAction";
import { useTranslation } from "react-i18next";
import { convertToUppercase } from '../../utils/convertToLowerUppercase';


export default function CampaignProductDetail({...props}){
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const invSingleDataObj = useSelector(state => state.inventorySingleReducer);
    const { loading, resSinleInv } = invSingleDataObj
    const queryState = resSinleInv?.data;
    const lang = sessionStorage.getItem("lang");
    const { t: landingPageLang, i18n } = useTranslation('landingPage');
    
    const tractor_id = queryParams.get("tractor_id");
    const navigate = useNavigate();

    useEffect(()=>{
        dispatch(inventorySingleRequest({reqSinleInv:{tractor_id}}))
        i18n.changeLanguage(convertToUppercase(lang));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tractor_id])

    const backButton = () => {
        dispatch(leadsSubmitReset());
        dispatch(sendOtpReset());
        dispatch(verifyOtpReset());
        navigate(`/lpsv2/`);
    }

    return(
        <div>
            <Header>
                <Header.BackButtonContainer>
                    <Header.BackIcon src="../assets/arrow_back.svg" alt="" onClick={backButton} />
                    <Header.ButtonText>{landingPageLang("back")}</Header.ButtonText>
                </Header.BackButtonContainer>
            </Header>
            { loading ?  <Loader /> :
            
            (queryState && typeof(queryState) === "object" && Object.keys(queryState).length > 0) ?
            <>
            <ProductDetailContainer>
                {(typeof(queryState[`image_links`]) === "object" && Object.keys(queryState[`image_links`])?.length > 0) && <Gallery tractor_id={tractor_id} productFilterdata={queryState} />}
                <SectionHeader>
                <SectionHeader.Title style={{marginBottom:'5px'}}>
                {queryState[`brand`]} {queryState[`model`]}
                {queryState?.["rating"] > 0 && 
                            <BuyCards.Star src={`../assets/staricon/${queryState?.["rating"]} star.svg`} alt={`${queryState?.["rating"]} star`} />
                }
                </SectionHeader.Title>
                </SectionHeader>
                {queryState.user_location && 
                <ProductDetailContainer.LocationLabel>
                <ProductDetailContainer.LocationText><ProductDetailContainer.LocationIcon src="../assets/location_icon.svg" alt="location icon" /> {queryState.user_location}</ProductDetailContainer.LocationText>
                </ProductDetailContainer.LocationLabel>
                }
                <ProductDetailContainer.ProductContents>
                    <ProductDetailContainer.PriceText>₹ {queryState["max_price"]}</ProductDetailContainer.PriceText>
                        <ProductDetailContainer.FeatureContainer> 
                            <ProductDetailContainer.FeatureDetails>
                            <ProductDetailContainer.FeatureIcon src="../assets/certify/battery.svg" alt="" />
                            {queryState[`is_battery_branded`] && <ProductDetailContainer.FeatureText1>{queryState[`is_battery_branded`] ? 'Branded' : ''}</ProductDetailContainer.FeatureText1>}
                            <ProductDetailContainer.FeatureText2>{queryState[`battery`] ? 'Available' : 'Not Available'}</ProductDetailContainer.FeatureText2>
                            <ProductDetailContainer.FeatureLabel>Battery</ProductDetailContainer.FeatureLabel>
                            </ProductDetailContainer.FeatureDetails>
                            <ProductDetailContainer.FeatureDetails>
                            <ProductDetailContainer.FeatureIcon src="../assets/certify/buying_year.svg" alt="" />
                            <ProductDetailContainer.FeatureText2><b>{queryState[`buying_year`]}</b></ProductDetailContainer.FeatureText2>
                            <ProductDetailContainer.FeatureLabel>Year</ProductDetailContainer.FeatureLabel>
                            </ProductDetailContainer.FeatureDetails>
                            <ProductDetailContainer.FeatureDetails>
                            <ProductDetailContainer.FeatureIcon src="../assets/certify/engine_hours.svg" alt="" />
                            <ProductDetailContainer.FeatureText2>{queryState[`engine_hours`] === -1 ? "NA" : `${queryState[`engine_hours`]} Hrs`}</ProductDetailContainer.FeatureText2>
                            {queryState[`engine_condition`] && <ProductDetailContainer.FeatureLabel>{queryState[`engine_condition`]}</ProductDetailContainer.FeatureLabel>}
                            </ProductDetailContainer.FeatureDetails>
                            <ProductDetailContainer.FeatureDetails>
                            <ProductDetailContainer.FeatureIcon src="../assets/certify/engine_power.svg" alt="" />
                            <ProductDetailContainer.FeatureText2>{queryState[`engine_power`] === -1 ? "NA" : queryState[`engine_power`] }</ProductDetailContainer.FeatureText2>
                            <ProductDetailContainer.FeatureLabel>Engine Power</ProductDetailContainer.FeatureLabel>
                            </ProductDetailContainer.FeatureDetails>
                            <ProductDetailContainer.FeatureDetails>
                            <ProductDetailContainer.FeatureIcon src="../assets/certify/tyre_condition.svg" alt="" />
                            <ProductDetailContainer.FeatureText2>{
                            (queryState[`tyre_condition`] === "Original") ?
                                (queryState[`is_tyre_brand_mrf`]) ? "MRF Original" : "Original"
                            :    
                            queryState[`tyre_condition`]
                            }
                            </ProductDetailContainer.FeatureText2>
                            <ProductDetailContainer.FeatureLabel>{queryState[`tyre_condition`] === "Original" ? queryState[`tyre_state`] : "Tyre Condition"}</ProductDetailContainer.FeatureLabel>
                            </ProductDetailContainer.FeatureDetails>
                            <ProductDetailContainer.FeatureDetails>
                            <ProductDetailContainer.FeatureIcon src="../assets/certify/Finance.svg" alt="" />
                            <ProductDetailContainer.FeatureText2>{queryState[`finance`] === "0%" ? 'NA' : `Upto* ${queryState[`finance`]}`}</ProductDetailContainer.FeatureText2>
                            <ProductDetailContainer.FeatureLabel>Finance</ProductDetailContainer.FeatureLabel>
                            </ProductDetailContainer.FeatureDetails>
                    </ProductDetailContainer.FeatureContainer>
                    </ProductDetailContainer.ProductContents> 
            </ProductDetailContainer>
            <AdditionalInformation queryState={queryState} lang={lang} />   
            <div style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", margin:"10px"}}>
                <div style={{display: "flex", justifyContent: 'center',alignItems:"center",marginBottom:'20px'}}>          
                    <h3 style={{textAlign:"center",color:"#652078",fontSize:"26px"}}>{landingPageLang('form_header')}</h3>
                </div>
                <CampaignForm/>
            </div>
            </>
            : <p>No Data Found</p>  
        }
        </div>
    )
}