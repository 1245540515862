import * as types from "../types";

export const fetchManufacturerStateRequest = () => ({
  type: types.FETCH_MANUFACTURER_AND_STATE_REQUEST,
});

export const fetchManufacturerStateSuccess = ({ manufacturerState }) => ({
  type: types.FETCH_MANUFACTURER_AND_STATE_SUCCESS,
  payload: {
    manufacturerState,
  },
});

export const fetchManufacturerStateFailure = ({ error }) => ({
  type: types.FETCH_MANUFACTURER_AND_STATE_FAILURE,
  payload: {
    error: error.message,
  },
});