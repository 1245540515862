import  i18n  from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

i18n
.use(HttpBackend)
.use(initReactI18next)
.init({
    react: {
        useSuspense: false
    },
    backend:{
        loadPath: '/Lang/{{lng}}/{{ns}}.json',
    },
    lng: "MR",
    fallbackLng: 'MR',
    interpolation: {
        escapeValue: false,
    },
})

export default i18n;

//debug:true
// onLoad: (languages, namespaces, xhr) => {
//     console.warn('Loaded languages', languages);
//     console.warn('Loaded namespaces', namespaces);
//     console.warn('Loaded data via xhr', xhr);
// },