import * as types from "../types";

export const airtableUserLocationRequest = ({languageCode}) => ({
    type: types.FETCH_AIRTABLE_USER_LOCATION_REQUEST,
    payload:{
        languageCode
    }
})

export const airtableUserLocationSuccess = ({resATLocation}) => ({
    type: types.FETCH_AIRTABLE_USER_LOCATION_SUCCESS,
    payload:{
        resATLocation
    }
})

export const airtableUserLocationFailure = ({error}) => ({
    type: types.FETCH_AIRTABLE_USER_LOCATION_FAILURE,
    payload:{
        error: error.message
    }
})

export const airtableUserLocationReset = () => ({
    type: types.FETCH_AIRTABLE_USER_LOCATION_RESET,
})