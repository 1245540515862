import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const CardImage = styled.img`
  width: 100%;
  min-height: 170px;
  max-height: 170px;
  @media (min-width: 425px) {
    object-fit: contain;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr;
  @media (min-width: 768px) {
    display: flex;
    overflow: scroll;
  }
`;


export const Contact = styled.a`
  text-decoration: none;
`;