import styled from "styled-components";

export const Container = styled.div`
width: 100%;
height: auto;
margin-bottom: 20px;
`;

export const ImageDisplayContainer = styled.div`
  width: 100%;  
  height: 328px;
  position: relative;
  margin-bottom: 8px;
  border-radius: 4px;
`;

export const ImageDisplay = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const DiscountTag = styled.div`
    width: auto;
    height: 24px;
    padding: 3px 9px 5px 9px;
    background: #DB0000 0% 0% no-repeat padding-box;
    border-radius: 4px 0px;
    position: absolute;
`;

export const DiscountValue = styled.p`
    font-size: 12px;
    letter-spacing: 0px;
    color: #FFFFFF;
`;

export const ImageDisplayArrow = styled.img`
  position: absolute;
  z-index: 2;
  top: 45%;
  left: ${(props) => props.direction === "left" && "2%"};
  right: ${(props) => props.direction === "right" && "2%"};
  pointer: cursor;
  width: 36px;
  height: 36px;
  transform: rotate(${(props) => props.direction === "left" && "180deg"});
`;

export const SelectImagesContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  gap: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
`;

export const SelectImageContainer = styled.div`
  width: 25%;
  height: 74px;
  border-radius: 4px;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  opacity: 1;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  /* border: 2px solid #f46d19; */
  border-width: {
    ${(props) => (props.open ? "2" : "1")}px;
  }
  display: flex;
  justify-content: center;
  align-items:center;
  box-shadow: 0px 3px 4px #00000029;  
  ${props =>  (props.color==="#F37021") ? `border: 2px solid #F37021;`:""}

`;

export const SelectImage = styled.img`
  width: 74px;
  height: 74px;
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
`;