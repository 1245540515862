import React, { useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../components/header"
import Loader from "../components/Loader/Loader";
import useFetch from "../utils/useFetch";
import './ourteam.css'
import { useDispatch, useSelector } from "react-redux";
import Section from "../components/section";
import BuyCards from "../components/buyCard";
import SectionHeader from "../components/sectionheader";
import BottomHighlightLine from "../components/bottomhighlight";
import { useEffect } from "react";
import { inventoryRequest } from "../store/actions/dealerInventoryAction";
import urlContain from "../utils/urlContain";
import { setLanguage } from "../store/actions/languageAction";
import { useTranslation } from 'react-i18next';
import { useChangLanguage } from "../utils/useChangLanguage";
import { convertToLowercase, convertToUppercase } from "../utils/convertToLowerUppercase";


export default function AboutUSContainer(){
    const { t:common } = useTranslation("common");
    const location = useLocation();
    const [userId, setUserId] = useState("");
    const queryParams = new URLSearchParams(location.search);
    const languageObj = useSelector(state => state.languageReducer);
    const { languageCode } = languageObj;
    const lang = convertToUppercase(queryParams.get("lang") || languageCode);
    const { dealer_location } = useParams();
    const dispatch = useDispatch();
    const dealerInvObj = useSelector(state => state.dealerInventoryReducer)
    const { loading, resInvData } = dealerInvObj;

    const [ handleChangeLanguage ] = useChangLanguage();
    const [posterData, posterFetching, posterError] = useFetch({
        tableName: "tractor",
        fields: [
          `Title_Poster(${lang})`,
          'Dealer Location',
          'user_id'
      ],
        maxRecords: 30,
        filterByFormula: "",
    });


    const [fetchData, fetchFetching, fetchError] = useFetch({
        tableName: "About us",
        fields: [
          `Title(${lang})`,
          `Image`,
          `contact us`,
          `Add(${lang})`,
          `add_google_link`,
          `click_here_text(${lang})`,
          `title_text(${lang})`,
          `certified_line1(${lang})`,
          `certified_line2(${lang})`,
          `certified_line3(${lang})`,
          `email_id`,
          `team_text(${lang})`,
          `team_person_name(${lang})`, 
          `team_person_contact`, 
          `team_person_email_id`, 
          `team_person_designation(${lang})`, 
          `team_person_photo`,
          `user_id`,
          `Dealer Location`
      ],
        maxRecords: 100,
        filterByFormula: "",
    });

    let navigate = useNavigate();
    const backButton = () => {
        navigate(`/`)
    }    

    useEffect(() => {
        let useridVar = userId;
        posterData?.filter((postFilter) => {convertToLowercase(postFilter["Dealer Location"]) === convertToLowercase(dealer_location) && (useridVar = postFilter["user_id"])})
        setUserId(useridVar);
    },[posterData])

    useEffect(() => {
        (userId && userId !== "") && dispatch(inventoryRequest({reqInv:{user_id:userId}}))
    },[userId])

    useEffect(() => {
         if(lang && lang !== "") {
            handleChangeLanguage(lang);
            dispatch(setLanguage({languageCode:lang}));
         }  
    },[lang])
    
    // const location = {
    //     address: '1600 Amphitheatre Parkway, Mountain View, california.',
    //     lat: 37.42216,
    //     lng: -122.08427,
    // }

    const handleNav = (tractor_id,prodType) => {
        navigate(`/productDetails?lang=${lang}&type=${prodType}&pagetype=dealer&id=${userId}&dealer_location=${dealer_location}&tractor_id=${tractor_id}`)
    }
    

    return (
        <>
        <Header>
            <Header.BackButtonContainer>
                <Header.BackIcon src="../assets/arrow_back.svg" alt="" onClick={backButton} />
                <Header.ButtonText>{common("back_to_home")}</Header.ButtonText>
            </Header.BackButtonContainer>
        </Header>
{ (posterFetching || fetchFetching) ? <Loader/>  :  <div>
    {fetchData?.map((item,index) => 
        (userId && userId === item?.user_id) &&
        <div key={index}>
         <img className="title_img" src={item?.[`Image`]?.[0]?.url} alt="" />
         <div style={{ display:'flex',flexDirection:'column', background: '#FFFFFF 0% 0% no-repeat padding-box',padding:'10px', gap: '10px',minHeight:'600px'}}>
         <h1>{item?.[`Title(${lang})`]}</h1>
         <div style={{fontWeight: '700',fontSize:'16px'}}>
         <p style={{margin:'10px 0',fontWeight:'500'}}>{item?.[`title_text(${lang})`]}</p>
             <div style={{display:'flex',alignItems:'center',gap:'10px',marginBottom:'10px'}}>
                 <img src="../assets/agreement.svg" alt="" />
                 <p>{item?.[`certified_line1(${lang})`]}</p>
             </div>
             <div style={{display:'flex',alignItems:'center',gap:'10px',marginBottom:'10px'}}>
             <img src="../assets/certificate.svg" alt="" />
             <p>{item?.[`certified_line2(${lang})`]}</p>
             </div>
             <div style={{display:'flex',alignItems:'center',gap:'10px',marginBottom:'10px'}}>
             <img src="../assets/finance.svg" alt="" />
             <p>{item?.[`certified_line3(${lang})`]}</p>
             </div>
         </div>
         <div>
             <p><b>{common("contact_us")}:</b><a style={{  textDecoration:'none',fontSize:'17px'}} href={`  tel: ${item?.[`contact us`]}`}><b> {item?.[`contact us`]}</b></a></p>
         </div>
         <div>
             <span><b>{common("address")}: </b></span>
             <span>{item?.[`Add(${lang})`]}</span>
             <p><a style={{ textDecoration:'none',fontSize:'14px'}} href={item?.[`add_google_link`]}>{item?.[`click_here_text(${lang})`]}:</a></p>
         </div>
         <h2 style={{textAlign:'center'}}>{item?.[`team_text(${lang})`]}</h2>
         <div className="row">
         <div className="column">
             <div className="card">
             <img src={item?.[`team_person_photo`]?.[0]?.url} alt={item?.[`team_person_name(${lang})`]} style={{width:"100%",height:'204px',objectFit: "contain"}} />
             <div className="container">
                 <h2>{item?.[`team_person_name(${lang})`]}</h2>
                 <p className="title" style={{marginBottom:'3px'}}>{item?.[`team_person_designation(${lang})`]}</p>
                 <p  style={{marginBottom:'3px'}}>{item?.[`team_person_email_id`]}</p>
                 <p  style={{marginBottom:'3px'}}><a style={{  textDecoration:'none',color:'#000'}} href={`  tel: ${item?.[`team_person_contact`]}`}>{item?.[`team_person_contact`]}</a></p>
             </div>
             </div>
         </div>
         </div>
         </div>
         </div>
    )
}
          {loading ? <Loader /> : 
        (resInvData?.data?.filter((item) => (item.status === 1 || item.status === 2 || item.status === 3 ) && item).length > 0 && userId && userId !== "") && <>
        <Section>
        <SectionHeader>
        <SectionHeader.Title>
        {common("live_inventory")}
        </SectionHeader.Title>
        <BottomHighlightLine></BottomHighlightLine>
        </SectionHeader>
        </Section>
            <BuyCards>
         {resInvData?.data?.sort((a, b) => (b.tractor_id - a.tractor_id))?.map((item,index) => 
         (item.status === 1 || item.status === 2 || item.status === 3) &&
            <BuyCards.Card key={index} onClick={() => handleNav(item[`tractor_id`],"inventory")}>
                    {(typeof(item[`image_links`]) === "object" && Object.keys(item[`image_links`])?.length > 0) ? <BuyCards.Image src={urlContain(item[`image_links`]?.[0]?.["image"])} alt="" /> : <BuyCards.Image src="../assets/no_image_available.png" alt="" />}
                     <BuyCards.InfoContainer>
                     <BuyCards.Title>{item["brand"]}&nbsp;{item["model"]}</BuyCards.Title>
                     {/* <BuyCards.InfoText>Brand: {item["brand"]}
                     </BuyCards.InfoText> */}
                     <BuyCards.Price>
                        <b>₹ {item["max_price"]}</b>
                        {item["rating"] > 0 && 
                            <BuyCards.Star src={`../assets/staricon/${item["rating"]} star.svg`} alt={`${item["rating"]} star`} />
                        }
                     </BuyCards.Price>
                     <BuyCards.SpecificationContainer>
                        <BuyCards.Specification>
                            <BuyCards.Icon src="../assets/certify/engine_hours.svg" alt="" />
                            <BuyCards.SpecificationValue><BuyCards.SpecificationLabel>Hrs</BuyCards.SpecificationLabel>{item[`engine_hours`] === -1 ? "NA" : `${item[`engine_hours`]}`}</BuyCards.SpecificationValue>
                        </BuyCards.Specification>
                        <BuyCards.Specification>
                            <BuyCards.Icon src="../assets/certify/engine_power.svg" alt="" />
                            <BuyCards.SpecificationValue><BuyCards.SpecificationLabel>Power</BuyCards.SpecificationLabel>{item[`engine_power`] === -1 ? "NA" : `${item[`engine_power`]}`}</BuyCards.SpecificationValue>
                        </BuyCards.Specification>
                        <BuyCards.Specification>
                            <BuyCards.Icon src="../assets/certify/tyre_condition.svg" alt="" />
                            <BuyCards.SpecificationValue><BuyCards.SpecificationLabel>Tyre</BuyCards.SpecificationLabel>{item[`tyre_condition`] === "Original" ? "Original" : item[`tyre_condition`] }</BuyCards.SpecificationValue>
                        </BuyCards.Specification>
                    </BuyCards.SpecificationContainer>
                    <BuyCards.ButtonContainer>
                    <BuyCards.RTONumber>
                    {
                        item?.additional_featureInfo?.["Registration (RTO) No."] ||
                        item?.additional_featureInfo?.["Registration (RTO) No"]
                    }
                    </BuyCards.RTONumber>
                    <BuyCards.Button>{common("see_details")} <img src="../assets/arrow/right_arrow_black.svg" alt="right arrow" /></BuyCards.Button>
                    </BuyCards.ButtonContainer>
                     </BuyCards.InfoContainer>
             </BuyCards.Card>
             )}
         </BuyCards>
         </> 
         }
        </div>}  
         
        {(!posterFetching && !loading && (fetchData?.filter((item,index) => (userId && userId === item?.user_id) && item) || '').length === 0 &&  (resInvData?.data || '').length === 0) && 
        <div style={{display:'flex',justifyContent:'center'}}>
            No Data Found
        </div>}  
        </>
    )
}