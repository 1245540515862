import * as types from "../types";
import * as _api from "../../api";
import { call, put, takeLatest } from "redux-saga/effects";
import { clearErrors, returnErrors } from "../actions/errorAction";
import { inventoryFailure, inventorySuccess } from "../actions/dealerInventoryAction";

function* asyncfetchDealerInvtorySaga(action){
    try{
        yield put(clearErrors());
        let resInvData = yield call(_api.fetchDealerInventory,{
            reqInv: action.payload.reqInv
        })
        yield put(inventorySuccess({resInvData}));
    }catch(e){
        yield put(inventoryFailure({error:e}));
        yield put(returnErrors({msg: e.message, status: true}));
    }   
}

export default function* fetchDealerInventorySaga(){
    yield takeLatest(types.FETCH_DEALER_INVENTORY_DATA_REQUEST, asyncfetchDealerInvtorySaga)
}