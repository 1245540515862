import * as types from "../types";

export const fetchBrandModelRequest = () => ({
    type: types.FETCH_BRAND_MODEL_REQUEST,
})

export const fetchBrandModelSuccess = ({resBrandModel}) => ({
    type: types.FETCH_BRAND_MODEL_SUCCESS,
    payload: {
        resBrandModel
    }
})

export const fetchBrandModelFailure = ({error}) => ({
    type: types.FETCH_BRAND_MODEL_FAILURE,
    payload: {
        error: error.message
    }
})