import React from "react";
import {
  Container,
  Card,
  DiscountTag,
  DiscountValue,
  Title,
  InfoText,
  Price,
  Star,
  SpecificationContainer,
  Specification,
  Icon,
  SpecificationLabel,
  SpecificationValue,
  ButtonContainer,
  Button,
  RTONumber,
  InfoContainer,
  TitleTooltip,
  TitleContent,
  Image,
  FilterContainer,
  FilterButton,
  ButtonIcon,
  FilterContent,
  Message,
  LoadMore
} from "./styles/buyCard";

export default function BuyCards({ children, ...props }) {
    return <Container {...props}>{children}</Container>;
}

BuyCards.Card = function BuyCardsCard({ children, ...props }) {
  //console.log(props)
  return <Card {...props}>{children}</Card>;
};

BuyCards.Image = function BuyCardsImage({ ...props }) {
  //console.log(props)
  return <Image {...props} />;
};

BuyCards.DiscountTag = function BuyCardsDiscountTag({ children, ...props }) {
  return <DiscountTag {...props}>{children}</DiscountTag>;
};

BuyCards.DiscountValue = function BuyCardsDiscountValue({
  children,
  ...props
}) {
  return <DiscountValue {...props}>{children}</DiscountValue>;
};

BuyCards.InfoContainer = function BuyCardsInfoContainer({
  children,
  ...props
}) {
  return <InfoContainer {...props}>{children}</InfoContainer>;
};

BuyCards.Title = function BuyCardsTitle({ children, ...props }) {
  return <Title {...props}>{children}</Title>;
};

BuyCards.TitleContent = function BuyCardsTitleContent({ children, ...props }) {
  return <TitleContent {...props}>{children}</TitleContent>;
};

BuyCards.TitleTooltip = function BuyCardsTitleTooltip({ children, ...props }) {
  return <TitleTooltip {...props}>{children}</TitleTooltip>;
};

BuyCards.InfoText = function BuyCardsInfoText({ children, ...props }) {
  return <InfoText {...props}>{children}</InfoText>;
};

BuyCards.Price = function BuyCardsPrice({ children, ...props }) {
  return <Price {...props}>{children}</Price>;
};

BuyCards.Star = function BuyCardsStar({ children, ...props }) {
  return <Star {...props}>{children}</Star>;
};
 
BuyCards.SpecificationContainer = function BuyCardsSpecificationContainer({ children, ...props }) {
  return <SpecificationContainer {...props}>{children}</SpecificationContainer>;
};

BuyCards.Specification = function BuyCardsSpecification({ children, ...props }) {
  return <Specification {...props}>{children}</Specification>;
};

BuyCards.Icon = function BuyCardsIcon({ ...props }) {
  return <Icon {...props} />;
};

BuyCards.SpecificationLabel = function BuyCardsSpecificationLabel({ children, ...props }) {
  return <SpecificationLabel {...props}>{children}</SpecificationLabel>;
};

BuyCards.SpecificationValue = function BuyCardsSpecificationValue({ children, ...props }) {
  return <SpecificationValue {...props}>{children}</SpecificationValue>;
};

BuyCards.ButtonContainer = function BuyCardsButtonContainer({ children, ...props }) {
  return <ButtonContainer {...props}>{children}</ButtonContainer>;
};

BuyCards.Button = function BuyCardsButton({ children, ...props }) {
  return <Button {...props}>{children}</Button>;
};

BuyCards.RTONumber = function BuyCardsRTONumber({ children, ...props }) {
  return <RTONumber {...props}>{children}</RTONumber>;
};

BuyCards.FilterContainer = function BuyCardsFilterContainer({
  children,
  ...props
}) {
  return <FilterContainer {...props}>{children}</FilterContainer>;
};

BuyCards.FilterButton = function BuyCardsFilterButton({ children, ...props }) {
  return <FilterButton {...props}>{children}</FilterButton>;
};

BuyCards.ButtonIcon = function BuyCardsButtonIcon({ ...props }) {
  return <ButtonIcon {...props} />;
};

BuyCards.FilterContent = function BuyCardsFilterContent({
  children,
  ...props
}) {
  return <FilterContent {...props}>{children}</FilterContent>;
};

BuyCards.Message = function BuyCardsMessage({ children, ...props }) {
  return <Message {...props}>{children}</Message>;
};

BuyCards.LoadMore = function BuyCardsLoadMore({ children, ...props }) {
  return <LoadMore {...props}>{children}</LoadMore>;
};
