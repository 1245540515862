import axios from "axios";
import { API_BASE_URL,API_ROUTES } from "../config";

export const fetchManufacturerState = async () => {
  try {
    const res = await axios({
      method: "get",
      url: `${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_PRICING_MANUFACTURER}`,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPricingOtherDetail = async ({ requestPricingOtherDetail }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_PRICING_OTHER_DATA}`,
      data: requestPricingOtherDetail
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchPricing = async ({ requestPrice }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_PRICING_DATA}`,
      data: requestPrice
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const submitLeadsApi = async ({ requestLeads }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.BASE_URL}${API_ROUTES.LEADS_CAPTURE}`,
      data: requestLeads
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchInventorySingle = async ({ reqSinleInv }) => {
  try {
    const res = await axios({
      method: "get",
      url: `${API_BASE_URL.BASE_URL}${API_ROUTES.INVENTORY_SINGLE_ROUTE}${reqSinleInv.tractor_id}`,
      headers:{ 'Content-Type': 'application/json' }
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};      

export const fetchDealerInventory = async ({reqInv}) => {
        try {
          const res = await axios({
            method: "get",
            url: `${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_DEALER_INVENTORY_ROUTE}`,
            headers:{ 'Content-Type': 'application/json','User-Id':`${reqInv.user_id}` }
          });
          return res.data;
        } catch (error) {
          throw error;
        }
};

export const fetchAllDealerInventory = async () => {
  try {
    const res = await axios({
        method: "get",
        url: `${API_BASE_URL.BASE_URL}${API_ROUTES.ALL_DEALER_INVENTORY_ROUTE}`,
        headers:{ 'Content-Type': 'application/json'}
    });
    return res.data;
  }catch(error){
    throw error;
  }
}

export const fetchUserLocation = async () => {
  try {
    const res = await axios({
        method: "get",
        url: `${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_USER_LOCATION_ROUTE}`,
        headers:{ 'Content-Type': 'application/json'}
    });
    return res.data;
  }catch(error){
    throw error;
  }
}

export const fetchBuySellFinanceList = async ({key}) => {
  try {
    const res = await axios({
        method: "get",
        url: `${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_BUYSELL_FINANCE_LIST_ROUTE}?key=${key}`,
        headers:{ 'Content-Type': 'application/json'}
    });
    return res.data;
  }catch(error){
    throw error;
  }
}

export const fetchExepnseChassisData = async ({reqChassis}) => {
  try {
    const res = await axios({
        method: "get",
        url: `${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_EXPENSE_CHASSIS_DATA_ROUTE}${reqChassis}`,
        headers:{ 'Content-Type': 'application/json'}
    });
    return res.data;
  }catch(error){
    throw error;
  }
}

export const sendOtp = async ({ requestOtp }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.BASE_URL}${API_ROUTES.SEND_OTP_REQUEST}`,
      data: requestOtp
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const verifyOtpData = async ({ verifyOtp }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.BASE_URL}${API_ROUTES.VERIFY_OTP_REQUEST}`,
      data: verifyOtp
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchCampaignInventory = async ({reqCampaignInv}) => {
        try {
          const res = await axios({
            method: "get",
            url: `${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_DEALER_INVENTORY_ROUTE}`,
            headers:{ 'Content-Type': 'application/json','User-Id':`${reqCampaignInv.user_id}` }
          });
          return res.data;
        } catch (error) {
          throw error;
        }
};

export const fetchAdditionalInfoFeature = async ({reqAddInfoQues}) => {
  try {
    const res = await axios({
      method: "get",
      url: `${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_ADDITIONAL_INFO_QUESTION_JSON}${reqAddInfoQues}`,
      headers:{ 'Content-Type': 'application/json'}
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const fetchBrandModelApi = async () => {
  try {
    const res = await axios({
      method: "get",
      url: `${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_BRAND_MODEL}`,
      headers:{ 'Content-Type': 'application/json'}
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const fetchNTSingleTractorApi = async ({ reqTractorId }) => {
  try {
    const res = await axios({
      method: "get",
      url: `${API_BASE_URL.BASE_URL}${API_ROUTES.FETCH_SINGLE_NT_TRACTOR_ROUTE}${reqTractorId}`,
      headers:{ 'Content-Type': 'application/json' }
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};    

export const postNTVerifyTractorApi = async ({ postNtStatus }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.BASE_URL}${API_ROUTES.POST_NT_TRACTOR_ID_VERIFY_ROUTE}`,
      data: postNtStatus
    });
    return data;
  } catch (error) {
    throw error;
  }
};

