 // Import the functions you need from the SDKs you need
 import { initializeApp } from "firebase/app";
 import { getDatabase } from "firebase/database";
 // TODO: Add SDKs for Firebase products that you want to use
 // https://firebase.google.com/docs/web/setup#available-libraries

 // Your web app's Firebase configuration
 const firebaseConfig = {
  apiKey: "AIzaSyBmGRDhT5xr0PMOQkpvXiMTUF7cMnXsxSA",
  authDomain: "used-tractors.firebaseapp.com",
  databaseURL: "https://used-tractors-staging.firebaseio.com/",
  projectId: "used-tractors",
  storageBucket: "used-tractors.appspot.com",
  messagingSenderId: "631695707144",
  appId: "1:631695707144:android:e12123b1df3415325055bd"
};;

 // Initialize Firebase
 const app = initializeApp(firebaseConfig);
 const db = getDatabase(app);

 export default db;