import React from "react";
import BottomHighlight from "../../components/bottomhighlight";
import SectionHeader from "../../components/sectionheader";
import Section from "../../components/section";
import useFetch from "../../utils/useFetch";
import Loader from "../../components/Loader/Loader";
import Poster from "../../components/poster";
import { useSelector } from "react-redux";
import { desktopView, mobileView } from "../viewType";


export default function OfferSection() {
  const languageObj = useSelector(state => state.languageReducer);
  const { languageCode } = languageObj;
  const lang = languageCode.toUpperCase();
  const [offersData, offersFetching, offersError] = useFetch({
    tableName: "Offer",
    fields: [
      `offer_headline(${lang})`,
      "offer_title",
      "offer_product_name",
      "offer_image",
      "offer_amount",
      `Desktop_Banner(${lang})`,
      `banner(${lang})`
    ],
    filterByFormula: "",
  });

  return (
    (offersFetching ? 
      <div style={{ display:'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Loader />
      </div>
     : 
    (offersData?.filter((filterData) => filterData.hasOwnProperty(`banner(${lang})`) && filterData).length > 0 && <Section>
      <SectionHeader>
        <SectionHeader.Title>
          {offersData[0][`offer_headline(${lang})`]}
        </SectionHeader.Title>
        <BottomHighlight></BottomHighlight>
      </SectionHeader>
      {/* <OfferCard>
        {offersData.map(
          (item, index) =>
            Object.keys(item).length > 0 && (
              <OfferCard.Card key={index}>
                <OfferCard.Circle className="circle"></OfferCard.Circle>
                {item.hasOwnProperty("offer_title") && (
                  <OfferCard.Title>{item.offer_title}</OfferCard.Title>
                )}
                {item.hasOwnProperty("offer_amount") && (
                  <OfferCard.Amount>
                    <FaRupeeSign />
                    {item.offer_amount}
                  </OfferCard.Amount>
                )}
                <OfferCard.UnderLine></OfferCard.UnderLine>
                {item.hasOwnProperty("offer_product_name") && (
                  <OfferCard.ProdName>
                    {item.offer_product_name}
                  </OfferCard.ProdName>
                )}
                {item.hasOwnProperty("offer_image") && (
                  <OfferCard.Image src={item.offer_image[0].url} />
                )}
              </OfferCard.Card>
            )
        )}
      </OfferCard> */}
        <div style={{ display: "flex", overflow: "scroll" }}>
            {<Poster.GridContainer>
              {offersData?.map((item,index) =>{ 
                if(item.hasOwnProperty(`Desktop_Banner(${lang})`) && desktopView){
                return <Poster.CardImage key={index} src={item?.[`Desktop_Banner(${lang})`][0].url} />
                }
                if(item.hasOwnProperty(`banner(${lang})`) && mobileView){
                return <Poster.CardImage key={index} src={item?.[`banner(${lang})`][0].url} />
                }
                })}
            </Poster.GridContainer>}
        </div>
    </Section>)
    )
  );
}