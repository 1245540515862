import { takeLatest, call, put } from "redux-saga/effects";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";
import { submitNtTractorVerifyFailure, submitNtTractorVerifySuccess } from "../actions/postNtTractorVerifyAction";

function* asyncPostNtTractorVerifySaga(action) {
  try {
    yield put(clearErrors());
    let resNtverify = yield call(_api.postNTVerifyTractorApi, {
        postNtStatus: action.payload.postNtStatus,
    });
    yield put(submitNtTractorVerifySuccess({ resNtverify }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(submitNtTractorVerifyFailure({ error: e }));
  }
}
export default function* postNtTractorVerifySaga() {
  yield takeLatest(types.POST_NT_TRACTOR_ID_VERIFY_REQUEST, asyncPostNtTractorVerifySaga);
}