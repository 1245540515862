import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchPricingSuccess,
  fetchPricingFailure,
} from "../actions/pricingDataAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchPricingSaga(action) {
  try {
    yield put(clearErrors());
    let resPrice = yield call(_api.fetchPricing, {
      requestPrice: action.payload.requestPrice,
    });
    yield put(fetchPricingSuccess({ resPrice }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchPricingFailure({ error: e }));
  }
}
export default function* fetchPricingSaga() {
  yield takeLatest(types.FETCH_PRICING_DATA_REQUEST, asyncFetchPricingSaga);
}