import React from "react";
import SpecificationContainer from "../../../components/specification";
import { dateTime_MWORD_AMPM } from "../../../utils/dateFormat";
import { BiRupee } from "react-icons/bi";
import dateFormatDDMMYYYY from "../../../utils/dateFormatDDMMYYYY";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../utils/useQuery";

export default function ExpenseLiquidationView({ value }) {
  const queryParams = useQuery();
  const userid = queryParams.get("userid");
  const brand = queryParams.get("brand");
  const model = queryParams.get("model");
  const year = queryParams.get("year");
  const rto = queryParams.get("rto");


  const allData = value;
  let dateTime = value?.["updated_at"];
  value = value?.["form_data"];

  const headerFontSize = "12px";
  const valueFontSize = "12px";
  const bgColor = "#F1E5F1";

  let navigate = useNavigate();
  const editTo = () => {
    navigate(`/edit-expense-tracker?userid=${userid}&brand=${brand}&model=${model}&year=${year}&rto=${rto}`, {
      state: { expense_id: allData.id, formType: allData.form_type },
    });
  };

  return (
    <SpecificationContainer style={{ marginTop: "24px" }}>
      <SpecificationContainer.TableConatiner>
        <SpecificationContainer.TableHeader
          bgColor={bgColor}
        >
          <SpecificationContainer.TableHeaderText>
            <b>Selling Details</b>
            <span className="edit_text" onClick={() => editTo()}>
              <img src="../assets/expense/edit.svg" alt="edit" />
              Edit
            </span>
          </SpecificationContainer.TableHeaderText>
        </SpecificationContainer.TableHeader>
      </SpecificationContainer.TableConatiner>
      {value?.["sale_date"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Selling Date
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {dateFormatDDMMYYYY(value?.["sale_date"])}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.["selling_price"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              <span>Selling Price</span>
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
            <BiRupee />{value?.["selling_price"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.["sold_to"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Sold to
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.["sold_to"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.["name_of_buyer"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Name of buyer
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.["name_of_buyer"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.["contact_of_buyer"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Contact of buyer
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.["contact_of_buyer"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.["financing_taken"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Financing Taken
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.["financing_taken"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.["enter_financier_name"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Financier name
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.["enter_financier_name"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {(value?.["specify_other"] && value?.["specify_other"] !== "") && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Other
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.["specify_other"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {value?.["enter_loan_amount"] !== 0 && value?.["enter_loan_amount"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              <span>Loan amount</span>
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
            <BiRupee />{value?.["enter_loan_amount"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {(value?.["loan_number"] !== "" && value?.["loan_number"]) && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              <span>Loan Number</span>
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
            {value?.["loan_number"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {(value?.["hpa_number"] !== "" && value?.["hpa_number"]) && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              <span>HPA Number</span>
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
            {value?.["hpa_number"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      {(value?.["hpa_date"] !== "" && value?.["hpa_date"]) && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              <span>HPA Date</span>
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
            {value?.["hpa_date"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}          
      {value?.["remarks"] && (
        <SpecificationContainer.TableConatiner>
          <SpecificationContainer.TableHeader fontSize={headerFontSize}>
            <SpecificationContainer.TableHeaderText>
              Remarks
            </SpecificationContainer.TableHeaderText>
          </SpecificationContainer.TableHeader>
          <SpecificationContainer.TableValues fontSize={valueFontSize}>
            <SpecificationContainer.TableValuesText>
              {value?.["remarks"]}
            </SpecificationContainer.TableValuesText>
          </SpecificationContainer.TableValues>
        </SpecificationContainer.TableConatiner>
      )}
      <SpecificationContainer.TableConatiner>
        <SpecificationContainer.TableHeader fontSize={headerFontSize}>
          <SpecificationContainer.TableHeaderText>
            Timestamp
          </SpecificationContainer.TableHeaderText>
        </SpecificationContainer.TableHeader>
        <SpecificationContainer.TableValues fontSize={valueFontSize}>
          <SpecificationContainer.TableValuesText>
            {dateTime_MWORD_AMPM(dateTime)}
          </SpecificationContainer.TableValuesText>
        </SpecificationContainer.TableValues>
      </SpecificationContainer.TableConatiner>
    </SpecificationContainer>
  );
}
