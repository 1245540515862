import Airtable from "airtable";
import store from "../store";


let url = new URL(window.location.href);
let baseUrl = url.origin;
let pathName = url.pathname
let baseID;


if(baseUrl === "https://production.d1kir5c0e4cr89.amplifyapp.com" || baseUrl === "https://etractorworld.com"){
  baseID = "appyndUA2QiIMMJJq"
}else{
  baseID = "appfTOfjqxfHNrr5G"
}

const initializeFaqBase = (baseId) => {
  return new Airtable({
    apiKey: process.env.REACT_APP_AIRTABLE_PRIVATE_KEY,
  }).base(baseId);
};

export let utFaqBase = initializeFaqBase('');

let faqBaseId = {
  "/faq": {
  EN:'app5zQajeMKp914TZ',
  HI:'app3FOvl4G9u0NFka'
},
"/nt-faq": {
  EN:'appgcGQRrsKFp0dRb',
  HI:'appOlVUXqY0j1GgqU'
}
}

if(faqBaseId[pathName]){
  store.subscribe(() => {
    const counterState = store.getState().faqLanguageReducer;
    utFaqBase = initializeFaqBase(faqBaseId[pathName][counterState.languageCode]);
  });  
}



export const base = new Airtable({
  apiKey: process.env.REACT_APP_AIRTABLE_PRIVATE_KEY,
}).base(baseID);


export const digitalBase = new Airtable({
  apiKey: "keyazRkTxiKMkxebe",
}).base("appGfgHtDxAfgz4vQ");

