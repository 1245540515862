import React from "react";
import { SlClose } from "react-icons/sl";
import { Container, Modal, CloseButton, Title, Image, Head, Main } from "./styles/previewModal";

export default function ModalWrapper({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

ModalWrapper.Head = function ModalWrapperHead({ children,closeModal,...props }){
  return <Head {...props}>{children}<SlClose className="close-icon" onClick={closeModal} /></Head>
}

ModalWrapper.Main = function ModalWrapperMain({ children,...props }){
  return <Main {...props}>{children}</Main>
}

ModalWrapper.Modal = function ModalWrapperModal({ children, ...props }) {
  return <Modal {...props}>{children}</Modal>;
};

ModalWrapper.CloseButton = function ModalWrapperCloseButton({
  children,
  ...props
}) {
  return <CloseButton {...props}>{children}</CloseButton>;
};

ModalWrapper.Title = function ModalWrapperTitle({children,...props}){
    return <Title {...props}>{children}</Title>
}

ModalWrapper.Image = function ModalWrapperImage({...props}){
    return <Image {...props} />
}