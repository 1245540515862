import * as types from "../types";

const initialState = {
    showFilter: false,
    userid: null,
    finalOption:null,
    filterOptions: {status:''},
    filteredData:[]
}

export default function expenseFilterReducer(state = initialState, action ){

    switch(action.type){

        case types.FILTER_EXPENSE_SHOW:
            return {
                ...state,
                userid: action.payload.userid,
                showFilter: action.payload.showFilter
            }

        case types.FILTER_EXPENSE_TYPE_DATA:
            return {
             ...state,
             filterOptions: {status:action.payload.filterOptions}
            }

        case types.FILTER_EXPENSE_FINAL_OPTION:
            return {
             ...state,
             finalOption: action.payload.finalOption
            }

        case types.FILTER_EXPENSE_SET_DATA:
            return {
             ...state,
             filteredData: action.payload.filteredData
            }

            
        case types.FILTER_EXPENSE_TYPE_RESET:
            return {
                ...state,
                showFilter:false,
                finalOption: null,
                filterOptions: {status:''},
                filteredData: []
            }
            
        default :
            return state
    }
}