import React from "react";
import { Container, Title, TitleWrapper, Image } from "./styles/main";

export default function Main({children,...props}){
    return <Container {...props}>{children}</Container>
}

Main.TitleWrapper = function MainTitleWrapper({children, ...props}){
    return <TitleWrapper {...props}>{children}</TitleWrapper>
}

Main.Title = function MainTitle({children, ...props}){
    return <Title {...props}>{children}</Title>
}

Main.Image = function MainIMage({...props}){
    return <Image {...props} />
}