import * as types from "../types";

const initialState = {
    loading: false,
    key: null,
    resFinList: null,
    error: null
}

export default function buySellFinanceListReducer(state = initialState, action){

    switch(action.type){
        case types.FETCH_BUYSELL_FINANCELIST_REQUEST:
            return {
                loading: true,
                key: action.payload.key
            }

        case types.FETCH_BUYSELL_FINANCELIST_SUCCESS:
            return {
                loading: false,
                resFinList: action.payload.resFinList
            }

        case types.FETCH_BUYSELL_FINANCELIST_FAILURE:
            return {
                loading: false,
                key : null,
                resFinList: null,
                error: action.payload.error
            }

        case types.FETCH_BUYSELL_FINANCELIST_RESET:
            return {
                loading: false,
                key: null,
                resFinList: null,
                error: null
            }

        default :
            return state;
    }

}