import * as types from "../types"

export const campaignInventoryRequest = ({reqCampaignInv}) => ({
    type: types.CAMPAIGN_INVENTORY_REQUEST,
    payload:{
        reqCampaignInv
    }
})

export const campaignInventorySuccess = ({resCampaignInvData}) =>({
    type: types.CAMPAIGN_INVENTORY_SUCCESS,
    payload:{
        resCampaignInvData
    }
})

export const campaignInventoryFailure = ({error}) => ({
    type: types.CAMPAIGN_INVENTORY_FAILURE,
    payload: {
        error: error.message
    }
})

export const campaignInventoryReset = () => ({
    type: types.CAMPAIGN_INVENTORY_RESET,
  });

export const campaignPagePosition = ({currentPagePosition}) => ({
    type: types.CAMPAIGN_PAGE_POSITION,
    payload:{
        currentPagePosition
    }
});

