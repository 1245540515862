import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import useFaqFetch from "../../utils/useFaqFetch";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import "../../index.css";
import Loader from "../../components/Loader/Loader";

const fadeIn = keyframes`
  0% {
    background: rgba(0, 0, 0, 0);
  }
  100% {
    background: rgba(0, 0, 0, 0.7);
  }
`;

const killShot = keyframes`
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(300px) rotate(45deg);
    opacity: 0;
  }
`;

const fadeToRed = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.6);
  }
  100% {
    background-color: rgba(255, 0, 0, 0.8);
  }
`;

const modalContentFadeIn = keyframes`
  0% {
    opacity: 0;
    top: -20px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
`;

const modalContentFadeOut = keyframes`
  0% {
    opacity: 1;
    top: 0px;
  }
  100% {
    opacity: 0;
    top: -20px;
  }
`;

const bondJamesBond = keyframes`
  0% {
    transform: translateX(1000px);
  }
  80% {
    transform: translateX(0px);
    border-radius: 75px;
    height: 75px;
    width: 75px;
  }
  90% {
    border-radius: 3px;
    height: 182px;
    width: 247px;
  }
  100% {
    border-radius: 3px;
    height: auto;
    width: 90%;
    max-width: 760px;
  }
`;

const slowFade = keyframes`
  0% {
    opacity: 1;
  }
  99.9% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: ${(props) => (props.showModal ? "scale(1)" : "scale(0)")};
  z-index: 1;

  &.seven {
    transform: scale(1);
    .modal-background {
      background: rgba(0, 0, 0, 0);
      animation: ${fadeIn} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      .modal {
        height: 75px;
        width: 75px;
        border-radius: 75px;
        overflow: auto;
        animation: ${bondJamesBond} 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        .close_button,
        .carousel_inner,
        .carousel_loader,
        h3,
        p {
          opacity: 0;
          position: relative;
          animation: ${modalContentFadeIn} 0.5s 1s linear forwards;
        }
      }
    }
  }  

    &.out {
      animation: ${slowFade} 0.5s 1.5s linear forwards;
      .modal-background {
        background-color: rgba(0, 0, 0, 0.7);
        animation: ${fadeToRed} 2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        .modal {
          border-radius: 3px;
          height: auto;
          width: 90%;
          max-width: 600px;
          animation: ${killShot} 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
          .close_button,
          .carousel_inner,
          .carousel_loader,
          h3,
          p {
            animation: ${modalContentFadeOut} 0.5s 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
          }
        }
      }
    }
  }

  .modal-background {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    text-align: center;
    height: 100%;
    width: 100%;

    .modal {
      background: white;
      border-radius: 10px;
      font-weight: 300;
      position: relative;
      max-height: 90vh;
      overflow-y: auto;

      h3 {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 15px;
      }

      p {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
`;

const StyledFaqModal = styled.div`
	background-color: #fff;
	padding: 20px;
	box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	width: 100%;
	height: auto;
	overflow: hidden;
	position: relative;
	opacity: 1;
`;

const StyledHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	color: ${({ theme }) => theme.primaryColor};
`;

const StyledCloseButton = styled.span`
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 50%;
	padding: 3px;
	margin: 5px 5px 0 0;
	background-color: rgba(0, 0, 0, 0.1);
`;

const StyledCarouselContainer = styled.div`
	display: flex;
`;

const fadeInCarousel = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOutCarousel = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
`;

const StyledCarouselItem = styled.div`
	flex: 0 0 auto;
	height: ${({ hasImage }) => (hasImage ? "auto" : "300px")};
	width: 100%;
	transition: transform 0.5s ease;
	transform-origin: center;

	&.active {
		opacity: 1;
		transform: scale(1);
		animation: ${fadeInCarousel} 0.7s ease-in-out forwards;
	}

	&:not(.active) {
		opacity: 0;
		transform: scale(0.8);
		animation: ${fadeOutCarousel} 0.7s ease-in-out forwards;
	}

	&:hover {
		transform: scale(1.05);
	}
`;

const StyledContentContainer = styled.div`
	height: auto;
	max-height: 400px;
	overflow-y: auto;
	padding: 10px;
	text-align: left;
`;

const PreStyled = styled.pre`
	white-space: pre-wrap;
	font-family: inherit;
	line-height: 1.5;
`;

const StyledImageWrapper = styled.div`
	width: 100%;
	height: ${({ hasImage }) => (hasImage ? "auto" : "0")};
	overflow: hidden;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: ${({ loaded }) =>
			loaded ? "transparent" : "rgba(0, 0, 0, 0.1)"};
		filter: blur(20px);
		transition: filter 0.5s ease-in-out, opacity 1.5s ease-in-out;
		z-index: 1;
		opacity: ${({ loaded }) => (loaded ? 1 : 0)};
		animation: fadeInOut 1.5s infinite alternate;
	}

	@keyframes fadeInOut {
		0%,
		100% {
			opacity: 0.5;
		}
		50% {
			opacity: 1;
		}
	}
`;

const StyledImage = styled.img`
	max-width: 100%;
	height: auto;
	max-height: 500px;
	object-fit: contain;
	border: 2px solid #000;
	border-radius: 8px;
	opacity: ${({ loaded }) => (loaded ? 1 : 0)};
	transition: opacity 0.5s ease-in-out;

	&:not([src]) {
		display: none;
	}
`;

export const StyledNavButton = styled.button`
	background: #fff;
	border: none;
	height: 30px;
	width: 30px;
	margin: 5px;
	cursor: pointer;
`;

// const StyledPrevButton = styled(StyledNavButton)`
// 	left: 0;
// `;

// const StyledNextButton = styled(StyledNavButton)`
// 	right: 0;
// `;

const ImageLoader = ({ src }) => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const img = new Image();
		img.src = src;
		img.onload = () => {
			setLoaded(true);
		};
	}, [src]);

	return (
		<StyledImageWrapper hasImage={Boolean(src)} loaded={loaded}>
			<StyledImage src={src} loaded={loaded} />
		</StyledImageWrapper>
	);
};

const FaqModal = ({ isOpen, setModalOpen, faqId, questionName }) => {
	const [faqQuestionDetails, faqQuestionDetailsFetch, faqQuestionDetailsError] =
		useFaqFetch({
			tableName: faqId,
			fields: [`content`, `image`],
			filterByFormula: "",
		});

	const [currentIndex, setCurrentIndex] = useState(0);
	const carouselRef = useRef(null);

	const nextSlide = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % faqQuestionDetails.length);
	};

	const prevSlide = () => {
		setCurrentIndex(
			(prevIndex) =>
				(prevIndex - 1 + faqQuestionDetails.length) % faqQuestionDetails.length
		);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	const handleOutsideClick = (event) => {
		if (event.target === event.currentTarget) {
			closeModal();
		}
	};

	return (
		<>
			<ModalContainer
				showModal={isOpen}
				className={isOpen ? "seven" : ""}
				onClick={handleOutsideClick}
			>
				<div className="modal-background">
					<div className="modal">
						<StyledHeader>
							<div></div>
							<StyledCloseButton className="close_button" onClick={closeModal}>
								<img
									src="../assets/close.svg"
									alt=""
									style={{ width: "20px", height: "20px" }}
								/>
							</StyledCloseButton>
						</StyledHeader>
						<p style={{ fontWeight: "bold", fontSize: "17px" }}>
							{questionName}
						</p>
						<StyledFaqModal>
							{faqQuestionDetailsFetch ? (
								<div className="carousel_loader">
									<Loader />
								</div>
							) : (
								<div className="carousel_inner">
									<StyledCarouselContainer
										ref={carouselRef}
										style={{
											transform: `translateX(-${currentIndex * 100}%)`,
										}}
									>
										{faqQuestionDetails?.map((faqQuestionDetail, index) => (
											<StyledCarouselItem
												className={`${index === currentIndex ? "active" : ""}`}
												key={`faqQuestionDetail_${index}`}
												hasImage={faqQuestionDetail?.[`image`]}
												visible={index === currentIndex} // Set visibility based on the current index
											>
												<ImageLoader
													src={faqQuestionDetail?.[`image`]?.[0]?.url}
												/>
												{faqQuestionDetails?.length < 2 && <br />}
												{faqQuestionDetails &&
													faqQuestionDetails?.length >= 2 &&
													(faqQuestionDetail?.[`image`]?.[0]?.url ||
														faqQuestionDetail?.[`content`]) && (
														<div
															style={{
																display: "flex",
																alignItems: "center",
																justifyContent: "center",
																padding: "10px",
																fontWeight: "bold",
															}}
														>
															<p>Total Pages: {faqQuestionDetails.length}</p>
															<StyledNavButton
																onClick={prevSlide}
																disabled={currentIndex === 0}
															>
																<BiChevronLeft
																	style={{ height: "30px", width: "30px" }}
																/>
															</StyledNavButton>
															{currentIndex + 1}
															<StyledNavButton
																onClick={nextSlide}
																disabled={
																	currentIndex + 1 ===
																	faqQuestionDetails?.length
																}
															>
																<BiChevronRight
																	style={{ height: "30px", width: "30px" }}
																/>
															</StyledNavButton>
														</div>
													)}
												{faqQuestionDetail?.[`content`] && (
													<StyledContentContainer className="scrollbar_visible">
														<PreStyled>
															{faqQuestionDetail?.[`content`]}
														</PreStyled>
													</StyledContentContainer>
												)}
											</StyledCarouselItem>
										))}
									</StyledCarouselContainer>
								</div>
							)}
						</StyledFaqModal>
					</div>
				</div>
			</ModalContainer>
		</>
	);
};

export default FaqModal;
