import { takeLatest, call, put } from "redux-saga/effects";
import {
  sendOtpSuccess,
  sendOtpFailure,
} from "../actions/sendOtpAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncSendOtpSaga(action) {
  try {
    yield put(clearErrors());
    let resOtp = yield call(_api.sendOtp, {
      requestOtp: action.payload.requestOtp,
    });
    yield put(sendOtpSuccess({ resOtp }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(sendOtpFailure({ error: e }));
  }
}
export default function* sendOtpSaga() {
  yield takeLatest(types.SEND_OTP_REQUEST, asyncSendOtpSaga);
}