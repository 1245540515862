import { useDispatch, useSelector } from "react-redux";
import {
  setInvSortReset
} from "../store/actions/invSortAction";
import { setInvFilteredArrayAction } from "../store/actions/invFilterAction";
import { ProductContext } from "./productContext";
import { useContext } from "react";

export const useInventorySort = () => {
  const dispatch = useDispatch();
  const filterReducerObj = useSelector((state) => state.invFilterReducer);
  let { filteredArray } = filterReducerObj;

  const {
    setSortShow
  } = useContext(ProductContext);

  const reset = () => {
    dispatch(setInvSortReset());
    setSortShow(false);
  };


  const applySorting = (dataArray, value) => {
    const criteriaMapping = {
      rating: "rating",
      price: "max_price",
      hours: "engine_hours",
    };

    const sortOrderMapping = {
      Low_to_high: 1,
      high_to_Low: -1,
    };

    let allsortted = [];
    //min_price engine_hours rating
    const sortCriteria = (value || '').split("_")[0];
    const sortDirection = (value || '').substr(-11);

    if (criteriaMapping[sortCriteria] && sortOrderMapping[sortDirection]) {
      const sortOrder = sortOrderMapping[sortDirection];
      const sortBy = criteriaMapping[sortCriteria];
      //console.log(sortOrder, sortBy, dataArray)
      allsortted = dataArray?.filter((item) => (item.status === 1 || item.status === 2 || item.status === 3))?.sort((a, b) => sortOrder * (a[sortBy] - b[sortBy]));
      //console.log("filteredArray",filteredArray,filterObj, allsortted)
      if(filteredArray.length > 0){
        dispatch(setInvFilteredArrayAction({filteredArray: allsortted}));
      }
    }
    return allsortted;
  };

  return [applySorting, reset];
};
