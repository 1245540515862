import { takeLatest, call, put } from "redux-saga/effects";
import {
  verifyOtpSuccess,
  verifyOtpFailure,
} from "../actions/verifyOtpAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncVerifyOtpSaga(action) {
  try {
    yield put(clearErrors());
    let resVerifyOtp = yield call(_api.verifyOtpData, {
      verifyOtp: action.payload.verifyOtp,
    });
    yield put(verifyOtpSuccess({ resVerifyOtp }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(verifyOtpFailure({ error: e }));
  }
}
export default function* verifyOtpSaga() {
  yield takeLatest(types.VERIFY_OTP_REQUEST, asyncVerifyOtpSaga);
}