import * as types from "../types";

const initialState = {
    loading: false,
    reqCampaignInv: null,
    currentPagePosition:1,
    resCampaignInvData: null,
    error: null
}

export default function campaignInventoryReducer(state = initialState, action){
    switch(action.type){
        case types.CAMPAIGN_INVENTORY_REQUEST:
            return {
                ...state,
                loading: true,
                reqCampaignInv: action.payload.reqCampaignInv
            }

        case types.CAMPAIGN_INVENTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                resCampaignInvData: action.payload.resCampaignInvData
        }

        case types.CAMPAIGN_INVENTORY_FAILURE:
            return {
                ...state,
                loading: false,
                reqCampaignInv: null,
                resCampaignInvData: null,
                currentPagePosition: 1,
                error: action.payload.error
        }

        case types.CAMPAIGN_INVENTORY_RESET:
            return {
                ...state,
                loading: false,
                reqCampaignInv: null,
                resCampaignInvData: null,
                currentPagePosition: 1,
                error: null
        }

        case types.CAMPAIGN_PAGE_POSITION:
            return {
                ...state,
                currentPagePosition: action.payload.currentPagePosition
        }
         
        default: 
            return state
    }

}