import * as types from "../types";

export const categoryRequest = () => ({
    type: types.FETCH_CATEGORY_REQUEST
})

export const categorySuccess = ({resCategory}) => ({
    type: types.FETCH_CATEGORY_SUCCESS,
    payload: {
        resCategory
    }
})