import React from "react";
import { Container, EachSlideEffect, Slides,BackgroundImage } from "./styles/slideShow";
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';


export default function SlideShow({children, ...props}){
    return <Container {...props}>{children}</Container>
} 

SlideShow.Slides = function SlideShowSlides({children,...props}){
    return <Slides {...props}><Slide indicators={true} duration={3000}>{children}</Slide></Slides>
}

SlideShow.EachSlideEffect = function SlideShowEachSlideEffect({children,...props}){
    return <EachSlideEffect {...props}>{children}</EachSlideEffect>
}

SlideShow.BackgroundImage = function SlideShowBackgroundImage({...props}){
    return <BackgroundImage {...props} />
}
