import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Poster from "../../components/poster";
import useFetch from "../../utils/useFetch";
import SlideShow from "../../components/slideShow";
import { useSelector } from "react-redux";
import { desktopView, mobileView } from "../viewType";

export default function PosterContainer() {

  const languageObj = useSelector(state => state.languageReducer);
  const { languageCode } = languageObj;
  const lang = languageCode.toUpperCase();

  const [posterData, posterFetching, posterError] = useFetch({
    tableName: "tractor",
    fields: [
      `Title_Poster(${lang})`,
      `Desktop_Title_Poster(${lang})`,
      'Dealer Location',
      'user_id'
  ],
    maxRecords: 30,
    filterByFormula: "",
  });


  return (
    // (posterFetching ? 
    //   <div style={{ display:'flex', alignItems: 'center', justifyContent: 'center' }}>
    //       <Loader />
    //   </div>
    //  : 
    // <Poster>
    //   {/* <Poster.Contact href={`tel: ${posterData[0].sampark_kare}`}>
    //     <Poster.Image src={posterData[0].Title_Poster[0].url} />
    //   </Poster.Contact> */}
    //   <Link to={`/aboutus`}>
    //   <Poster.Image src={posterData[1].Title_Poster[0].url} />
    //   </Link>
    // </Poster>
    (posterFetching ? 
      <div style={{ display:'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Loader />
      </div>
     : 
    <Poster>
      {/* <Poster.Contact href={`tel: ${posterData[0].sampark_kare}`}>
        <Poster.Image src={posterData[0].Title_Poster[0].url} />
      </Poster.Contact> */}

      {/* <Poster.Image src={posterData[0].Title_Poster[0].url} /> */}

      <SlideShow>
        <SlideShow.Slides>
        {posterData?.map((posterItem, index) => (
         (posterItem.user_id && posterItem["Dealer Location"] && posterItem.user_id !== "" && posterItem["Dealer Location"] !== "")  ? 
         posterItem[`Title_Poster(${lang})`]?.[0]?.url &&
         <Link to={`/aboutus/${posterItem["Dealer Location"]}?lang=${lang}`} key={index}>
          <SlideShow.EachSlideEffect>
            {(desktopView && posterItem[`Desktop_Title_Poster(${lang})`]?.[0]?.url) && <SlideShow.BackgroundImage src={posterItem[`Desktop_Title_Poster(${lang})`]?.[0]?.url} alt="" />}
            {(mobileView && posterItem[`Title_Poster(${lang})`]?.[0]?.url) && <SlideShow.BackgroundImage src={posterItem[`Title_Poster(${lang})`]?.[0]?.url} alt="" loading="lazy" />}            
          </SlideShow.EachSlideEffect>
        </Link> :
          posterItem[`Title_Poster(${lang})`]?.[0]?.url &&
          <SlideShow.EachSlideEffect  key={index}>
            {(desktopView && posterItem[`Desktop_Title_Poster(${lang})`]?.[0]?.url) && <SlideShow.BackgroundImage src={posterItem[`Desktop_Title_Poster(${lang})`]?.[0]?.url} alt="" />}
            {(mobileView && posterItem[`Title_Poster(${lang})`]?.[0]?.url) && <SlideShow.BackgroundImage src={posterItem[`Title_Poster(${lang})`]?.[0]?.url} alt="" loading="lazy" />}     
          </SlideShow.EachSlideEffect>
        ))}
        </SlideShow.Slides>
      </SlideShow>
    </Poster>

    )
  );
}