import { digitalBase } from "../config/airtable.config";
import { useState } from "react";

export const useCampaignPost = ({ tableName, fields }) => {
  const [submitting, setSubmitting] = useState(false);
  const [postError, setPostError] = useState(false);
  const [success, setSuccess] = useState(false);
  const postHandler = () => {
    setSubmitting(true);
    digitalBase(tableName).create(
      [
        {
          fields,
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err);
          setPostError(err);
          setSubmitting(false);
          return;
        }
        records.forEach(function (_record) {
          setSuccess(true);
          setSubmitting(false);
        });
      }
    );
  };
  return [postHandler, submitting, postError, success];
};