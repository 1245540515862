import React from "react";
import { Container, Image, Contact, GridContainer, CardImage } from "./styles/poster";

export default function Poster({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

Poster.Image = function PosterImage({ ...props }) {
  return <Image {...props} />;
};

Poster.CardImage = function PosterCardImage({ ...props }) {
  return <CardImage {...props} />;
};

Poster.GridContainer = function PosterGridContainer({ children, ...props }) {
  return <GridContainer {...props}>{children}</GridContainer>;
};

Poster.Contact = function PosterContact({ children, ...props }) {
  return <Contact {...props}>{children}</Contact>;
};