import React from "react";
import { ColorRing, InfinitySpin } from "react-loader-spinner";

export default function Loader() {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<ColorRing
				visible={true}
				height="40"
				width="40"
				ariaLabel="blocks-loading"
				wrapperStyle={{}}
				wrapperClass="blocks-wrapper"
				colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
			/>
		</div>
	);
}

export const InfinitySpinLoader = () => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<InfinitySpin
				visible={true}
				width="200"
				color="#f47e60"
				ariaLabel="infinity-spin-loading"
			/>
		</div>
	);
};
