import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchPricingOtherDetailSuccess,
  fetchPricingOtherDetailFailure,
} from "../actions/pricingOtherDetailsAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchPricingOtherDetailSaga(action) {
  try {
    yield put(clearErrors());
    let pricingOtherDetail = yield call(_api.fetchPricingOtherDetail, {
      requestPricingOtherDetail: action.payload.requestPricingOtherDetail,
    });
    yield put(fetchPricingOtherDetailSuccess({ pricingOtherDetail }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchPricingOtherDetailFailure({ error: e }));
  }
}
export default function* fetchPricingOtherDetailSaga() {
  yield takeLatest(types.FETCH_PRICING_OTHER_DETAILS_REQUEST, asyncFetchPricingOtherDetailSaga);
}