import React, { Fragment, useEffect, useState }  from "react";
import Swal from "sweetalert2";
import './pricingTool.css';
import Form from "../../components/form";
import Header from "../../components/header";
import { BiArrowBack } from 'react-icons/bi';
import { connect } from "react-redux";
import { fetchManufacturerStateRequest } from "../../store/actions/manufacturerStateAction";
import { fetchPricingOtherDetailRequest } from "../../store/actions/pricingOtherDetailsAction";
import { fetchPricingRequest, fetchPricingReset } from "../../store/actions/pricingDataAction";
import pricingToolYear from "./pricingToolYear.json";
import marginPercentage from "./marginPercentage.json";
import { FcInfo } from "react-icons/fc";
import { useQuery } from "../../utils/useQuery";
import { matchRange } from "../../utils/matchRange";

function PricingTool({...props}){
    const [manufacturer,setManufacturer]=useState([{
      label:"Select Manufacturer",
      value:"",
    }]);
    const [model,setModel]=useState([{
      label:"Select Model",
      value:"",
    }]);
    const [state,setState]=useState([{
      label:"Select State",
      value:"",
    }]);
    const [engineHours,setEngineHours]=useState([{
      label:"Select Engine Hours",
      value:"",
    }]);
    const [tyreHealth,setTyreHealth]=useState([{
      label:"Select Tyre Health",
      value:"",
    }]);

    const [selectedManufacturer,setSelectedManufacturer]=useState("");
    const [selectedState,setSelectedState]=useState("");
    const [selectedModel,setSelectedModel]=useState("");
    const [selectedEngineHours,setSelectedEngineHours]=useState("");
    const [selectedTyreHealth,setSelectedTyreHealth]=useState("");
    const [selectedModelYear,setSelectedModelYear]=useState("");
    const [otherCost,setOtherCost]=useState("");
    const [selectedMargin,setSelectedMargin]=useState("");

    const [selectedPriceType, setSelectedPriceType] = useState('selling');

    const queryParams = useQuery();
    const priceTypeQuery = queryParams.get("price_type");
    const manufacturerQuery = queryParams.get("manufacturer");
    const stateQuery = queryParams.get("state");
    const modelQuery = queryParams.get("model");
    const engineHoursQuery = queryParams.get("engine_hours");
    const yearQuery = queryParams.get("year");

    //android app buying price queryparams changes.start
    useEffect(() => {
        priceTypeQuery && setSelectedPriceType(priceTypeQuery);
        manufacturerQuery && setSelectedManufacturer(manufacturerQuery);
        stateQuery && setSelectedState(stateQuery);
        modelQuery && setSelectedModel(modelQuery);
        yearQuery && setSelectedModelYear(yearQuery);
    },[priceTypeQuery,manufacturerQuery,stateQuery,modelQuery,yearQuery])

    useEffect(() => {
      if((engineHoursQuery || engineHoursQuery === "") && engineHours.length > 0){
        let matchedEngineHoursRange = matchRange(engineHoursQuery, engineHours);
        setSelectedEngineHours(matchedEngineHoursRange);
      }
    },[engineHours, engineHoursQuery])
    //android app buying price queryparams changes.end

    useEffect(() =>{
      if(!priceTypeQuery){
        props.getManufacturerState();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() =>{
      if (props.manufacturerStateData && props.manufacturerStateData.status) {
      let list = props.manufacturerStateData.data["manufacturer"].map((item) => ({
        label: item.manufacturer,
        value: item.manufacturer,
      }));
      list.sort((a, b) => (a.value > b.value ? 1 : -1));
      let unqiueManufacturerTypes = [...new Set(list.map((a) => JSON.stringify(a)))].map((a) => JSON.parse(a));
      let manufacturerData=[...manufacturer,...unqiueManufacturerTypes];
      setManufacturer(manufacturerData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.manufacturerStateData]);

    useEffect(()=>{
      let stateList=[];
      if(selectedManufacturer !== "" && !priceTypeQuery){
        for(let i=0;i<props.manufacturerStateData.data["manufacturer"].length;i++){
          if(props.manufacturerStateData.data["manufacturer"][i]["manufacturer"] === selectedManufacturer){
            stateList.push({
              label: props.manufacturerStateData.data["state"][i]["state"],
              value: props.manufacturerStateData.data["state"][i]["state"],
            })
          }
        }
        stateList.sort((a, b) => (a.value > b.value ? 1 : -1));
        stateList.unshift({
          label:"Select State",
          value:"",
        })
        let stateData=[...stateList];
        setState(stateData);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedManufacturer]);

    useEffect(()=>{
      if(selectedManufacturer !== "" && selectedState !== ""){
            if(!priceTypeQuery){
              setModel([{
              label:"Select Model",
              value:"",
              }]);
              setTyreHealth([{
                label:"Select Tyre Health",
                value:"",
              }]);
            }
            setEngineHours([{
              label:"Select Engine Hours",
              value:"",
            }]);
            setOtherCost("");    
          let requestPricingOtherDetail={
          manufacturer : selectedManufacturer,
          state : selectedState
        };
        props.getPricingOtherDetail({requestPricingOtherDetail});
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedManufacturer,selectedState]);

    useEffect(()=>{
      if(props.pricingOthersData && props.pricingOthersData.status){
        if(!priceTypeQuery){
          let listModelData=props.pricingOthersData.data["models"].map((item) => ({
            label: item,
            value: item,
          }));
          let modelData=[...model,...listModelData];
          let listTyreHealthData=props.pricingOthersData.data["tyre_health"].map((item) => ({
            label: item,
            value: item,
          }));
          let tyreHealthData=[...tyreHealth,...listTyreHealthData];
          setModel(modelData);
          setTyreHealth(tyreHealthData);
        }
        let listEngineHoursData=props.pricingOthersData.data["engine_hours"].map((item) => ({
          label: item,
          value: item,
        }));
        let engineHoursData=[...engineHours,...listEngineHoursData];
        setEngineHours(engineHoursData);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.pricingOthersData])

    const sellingPrice=()=>{
      if(props.pricingData && props.pricingData.status){
        if(props.pricingData.data?.hasOwnProperty('expected_selling_price_min')){
          return `₹ ${props.pricingData.data?.["expected_selling_price_min"]} - ₹ ${props.pricingData.data?.["expected_selling_price_max"]}`
        }else{
          return `₹ ${props.pricingData.data?.["expected_selling_price"]}`
        }
      }
    }

    const buyingPrice=()=>{
      if(props.pricingData && props.pricingData.status){
        return `₹ ${props.pricingData.data["expected_buying_price_min"]} - ₹ ${props.pricingData.data["expected_buying_price_max"]}`
      }
    }

    const confidenceMeterImage=()=>{
      if(props.pricingData && props.pricingData.status){
        if(props.pricingData.data?.["confidence"].toLowerCase() === "high"){
          return "../assets/confidence_high.svg"
        }else if(props.pricingData.data?.["confidence"].toLowerCase() === "moderate"){
          return "../assets/confidence_medium.svg"
        }else if(props.pricingData.data?.["confidence"].toLowerCase() === "low"){
          return "../assets/confidence_low.svg"
        }else{
          return ""
        }
      }
    }

    useEffect(()=>{
      if(props.pricingData && props.pricingData.status && selectedPriceType === 'buying'){
        Swal.fire({
        html: `<div style="padding:10px;">
                  <span style="margin-bottom:5px;display:flex;align-items:center;font-weight:400;font-size:16px"><img src="../assets/Rupee.svg" alt=""/> Expected Buying Price</span>
                  <p style="font-weight:700;font-size:20px">${buyingPrice()}</p>
                </div>
                <div style="padding:10px;display:flex;align-items:center;justify-content:center;flex-direction:column;">
                  <img src=${confidenceMeterImage()} alt=""/>
                  <span style="font-weight:700;font-size:18px;color:#333333;text-align:center;">${props.pricingData.data?.["confidence"].toUpperCase()}</span>
                  <span style="font-weight:400;font-size:14px;color:#666666;">CONFIDENCE METER</span>
                </div>
                <div style="padding:10px;">
                  <p style="font-size:14px;display:flex;alignItems:center;">
                    <img src="https://cdn.iconscout.com/icon/premium/png-256-thumb/information-1534465-1300527.png?f=webp&w=128" alt="" width="20px" height="20px"/>Prices shown are inclusive of ${selectedMargin} margin
                  </p>
                </div>
                `,
          customClass: {
            container: "position-absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        confirmButtonColor: "#652078",
        toast: true,
        confirmButtonText: "Close",
        position: "bottom-center",
        }).then((res)=>{
          if(res){
            props.pricingReset();
            priceTypeQuery && handleRedirect();
          }
        });
      }if(props.pricingData && props.pricingData.status && selectedPriceType === 'selling'){
        Swal.fire({
        html: `<div style="padding:10px;">
                  <span style="margin-bottom:5px;display:flex;align-items:center;font-weight:400;font-size:16px"><img src="../assets/Rupee.svg" alt=""/> Expected Selling Price</span>
                  <p style="font-weight:700;font-size:20px">${sellingPrice()}</p>
                </div>
                <div style="padding:10px;display:flex;align-items:center;justify-content:center;flex-direction:column;">
                  <img src=${confidenceMeterImage()} alt=""/>
                  <span style="font-weight:700;font-size:18px;color:#333333;text-align:center;">${props.pricingData.data?.["confidence"].toUpperCase()}</span>
                  <span style="font-weight:400;font-size:14px;color:#666666;">CONFIDENCE METER</span>                
                </div>
                `,
          customClass: {
            container: "position-absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        confirmButtonColor: "#652078",
        toast: true,
        confirmButtonText: "Close",
        position: "bottom-center",
        }).then((res)=>{
          if(res){
            props.pricingReset();
          }
        });
      }else if(props.pricingData && !props.pricingData.status){
        Swal.fire({
      html: `<div style={{text-align:"center"}}>${props.pricingData.message}</div>`,
      customClass: {
        container: "position-absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      confirmButtonColor: "#652078",
      toast: true,
      confirmButtonText: "Close",
      position: "bottom-center",
    }).then((res)=>{
          if(res){
            // window.location.reload();
            props.pricingReset();
          }
      });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.pricingData])
    
    const handleSubmit=(e)=>{
      //submit
      
      e.preventDefault();
      if(selectedPriceType === "selling" && selectedManufacturer !== "" && selectedState !== "" && selectedModel !== "" 
          && selectedModelYear !== "" && selectedEngineHours !== ""){
            let requestPrice={
              "manufacturer": selectedManufacturer,
              "model": selectedModel,
              "model year": selectedModelYear,
              "state": selectedState,
              "engine hours": selectedEngineHours,
              "other_expected_costs": "0",
              "expected_tyre_cost": "0",
              "dealer_margin": "0%",
            };

          props.getPricing({requestPrice});
          }else if(selectedManufacturer !== "" && selectedState !== "" && selectedModel !== "" 
          && selectedModelYear !== "" && selectedEngineHours !== "" && selectedTyreHealth !== "" && otherCost !== "" && selectedMargin !== ""){
            let requestPrice={
              "manufacturer": selectedManufacturer,
              "model": selectedModel,
              "model year": selectedModelYear,
              "state": selectedState,
              "engine hours": selectedEngineHours,
              "other_expected_costs": otherCost,
              "expected_tyre_cost": selectedTyreHealth,
              "dealer_margin":selectedMargin
            };

          props.getPricing({requestPrice});
          }else{
            Swal.fire({
            icon: "info",
            text: "All Fields are Mandatory",
            confirmButtonColor: '#652078',
          })
        }
    }

    const handleInfoClick=()=>{
        Swal.fire({
          html: `<div style={{text-align:"center"}}>Like Documentation, RTO charges etc.</div>`,
          customClass: {
            container: "position-absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          confirmButtonColor: "#652078",
          toast: true,
          confirmButtonText: "Close",
          position: "bottom-center",
        })
    }

    const handleMarginInfoClick=()=>{
        Swal.fire({
          html: `<div style={{text-align:"center"}}>Desired % margin on cost price of the tractor</div>`,
          customClass: {
            container: "position-absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          confirmButtonColor: "#652078",
          toast: true,
          confirmButtonText: "Close",
          position: "bottom-center",
        })
    }

    const handleRedirect = () => {
      window.location.href =
        window.location.href + "&submit.jotform.com/submit";
    };

    const handlePriceType = (event) => {
      setSelectedPriceType(event.target.value);
      if(event.target.value === "buying"){
        setOtherCost("");
        setSelectedMargin("");
        setSelectedTyreHealth("");
      }
    };

    return (
        <>
        <Header>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:'5px',color:'#FFFF'}} onClick={handleRedirect}>
            <BiArrowBack style={{color:'#FFFF', height:'40px', width:'25px',marginLeft:"10px"}} />
            <p style={{fontSize:"16px",fontWeight:"700"}}>{priceTypeQuery ? "Check Buying Price" : "Pricing Tool"}</p>
            </div>
        </Header>
            <Form onSubmit={handleSubmit} style={{margin:"0px auto"}}>
                <Form.SectionContainer shadow={true}>
                  <Form.Section style={{padding:"20px 20px",display:"flex",justifyContent:"flex-start"}}>
                      {!priceTypeQuery && <div className="priceCategoryWrapper">
                        <p className="priceCategoryHeader">See Pricing of</p>
                        <div className="priceCategoryElement">
                          <div className={selectedPriceType === "selling" ? "selectedPrice":"commonSelected"}>
                            <input 
                              type="radio" 
                              value="selling"
                              checked={selectedPriceType === 'selling'}
                              onChange={handlePriceType} />
                            <label className="priceCategoryLabel">SELLING</label>
                          </div>
                          <div className={selectedPriceType === "buying" ? "selectedPrice":"commonSelected"}>
                            <input 
                              type="radio" 
                              value="buying"
                              checked={selectedPriceType === 'buying'}
                              onChange={handlePriceType} />
                            <label className="priceCategoryLabel">BUYING</label>
                          </div>
                        </div>
                      </div>}
                     {!priceTypeQuery && 
                     <Fragment> 
                      <Form.GroupFields>
                            <Form.Label htmlfor="manufacturer">
                              Manufacturer
                            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                            </Form.Label>
                              <div className="pricingFormWrapper">
                                <img src="../assets/manufacturer.svg" alt=""/>
                                <Form.PricingDropdown
                                  name="manufacturer"
                                  id="manufacturer"
                                  options={manufacturer}
                                onChange={(e) => setSelectedManufacturer(e.target.value)}
                                />
                              </div>
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="state">
                            State
                            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                            </Form.Label>
                            <div className="pricingFormWrapper">
                              <img src="../assets/state.svg" alt=""/>
                              <Form.PricingDropdown
                              name="state"
                              id="state"
                              options={state}
                              onChange={(e) => setSelectedState(e.target.value)}
                            />
                            </div>
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="model">
                            Model
                            <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                            </span>
                            </Form.Label>
                            <div className="pricingFormWrapper">
                              <img src="../assets/manufacturer.svg" alt=""/>
                              <Form.PricingDropdown
                              name="model"
                              id="model"
                              options={model}
                              onChange={(e) => setSelectedModel(e.target.value)}
                            />
                            </div>
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="model_year">
                            Model Year
                            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                            </Form.Label>
                            <div className="pricingFormWrapper">
                              <img src="../assets/year.svg" alt=""/>
                              <Form.PricingDropdown
                              name="model_year"
                              id="model_year"
                              options={pricingToolYear}
                              onChange={(e) => setSelectedModelYear(e.target.value)}
                            />
                            </div>
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <Form.Label htmlfor="engine_hours">
                            Engine Hours
                            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                            </Form.Label>
                            <div className="pricingFormWrapper">
                              <img src="../assets/engine_hours.svg" alt=""/>
                              <Form.PricingDropdown
                              name="engine_hours"
                              id="engine_hours"
                              options={engineHours}
                              onChange={(e) => setSelectedEngineHours(e.target.value)}
                            />
                            </div>
                    </Form.GroupFields>
                    </Fragment>}
                    {selectedPriceType === "buying" && (
                      <>
                        <Form.GroupFields>
                            <Form.Label htmlfor="tyre_health">
                            Tyre Cost
                            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                            </Form.Label>
                            <div className="pricingFormWrapper">
                              <img src="../assets/tyre_health.svg" alt=""/>
                              <Form.PricingInput
                              name="tyre_health"
                              id="tyre_health"
                              type="number"
                              placeholder="Tyre Cost"
                              onChange={(e) => setSelectedTyreHealth(e.target.value)}
                            />
                            </div>
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <div style={{display:"flex"}}>
                              <Form.Label htmlfor="other_expected_costs">
                                Other Expected Costs
                                <span style={{ color: "red", marginLeft: "2px", textAlign:"center" }}>*</span>
                              </Form.Label>
                              <FcInfo
                                style={{width: "20px", height: "20px" }}
                                onClick={handleInfoClick}/>
                              </div>
                            <div className="pricingFormWrapper">
                              <img src="../assets/other_cost.svg" alt=""/>
                              <Form.PricingInput
                              name="other_expected_costs"
                              id="other_expected_costs"
                              type="number"
                              placeholder="Other Costs"
                              onChange={(e) => setOtherCost(e.target.value)}
                            />
                            </div>
                    </Form.GroupFields>
                    <Form.GroupFields>
                            <div style={{display:"flex"}}>
                              <Form.Label htmlfor="margin_percentage">
                                Dealer Margin %
                                <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                              </Form.Label>
                              <FcInfo
                                style={{width: "20px", height: "20px" }}
                                onClick={handleMarginInfoClick}/>
                            </div>
                            <div className="pricingFormWrapper">
                              <Form.PricingDropdown
                              name="margin_percentage"
                              id="margin_percentage"
                              options={marginPercentage}
                              onChange={(e) => setSelectedMargin(e.target.value)}
                            />
                            </div>
                    </Form.GroupFields>
                      </>
                    )}
                  </Form.Section>
                  <Form.ButtonContainer>
                    {<Form.Button>{props.pricingDataLoading ? "SUBMITTING":"SUBMIT"}</Form.Button>}
                  </Form.ButtonContainer>
              </Form.SectionContainer>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
  manufacturerStateLoading: state.fetchManufacturerStateReducer.loading,
  manufacturerStateData: state.fetchManufacturerStateReducer.manufacturerState,
  pricingOthersData: state.fetchPricingOtherDetailReducer.pricingOtherDetail,
  pricingDataLoading: state.fetchPricingReducer.loading,
  pricingData: state.fetchPricingReducer.resPrice,
});

const mapDispatchToProps = (dispatch) => ({
  getManufacturerState:() => dispatch(fetchManufacturerStateRequest()),
  getPricingOtherDetail:({requestPricingOtherDetail})=>{
    dispatch(fetchPricingOtherDetailRequest({requestPricingOtherDetail}))
  },
  getPricing:({requestPrice})=>{
    dispatch(fetchPricingRequest({requestPrice}))
  },
  pricingReset:()=>{
    dispatch(fetchPricingReset())
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(PricingTool);
